import { combineReducers } from "redux";
import { loginReducer } from "./Login.reducer";
import { userReducer } from "./User.reducer";
import { floorReducer } from "./Floor.reducer";
import { clientReducer } from "./Client.reducer";
import { buildingReducer } from "./Building.reducer";
import { templateReducer } from "./Template.reducer";
import { doorReducer } from "./Door.reducer";
import { jobReducer } from "./Job.reducer";
import { partReducer } from "./Part.reducer";
import { maintenanceRequestReducer } from "./MaintenanceRequest.reducer";
import { pastInspectionReducer } from "./PastInspection.reducer";
import { scheduleReducer } from "./Schedule.reducer";
import { workerTaskReducer } from "./WorkerTask.reducer";
import { irommongeryReducer } from "./Ironmongery.reducer";
import { irommongeryPackReducer } from "./IronmongeryPack.reducer";
import { tierReducer } from "./Tier.reducer";
import { dashboardReducer } from "./dashboard.reducer";
import { subscriptionReducer } from "./Subscription.reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  floor: floorReducer,
  client: clientReducer,
  building: buildingReducer,
  template: templateReducer,
  door: doorReducer,
  job: jobReducer,
  part: partReducer,
  maintenanceRequest: maintenanceRequestReducer,
  pastInspection: pastInspectionReducer,
  schedule: scheduleReducer,
  workerTask: workerTaskReducer,
  ironmongery: irommongeryReducer,
  ironmongeryPack: irommongeryPackReducer,
  tier: tierReducer,
  dashboard: dashboardReducer,
  subscription: subscriptionReducer,
});

export default rootReducer;
