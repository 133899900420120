import { WORKER_SCHEDULED_TASK, WORKER_COMPLETED_TASK } from "../actions";

const initalState = {
  loading: false,
  scheduledTaskList: [],
  scheduledCount: 0,
  loadingCompletedTask: false,
  completedTaskList: [],
  completedCount: 0,
};

export function workerTaskReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case WORKER_SCHEDULED_TASK.LOADING:
      return {
        ...state,
        loading: true,
        scheduledTaskList: null,
      };
    case WORKER_SCHEDULED_TASK.SUCCESS:
      return {
        ...state,
        loading: false,
        scheduledTaskList: payload.rows,
        scheduledCount: payload.total,
      };
    case WORKER_SCHEDULED_TASK.ERROR:
      return {
        ...state,
        loading: false,
        scheduledTaskList: null,
      };

    case WORKER_COMPLETED_TASK.LOADING:
      return { ...state, loadingCompletedTask: true, completedTaskList: null };
    case WORKER_COMPLETED_TASK.SUCCESS:
      return {
        ...state,
        loadingCompletedTask: false,
        completedTaskList: payload.rows,
        completedCount: payload.total,
      };
    case WORKER_COMPLETED_TASK.ERROR:
      return { ...state, loadingCompletedTask: false, completedTaskList: null };
    default:
      return state;
  }
}
