import { api, APIS, TABLE_LIMIT } from "../config";
import { PAST_INSPECTION, PAST_INSPECTION_DETAIL } from "./ActionTypes.action";

export function getPastInspectionAction(partId, page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: PAST_INSPECTION.LOADING });
      res = await api(
        `${APIS.pastInspection}/${partId}?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: PAST_INSPECTION.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: PAST_INSPECTION.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: PAST_INSPECTION.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getPastInspectionDetailAction(doorPartId, from) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: PAST_INSPECTION_DETAIL.LOADING });
      res = await api(
        `${APIS.pastInspectionDetail}/${doorPartId}?from=${from}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: PAST_INSPECTION_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: PAST_INSPECTION_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: PAST_INSPECTION_DETAIL.ERROR });

      console.error(message);
      return 0;
    }
  };
}
