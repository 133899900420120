import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { useDocTitle } from "../../../../../hooks";
import { DefaultButton, Box } from "../../../../common";
import Table from "../../../../common/table/Table.common";
import { Bold, Chip, Image, Paragraph } from "../../../../generics";
import {
  buildingOwnershipRequestedForMeAction,
  buildingOwnershipResponseAction,
} from "../../../../../actions";
import { FILE_URL } from "../../../../../config";

// IMPORT HOCS
import { CompWrapper, Card } from "../../../../hoc";

export const RequestForMePage = () => {
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  useDocTitle("Client");

  const dispatch = useDispatch();
  const {
    ownershipRequestsforMeListloading,
    ownershipRequestsforMeList,
    ownershipRequestsforMeTotalCount,
    ownershipRequestsforMeListisLast,
    responseLoading,
  } = useSelector((state) => state.building);

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(buildingOwnershipRequestedForMeAction(pageNumber));
  }, [pageNumber]);

  const viewHandler = (id) => {
    navigate(routes["Transfer Requests"].path + "/view/" + id);
  };
  const responseHandler = (item, response) => {
    const body = {
      status: response === "accept" ? true : false,
    };
    dispatch(buildingOwnershipResponseAction(item?.id, body, toast));
  };

  return (
    <Box mt={20}>
      <Table
        columns={[
          {
            name: "Photo",
            field: "site_details",
            render: (rowData) =>
              rowData && (
                <Image
                  alt="Avatar"
                  link={`${FILE_URL}/site/${rowData.prop_image[0]}`}
                  style={{ height: 60, width: 60, borderRadius: 50 }}
                />
              ),
          },

          {
            field: "site_details",
            name: "name",
            render: (rowData) =>
              rowData && <Paragraph>{rowData?.prop_name}</Paragraph>,
          },

          {
            field: "company_details",
            name: "owner",
            render: (rowData) =>
              rowData && <Paragraph>{rowData?.cont_comp_name}</Paragraph>,
          },
          {
            field: "company_details",
            name: "phone",
            render: (rowData) =>
              rowData && (
                <Paragraph>{rowData?.cont_comp_phone.join(", ")}</Paragraph>
              ),
          },
          {
            field: "status",
            name: "Status",
            render: (rowData) =>
              typeof rowData === "object" ? (
                <Chip style={{ backgroundColor: "#ffc107" }}>Pending</Chip>
              ) : !!rowData ? (
                <Chip style={{ backgroundColor: "#28a745" }}>Accepted</Chip>
              ) : (
                <Chip style={{ backgroundColor: "#ff4343" }}>Rejected</Chip>
              ),
          },
        ]}
        data={ownershipRequestsforMeList}
        dataLoader={ownershipRequestsforMeListloading}
        totalCount={ownershipRequestsforMeTotalCount}
        actions
        onViewHandler={viewHandler}
        onResponseHandler={responseHandler}
        responseLoading={responseLoading}
      />
    </Box>
  );
};
