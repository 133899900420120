import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const selectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 4,
    borderColor: isFocused ? "#0284a4" : "#E1E1E1",
    backgroundColor: "#F8F8F8",
    boxShadow: isFocused && "none",
    "&:hover": { borderColor: "#0284a4" },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#0284a4" : "#fff",
      "&:hover": { backgroundColor: isSelected ? "#0284a4" : "#e9e9e9" },
    };
  },
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
};

const selectBorderlessStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 4,
    borderColor: "transparent",
    backgroundColor: "#fafafa",
    boxShadow: isFocused && "none",
    "&:hover": { borderColor: "transparent" },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#0284a4" : "#fff",
      "&:hover": { backgroundColor: isSelected ? "#0284a4" : "#e9e9e9" },
    };
  },
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
};

export const SelectField = ({
  options,
  formatGroupLabel,
  onChangeValue,
  getOptionLabel = "label",
  getOptionValue = "id",
  isSearchable,
  isClearable,
  placeholder,
  isLoading,
  defaultValue,
  isMulti,
  value,
  isOptionDisabled,
  formatOptionLabel,
  instanceId = "react-select",
}) => {
  let optionLabel;
  if (typeof getOptionLabel === "string") {
    optionLabel = (option) => `${option[getOptionLabel]}`;
  } else if (typeof getOptionLabel === "function") {
    optionLabel = getOptionLabel;
  }

  let optionValue;
  if (typeof getOptionValue === "string") {
    optionValue = (option) => `${option[getOptionValue]}`;
  } else if (typeof getOptionValue === "function") {
    optionValue = getOptionValue;
  }

  return (
    <div>
      <Select
        formatOptionLabel={formatOptionLabel}
        isOptionDisabled={isOptionDisabled}
        isMulti={isMulti}
        instanceId={instanceId}
        className="selectfield"
        classNamePrefix="react-select"
        styles={selectStyles}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isLoading={isLoading}
        onChange={onChangeValue}
        getOptionLabel={optionLabel}
        getOptionValue={optionValue}
        options={options}
        formatGroupLabel={formatGroupLabel}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
      />
    </div>
  );
};

export const SelectFieldBorderless = ({
  instanceId = "react-select",
  options,
  formatGroupLabel,
  onChangeValue,
  getOptionLabel = "label",
  getOptionValue = "id",
  isSearchable,
  isClearable,
  placeholder,
  isLoading,
  defaultValue,
  isMulti,
  value,
  isOptionDisabled,
}) => {
  let optionLabel;
  if (typeof getOptionLabel === "string") {
    optionLabel = (option) => `${option[getOptionLabel]}`;
  } else if (typeof getOptionLabel === "function") {
    optionLabel = getOptionLabel;
  }

  let optionValue;
  if (typeof getOptionValue === "string") {
    optionValue = (option) => `${option[getOptionValue]}`;
  } else if (typeof getOptionValue === "function") {
    optionValue = getOptionValue;
  }

  return (
    <div>
      <Select
        isOptionDisabled={isOptionDisabled}
        isMulti={isMulti}
        instanceId={instanceId}
        className="selectfield"
        classNamePrefix="react-select"
        styles={selectBorderlessStyles}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isLoading={isLoading}
        onChange={onChangeValue}
        getOptionLabel={optionLabel}
        getOptionValue={optionValue}
        options={options}
        formatGroupLabel={formatGroupLabel}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
      />
    </div>
  );
};

SelectField.propTypes = {
  data: PropTypes.any,
  isViewing: PropTypes.bool,
  getOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  getOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
};

SelectField.defaultProps = {
  getOptionLabel: "label",
  getOptionValue: "id",
};

SelectFieldBorderless.propTypes = {
  data: PropTypes.any,
  isViewing: PropTypes.bool,
  getOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  getOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
};

SelectFieldBorderless.defaultProps = {
  getOptionLabel: "label",
  getOptionValue: "id",
};
