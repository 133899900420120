import React, { useEffect, useState } from "react";
import { CompWrapper, Card, ActivityIndicator } from "../../../../hoc";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  KeyValue,
  DefaultButton,
  Grid,
  PrimaryButton,
  GhostButton,
  ConfirmationModal,
} from "../../../../common";
import { Bold, Chip, Image, Label, Paragraph } from "../../../../generics";
import {
  buildingOwnershipRequestedDetailAction,
  buildingOwnershipResponseAction,
  BUILDING_OWNERSHIP_REQUESTED_DETAIL,
  getBuildingDetailAction,
} from "../../../../../actions";
import { FILE_URL } from "../../../../../config";
import { Modal } from "react-uicomp";

export const RequestDetailPage = () => {
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const {
    requestedDetail,
    requestedDetailLoading,
    responseLoading,
  } = useSelector((state) => state.building);

  const {
    user: {
      data: { company_details },
    },
  } = useSelector((state) => state.user);

  const { navigation, params } = useNavigation();

  useEffect(() => {
    dispatch(buildingOwnershipRequestedDetailAction(params?.id));

    return () => dispatch({ type: BUILDING_OWNERSHIP_REQUESTED_DETAIL.CLEAR });
  }, [params?.id]);

  const onResponse = (response, callback) => {
    const body = {
      status: response,
    };
    dispatch(
      buildingOwnershipResponseAction(params?.id, body, toast, callback),
    );
  };
  return (
    <CompWrapper>
      {requestedDetail && (
        <Box flexBox vertical style={{ rowGap: 20 }}>
          <Card>
            <Box className={"contractor-wrapper"} flexBox jSpace>
              <KeyValue
                data={{
                  Name: requestedDetail?.site_details?.prop_name,
                  Type: requestedDetail?.site_details?.prop_type,
                  Client: requestedDetail?.site_details?.prop_construction,
                  Address: requestedDetail?.site_details?.prop_address?.value,
                  Floors: requestedDetail?.site_details?.floors,
                  Stairs: requestedDetail?.site_details?.stairs,
                }}
              />
              {typeof requestedDetail?.status === "object" ? (
                company_details?.id ===
                  requestedDetail?.to_company_details?.id && (
                  <Box flexBox row style={{ columnGap: 20 }}>
                    <ConfirmationModal
                      displayElement={<PrimaryButton title="Accept" />}
                      label="Are you sure you want to Accept?"
                      onConfirmClick={(callback) => {
                        onResponse(true, () => callback());
                      }}
                      onCancelClick={() => {
                        console.log("Canceled");
                      }}
                      confirmLabel="Accept"
                      cancelLabel="Cancel"
                      loading={responseLoading}
                    />

                    <ConfirmationModal
                      displayElement={
                        <DefaultButton
                          style={{
                            background: "#ff4343",
                            color: "white",
                          }}
                          title="Reject"
                        />
                      }
                      label="Are you sure you want to Reject?"
                      onConfirmClick={(callback) => {
                        onResponse(false, () => callback());
                      }}
                      onCancelClick={() => {
                        console.log("Canceled");
                      }}
                      confirmLabel="Reject"
                      cancelLabel="Cancel"
                      loading={responseLoading}
                      danger
                    />
                  </Box>
                )
              ) : !!requestedDetail?.status ? (
                <Paragraph>
                  <Chip style={{ backgroundColor: "#28a745" }}>
                    Ownership Transfered
                  </Chip>
                </Paragraph>
              ) : (
                <Paragraph>
                  <Chip style={{ backgroundColor: "#ff4343" }}>
                    Ownership Transfer Request Rejected
                  </Chip>
                </Paragraph>
              )}
            </Box>
            <Grid.Container lg={4} md={2} sm={1}>
              {requestedDetail?.site_details?.prop_image?.length
                ? requestedDetail?.site_details?.prop_image?.map(
                    (item, index) => {
                      return (
                        <Box key={index}>
                          <Image
                            style={{
                              width: "100%",
                              maxHeight: 200,
                              objectFit: "contain",
                            }}
                            link={`${FILE_URL}/site/${item}`}
                            alt="Contractor image"
                            className="avatar"
                          />
                        </Box>
                      );
                    },
                  )
                : null}
            </Grid.Container>
          </Card>

          <Box flexBox jSpace style={{ columnGap: 20 }}>
            <Card>
              <Bold> Requested by </Bold>
              <Box className={"contractor-wrapper"} flexBox jSpace>
                <KeyValue
                  data={{
                    Name: requestedDetail?.from_company_details.cont_comp_name,
                    Email:
                      requestedDetail?.from_company_details.cont_comp_email,
                    Address:
                      requestedDetail?.from_company_details.cont_comp_address
                        .value,
                    Phone: requestedDetail?.from_company_details.cont_comp_phone.join(
                      ",",
                    ),
                  }}
                />

                <Box>
                  <Image
                    style={{ height: 100, maxWidth: 100 }}
                    // style={{ width: "100%" }}
                    link={`${FILE_URL}/contractor/${requestedDetail?.from_company_details?.cont_comp_logo}`}
                    alt="Contractor image"
                    className="avatar"
                  />
                </Box>
              </Box>
            </Card>
            <Card>
              <Bold>Current Ownership</Bold>
              <Box className={"contractor-wrapper"} flexBox jSpace>
                <KeyValue
                  data={{
                    Name: requestedDetail?.to_company_details.cont_comp_name,
                    Email: requestedDetail?.to_company_details.cont_comp_email,
                    Address:
                      requestedDetail?.to_company_details.cont_comp_address
                        .value,
                    Phone: requestedDetail?.to_company_details.cont_comp_phone.join(
                      ",",
                    ),
                  }}
                />

                <Box>
                  <Image
                    style={{ height: 100, maxWidth: 100 }}
                    link={`${FILE_URL}/contractor/${requestedDetail?.to_company_details?.cont_comp_logo}`}
                    alt="Contractor image"
                    className="avatar"
                  />
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      )}
    </CompWrapper>
  );
};
