import { api, APIS, TABLE_LIMIT } from "../config";
import {
  SUPERADMIN_DASHBOARD,
  SUPERADMIN_PREVIOUOS_DASHBOARD,
} from "./ActionTypes.action";

//GET SCHEDULED TASK
export function getDashboardSuperadmin() {
  return async function (dispatch) {
    let res;
    try {
      res = await api(APIS.dashboard);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: SUPERADMIN_DASHBOARD.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: SUPERADMIN_DASHBOARD.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: SUPERADMIN_DASHBOARD.ERROR });
    }
  };
}

export function getDashboardPrevDataAction() {
  return async function (dispatch) {
    let res;
    try {
      res = await api(`${APIS.dashboard}?isPrev=true`);
      const { success, data } = res.data;
      if (success) {
        dispatch({
          type: SUPERADMIN_PREVIOUOS_DASHBOARD.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: SUPERADMIN_PREVIOUOS_DASHBOARD.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: SUPERADMIN_PREVIOUOS_DASHBOARD.ERROR });
    }
  };
}
