import { api, APIS, TABLE_LIMIT } from "../config";
import {
  DOORMAPPED,
  ADD_JOB,
  GET_JOB_LIST,
  PLOT,
  JOB_DETAIL,
  DELETE_JOB_PART,
  EDIT_JOB,
  UPDATE_WORKER,
  GET_DOOR_PART_JOB_DOOR,
  GET_APP_ATTACHMENTS,
  GET_JOB_LOGS,
  UPLOAD_ATTACHMENTS,
} from "./ActionTypes.action";

// export function postDoorAction(body, callback, toast) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: ADD_DOOR.LOADING });
//       res = await api(APIS.door, "POST", body);

//       const { success, data } = res.data;

//       if (success) {
//         dispatch({ type: ADD_DOOR.SUCCESS });
//         callback && callback();
//         toast({
//           message: data.message,
//           type: "success",
//         });
//       } else {
//         dispatch({ type: ADD_DOOR.ERROR });
//         toast({
//           message: data.message,
//           type: "error",
//         });
//       }
//     } catch ({ message }) {
//       dispatch({ type: ADD_DOOR.ERROR });
//       toast({
//         message: `Image upload Failed!!`,
//         type: "error",
//       });
//       console.error(message);
//       return 0;
//     }
//   };
// }

// export function editDoorAction(DoorId, body, callback, toast) {
//   return async function (dispatch) {
//     console.log("edit");
//     let res;
//     try {
//       dispatch({ type: EDIT_DOOR.LOADING });
//       res = await api(`${APIS.door}/${DoorId}`, "PATCH", body);

//       const { success, data } = res.data;

//       if (success) {
//         dispatch({ type: EDIT_DOOR.SUCCESS });
//         callback && callback();
//         toast({
//           message: data.message,
//           type: "success",
//         });
//       } else {
//         dispatch({ type: EDIT_DOOR.ERROR });
//         toast({
//           message: data.message,
//           type: "error",
//         });
//       }
//     } catch ({ message }) {
//       dispatch({ type: EDIT_DOOR.ERROR });
//       toast({
//         message: `Image upload Failed!!`,
//         type: "error",
//       });
//       console.error(message);
//       return 0;
//     }
//   };
// }

export function getJobList(
  page = 1,
  // status = "all",
  job_type,
  job_status,
  searchText,
  startTime,
) {
  return async function (dispatch) {
    let res;

    let filter = "";
    if (!!searchText) {
      filter += `&text=${searchText}`;
    }
    if (!!job_status) {
      filter += `&job_status=${job_status}`;
    }

    if (!!job_type) {
      filter += `&job_type=${job_type}`;
    }
    if (!!startTime) {
      filter += `&start_time=${startTime}`;
    }

    try {
      dispatch({ type: GET_JOB_LIST.LOADING });
      res = await api(
        `${"/dashboard/job/search"}?page=${page}&limit=9${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_JOB_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_JOB_LIST.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: GET_JOB_LIST.ERROR });
      return 0;
    }
  };
}

// export function getDoorDetailAction(id = 1) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: DOOR_DETAIL.LOADING });
//       res = await api(`${APIS.door}/${id}`);

//       const { success, data } = res.data;

//       if (success) {
//         dispatch({ type: DOOR_DETAIL.SUCCESS, payload: data.data });
//       } else {
//         dispatch({ type: DOOR_DETAIL.ERROR });
//       }
//     } catch ({ message }) {
//       dispatch({ type: DOOR_DETAIL.ERROR });
//       console.error(message);
//       return 0;
//     }
//   };
// }

export function getDoorWithMap(DoorId) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: DOORMAPPED.LOADING });

      res = await api(`${APIS.door}/floor/map/${DoorId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DOORMAPPED.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: DOORMAPPED.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: DOORMAPPED.ERROR });
      return 0;
    }
  };
}

export function plotAction(body, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: PLOT.LOADING });
      res = await api(`${APIS.door}/10`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: PLOT.SUCCESS });
        // dispatch(getDoorPlan());
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: PLOT.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: PLOT.ERROR });
      toast({
        message: `Plot Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function addJobAction(formData, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_JOB.LOADING });
      res = await api(APIS.job, "POST", formData, { file: true });
      const { success, data, message } = res.data;
      if (success) {
        dispatch({ type: ADD_JOB.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: ADD_JOB.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_JOB.ERROR });
      toast({
        message: `Error!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

// export function editJobAction(id, formData, callback, toast) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: EDIT_JOB.LOADING });
//       res = await api(`${APIS.job}/${id}`, "PATCH", formData, { file: true });
//       const { success, data } = res.data;
//       if (success) {
//         dispatch({ type: EDIT_JOB.SUCCESS });
//         callback && callback();
//         toast({
//           message: data.message,
//           type: "success",
//         });
//       } else {
//         dispatch({ type: EDIT_JOB.ERROR });
//         toast({
//           message: data.message,
//           type: "error",
//         });
//       }
//     } catch ({ message }) {
//       dispatch({ type: EDIT_JOB.ERROR });
//       toast({
//         message: `Error!!`,
//         type: "error",
//       });
//       console.error(message);
//       return 0;
//     }
//   };
// }

export function editJobAction(
  id,
  formData,
  workerBody,
  // newPartBody,
  callback,
  toast,
) {
  return async function (dispatch) {
    let res;
    dispatch({ type: EDIT_JOB.LOADING });

    const infoPromise = new Promise(async (resolve, reject) => {
      if (true) {
        try {
          let res = await api(`${APIS.job}/${id}`, "PATCH", formData, {
            file: true,
          });
          const { success, message } = res.data;
          if (success) {
            resolve();
          } else {
            toast({
              message: message,
              type: "error",
            });
          }
        } catch (e) {
          console.log(e);
          reject();
        }
      } else {
        resolve();
      }
    });

    const workerPromise = new Promise(async (resolve, reject) => {
      if (Object.keys(workerBody).length > 0) {
        try {
          let res = await api(`${APIS.workerTask}/${id}`, "PATCH", workerBody);
          const { success, message } = res.data;
          if (success) {
            resolve();
          } else {
            toast({
              message: message,
              type: "error",
            });
          }
        } catch (e) {
          reject();
        }
      } else {
        resolve();
      }
    });

    // const partPromise = new Promise(async (resolve, reject) => {
    //   if (Object.keys(newPartBody).length > 0) {
    //     try {
    //       let res = await api(`/job/part/${id}`, "PATCH", newPartBody);
    //       const { success, message } = res.data;
    //       if (success) {
    //         resolve();
    //       } else {
    //         toast({
    //           message: message,
    //           type: "error",
    //         });
    //       }
    //     } catch (e) {
    //       reject();
    //     }
    //   } else {
    //     resolve();
    //   }
    // });

    Promise.all([infoPromise, workerPromise]).then(async () => {
      dispatch({ type: EDIT_JOB.SUCCESS });
      toast({
        message: "Job Successfully edited",
        type: "success",
      });
      callback && callback();
    });
  };
}

// upload attachments
export function uploadAttachments(id, formData, successCb, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: UPLOAD_ATTACHMENTS.LOADING });
      res = await api(`${APIS.job}/${id}`, "PATCH", formData, { file: true });
      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: UPLOAD_ATTACHMENTS.SUCCESS });
        successCb && successCb();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: UPLOAD_ATTACHMENTS.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: UPLOAD_ATTACHMENTS.ERROR });
      toast({
        message: `Error!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function getJobDelailByIdAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: JOB_DETAIL.LOADING });
      res = await api(`${APIS.job}/${id}`);
      const { success, data } = res.data;

      if (success) {
        dispatch({ type: JOB_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: JOB_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: JOB_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function deleteJobPartByIdAction(id, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_JOB_PART.LOADING });
      res = await api(`${APIS.job}/jobPart/${id}`, "DELETE");
      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DELETE_JOB_PART.SUCCESS, payload: id });
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
      } else {
        dispatch({ type: DELETE_JOB_PART.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_JOB_PART.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function updateJobWorkerAction(id, body, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: UPDATE_WORKER.LOADING });
      res = await api(`${APIS.workerTask}/${id}`, "PATCH", body);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: UPDATE_WORKER.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: UPDATE_WORKER.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: UPDATE_WORKER.ERROR });
      toast({
        message: `Error!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function getDoorPartByJobDoor(jobId, doorId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_DOOR_PART_JOB_DOOR.LOADING });
      res = await api(`${APIS.doorPart}/job/${jobId}?door=${doorId}`);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: GET_DOOR_PART_JOB_DOOR.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_DOOR_PART_JOB_DOOR.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_DOOR_PART_JOB_DOOR.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getAppAttachments(jobId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_APP_ATTACHMENTS.LOADING });
      res = await api(`${APIS.response}/attach/${jobId}`);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: GET_APP_ATTACHMENTS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_APP_ATTACHMENTS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_APP_ATTACHMENTS.ERROR });
      console.error(message);
      return 0;
    }
  };
}

// ACTIVITY LOG OF JOB
export function getLogOfJob(jobId, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_JOB_LOGS.LOADING });

      if (page === 1) {
        dispatch({ type: GET_JOB_LOGS.CLEAR });
      }

      res = await api(
        `${APIS.activity}/job/${jobId}?page=${page}&limit=${TABLE_LIMIT}`,
      );
      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_JOB_LOGS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_JOB_LOGS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_JOB_LOGS.ERROR });
      console.error(message);
      return 0;
    }
  };
}
