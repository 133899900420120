import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigation } from "react-auth-navigation";
import { AiFillFile } from "react-icons/all";
import moment from "moment";

import { getComplain } from "../../../../actions";
import { KeyValue, Box } from "../../../common";
import { Paragraph, Title } from "../../../generics";
import { CompWrapper, Card } from "../../../hoc";
import { FILE_URL } from "../../../../config";

// temp
const ATTACHMENTS = ["1.jpg", "sample.xd", "tempFile.png"];

const getJobType = (item) => {
  switch (item) {
    case "MAINTAIN_INSPEC":
      return "Maintenance (Repair)";
    case "MAINTAIN_INSTALL":
      return "Maintenance (Install)";

    case "INSPECTION":
      return "Inspection";

    default:
      return "Installation";
  }
};

const mapStateToProps = (state) => {
  return {
    maintenanceRequest: state.maintenanceRequest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getComplain,
    },
    dispatch,
  );
};

export const ViewMaintenanceRequestPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const { getComplain, maintenanceRequest } = props;
  const { complainLoader, complain } = maintenanceRequest;
  const { params } = useNavigation();

  const { id } = params;

  useEffect(() => {
    getComplain(id);
  }, [id, getComplain]);

  return (
    <CompWrapper>
      {complainLoader ? (
        <Box>Loading...</Box>
      ) : (
        complain && (
          <>
            <Title pb={20}>Details</Title>
            <Card>
              <KeyValue
                data={{
                  "Requested Date": moment(complain?.complain_date).format(
                    "Do MMM, YYYY",
                  ),
                  Remarks: complain?.complain_remarks,
                  Suggestion: getJobType(complain?.suggested_type),
                }}
              />
            </Card>

            <Title mt={20} pb={20}>
              Attachments
            </Title>
            <Card>
              <div className="mr-attachment-container">
                {complain?.complain_attachment?.length ? (
                  complain?.complain_attachment.map((file, index) => {
                    return (
                      <a
                        key={index}
                        target="_blank"
                        href={`${FILE_URL}/complain/${file}`}
                        className="mr-attachment">
                        <div style={{ marginRight: 10 }}>
                          <AiFillFile />
                        </div>
                        <div>{file}</div>
                      </a>
                    );
                  })
                ) : (
                  <Box>
                    <Paragraph>Attachment is not Available</Paragraph>
                  </Box>
                )}
              </div>
            </Card>
          </>
        )
      )}
    </CompWrapper>
  );
});
