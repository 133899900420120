import React, { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { getTierById, GET_TIER_DETAIL } from "../../../../actions";
import { useDocTitle } from "../../../../hooks";
import { Box } from "../../../common";

import { Bold, Image, Paragraph } from "../../../generics";

// IMPORT HOCS
import { CompWrapper, Card } from "../../../hoc";
import { AddFormComponent } from "../component";

export const EditTierPage = () => {
  const { params } = useNavigation();
  const { id } = params;
  const dispatch = useDispatch();
  const { tierDetail, tierDetailLoader } = useSelector((state) => state.tier);
  useDocTitle("Tier");

  useEffect(() => {
    dispatch(getTierById(id));
    return () => {
      dispatch({ type: GET_TIER_DETAIL.LOADING });
    };
  }, [id]);

  console.log("tierDetail>>", tierDetail);
  return (
    <CompWrapper>
      <Box>
        <Card>
          {!!tierDetail ? (
            <AddFormComponent {...{ tierDetail }} />
          ) : (
            <Box>loading...</Box>
          )}
          {/* <Box flexBox vertical>
          </Box> */}
        </Card>
      </Box>
    </CompWrapper>
  );
};
