import React, { useEffect } from "react";
import { Box } from "../../../common";
import { Bold, Chip, Paragraph, Title } from "../../../generics";

// IMPORT HOCS
import { Card } from "../../../hoc";
import { Cell, Pie, PieChart } from "recharts";
import { StatusLabel } from "./statusLabel.component";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardPrevDataAction } from "../../../../actions";
import { useNavigation } from "react-auth-navigation";

const pieData = [
  { name: "Group A", value: 151 },
  { name: "Group B", value: 211 },
  { name: "Group C", value: 41 },
];
const COLORS = ["#ff4343", "#ffc107", "#28a745"];
export const PreviousClientCard = () => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { prevData } = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(getDashboardPrevDataAction());
  }, []);

  const getLabel = (value) => {
    switch (value) {
      case "green":
        return "0-3 Months";
      case "yellow":
        return "3-6 Months";
      case "red":
        return "6+ Months";
    }
  };
  return (
    <Card>
      <Box>
        <Title
          style={{ fontSize: 18, cursor: "pointer" }}
          className="dashboard-card-title"
          onClick={() => navigate(routes["Previous Clients"].path)}>
          Previous Client
        </Title>
        {/* <Paragraph style={{ paddingTop: 4, fontSize: 14 }}>
          #job_ident_no
        </Paragraph> */}
      </Box>

      <Box mt={20} flexBox vertical style={{ rowGap: 10 }}>
        {!!prevData &&
          Object.keys(prevData).map((item, index) => {
            return (
              <StatusLabel
                key={index}
                color={item}
                label={getLabel(item)}
                value={`${prevData[item]}`}
                onClick={() => {
                  navigate(
                    routes["Previous Clients"].path + `?page=1&prev=${item}`,
                  );
                }}
              />
            );
          })}
      </Box>
    </Card>
  );
};
