import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { getLogOfDoor, getLogOfJob } from "../../../../../../actions";
import { TABLE_LIMIT } from "../../../../../../config";
import { ActivityIndicator } from "../../../../../hoc";

const getLogMessage = (log) => {
  const { doorMsg, isJson, join, json, isArray, array } = log;

  if (doorMsg) {
    return <div>{doorMsg}</div>;
  } else {
    if (isJson) {
      return (
        <div>
          {Object.keys(json).map((key) => {
            if (key === "configType" || key === "location") {
              return (
                <div>
                  <b>{key}</b> updated
                </div>
              );
            }

            return (
              <div>
                <b>{key}</b> {join} <b>{json[key]}</b>
              </div>
            );
          })}
        </div>
      );
    } else {
      if (isArray) {
        return null;
      }
    }
  }
};

export const DoorLogs = ({ doorDetail }) => {
  const { id } = doorDetail;

  const dispatch = useDispatch();
  const { doorLogs, doorLogsLoader, doorLogsCount } = useSelector(
    (state) => state.door,
  );

  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(doorLogsCount / TABLE_LIMIT);
  const canLoadMore = pageNumber < totalPages;

  const handleLoadMore = () => {
    setPageNumber((prev) => {
      const nextPageNumber = prev + 1;

      if (nextPageNumber <= totalPages) {
        return nextPageNumber;
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    dispatch(getLogOfDoor(id, pageNumber));
  }, [dispatch, id, pageNumber]);

  return (
    <div className="doordetail-logs">
      <div className="logs-title">Logs</div>

      <div className="logs-list">
        {doorLogs && doorLogs.length ? (
          doorLogs.map(
            (
              { profile_first_name, profile_last_name, created_at, log },
              index,
            ) => {
              const itemName = `${profile_first_name
                .charAt(0)
                .toUpperCase()}. ${profile_last_name}`;

              return (
                <div key={index} className="logs-list-item">
                  <div className="item-name">{itemName}</div>
                  <div className="item-time">
                    {moment(created_at).format("YYYY-MM-DD hh:mm A")}
                  </div>
                  <div className="item-message">{getLogMessage(log)}</div>
                </div>
              );
            },
          )
        ) : (
          <div>No logs</div>
        )}
      </div>

      <ActivityIndicator animating={doorLogsLoader}>
        {canLoadMore && (
          <div className="jobdetail-logs-loadmore">
            <button className="loadmore-button" onClick={handleLoadMore}>
              Load More
            </button>
          </div>
        )}
      </ActivityIndicator>
    </div>
  );
};
