import React, { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { getComplain } from "../../../../actions";
import { CompWrapper } from "../../../hoc";

export const EditMaintenancePage = () => {
  const { params } = useNavigation();
  const dispatch = useDispatch();
  const { complain, complainLoader } = useSelector(
    (state) => state.maintenanceRequest,
  );
  useEffect(() => {
    dispatch(getComplain(params.id));
  }, [params.id]);
  return (
    <CompWrapper>
      {!!complain && !complainLoader ? <div>hello</div> : <div>loading</div>}
    </CompWrapper>
  );
};
