import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaPlus, MdClose } from "react-icons/all";
import { useDispatch } from "react-redux";
import { useAuth } from "react-auth-navigation";

import { getTemplate, getTemplatePart } from "../../../../utils";
import { addTemplate, editTemplate } from "../../../../actions";
import { Title, Paragraph } from "../../../generics";
import {
  Box,
  DefaultButton,
  PrimaryButton,
  SelectField,
} from "../../../common";

const PART_LIST = [
  { name: "Frame", value: "Frame" },
  { name: "Door Leaf", value: "Door Leaf" },
  { name: "Glazing", value: "Glazing" },
  { name: "Glazing Installation", value: "Glazing Installation" },
  { name: "Lockset / Latch", value: "Lockset / Latch" },
  { name: "Panic Set", value: "Panic Set" },
  { name: "Closer", value: "Closer" },
  { name: "Floor Spring", value: "Floor Spring" },
  { name: "Hold Back Device", value: "Hold Back Device" },
  { name: "Hinges / Pivots", value: "Hinges / Pivots" },
  { name: "Fire Seals", value: "Fire Seals" },
  { name: "Smoke Seals", value: "Smoke Seals" },
  { name: "Door Bottom Seals", value: "Door Bottom Seals" },
  { name: "Letter Plate", value: "Letter Plate" },
  { name: "Handle Furniture", value: "Handle Furniture" },
  { name: "Push Plate", value: "Push Plate" },
  { name: "Kick Plate", value: "Kick Plate" },
  { name: "Bolt", value: "Bolt" },
  { name: "Hardware Intumescent Pack", value: "Hardware Intumescent Pack" },
  { name: "Air Transfer Grille", value: "Air Transfer Grille" },
  { name: "Astragal / Angle Stop", value: "Astragal / Angle Stop" },
  { name: "Edge Protector", value: "Edge Protector" },
  { name: "Door Function", value: "Door Function" },
  { name: "Wall Interface", value: "Wall Interface" },
  { name: "Other", value: "Other" },
];

export const Template = ({ id, data, isViewing, reFetch }) => {
  const [disabled, setDisabled] = useState(isViewing);

  const dispatch = useDispatch();
  const { toast } = useAuth();
  const [template, setTemplate] = useState({ ...getTemplate(data) });

  const addPartHandler = () => {
    const templateClone = { ...template };

    const parts = [...templateClone.parts, { ...getTemplatePart() }];

    templateClone.parts = parts;

    setTemplate(templateClone);
  };

  const addPartDetailHandler = (partIndex, key) => {
    const templateClone = { ...template };

    const parts = [...templateClone.parts];
    const detail = [...parts[partIndex][key], ""];

    parts[partIndex][key] = detail;
    templateClone.parts = parts;

    setTemplate(templateClone);
  };

  const deletePartHandler = (partIndex) => {
    const templateClone = { ...template };

    const parts = [...templateClone.parts].filter((_, i) => i !== partIndex);

    templateClone.parts = parts;

    setTemplate(templateClone);
  };

  const deletePartDetailHandler = (partIndex, detailIndex, key) => {
    const templateClone = { ...template };

    const parts = [...templateClone.parts];
    const detail = [...parts[partIndex][key]].filter(
      (_, i) => i !== detailIndex,
    );

    parts[partIndex][key] = detail;
    templateClone.parts = parts;

    setTemplate(templateClone);
  };

  const onChangeHandler = (key) => {
    return function (e) {
      const templateClone = { ...template };
      templateClone[key] = e.target.value;

      setTemplate(templateClone);
    };
  };

  const onChangePartsNameHandler = (partIndex, partName) => {
    // return function (e) {
    const templateClone = { ...template };
    console.log("haha", templateClone);

    const parts = [...templateClone.parts];
    const part = { ...parts[partIndex] };

    // part.name = e.target.value;
    part.name = partName;

    parts[partIndex] = part;
    templateClone.parts = parts;

    setTemplate(templateClone);
    // };
  };

  const onChangePartsDetailHandler = (partIndex, detailIndex, key) => {
    return function (e) {
      const templateClone = { ...template };

      const parts = [...templateClone.parts];
      const detail = [...parts[partIndex][key]];

      detail[detailIndex] = e.target.value;
      parts[partIndex][key] = detail;
      templateClone.parts = parts;

      setTemplate(templateClone);
    };
  };

  const onChangePartsScheduleHandler = (partIndex, key) => {
    return function (e) {
      const templateClone = { ...template };

      const parts = [...templateClone.parts];
      const schedule = { ...parts[partIndex].schedule };

      schedule[key] = e.target.value;
      parts[partIndex].schedule = schedule;
      templateClone.parts = parts;

      setTemplate(templateClone);
    };
  };

  const onSubmit = () => {
    if (isViewing) {
      dispatch(
        editTemplate(id, template, toast, () => {
          setDisabled(true);
        }),
      );
    } else {
      dispatch(addTemplate(template, toast));
    }
  };

  return (
    <Box className="add-template-container">
      <Box flexBox>
        <Box flex={1}>
          <Title mb={20}>Template</Title>
        </Box>

        <Box flexBox>
          {isViewing && (
            <PrimaryButton
              title={disabled ? "Edit" : "Cancel"}
              onClick={() => {
                if (!disabled) {
                  reFetch();
                }
                setDisabled((prev) => !prev);
              }}
            />
          )}
          {!disabled && (
            <>
              <div style={{ width: 20 }} />
              <DefaultButton title="Save" onClick={onSubmit} />
            </>
          )}
        </Box>
      </Box>

      <Box>
        <Box mb={30}>
          <Box mb={10} className="template-title">
            {template.name || ""}
          </Box>

          <Box flexBox mb={10} alCenter>
            <Paragraph mr={10}>Name</Paragraph>
            <input
              type="text"
              value={template.name}
              placeholder="Template name"
              onChange={onChangeHandler("name")}
              disabled={disabled}
            />
          </Box>

          <Box flexBox mb={10} alCenter>
            <Paragraph mr={10}>Description</Paragraph>
            <input
              type="text"
              value={template.description}
              placeholder="Description"
              onChange={onChangeHandler("description")}
              disabled={disabled}
            />
          </Box>

          {/* <Box flexBox mb={10} alCenter>
              <Paragraph mr={10}>Template Of</Paragraph>
              <input
                type="text"
                value={template.templateOf}
                placeholder="Template of"
                onChange={onChangeHandler("templateOf")}
              />
            </Box> */}

          <Box>
            <Box flexBox alCenter>
              <Paragraph>Parts</Paragraph>

              <Box ml={20}>
                {!disabled && (
                  <button
                    type="button"
                    onClick={addPartHandler}
                    disabled={disabled}>
                    <FaPlus />
                  </button>
                )}
              </Box>
            </Box>

            {template.parts.map(
              (
                { details, install, inspect, name, schedule },
                partIndex,
                arr,
              ) => {
                return (
                  <Box
                    className="parts-content"
                    key={partIndex}
                    pl={20}
                    mt={20}>
                    <Box flexBox mb={10} alCenter>
                      <Paragraph mr={10}>Name</Paragraph>
                      {/* <input
                        type="text"
                        value={name}
                        placeholder="Part name (eg. handle)"
                        onChange={onChangePartsNameHandler(partIndex)}
                        disabled={disabled}
                      /> */}
                      <Box flex={1}>
                        <SelectField
                          options={PART_LIST}
                          getOptionValue="value"
                          getOptionLabel="name"
                          onChangeValue={(item) => {
                            onChangePartsNameHandler(partIndex, item.value);
                          }}
                          isSearchable
                          placeholder="Part Name"
                          value={PART_LIST?.find(({ value }) => value === name)}
                        />
                      </Box>

                      {arr.length > 1 && (
                        <Box ml={20}>
                          <button
                            type="button"
                            className="close"
                            onClick={() => deletePartHandler(partIndex)}
                            disabled={disabled}>
                            <MdClose />
                          </button>
                        </Box>
                      )}
                    </Box>

                    <Box pl={20} mb={10}>
                      <Box flexBox mb={10} alCenter>
                        <Paragraph>Details</Paragraph>

                        <Box ml={20}>
                          {!disabled && (
                            <button
                              type="button"
                              onClick={() =>
                                addPartDetailHandler(partIndex, "details")
                              }
                              disabled={disabled}>
                              <FaPlus />
                            </button>
                          )}
                        </Box>
                      </Box>
                      {details.map((val, detailIndex, arr) => (
                        <Box key={detailIndex} pl={20} mb={10} alCenter flexBox>
                          <input
                            type="text"
                            value={val}
                            placeholder="Key (eg. size, color)"
                            onChange={onChangePartsDetailHandler(
                              partIndex,
                              detailIndex,
                              "details",
                            )}
                            disabled={disabled}
                          />

                          {arr.length > 1 && !disabled && (
                            <Box>
                              <button
                                type="button"
                                className="close"
                                onClick={() =>
                                  deletePartDetailHandler(
                                    partIndex,
                                    detailIndex,
                                    "details",
                                  )
                                }
                                disabled={disabled}>
                                <MdClose />
                              </button>
                            </Box>
                          )}
                        </Box>
                      ))}

                      <Box mb={10}>
                        <Box flexBox mb={10} alCenter>
                          <Paragraph>Installation Question</Paragraph>

                          <Box ml={20}>
                            {!disabled && (
                              <button
                                type="button"
                                onClick={() =>
                                  addPartDetailHandler(partIndex, "install")
                                }
                                disabled={disabled}>
                                <FaPlus />
                              </button>
                            )}
                          </Box>
                        </Box>

                        <Box pl={20}>
                          {install.length > 0 &&
                            install.map((value, installIndex, arr) => (
                              <Box key={installIndex} alCenter flexBox mb={10}>
                                <input
                                  type="text"
                                  {...{ value }}
                                  placeholder="Installation"
                                  onChange={onChangePartsDetailHandler(
                                    partIndex,
                                    installIndex,
                                    "install",
                                  )}
                                  disabled={disabled}
                                />

                                {arr.length > 1 && !disabled && (
                                  <Box>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() =>
                                        deletePartDetailHandler(
                                          partIndex,
                                          installIndex,
                                          "install",
                                        )
                                      }
                                      disabled={disabled}>
                                      <MdClose />
                                    </button>
                                  </Box>
                                )}
                              </Box>
                            ))}
                        </Box>
                      </Box>

                      <Box mb={10}>
                        <Box flexBox mb={10}>
                          <Paragraph>Inspection Question</Paragraph>

                          <Box ml={20}>
                            {!disabled && (
                              <button
                                type="button"
                                onClick={() =>
                                  addPartDetailHandler(partIndex, "inspect")
                                }
                                disabled={disabled}>
                                <FaPlus />
                              </button>
                            )}
                          </Box>
                        </Box>

                        <Box pl={20}>
                          {inspect.length > 0 &&
                            inspect.map((value, inspectIndex, arr) => (
                              <Box key={inspectIndex} alCenter flexBox mb={10}>
                                <input
                                  type="text"
                                  {...{ value }}
                                  placeholder="Inspection"
                                  onChange={onChangePartsDetailHandler(
                                    partIndex,
                                    inspectIndex,
                                    "inspect",
                                  )}
                                  disabled={disabled}
                                />
                                {arr.length > 1 && !disabled && (
                                  <Box>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() =>
                                        deletePartDetailHandler(
                                          partIndex,
                                          inspectIndex,
                                          "inspect",
                                        )
                                      }
                                      disabled={disabled}>
                                      <MdClose />
                                    </button>
                                  </Box>
                                )}
                              </Box>
                            ))}
                        </Box>
                      </Box>

                      <Box>
                        <Paragraph mr={10}>Scheduled Time</Paragraph>

                        {/* <select
                          value={schedule.duration}
                          onChange={onChangePartsScheduleHandler(
                            partIndex,
                            "duration",
                          )}
                          disabled={disabled}>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                        </select> */}

                        <Box flexBox alCenter jSpace>
                          <input
                            type="number"
                            min="0"
                            value={schedule.duration}
                            placeholder="0"
                            onChange={onChangePartsScheduleHandler(
                              partIndex,
                              "duration",
                            )}
                            disabled={disabled}
                          />

                          <select
                            style={{ marginLeft: 10 }}
                            value={schedule.unit}
                            onChange={onChangePartsScheduleHandler(
                              partIndex,
                              "unit",
                            )}
                            disabled={disabled}>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                          </select>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              },
            )}
          </Box>
        </Box>
      </Box>

      <Box flexBox>
        {isViewing && (
          <PrimaryButton
            title={disabled ? "Edit" : "Cancel"}
            onClick={() => {
              if (!disabled) {
                reFetch();
              }
              setDisabled((prev) => !prev);
            }}
          />
        )}
        {!disabled && (
          <>
            <div style={{ width: 20 }} />
            <DefaultButton title="Save" onClick={onSubmit} />
          </>
        )}
      </Box>
    </Box>
  );
};

Template.propTypes = {
  data: PropTypes.any,
  isViewing: PropTypes.bool,
};

Template.defaultProps = {
  isViewing: false,
};
