import React, { useState, useEffect, useRef } from "react";
import { useNavigation } from "react-auth-navigation";
import { MapContainer, ImageOverlay, Marker } from "react-leaflet";
import L from "leaflet";
import { Modal } from "react-uicomp";
import { useSelector, useDispatch } from "react-redux";

import { Box, IconButton, ToolTip } from "../../../../common";
import { Title, Paragraph } from "../../../../generics";
import { Card } from "../../../../hoc";
import { getDoorPartByJobDoor } from "../../../../../actions";
import { CENTER, BOUNDS, getXY } from "../../buildingPage/floor/components";
import { FaClipboardList } from "react-icons/fa";

import { FILE_URL } from "../../../../../config";

const TileMap = ({
  floorDetail,
  doorsMarker,
  setPartModal,
  setSelectedDoor,
}) => {
  const mapRef = useRef(null);
  const layerRef = useRef(null);

  useEffect(() => {
    mapRef.current = L.map("map", {
      crs: L.CRS.Simple,
    });
    layerRef.current = L.layerGroup().addTo(mapRef.current);
  }, []);

  // setup leaflet tile layer
  useEffect(() => {
    const tileLayer = L.tileLayer
      .zoomify(`${FILE_URL}/floor/${floorDetail?.id}/fptiles/{z}/{x}/{y}.png`, {
        width: 30000,
        height: 30000,
      })
      .addTo(mapRef.current);

    mapRef.current.fitBounds(tileLayer.getBounds());
  }, [floorDetail]);

  // markers
  useEffect(() => {
    if (doorsMarker) {
      layerRef.current.clearLayers(); // clear all layers of marker before adding new one

      doorsMarker.forEach((data) => {
        const { id, location, door_status } = data;

        const className = ["custom-marker"];
        if (door_status === true) className.push("completed");
        if (door_status === false) className.push("ongoing");
        if (door_status === null) className.push("scheduled");

        const divIcon = new L.divIcon({
          className: "my-custom-pin",
          iconAnchor: [12, 26],
          html: `<span class="${className.join(" ")}" />`,
        });

        L.marker(location, { icon: divIcon })
          .addTo(layerRef.current)
          .on("click", function () {
            setSelectedDoor(id);
            setPartModal(true);
          });
      });
    }
  }, [doorsMarker, setSelectedDoor, setPartModal]);

  return <div id="map" style={{ height: 780, zIndex: 1 }} />;
};

const FloorMapImageOverlay = ({
  doorsMarker,
  image,
  setPartModal,
  setSelectedDoor,
}) => {
  return (
    <>
      <ImageOverlay url={image} bounds={BOUNDS} />

      {/* MARKERS */}
      {doorsMarker.map((data, index) => {
        const { id, location, door_status } = data;

        const className = ["custom-marker"];
        if (door_status === true) className.push("completed");
        if (door_status === false) className.push("ongoing");
        if (door_status === null) className.push("scheduled");

        return (
          <Marker
            key={index}
            eventHandlers={{
              click() {
                setSelectedDoor(id);
                setPartModal(true);
              },
            }}
            icon={
              new L.divIcon({
                className: "my-custom-pin",
                iconAnchor: [12, 26],
                html: `<span class="${className.join(" ")}" />`,
              })
            }
            position={getXY(location[0], location[1])}></Marker>
        );
      })}
    </>
  );
};

export const FloorMap = ({
  data,
  image,
  jobId,
  jobType,
  job_status,
  floorDetail,
}) => {
  const [map, setMap] = useState(null); // reference to the map container
  const [partModal, setPartModal] = useState(false);
  const [selectedDoor, setSelectedDoor] = useState(null);
  const dispatch = useDispatch();
  const { doorPartList, doorPartListLoader } = useSelector(
    (state) => state.job,
  );
  const {
    params,
    navigation: { navigate },
    location,
  } = useNavigation();

  const doorsMarker = data.filter(({ location }) => location !== null); // Available Door markers

  const closePartModal = () => {
    setPartModal(false);
  };

  useEffect(() => {
    if (selectedDoor !== null && params?.id !== null) {
      // api call here
      dispatch(getDoorPartByJobDoor(params?.id, selectedDoor));
    }
  }, [params.id, selectedDoor, dispatch]);

  return (
    <>
      <div className="door-list-container">
        <div className="door-list">
          <Card containerStyle={{ paddingRight: 10 }}>
            <Box flexBox jSpace alCenter mb={20}>
              <Box>
                <Title>Doors List</Title>
              </Box>
            </Box>
            <div className="door-list-cards">
              {data.map(({ id, door_ident_no, door_name, fire_rating }, i) => (
                <div
                  key={i}
                  className="doorcard"
                  onClick={() => {
                    setSelectedDoor(id);
                    setPartModal(true);
                  }}>
                  <div className="doorcard-header">
                    <div className="doorcard-header-name">{door_name}</div>
                  </div>

                  <div className="doorcard-body">
                    <div className="body-info">
                      <div className="body-info-title">Door Ident No.</div>
                      <div className="body-info-data">{door_ident_no}</div>
                    </div>

                    <div className="body-info">
                      <div className="body-info-title">Fire Rating</div>
                      <div className="body-info-data">{fire_rating}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </div>

        <div className="floor-map">
          <Card containerStyle={{ padding: 0 }}>
            <div style={{ height: 780, overflow: "hidden" }}>
              {floorDetail.from_pdf === null ? (
                floorDetail.has_error ? (
                  <div className="floor-map-message error">
                    Error! Can't process floor plan, please re-upload the floor
                    plan.
                  </div>
                ) : (
                  <div className="floor-map-message processing">
                    Floor plan is processing... please comeback after a while.
                  </div>
                )
              ) : floorDetail.from_pdf === true ? (
                <TileMap
                  {...{
                    floorDetail,
                    doorsMarker,
                    setPartModal,
                    setSelectedDoor,
                  }}
                />
              ) : (
                <MapContainer
                  center={CENTER}
                  zoom={-1}
                  minZoom={-5}
                  bounds={BOUNDS}
                  crs={L.CRS.Simple}
                  attributionControl={false}
                  zoomControl={true}
                  whenCreated={setMap}
                  style={{ height: "100%", width: "100%", zIndex: 1 }}>
                  <FloorMapImageOverlay
                    {...{
                      map,
                      doorsMarker,
                      image,
                      setPartModal,
                      setSelectedDoor,
                    }}
                  />
                </MapContainer>
              )}
            </div>
          </Card>
        </div>
      </div>

      <Modal visible={partModal} onOutsideClick={() => closePartModal()}>
        <Title>Parts List</Title>
        <Box mt={20}>
          {doorPartList.length ? (
            doorPartList.map((item, index) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid #e1e1e1",
                    marginBottom: 16,
                  }}>
                  <Box key={index} flexBox jSpace alCenter pb={10}>
                    <Paragraph key={index}>
                      {item?.door_part_details?.part_name}
                    </Paragraph>
                    <IconButton
                      icon={
                        <ToolTip text="View" down>
                          <FaClipboardList />
                        </ToolTip>
                      }
                      onClick={() =>
                        // navigate(
                        //   `/jobs/details/${item?.job_part_ids}`,
                        // )
                        navigate(
                          `${location.pathname}/past-inspection/${item?.job_part_ids}`,
                        )
                      }
                    />
                  </Box>
                </div>
              );
            })
          ) : (
            <Paragraph>No parts found.</Paragraph>
          )}
        </Box>
      </Modal>
    </>
  );
};
