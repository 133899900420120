import {
  ADD_JOB,
  GET_JOB_LIST,
  JOB_DETAIL,
  EDIT_JOB,
  DELETE_JOB_PART,
  GET_DOOR_PART_JOB_DOOR,
  GET_APP_ATTACHMENTS,
  GET_JOB_LOGS,
  UPLOAD_ATTACHMENTS,
} from "../actions";

const initalState = {
  loader: false,
  editLoader: false,

  jobLoader: false,
  jobList: [],
  jobCount: null,

  jobDetail: null,
  jobDetailLoader: null,

  deleteJobLoader: false,

  doorPartList: [],
  doorPartListLoader: false,

  appAttachments: [],
  appAttachmentsLoader: false,

  jobLogs: [],
  jobLogsLoader: false,
  jobLogsCount: 0,

  uploadAttachmentsLoader: false,
};

export function jobReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_JOB.LOADING:
      return { ...state, loader: true };
    case ADD_JOB.SUCCESS:
      return { ...state, loader: false };
    case ADD_JOB.ERROR:
      return { ...state, loader: false };

    case EDIT_JOB.LOADING:
      return { ...state, editLoader: true };
    case EDIT_JOB.SUCCESS:
      return { ...state, editLoader: false };
    case EDIT_JOB.ERROR:
      return { ...state, editLoader: false };

    case GET_JOB_LIST.LOADING:
      return { ...state, jobLoader: true };
    case GET_JOB_LIST.SUCCESS:
      return {
        ...state,
        jobLoader: false,
        jobList: payload.rows,
        jobCount: payload.total,
      };
    case GET_JOB_LIST.ERROR:
      return { ...state, jobLoader: false };

    case JOB_DETAIL.LOADING:
      return { ...state, jobDetailLoader: true };
    case JOB_DETAIL.SUCCESS:
      return {
        ...state,
        jobDetailLoader: false,
        jobDetail: payload,
      };
    case JOB_DETAIL.ERROR:
      return { ...state, jobDetailLoader: false };

    case DELETE_JOB_PART.LOADING:
      return { ...state, deleteJobLoader: true };
    case DELETE_JOB_PART.SUCCESS: {
      const jobDetailClone = { ...state.jobDetail };

      const filteredJobPartsListClone = [
        ...jobDetailClone.job_parts_list,
      ].filter(({ id }) => id !== payload);

      jobDetailClone.job_parts_list = filteredJobPartsListClone;

      return {
        ...state,
        deleteJobLoader: false,
        jobDetail: jobDetailClone,
      };
    }
    case DELETE_JOB_PART.ERROR:
      return { ...state, deleteJobLoader: false };

    case GET_DOOR_PART_JOB_DOOR.LOADING:
      return { ...state, doorPartListLoader: true };
    case GET_DOOR_PART_JOB_DOOR.SUCCESS:
      return {
        ...state,
        doorPartList: payload,
        doorPartListLoader: false,
      };
    case GET_DOOR_PART_JOB_DOOR.ERROR:
      return { ...state, doorPartListLoader: false };

    case GET_APP_ATTACHMENTS.LOADING:
      return { ...state, appAttachmentsLoader: true };
    case GET_APP_ATTACHMENTS.SUCCESS:
      return { ...state, appAttachmentsLoader: false, appAttachments: payload };
    case GET_APP_ATTACHMENTS.ERROR:
      return { ...state, appAttachmentsLoader: false };

    case GET_JOB_LOGS.CLEAR:
      return {
        ...state,
        jobLogsLoader: false,
        jobLogs: [],
        jobLogsCount: 0,
      };
    case GET_JOB_LOGS.LOADING:
      return { ...state, jobLogsLoader: true };
    case GET_JOB_LOGS.SUCCESS:
      return {
        ...state,
        jobLogsLoader: false,
        jobLogs: [...state.jobLogs, ...payload.rows],
        jobLogsCount: payload.total,
      };
    case GET_JOB_LOGS.ERROR:
      return { ...state, jobLogsLoader: false };

    case UPLOAD_ATTACHMENTS.LOADING:
      return { ...state, uploadAttachmentsLoader: true };
    case UPLOAD_ATTACHMENTS.SUCCESS:
      return {
        ...state,
        uploadAttachmentsLoader: false,
      };
    case UPLOAD_ATTACHMENTS.ERROR:
      return { ...state, uploadAttachmentsLoader: false };

    default:
      return state;
  }
}
