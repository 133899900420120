import { GET_ALL_COMPLAINS, GET_COMPLAIN } from "../actions";

const initalState = {
  getLoader: false,
  complains: [],
  complainsCount: 0,
  complainLoader: false,
  complain: null,
};

export function maintenanceRequestReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_COMPLAINS.LOADING:
      return { ...state, getLoader: true };
    case GET_ALL_COMPLAINS.SUCCESS:
      return {
        ...state,
        getLoader: false,
        complains: payload.rows,
        complainsCount: payload.total,
      };
    case GET_ALL_COMPLAINS.ERROR:
      return { ...state, getLoader: false };

    case GET_COMPLAIN.LOADING:
      return { ...state, complainLoader: true };
    case GET_COMPLAIN.SUCCESS:
      return { ...state, complainLoader: false, complain: payload };
    case GET_COMPLAIN.ERROR:
      return { ...state, complainLoader: false };

    default:
      return state;
  }
}
