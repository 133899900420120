import React, { useEffect } from "react";
import axios from "axios";
import { useAuth } from "react-auth-navigation";
import { useSelector, useDispatch } from "react-redux";
import { useDocTitle } from "../../../hooks";
import { Box, DefaultButton, PrimaryButton } from "../../common";
import { Bold, Heading, Image, Paragraph } from "../../generics";
import { CompWrapper, Card, ActivityIndicator } from "../../hoc";
import EMAIL from "../../../assets/icons/emailverify.png";
import PAYMENT from "../../../assets/icons/payment.png";
import REJECT from "../../../assets/icons/reject.jpg";
import {
  getClientSubscription,
  makePaymentAction,
  openCustomerPortal,
  startTrial,
} from "../../../actions";

export const NewUsersDashboard = () => {
  useDocTitle("Dashboard");
  const { toast } = useAuth();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { data } = user?.user;
  const { profile_details, user_details, company_details } = data;

  const { clientSubscription, clientSubscriptionLoader } = useSelector(
    (state) => state.subscription,
  );

  const paymentHandler = () => {
    dispatch(makePaymentAction(clientSubscription?.tier_details?.id, toast));
  };

  const customerPortalHandler = () => {
    dispatch(openCustomerPortal(toast));
  };

  const startTrialHandler = () => {
    dispatch(startTrial(clientSubscription?.tier_details?.id, toast));
  };

  useEffect(() => {
    dispatch(getClientSubscription());
  }, []);

  const viewRender = (data) => {
    if (data.verified) {
      if (data.status === "unsubscribed") {
        return (
          <ActivityIndicator animating={clientSubscriptionLoader}>
            <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
              <Image
                link={PAYMENT}
                alt="Avatar"
                style={{
                  width: 100,
                  height: 100,
                }}
              />
              <Heading mt={20} style={{ textAlign: "center" }}>
                You still have pending payments
                <br /> to be made.
              </Heading>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                Your chosen package is '{clientSubscription?.tier_details?.name}
                '
              </Paragraph>
              <Heading mt={20} style={{ textAlign: "center" }}>
                £{clientSubscription?.tier_details?.price?.amount}
                <span style={{ fontSize: 12 }}>
                  /{clientSubscription?.tier_details?.price?.interval}
                </span>
              </Heading>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                Please click on proceed to payment to clear your dues and enjoy
                your subscription.
              </Paragraph>
              <Box flexBox alCenter>
                <Box mt={20}>
                  <PrimaryButton
                    type="sumbit"
                    title="Proceed to payment"
                    onClick={() => paymentHandler()}
                  />
                </Box>
                <Box mt={20} ml={20}>
                  <DefaultButton
                    type="sumbit"
                    title="Start 14 days Trial"
                    onClick={() => startTrialHandler()}
                  />
                </Box>
              </Box>
            </Box>
          </ActivityIndicator>
        );
      } else if (data.status === "canceled") {
        return (
          <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
            <Image
              link={REJECT}
              alt="Avatar"
              style={{
                width: 100,
                height: 100,
              }}
            />
            <Heading mt={20} style={{ textAlign: "center" }}>
              Your Subscription is canceled!
            </Heading>
            <Paragraph mt={20} style={{ textAlign: "center" }}>
              Your subscription is in canceled state at the moment. But your all
              information is still secured.
              <br /> You can renew your subscription any time to continue using
              our system.
            </Paragraph>
            <Paragraph mt={20} style={{ textAlign: "center" }}>
              You can renew from customer portal
            </Paragraph>
            <Box mt={20}>
              <PrimaryButton
                title="Go to Customer Portal"
                onClick={() => customerPortalHandler()}
              />
            </Box>
          </Box>
        );
      } else if (data.status === "expired_trial") {
      } else {
        return (
          <ActivityIndicator animating={clientSubscriptionLoader}>
            <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
              <Image
                link={PAYMENT}
                alt="Avatar"
                style={{
                  width: 100,
                  height: 100,
                }}
              />
              <Heading mt={20} style={{ textAlign: "center" }}>
                Your trial is expired. You can pay to <br />
                continue using our system.
              </Heading>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                Your chosen package is '{clientSubscription?.tier_details?.name}
                '
              </Paragraph>
              <Heading mt={20} style={{ textAlign: "center" }}>
                £{clientSubscription?.tier_details?.price?.amount}
                <span style={{ fontSize: 12 }}>
                  /{clientSubscription?.tier_details?.price?.interval}
                </span>
              </Heading>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                Please click on proceed to payment to clear your dues and enjoy
                your subscription.
              </Paragraph>
              <Box mt={20}>
                <PrimaryButton
                  title="Go to Customer Portal"
                  onClick={() => customerPortalHandler()}
                />
              </Box>
            </Box>
          </ActivityIndicator>
        );
      }
    } else {
      if (data.verified === null) {
        return (
          <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
            <Image
              link={EMAIL}
              alt="Avatar"
              style={{
                width: 100,
                height: 100,
              }}
            />
            <Heading mt={20} style={{ textAlign: "center" }}>
              You account is still <br />
              under verification process
            </Heading>
            <Paragraph mt={20} style={{ textAlign: "center" }}>
              You can update your company and personal details even in this
              phase before <br />
              verification if you want anything to change.
            </Paragraph>
            <Paragraph mt={20} style={{ textAlign: "center" }}>
              If you think its taking too long, then you can also directly
              contact us.
            </Paragraph>
            <Box mt={20}>
              <PrimaryButton
                title="Contact"
                onClick={() => window.open("https://dunbel.co.uk/contact/")}
              />
            </Box>
          </Box>
        );
      } else {
        return (
          <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
            <Image
              link={REJECT}
              alt="Avatar"
              style={{
                width: 100,
                height: 100,
              }}
            />
            <Heading mt={20} style={{ textAlign: "center" }}>
              You account is rejected!
            </Heading>
            <Paragraph mt={20} style={{ textAlign: "center" }}>
              You can update your company and personal details even in this
              phase before <br />
              verification if you want anything to change.
            </Paragraph>
            <Paragraph mt={20} style={{ textAlign: "center" }}>
              You can contact admin for any further queries
            </Paragraph>
            <Box mt={20}>
              <PrimaryButton
                title="Contact"
                onClick={() => window.open("https://dunbel.co.uk/contact/")}
              />
            </Box>
          </Box>
        );
      }
    }

    if (data.status !== "canceled") {
      if (data.verified) {
        if (data.paid) {
          return <Paragraph>Paid</Paragraph>;
        } else {
          return (
            <ActivityIndicator animating={clientSubscriptionLoader}>
              <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
                <Image
                  link={PAYMENT}
                  alt="Avatar"
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
                <Heading mt={20} style={{ textAlign: "center" }}>
                  You still have pending payments
                  <br /> to be made.
                </Heading>
                <Paragraph mt={20} style={{ textAlign: "center" }}>
                  Your chosen package is '
                  {clientSubscription?.tier_details?.name}'
                </Paragraph>
                <Heading mt={20} style={{ textAlign: "center" }}>
                  £{clientSubscription?.tier_details?.price?.amount}
                  <span style={{ fontSize: 12 }}>
                    /{clientSubscription?.tier_details?.price?.interval}
                  </span>
                </Heading>
                <Paragraph mt={20} style={{ textAlign: "center" }}>
                  Please click on proceed to payment to clear your dues and
                  enjoy your subscription.
                </Paragraph>
                <Box mt={20}>
                  <PrimaryButton
                    type="sumbit"
                    title="Proceed to payment"
                    onClick={() => paymentHandler()}
                  />
                </Box>
              </Box>
            </ActivityIndicator>
          );
        }
      } else {
        if (data.verified === null) {
          return (
            <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
              <Image
                link={EMAIL}
                alt="Avatar"
                style={{
                  width: 100,
                  height: 100,
                }}
              />
              <Heading mt={20} style={{ textAlign: "center" }}>
                You account is still <br />
                under verification process
              </Heading>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                You can update your company and personal details even in this
                phase before <br />
                verification if you want anything to change.
              </Paragraph>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                If you think its taking too long, then you can also directly
                contact us.
              </Paragraph>
              <Box mt={20}>
                <PrimaryButton
                  title="Contact"
                  onClick={() => window.open("https://dunbel.co.uk/contact/")}
                />
              </Box>
            </Box>
          );
        } else {
          return (
            <Box flexBox vertical alCenter jCenter style={{ height: "68vh" }}>
              <Image
                link={REJECT}
                alt="Avatar"
                style={{
                  width: 100,
                  height: 100,
                }}
              />
              <Heading mt={20} style={{ textAlign: "center" }}>
                You account is rejected!
              </Heading>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                You can update your company and personal details even in this
                phase before <br />
                verification if you want anything to change.
              </Paragraph>
              <Paragraph mt={20} style={{ textAlign: "center" }}>
                You can contact admin for any further queries
              </Paragraph>
              <Box mt={20}>
                <PrimaryButton
                  title="Contact"
                  onClick={() => window.open("https://dunbel.co.uk/contact/")}
                />
              </Box>
            </Box>
          );
        }
      }
    } else {
      return <Paragraph>Not Active</Paragraph>;
    }
  };

  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flex={1} flexBox alCenter jCenter>
            {viewRender(company_details)}
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};
