import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-uicomp";
import { useAuth, useNavigation } from "react-auth-navigation";
import { MdAdd, MdClose } from "react-icons/md";
import { isValid, useInput, validator } from "../../../../../../utils";
import {
  Box,
  DefaultButton,
  FormInput,
  GooglePlaces,
  InputField,
  PrimaryButton,
} from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { Paragraph, Title } from "../../../../../generics";
import {
  getClientPeopleAction,
  editUserAction,
} from "../../../../../../actions";

export const AdminTab = () => {
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const { location, params } = useNavigation();
  const { peopleLoading, peopleList, peopleCount } = useSelector(
    (state) => state.client,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getClientPeopleAction(params?.id, 2, pageNumber)); // 2 => Role ADMIN
  }, [params?.id, pageNumber]);

  const toggleHandler = (data, id) => {
    dispatch(
      editUserAction(id, { deactivate: !data }, onSuccessHandler, toast),
    );
  };

  const onSuccessHandler = () => {
    dispatch(getClientPeopleAction(params?.id, 2, pageNumber));
  };

  return (
    <Box>
      <Table
        columns={[
          {
            field: "profile_first_name",
            name: "first name",
          },
          {
            field: "profile_last_name",
            name: "last name",
          },
          {
            field: "email",
            name: "email",
          },
          {
            field: "profile_address",
            name: "address",
            render: (rowData) =>
              rowData && <Paragraph>{rowData?.value}</Paragraph>,
          },
          {
            field: "profile_phone",
            name: "phone",
            render: (rowData) =>
              rowData && <Paragraph>{rowData?.join(", ")}</Paragraph>,
          },
          {
            field: "deactivated",
            name: "active",
            render: (rowData, id) => (
              <input
                type="checkbox"
                checked={!rowData}
                onChange={() => toggleHandler(rowData, id)}
              />
            ),
          },
        ]}
        data={peopleList}
        dataLoader={peopleLoading}
        totalCount={peopleCount}
        // actions
        // deleteLoader={peopleLoading}
        // onDeleteHandler={deleteHandler}
        // onEditHandler={editHandler}
        // onViewHandler={viewHandler}
      />
    </Box>
  );
};
