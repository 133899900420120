import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { getLogOfJob } from "../../../../../actions";
import { TABLE_LIMIT } from "../../../../../config";
import { ActivityIndicator } from "../../../../hoc";

const getLogMessage = (log) => {
  const { jobMsg, isJson, join, json, isArray, array } = log;

  if (jobMsg) {
    return <div>{jobMsg}</div>;
  } else {
    if (isJson) {
      return (
        <div>
          {Object.keys(json).map((key) => (
            <div>
              <b>{key}</b> {join} <b>{json[key]}</b>
            </div>
          ))}
        </div>
      );
    } else {
      if (isArray) {
        return null;
      }
    }
  }
};

export const JobLogs = ({ jobDetail }) => {
  const { id } = jobDetail.job_details;

  const dispatch = useDispatch();
  const { jobLogs, jobLogsLoader, jobLogsCount } = useSelector(
    (state) => state.job,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(jobLogsCount / TABLE_LIMIT);
  const canLoadMore = pageNumber < totalPages;

  const handleLoadMore = () => {
    setPageNumber((prev) => {
      const nextPageNumber = prev + 1;

      if (nextPageNumber <= totalPages) {
        return nextPageNumber;
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    dispatch(getLogOfJob(id, pageNumber));
  }, [dispatch, id, pageNumber]);

  return (
    <div className="jobdetail-logs">
      <div className="logs-title">Logs</div>

      <div className="logs-list">
        {jobLogs && jobLogs.length ? (
          jobLogs.map(
            (
              { profile_first_name, profile_last_name, created_at, log },
              index,
            ) => {
              const itemName = `${profile_first_name
                .charAt(0)
                .toUpperCase()}. ${profile_last_name}`;

              return (
                <div key={index} className="logs-list-item">
                  <div className="item-name">{itemName}</div>
                  <div className="item-time">
                    {moment(created_at).format("YYYY-MM-DD hh:mm A")}
                  </div>
                  <div className="item-message">{getLogMessage(log)}</div>
                </div>
              );
            },
          )
        ) : (
          <div>No logs</div>
        )}
      </div>

      <ActivityIndicator animating={jobLogsLoader}>
        {canLoadMore && (
          <div className="jobdetail-logs-loadmore">
            <button className="loadmore-button" onClick={handleLoadMore}>
              Load More
            </button>
          </div>
        )}
      </ActivityIndicator>
    </div>
  );
};
