import {
  ADD_IRONMONGERY_CATEGORY,
  ADD_IRONMONGERY_PRODUCT,
  DELETE_IRONMONGERY_CATEGORY,
  DELETE_IRONMONGERY_PRODUCT,
  GET_ALL_IRONMONGERY_CATEGORY,
  GET_ALL_IRONMONGERY_PRODUCT,
  GET_IRONMONGERY_PRODUCT_DETAIL,
} from "../actions";

const initalState = {
  addCategoryLoader: false,
  addCategorySuccess: false,
  addCategoryError: false,

  categorylist: [],
  categoryLoader: false,

  deleteCategoryLoader: false,
  deleteCategorySuccess: false,

  addProductLoader: false,
  addProductSuccess: false,
  addProductError: false,

  productLoader: false,
  productlist: [],

  productDetailLoader: false,
  productDetail: null,

  deleteProductLoader: false,
  deleteProductSuccess: true,
};

export function irommongeryReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_IRONMONGERY_CATEGORY.LOADING:
      return {
        ...state,
        addCategoryLoader: true,
        addCategorySuccess: false,
        addCategoryError: false,
      };
    case ADD_IRONMONGERY_CATEGORY.SUCCESS:
      return {
        ...state,
        addCategoryLoader: false,
        addCategorySuccess: true,
        categorylist: [
          { id: payload.id, name: payload.name },
          ...state.categorylist,
        ],
      };
    case ADD_IRONMONGERY_CATEGORY.ERROR:
      return { ...state, addCategoryLoader: false, addCategoryError: true };

    case GET_ALL_IRONMONGERY_CATEGORY.LOADING:
      return {
        ...state,
        categoryLoader: true,
      };
    case GET_ALL_IRONMONGERY_CATEGORY.SUCCESS:
      return {
        ...state,
        categoryLoader: false,
        categorylist: payload,
      };
    case GET_ALL_IRONMONGERY_CATEGORY.ERROR:
      return { ...state, categoryLoader: false };

    case DELETE_IRONMONGERY_CATEGORY.LOADING:
      return {
        ...state,
        deleteCategoryLoader: true,
        deleteCategorySuccess: false,
      };
    case DELETE_IRONMONGERY_CATEGORY.SUCCESS: {
      const categorylistClone = [...state.categorylist].filter(
        ({ id }) => id !== payload,
      );
      return {
        ...state,
        deleteCategoryLoader: false,
        deleteCategorySuccess: true,
        categorylist: categorylistClone,
      };
    }
    case DELETE_IRONMONGERY_CATEGORY.ERROR:
      return {
        ...state,
        deleteCategoryLoader: false,
        deleteCategorySuccess: false,
      };

    case ADD_IRONMONGERY_PRODUCT.LOADING:
      return {
        ...state,

        addProductLoader: true,
        addProductSuccess: false,
        addProductError: false,
      };
    case ADD_IRONMONGERY_PRODUCT.SUCCESS:
      return {
        ...state,

        addProductLoader: false,
        addProductSuccess: true,
        productlist: payload.rows,
        productTotalcount: payload.total,
      };
    case ADD_IRONMONGERY_PRODUCT.ERROR:
      return {
        ...state,
        addProductLoader: false,
        addProductSuccess: false,
        addProductError: true,
      };

    case GET_ALL_IRONMONGERY_PRODUCT.LOADING:
      return {
        ...state,
        productLoader: true,
      };
    case GET_ALL_IRONMONGERY_PRODUCT.SUCCESS:
      return {
        ...state,
        productLoader: false,
        productlist: payload.rows,
        productTotalcount: payload.total,
      };
    case GET_ALL_IRONMONGERY_PRODUCT.ERROR:
      return { ...state, productLoader: false };

    case GET_IRONMONGERY_PRODUCT_DETAIL.LOADING:
      return {
        ...state,
        productDetailLoader: true,
      };
    case GET_IRONMONGERY_PRODUCT_DETAIL.SUCCESS:
      return {
        ...state,
        productDetailLoader: false,
        productDetail: payload,
      };
    case GET_IRONMONGERY_PRODUCT_DETAIL.ERROR:
      return { ...state, productDetailLoader: false };

    case DELETE_IRONMONGERY_PRODUCT.LOADING:
      return {
        ...state,
        deleteProductLoader: true,
      };
    case DELETE_IRONMONGERY_PRODUCT.SUCCESS: {
      const productlistClone = [...state.productlist].filter(
        ({ product_details }) => product_details.id !== payload,
      );
      return {
        ...state,
        deleteProductLoader: false,
        deleteProductSuccess: true,
        productlist: productlistClone,
      };
    }
    case DELETE_IRONMONGERY_PRODUCT.ERROR:
      return { ...state, deleteProductLoader: false };

    default:
      return state;
  }
}
