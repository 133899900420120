import React, { useEffect } from "react";
import { Modal } from "react-uicomp";
import { MdAdd, MdClose } from "react-icons/md";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  KeyValue,
  DefaultButton,
  IconButton,
  ToolTip,
  Tab,
  FormInput,
  InputField,
  GooglePlaces,
  ImageUpload,
  Grid,
  PrimaryButton,
} from "../../../common";
import { Bold, Image, Paragraph, Title, Chip } from "../../../generics";
// import { ViewerSection } from "./component/viewerSection";
import { FILE_URL } from "../../../../config";
import UPLOAD from "../../../../assets/icons/upload.png";
import { CompWrapper, Card, ActivityIndicator } from "../../../hoc";

import { MdEdit } from "react-icons/all";
import { UserSection } from "./component/userSection";
import { AdminSection } from "./component/adminSection";
import { isValid, useInput, validator } from "../../../../utils";
import { useDocTitle } from "../../../../hooks";
import {
  editClientAction,
  editUserInfoAction,
  getClientSubscription,
  getUserAction,
  makePaymentAction,
  openCustomerPortal,
  userAuthAction,
} from "../../../../actions";
import { SubscriptionHistory } from "./component/subscriptionHistory";

export const UsersPage = () => {
  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { editLoading } = useSelector((state) => state.client);
  const { data: userData } = user?.user;
  const { profile_details, user_details, company_details } = userData;
  useDocTitle("Client");

  const { clientSubscription, clientSubscriptionLoader } = useSelector(
    (state) => state.subscription,
  );

  const [showEditInfo, setShowEditInfo] = React.useState(false);
  const [showCompanyEditInfo, setCompanyShowEditInfo] = React.useState(false);
  const [locationName, setLocationName] = React.useState(
    profile_details.profile_address.value,
  );
  const [phone, setPhone] = React.useState(profile_details.profile_phone);

  const [locationNameCompany, setLocationNameCompany] = React.useState(
    company_details.cont_comp_address.value,
  );
  const [companyPhone, setCompanyPhone] = React.useState(
    company_details.cont_comp_phone,
  );

  const [image, setImage] = React.useState([]);

  const { data, onChangeHandler } = useInput({
    firstName: profile_details.profile_first_name ?? "default",
    lastName: profile_details.profile_last_name ?? "default",
    email: user_details.email ?? "default",
    jobTitle: profile_details.profile_job_title ?? "default",

    // Company Info
    contractorName: company_details.cont_comp_name ?? "default",
    contractorEmail: company_details.cont_comp_email ?? "default",
  });

  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, firstName, lastName, jobTitle } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("firstName", firstName.length === 0, () => {
      showMessage("First Name musn't be empty!");
    });
    validate("lastName", lastName.length === 0, () => {
      showMessage("Last Name musn't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("Email musn't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("Address musn't be empty!");
    });
    validate("jobTitle", jobTitle.length === 0, () => {
      showMessage("Job Title musn't be empty!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    const body = {
      firstName: firstName,
      lastName: lastName,
      profileAddress: { coordinates: [1, 1], value: locationName },
      profilePhone: phone,
      profileJobTitle: jobTitle,
    };

    dispatch(
      editUserInfoAction(
        body,
        () => {
          setShowEditInfo(false);

          dispatch(getUserAction());
        },
        toast,
      ),
    );
  };

  const submitCompanyHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { contractorName } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("contractorName", contractorName.length === 0, () => {
      showMessage("Contractor name musn't be empty!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    let formData = new FormData();
    await formData.append("contractorName", contractorName);
    await formData.append(
      "contractorAddress",
      JSON.stringify({
        coordinates: [1, 1],
        value: locationNameCompany,
      }),
    );

    await formData.append("contractorPhone", JSON.stringify(companyPhone));

    image.length !== 0 && (await formData.append("imageFile", image[0].file));

    dispatch(
      editClientAction(
        company_details.id,
        formData,
        () => {
          setCompanyShowEditInfo(false);
          dispatch(getUserAction());
        },
        toast,
      ),
    );
  };

  const changeHandle = (index) => {
    return function (e) {
      const phoneArray = [...phone];
      phoneArray[index] = e.target.value;
      setPhone(phoneArray);
    };
  };

  const onAdd = () => {
    setPhone([...phone, ""]);
  };

  const close = (index) => {
    const phoneClone = [...phone];

    phoneClone.splice(index, 1);
    setPhone(phoneClone);
  };

  const companyChangeHandle = (index) => {
    return function (e) {
      const phoneArray = [...companyPhone];
      phoneArray[index] = e.target.value;
      setCompanyPhone(phoneArray);
    };
  };

  const onComapnyAdd = () => {
    setCompanyPhone([...companyPhone, ""]);
  };

  const companyClose = (index) => {
    const phoneClone = [...companyPhone];

    phoneClone.splice(index, 1);
    setCompanyPhone(phoneClone);
  };

  let query = useQuery();
  console.log("haha", query.get("user_tab"));

  function useQuery() {
    return new URLSearchParams(location?.search);
  }

  const changeImage = (data) => {
    setImage(data);
  };

  const customerPortalHandler = () => {
    dispatch(openCustomerPortal(toast));
  };

  const paymentHandler = () => {
    dispatch(makePaymentAction(clientSubscription?.tier_details?.id, toast));
  };

  useEffect(() => {
    dispatch(getClientSubscription());
  }, []);

  const getSubscriptionStatus = (item) => {
    switch (item) {
      case "trialing":
        return "Trial";
      case "incomplete" || "incomplete_expired" || "past_due" || "unpaid":
        return "UnPaid";
      case "expired_trial":
        return "Trial Expired";

      case "unsubscribed":
        return "Not Subscribed";

      case "active":
        return "Active";

      default:
        return "Canceled";
    }
  };

  console.log("Client Summary", clientSubscription);
  return (
    <CompWrapper>
      {userData && (
        <Box flexBox vertical style={{ rowGap: 20 }}>
          <Card>
            <Box flexBox alStart jSpace>
              <Box>
                <Box flexBox alCenter>
                  <Box>
                    <Bold mb={10}>Client Company</Bold>
                  </Box>
                  <Box mt={-5}>
                    <IconButton
                      icon={
                        <ToolTip text="Edit" down>
                          <MdEdit />
                        </ToolTip>
                      }
                      onClick={() => {
                        setCompanyShowEditInfo(true);
                      }}
                    />
                  </Box>
                </Box>
                <Box className={"client-wrapper"} flexBox alCenter>
                  <Box mr={30}>
                    <Image
                      link={`${FILE_URL}/contractor/${company_details?.cont_comp_logo}`}
                      alt="Contractor image"
                      className="avatar"
                    />
                  </Box>
                  <KeyValue
                    data={{
                      Name: company_details?.cont_comp_name,
                      Email: company_details?.cont_comp_email,
                      Phone: company_details?.cont_comp_phone?.join(", "),
                      Address: company_details?.cont_comp_address?.value,
                    }}
                  />
                </Box>
              </Box>

              <Box>
                <Box flexBox alCenter>
                  <Box>
                    <Bold mb={10}>My Info</Bold>
                  </Box>
                  <Box mt={-5}>
                    <IconButton
                      icon={
                        <ToolTip text="Edit" down>
                          <MdEdit />
                        </ToolTip>
                      }
                      onClick={() => {
                        setShowEditInfo(true);
                      }}
                    />
                  </Box>
                </Box>
                <Box className={"client-wrapper"}>
                  <KeyValue
                    data={{
                      "First Name": profile_details?.profile_first_name,
                      "Last name": profile_details?.profile_last_name,
                      Email: user_details?.email,
                      Phone: profile_details?.profile_phone?.join(", "),
                      Address: profile_details?.profile_address?.value,
                      Role: user_details?.role,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Card>
          <Card>
            <Box flexBox vertical>
              <Box>
                <Bold mb={10}>Subscription</Bold>
              </Box>

              <Grid.Container lg={4}>
                <Box flexBox vertical alStart>
                  <Bold>Tier</Bold>
                  <Paragraph>
                    {clientSubscription?.tier_details?.name}
                  </Paragraph>
                </Box>

                <Box flexBox vertical alStart>
                  <Bold>Price</Bold>
                  <Paragraph>
                    £ {clientSubscription?.tier_details?.price?.amount}/
                    {clientSubscription?.tier_details?.price?.interval}
                  </Paragraph>
                </Box>

                <Box flexBox vertical alStart>
                  <Bold>Status</Bold>
                  <Paragraph>
                    <Chip>
                      {getSubscriptionStatus(
                        clientSubscription?.subscription_details?.status,
                      )}
                    </Chip>
                  </Paragraph>
                </Box>
                <Box>
                  <PrimaryButton
                    title="Open Customer Portal"
                    onClick={() =>
                      clientSubscription?.subscription_details?.status ===
                        "trialing" ||
                      clientSubscription?.subscription_details?.status ===
                        "unsubscribed"
                        ? paymentHandler()
                        : customerPortalHandler()
                    }
                  />
                </Box>
              </Grid.Container>
              <Grid.Container lg={4}>
                <Box flexBox vertical alStart>
                  <Bold>Start date</Bold>
                  <Paragraph>
                    {clientSubscription?.subscription_details?.start_date}
                  </Paragraph>
                </Box>

                <Box flexBox vertical alStart>
                  <Bold>Expiry date</Bold>
                  <Paragraph>
                    {clientSubscription?.subscription_details?.expiry_date}
                  </Paragraph>
                </Box>
              </Grid.Container>
            </Box>
          </Card>
        </Box>
      )}
      <Box mt={20}>
        <Tab
          activeUserProp={query.get("user_tab") === "1" ? true : false}
          panes={[
            {
              render: () => <AdminSection />,
              title: "Admins",
            },
            {
              render: () => (
                <UserSection
                  modalOpenProp={
                    query.get("open_modal") === "true" ? true : false
                  }
                />
              ),
              title: "Users",
            },
            // {
            //   render: () => <SubscriptionHistory />,
            //   title: "Subscription History",
            // },
          ]}
        />
        {/* <ViewerSection /> */}
      </Box>

      <Modal
        visible={showEditInfo}
        onOutsideClick={() => {
          setShowEditInfo(false);
        }}>
        <Box>
          <Box flexBox jCenter mb={10}>
            <Title>Edit My Info</Title>
          </Box>
          <form onSubmit={submitHandler}>
            <Box flexBox alCenter jSpace>
              <FormInput label="First Name">
                <InputField
                  placeholder="First Name"
                  name="firstName"
                  value={data.firstName}
                  onChange={onChangeHandler("firstName")}
                  type="text"
                />
              </FormInput>
              <Box ml={10}>
                <FormInput label="Last Name">
                  <InputField
                    placeholder="Last Name"
                    name="lastName"
                    value={data.lastName}
                    onChange={onChangeHandler("lastName")}
                    type="text"
                  />
                </FormInput>
              </Box>
            </Box>
            <Box flexBox alCenter jSpace>
              <FormInput label="Address">
                <GooglePlaces
                  setLocationName={setLocationName}
                  defaultValue={profile_details.profile_address.value}
                />
              </FormInput>
              <Box ml={10}>
                <FormInput label="Job Title">
                  <InputField
                    placeholder="Job Title"
                    name="jobTitle"
                    value={data.jobTitle}
                    onChange={onChangeHandler("jobTitle")}
                    type="text"
                  />
                </FormInput>
              </Box>
            </Box>
            <FormInput label="Email">
              <InputField
                placeholder="Email"
                name="email"
                value={data.email}
                onChange={onChangeHandler("email")}
                type="email"
                disabled
              />
            </FormInput>

            <FormInput
              label="Phone"
              newElement={
                <div
                  type="button"
                  className="close"
                  style={{ cursor: "pointer" }}
                  onClick={onAdd}>
                  <MdAdd color="green" />
                </div>
              }>
              <Box>
                {phone.length > 0 &&
                  phone.map((item, index) => {
                    return (
                      <Box flexBox alCenter key={index} mb={10}>
                        <InputField
                          placeholder="Phone"
                          name="phone"
                          value={item}
                          onChange={changeHandle(index)}
                          type="number"
                          min="0"
                        />
                        {phone.length > 1 && (
                          <Box ml={20}>
                            <div
                              type="button"
                              className="close"
                              style={{ cursor: "pointer" }}
                              onClick={() => close(index)}>
                              <MdClose color="red" />
                            </div>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </FormInput>
            <ActivityIndicator animating={false}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Modal>

      {/* EDIT CONTRACTOR DETAILS */}
      <Modal
        visible={showCompanyEditInfo}
        onOutsideClick={() => {
          setCompanyShowEditInfo(false);
        }}>
        <Box>
          <Box flexBox jCenter mb={10}>
            <Title>Edit Company Info</Title>
          </Box>
          <form onSubmit={submitCompanyHandler}>
            <Box flexBox alCenter jSpace>
              <FormInput label="Company Name">
                <InputField
                  placeholder="Company Name"
                  name="contractorName"
                  value={data.contractorName}
                  onChange={onChangeHandler("contractorName")}
                  type="text"
                />
              </FormInput>
              <Box ml={10}>
                <FormInput label="Company Email">
                  <InputField
                    placeholder="Company Email"
                    name="contractorEmail"
                    value={data.contractorEmail}
                    onChange={onChangeHandler("contractorEmail")}
                    type="email"
                    disabled={true}
                  />
                </FormInput>
              </Box>
            </Box>
            <Box>
              <FormInput label="Address">
                <GooglePlaces
                  setLocationName={setLocationNameCompany}
                  defaultValue={company_details.cont_comp_address.value}
                />
              </FormInput>
            </Box>

            <FormInput
              label="Company Phone"
              newElement={
                <div
                  type="button"
                  className="close"
                  style={{ cursor: "pointer" }}
                  onClick={onComapnyAdd}>
                  <MdAdd color="green" />
                </div>
              }>
              <Box>
                {companyPhone.length > 0 &&
                  companyPhone.map((item, index) => {
                    return (
                      <Box flexBox alCenter key={index} mb={10}>
                        <InputField
                          placeholder="Phone"
                          name="phone"
                          value={item}
                          onChange={companyChangeHandle(index)}
                          type="number"
                          min="0"
                        />
                        {companyPhone.length > 1 && (
                          <Box ml={20}>
                            <div
                              type="button"
                              className="close"
                              style={{ cursor: "pointer" }}
                              onClick={() => companyClose(index)}>
                              <MdClose color="red" />
                            </div>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </FormInput>

            <FormInput label="Logo">
              <ImageUpload
                onChange={changeImage}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={1} md={1} sm={1}>
                      {imageData &&
                        imageData.map((item, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: "inline-block",
                                boxShadow: "0px 2px 10px 0px #888888",
                              }}>
                              <Image
                                alt="Avatar"
                                link={item.url}
                                style={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "contain",
                                  overflow: "hidden",
                                }}
                              />
                            </div>
                          </div>
                        ))}

                      {company_details?.cont_comp_logo && image.length == 0 && (
                        <Box>
                          <div
                            style={{
                              display: "inline-block",
                              boxShadow: "0px 2px 10px 0px #888888",
                            }}>
                            <Image
                              alt="Avatar"
                              link={`${FILE_URL}/contractor/${company_details?.cont_comp_logo}`}
                              style={{
                                width: 200,
                                height: 200,
                                objectFit: "contain",
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        </Box>
                      )}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>
            <ActivityIndicator animating={editLoading}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Modal>
    </CompWrapper>
  );
};
