import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaPlus, MdClose } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";

import {
  getIronmongery,
  getIronmongeryPart,
  makeUniqueArray,
} from "../../../../utils";
import { Title, Paragraph } from "../../../generics";
import {
  Box,
  DefaultButton,
  PrimaryButton,
  SelectField,
} from "../../../common";
import {
  addIronmongeryPack,
  editIronmongeryPack,
  getAllIronmongeryCategory,
} from "../../../../actions";
import { api, APIS } from "../../../../config";
const list = [
  { product_name: "name a", id: 1 },
  { product_name: "name b", id: 2 },
  { product_name: "name c", id: 3 },
  { product_name: "name d", id: 4 },
];

const PART_LIST = [
  { name: "Frame", value: "Frame" },
  { name: "Door Leaf", value: "Door Leaf" },
  { name: "Glazing", value: "Glazing" },
  { name: "Glazing Installation", value: "Glazing Installation" },
  { name: "Lockset / Latch", value: "Lockset / Latch" },
  { name: "Panic Set", value: "Panic Set" },
  { name: "Closer", value: "Closer" },
  { name: "Floor Spring", value: "Floor Spring" },
  { name: "Hold Back Device", value: "Hold Back Device" },
  { name: "Hinges / Pivots", value: "Hinges / Pivots" },
  { name: "Fire Seals", value: "Fire Seals" },
  { name: "Smoke Seals", value: "Smoke Seals" },
  { name: "Door Bottom Seals", value: "Door Bottom Seals" },
  { name: "Letter Plate", value: "Letter Plate" },
  { name: "Handle Furniture", value: "Handle Furniture" },
  { name: "Push Plate", value: "Push Plate" },
  { name: "Kick Plate", value: "Kick Plate" },
  { name: "Bolt", value: "Bolt" },
  { name: "Hardware Intumescent Pack", value: "Hardware Intumescent Pack" },
  { name: "Air Transfer Grille", value: "Air Transfer Grille" },
  { name: "Astragal / Angle Stop", value: "Astragal / Angle Stop" },
  { name: "Edge Protector", value: "Edge Protector" },
  { name: "Door Function", value: "Door Function" },
  { name: "Wall Interface", value: "Wall Interface" },
  { name: "Other", value: "Other" },
];

export const ProductTemplate = ({ id, data, isViewing, reFetch }) => {
  const { history } = useNavigation();
  const [disabled, setDisabled] = useState(isViewing);

  const [removedPart, setRemovedPart] = useState([]);

  const dispatch = useDispatch();
  const { toast } = useAuth();
  const [template, setTemplate] = useState({ ...getIronmongery(data) });

  useEffect(() => {
    dispatch(getAllIronmongeryCategory());
  }, []);

  const [uniqueProducts, setUniqueProducts] = useState([]);
  useEffect(() => {
    if (template.groupList.length > 0) {
      const newArray = template.groupList.map(({ productId }) => productId);

      setUniqueProducts(makeUniqueArray(newArray));
    }
  }, [template]);

  const addPartHandler = () => {
    const templateClone = { ...template };

    const groupListClone = [
      ...templateClone.groupList,
      { ...getIronmongeryPart() },
    ];

    templateClone.groupList = groupListClone;

    setTemplate(templateClone);
  };

  const deletePartHandler = (partIndex) => {
    const templateClone = { ...template };

    const groupListClone = [...templateClone.groupList].filter(
      (_, i) => i !== partIndex,
    );

    templateClone.groupList = groupListClone;

    setTemplate(templateClone);
  };

  const onChangeHandler = (key) => {
    return function (e) {
      const templateClone = { ...template };
      templateClone[key] = e.target.value;

      setTemplate(templateClone);
    };
  };

  const onChangePartsNameHandler = (partIndex, partName) => {
    // return function (e) {
    const templateClone = { ...template };

    const groupListClone = [...templateClone.groupList];
    const part = { ...groupListClone[partIndex] };

    // part.partName = e.target.value;
    part.partName = partName;

    groupListClone[partIndex] = part;
    templateClone.groupList = groupListClone;

    setTemplate(templateClone);
    // };
  };

  const changeProductValue = (partIndex, key, value) => {
    let newValue;
    if (typeof value === "object") {
      newValue = value?.map((item) => {
        return item.id;
      });
    } else {
      newValue = value;
    }

    const templateClone = { ...template };

    const groupListClone = [...templateClone.groupList];
    const part = { ...groupListClone[partIndex] };

    part[key] = newValue;

    groupListClone[partIndex] = part;
    templateClone.groupList = groupListClone;

    setTemplate(templateClone);
  };

  const finalTemplate = () => {
    const templateClone = { ...template };
    const grouplistClone = [...templateClone.groupList].map(
      ({ partName, productId }) => {
        return {
          partName,
          productId,
        };
      },
    );

    templateClone.groupList = grouplistClone;
    return templateClone;
  };

  const addedPartsTemplate = () => {
    const templateClone = { ...template };
    const grouplistClone = [...templateClone.groupList]
      .filter((item) => !!item.id === false)
      .map(({ partName, productId }) => {
        return {
          partName,
          productId,
        };
      });

    templateClone.groupList = grouplistClone;
    return templateClone;
  };

  const editPartsTemplate = () => {
    const templateClone = { ...template };
    const grouplistClone = [...templateClone.groupList]
      .filter((item) => !!item.id)
      .map(({ partName, productId, id }) => {
        return {
          id,
          partName,
          productId,
        };
      });
    return grouplistClone;
  };

  const removePartHandler = (item) => {
    const removedPartClone = [...removedPart, item];
    makeUniqueArray(removedPartClone);
    setRemovedPart(makeUniqueArray(removedPartClone));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    // console.log(template);
    if (isViewing) {
      const updatedList = editPartsTemplate();
      const body = addedPartsTemplate();
      if (removedPart.length > 0) {
        body.removeIdList = removedPart;
      }
      if (body.groupList.length === 0) {
        //if no new parts added
        delete body.groupList;
      }
      console.log({ updatedList });
      dispatch(
        editIronmongeryPack(id, body, updatedList, toast, () => {
          setDisabled(true);
        }),
      );
    } else {
      dispatch(
        addIronmongeryPack(finalTemplate(), toast, () => {
          history.goBack();
        }),
      );
    }
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Box className="add-template-container">
        <Box flexBox>
          <Box flex={1}>
            <Title mb={20}>Ironmongery Pack</Title>
          </Box>

          <Box flexBox>
            {isViewing && (
              <PrimaryButton
                title={disabled ? "Edit" : "Cancel"}
                onClick={(e) => {
                  e.preventDefault();
                  if (!disabled) {
                    reFetch();
                  }
                  setDisabled((prev) => !prev);
                }}
              />
            )}
            {!disabled && (
              <>
                <div style={{ width: 20 }} />
                <DefaultButton title="Save" type="submit" />
              </>
            )}
          </Box>
        </Box>

        <Box>
          <Box mb={30}>
            <Box mb={10} className="template-title">
              {template.name || ""}
            </Box>

            <Box flexBox mb={10} alCenter>
              <Paragraph mr={10}>Name</Paragraph>
              <input
                type="text"
                value={template.name}
                placeholder="Ironmongery pack name"
                onChange={onChangeHandler("name")}
                disabled={disabled}
                required
              />
            </Box>

            <Box flexBox mb={10} alCenter>
              <Paragraph mr={10}>Description</Paragraph>
              <input
                type="text"
                value={template.description}
                placeholder="Description"
                onChange={onChangeHandler("description")}
                disabled={disabled}
                required
              />
            </Box>
            <Box>
              <Box flexBox alCenter>
                <Paragraph>Parts</Paragraph>

                <Box ml={20}>
                  {!disabled && (
                    <button
                      type="button"
                      onClick={addPartHandler}
                      disabled={disabled}>
                      <FaPlus />
                    </button>
                  )}
                </Box>
              </Box>

              {template.groupList.map((item, partIndex, arr) => {
                return (
                  <Parts
                    key={partIndex}
                    data={item}
                    // partName={partName}
                    partIndex={partIndex}
                    onChangePartsNameHandler={onChangePartsNameHandler}
                    deletePartHandler={deletePartHandler}
                    changeProductValue={changeProductValue}
                    arr={arr}
                    disabled={disabled}
                    uniqueProducts={uniqueProducts}
                    removePartHandler={removePartHandler}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>

        <Box flexBox>
          {isViewing && (
            <PrimaryButton
              title={disabled ? "Edit" : "Cancel"}
              onClick={(e) => {
                e.preventDefault();
                if (!disabled) {
                  reFetch();
                }
                setDisabled((prev) => !prev);
              }}
            />
          )}
          {!disabled && (
            <>
              <div style={{ width: 20 }} />
              <DefaultButton title="Save" type="submit" />
            </>
          )}
        </Box>
      </Box>
    </form>
  );
};

const Parts = ({
  onChangePartsNameHandler,
  deletePartHandler,
  changeProductValue,
  // partName,
  partIndex,
  arr,
  disabled,
  uniqueProducts,
  data,
  removePartHandler,
}) => {
  const { categorylist } = useSelector((state) => state.ironmongery);

  const [productList, setProductList] = useState([]);
  const [category, setCategory] = useState(data.ironmongeryCategory);

  useEffect(() => {
    let mount = true;
    if (!!category && mount) {
      (async () => {
        setProductList([]);
        const res = await api(
          `${APIS.ironmongeryProduct}/all?page=1&limit=20&catid=${category}`,
        );
        const { success, data: resData } = res.data;
        if (success) {
          setProductList(resData.data.rows);
        }
      })();
    }
    return () => {
      mount = false;
    };
  }, [category]);
  const getProduct = () => {
    return productList?.filter(
      ({ product_details }) => product_details.id === data.productId,
    );
  };

  return (
    <Box className="parts-content" pl={20} mt={20}>
      <Box flexBox mb={10} alCenter>
        <Paragraph mr={10}>Name</Paragraph>
        {/* <input
          type="text"
          value={data.partName}
          placeholder="Part name (eg. handle)"
          onChange={onChangePartsNameHandler(partIndex)}
          disabled={disabled}
          required
        /> */}
        <Box flex={1}>
          <SelectField
            options={PART_LIST}
            getOptionValue="value"
            getOptionLabel="name"
            onChangeValue={(item) => {
              onChangePartsNameHandler(partIndex, item.value);
            }}
            isSearchable
            placeholder="Part Name"
            value={PART_LIST?.find(({ value }) => value === data.partName)}
          />
        </Box>

        {arr.length > 1 && (
          <Box ml={20}>
            <button
              type="button"
              className="close"
              onClick={(e) => {
                e.preventDefault();
                !!data.id && removePartHandler(data.id);
                deletePartHandler(partIndex);
              }}
              disabled={disabled}>
              <MdClose />
            </button>
          </Box>
        )}
      </Box>

      <Box mb={10} alCenter>
        <Paragraph mr={10} mb={8}>
          Irommongery Category
        </Paragraph>
        <SelectField
          instanceId="ironmongeryCategory"
          options={categorylist}
          getOptionValue="id"
          getOptionLabel="name"
          onChangeValue={(item) => {
            setCategory(item.id);
            // changeProductValue(partIndex, "ironmongeryCategory", item.id);
          }}
          isSearchable
          placeholder="Ironmongery Category"
          value={categorylist?.find(
            ({ id }) => id === data.ironmongeryCategory,
          )}
          // isOptionDisabled={isDoorOptionDisabled}
        />
      </Box>

      <Box mb={10} alCenter>
        <Paragraph mr={10} mb={8}>
          Irommongery Product
        </Paragraph>

        <SelectField
          instanceId="productId"
          options={productList.filter(
            ({ product_details: { id } }) => !uniqueProducts?.includes(id),
          )}
          getOptionLabel={(option) => `${option.product_details.name}`}
          getOptionValue={(option) => `${option.product_details.id}`}
          // getOptionValue="id"
          onChangeValue={(item) => {
            changeProductValue(partIndex, "productId", item.product_details.id);
          }}
          isSearchable
          placeholder="Ironmongery Product"
          value={getProduct()}
        />
      </Box>
    </Box>
  );
};

ProductTemplate.propTypes = {
  data: PropTypes.any,
  isViewing: PropTypes.bool,
};

ProductTemplate.defaultProps = {
  isViewing: false,
};
