import {
  ADD_DOOR,
  GET_DOOR_LIST,
  DOOR_DETAIL,
  PLOT,
  DOORMAPPED,
  EDIT_DOOR,
  ADD_DOOR_PART,
  GET_DOOR_LIST_APPEND,
  ADD_BATCH_DOOR,
  GET_DOOR_LOGS,
  DOOR_TRASH_LIST,
  RESTORE_DOOR_TRASH,
} from "../actions";

const initalState = {
  loader: false,
  editLoader: false,

  getDoorLoader: false,
  doorList: [],
  doorCount: null,
  plotLoader: false,

  // loadmore
  doorListAppendLoader: false,
  doorListAppend: [],
  doorListCount: 0,

  doorDetail: null,
  doorDetailLoader: null,
  doorBreadCrumb: null,

  doorMappedList: [],
  doorMappedLoading: false,

  addDoorPartLoader: false,

  addBatchDoorLoader: false,

  doorLogs: [],
  doorLogsLoader: false,
  doorLogsCount: 0,

  doorTrashList: [],
  doorTrashLoader: false,
  doorTrashCount: 0,

  restoreDoorLoader: false,
};

export function doorReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_DOOR.LOADING:
      return { ...state, loader: true };
    case ADD_DOOR.SUCCESS:
      return { ...state, loader: false };
    case ADD_DOOR.ERROR:
      return { ...state, loader: false };

    case EDIT_DOOR.LOADING:
      return { ...state, editLoader: true };
    case EDIT_DOOR.SUCCESS:
      return { ...state, editLoader: false };
    case EDIT_DOOR.ERROR:
      return { ...state, editLoader: false };

    case GET_DOOR_LIST.LOADING:
      return { ...state, getDoorLoader: true };
    case GET_DOOR_LIST.SUCCESS:
      return {
        ...state,
        getDoorLoader: false,
        doorList: payload.rows,
        doorCount: payload.total,
      };
    case GET_DOOR_LIST.ERROR:
      return { ...state, getDoorLoader: false };

    // load more
    case GET_DOOR_LIST_APPEND.CLEAR:
      return {
        ...state,
        doorListAppendLoader: false,
        doorListAppend: [],
        doorListCount: 0,
      };
    case GET_DOOR_LIST_APPEND.LOADING:
      return { ...state, doorListAppendLoader: true };
    case GET_DOOR_LIST_APPEND.SUCCESS:
      return {
        ...state,
        doorListAppendLoader: false,
        doorListAppend: [...state.doorListAppend, ...payload.rows],
        doorListCount: payload.total,
      };
    case GET_DOOR_LIST_APPEND.ERROR:
      return { ...state, doorListAppendLoader: false };

    case DOOR_DETAIL.CLEAR:
      return {
        ...state,
        doorDetail: null,
        doorBreadCrumb: null,
      };
    case DOOR_DETAIL.LOADING:
      return { ...state, doorDetailLoader: true, doorDetail: null };
    case DOOR_DETAIL.SUCCESS:
      return {
        ...state,
        doorDetailLoader: false,
        doorDetail: payload.details,
        doorBreadCrumb: payload.breadcrum,
      };
    case DOOR_DETAIL.ERROR:
      return { ...state, doorDetailLoader: false };

    case PLOT.LOADING:
      return { ...state, plotLoader: true };
    case PLOT.SUCCESS:
      return { ...state, plotLoader: false };
    case PLOT.ERROR:
      return { ...state, plotLoader: false };

    case DOORMAPPED.LOADING:
      return { ...state, doorMappedLoading: true };
    case DOORMAPPED.SUCCESS:
      return {
        ...state,
        doorMappedLoading: false,
        doorMappedList: payload,
      };
    case DOORMAPPED.ERROR:
      return { ...state, doorMappedLoading: false };

    case ADD_DOOR_PART.LOADING:
      return { ...state, addDoorPartLoader: true };
    case ADD_DOOR_PART.SUCCESS:
      return {
        ...state,
        addDoorPartLoader: false,
      };
    case ADD_DOOR_PART.ERROR:
      return { ...state, addDoorPartLoader: false };

    case ADD_BATCH_DOOR.LOADING:
      return { ...state, addBatchDoorLoader: true };
    case ADD_BATCH_DOOR.SUCCESS:
      return {
        ...state,
        addBatchDoorLoader: false,
      };
    case ADD_BATCH_DOOR.ERROR:
      return { ...state, addBatchDoorLoader: false };

    case GET_DOOR_LOGS.CLEAR:
      return {
        ...state,
        doorLogsLoader: false,
        doorLogs: [],
        doorLogsCount: 0,
      };
    case GET_DOOR_LOGS.LOADING:
      return { ...state, doorLogsLoader: true };
    case GET_DOOR_LOGS.SUCCESS:
      return {
        ...state,
        doorLogsLoader: false,
        doorLogs: [...state.doorLogs, ...payload.rows],
        doorLogsCount: payload.total,
      };
    case GET_DOOR_LOGS.ERROR:
      return { ...state, doorLogsLoader: false };

    case DOOR_TRASH_LIST.CLEAR:
      return {
        ...state,
        doorTrashLoader: false,
        doorTrashList: [],
        doorTrashCount: 0,
      };
    case DOOR_TRASH_LIST.LOADING:
      return { ...state, doorTrashLoader: true };
    case DOOR_TRASH_LIST.SUCCESS:
      return {
        ...state,
        doorTrashLoader: false,
        doorTrashList: payload.rows,
        doorTrashCount: payload.total,
      };
    case DOOR_TRASH_LIST.ERROR:
      return { ...state, doorTrashLoader: false };

    case RESTORE_DOOR_TRASH.LOADING:
      return { ...state, restoreDoorLoader: true };
    case RESTORE_DOOR_TRASH.SUCCESS: {
      const doorTrashListClone = [...state.doorTrashList].filter(
        ({ id }) => id !== payload,
      );

      return {
        ...state,
        restoreDoorLoader: false,
        doorTrashList: [...doorTrashListClone],
      };
    }
    case RESTORE_DOOR_TRASH.ERROR:
      return { ...state, restoreDoorLoader: false };

    default:
      return state;
  }
}
