import React, { useEffect, useState } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { MdSave } from "react-icons/all";

import {
  getPartOfDoorAction,
  getDoorDetailAction,
  editDoorAction,
  getIronmongeryPackList,
  DOOR_DETAIL,
} from "../../../../../actions";
import { Card, CompWrapper } from "../../../../hoc";
import {
  Box,
  FormInput,
  InputField,
  Grid,
  DefaultButton,
  PrimaryButton,
  SelectField,
  CheckBox,
} from "../../../../common";
import Table from "../../../../common/table/Table.common";
import { Bold, Title } from "../../../../generics";
import { useDocTitle } from "../../../../../hooks";
import {
  useInput,
  validator,
  isValid,
  capitalizeFirstLetter,
  patchOnlyEditedData,
  patchOnlyEditedDataRaw,
} from "../../../../../utils";

import {
  FIRE_RATING_ACTUAL,
  FIRE_RATING,
  SET,
  ACTING,
  LATCHED,
} from "../floor/components";
import { DoorLogs } from "./components";

const DoorDetail = () => {
  const { params, navigation, location } = useNavigation();
  const { navigate, routes } = navigation;
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const { partList, partListLoader } = useSelector((state) => state.part);
  const { doorDetail, doorBreadCrumb } = useSelector((state) => state.door);
  const { ironmongeryPackLoader, ironmongeryPacklist } = useSelector(
    (state) => state.ironmongeryPack,
  );
  const [ironmongeryPackFormat, setIronmongeryPackFormat] = useState([]);

  useEffect(() => {
    setIronmongeryPackFormat(
      ironmongeryPacklist.map((item) => {
        return {
          name: item.group_details.name,
          value: item.group_details.id,
        };
      }),
    );
  }, [ironmongeryPacklist]);

  useEffect(() => {
    dispatch(getIronmongeryPackList());
  }, []);

  const [check, setCheck] = useState(() => {
    if (doorDetail.overpanel) {
      return { yes: true };
    } else if (doorDetail.overpanel === false) {
      return { no: true };
    } else {
      return {};
    }
  });

  const [sideLights, setSideLights] = useState(() => {
    if (doorDetail.side_light === "1") {
      return { one: true };
    } else if (doorDetail.side_light === "2") {
      return { two: true };
    } else {
      return {};
    }
  });

  const prevData = {
    doorName: doorDetail.door_name,
    fireRating: FIRE_RATING_ACTUAL.includes(doorDetail.fire_rating)
      ? doorDetail.fire_rating
      : "Other",
    configType: {
      type: capitalizeFirstLetter(doorDetail?.config_type?.type ?? ""),
    },
    soHeight: doorDetail.so_height ?? "",
    soWidth: doorDetail.so_width ?? "",
    soDepth: doorDetail.so_depth ?? "",
  };

  const { data, onChangeHandler } = useInput({
    id: doorDetail.id,
    doorType: doorDetail?.config_type?.type ?? "",
    fireRatingText: doorDetail.fire_rating,
    acousticRating: doorDetail.acoustic_rating ?? "",
    // ironmongery: doorDetail.ironmongery,
    set: doorDetail.config_type.set,
    acting: doorDetail.config_type.acting,
    latched: doorDetail.config_type.latched,
    ironmongeryPack: doorDetail.im_group_id,

    ...prevData,
  });

  useEffect(() => {
    dispatch(getPartOfDoorAction(params.id));
  }, [dispatch, params.id]);

  const viewHandler = (id = 1) => {
    // navigate(`/building/part/${id}`);
    navigate(`${location.pathname}/part/${id}`);
  };

  const editHandler = (item) => {
    navigate({
      // pathname: `/building/door/editpart/${item.id}`,
      pathname: `${location.pathname}/editpart/${item.id}`,
      state: { doorId: params.id },
    });
  };

  useEffect(() => {
    if (doorDetail) {
      if (doorDetail.overpanel) {
        setCheck({ yes: true });
      } else if (doorDetail.overpanel === false) {
        setCheck({ no: true });
      } else {
        setCheck({});
      }
    }
  }, [doorDetail, data]);

  useEffect(() => {
    if (doorDetail) {
      if (doorDetail.side_light === "1") {
        setSideLights({ one: true });
      } else if (doorDetail.side_light === "2") {
        setSideLights({ two: true });
      } else {
        setSideLights({});
      }
    }
  }, [doorDetail, data]);

  const toggleCheckBox = (data) => {
    setCheck({ [`${data}`]: !check[data] });
  };

  const toggleSideLights = (data) => {
    setSideLights({ [`${data}`]: !check[data] });
  };

  const showMessage = (message) => {
    toast({ message, type: "error" });
  };

  // Submit edited door details
  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const {
      doorName,
      doorType,
      fireRating,
      fireRatingText,
      soHeight,
      soWidth,
      soDepth,
      acousticRating,
      // ironmongery,
      set,
      acting,
      latched,
      ironmongeryPack,
    } = data;

    validate("doorName", doorName.length === 0, () => {
      showMessage("Door name can't be empty!");
    });
    validate("doorType", doorType.length === 0, () => {
      showMessage("Door type can't be empty!");
    });
    validate("fireRating", fireRating.length === 0, () => {
      showMessage("Fire rating can't be empty!");
    });

    validate("soHeight", soHeight.length === 0, () => {
      showMessage("S.O. Height can't be empty!");
    });
    validate("soWidth", soWidth.length === 0, () => {
      showMessage("S.O. Width can't be empty!");
    });
    validate("soDepth", soDepth.length === 0, () => {
      showMessage("S.O. Depth can't be empty!");
    });

    if (!isValid(catchedErrors)) {
      return;
    }

    const body = {
      doorName: capitalizeFirstLetter(doorName),
      fireRating: fireRating,
      configType: {
        type: capitalizeFirstLetter(doorType),
      },
      soHeight: soHeight,
      soWidth: soWidth,
      soDepth: soDepth,
    };

    if (data.fireRating === "Other") {
      body["fireRating"] = fireRatingText;
    }

    if (acousticRating) {
      body["acousticRating"] = capitalizeFirstLetter(acousticRating);
    }

    // if (ironmongery) {
    //   body["ironmongery"] = capitalizeFirstLetter(ironmongery);
    // }

    if (set) {
      body["configType"]["set"] = set;
    }

    if (acting) {
      body["configType"]["acting"] = acting;
    }

    if (latched) {
      body["configType"]["latched"] = latched;
    }

    if (ironmongeryPack) {
      body["imGroupId"] = ironmongeryPack;
    }

    if (Object.keys(check).length) {
      body["overpanel"] = Object.keys(check)[0] === "yes" ? true : false;
    }

    if (Object.keys(sideLights).length) {
      body["sideLight"] = Object.keys(sideLights)[0] === "one" ? 1 : 2;
    }

    let bodyObj = {};
    const patchData = patchOnlyEditedDataRaw(bodyObj, prevData, body);

    // NEED WORKS HERE

    if (patchData.length) {
      dispatch(editDoorAction(data.id, bodyObj, () => false, toast));
    }
  };

  useDocTitle("Door Detail");

  if (!!doorDetail === false) {
    return <div>Loading...</div>;
  }

  return (
    <CompWrapper breadcrumbData={doorBreadCrumb}>
      <Card containerStyle={{ padding: 0 }}>
        <Box className="doordetail-header">
          <Title mb={10}>Door Details</Title>
        </Box>

        <Box flexBox>
          {doorDetail && (
            <Box flex={2} style={{ padding: 20 }}>
              <form onSubmit={submitHandler}>
                <Grid.Container lg={2} md={2}>
                  <FormInput label="Door Number">
                    <InputField
                      placeholder="Door Number"
                      name="doorName"
                      value={data.doorName}
                      onChange={onChangeHandler("doorName")}
                      type="text"
                    />
                  </FormInput>

                  <FormInput label="Door Type">
                    <InputField
                      placeholder="Door Type"
                      name="doorType"
                      value={data.doorType}
                      onChange={onChangeHandler("doorType")}
                      type="text"
                    />
                  </FormInput>
                </Grid.Container>

                <Grid.Container lg={2} md={2}>
                  <FormInput label="Door Identification No.">
                    <InputField
                      type="text"
                      value={doorDetail?.door_ident_no}
                      disabled
                    />
                  </FormInput>

                  <FormInput label="Fire Rating">
                    <SelectField
                      options={FIRE_RATING}
                      getOptionLabel="name"
                      getOptionValue="value"
                      onChangeValue={(item) => {
                        onChangeHandler("fireRating", item?.value)();
                      }}
                      isSearchable
                      placeholder="Select Type"
                      value={FIRE_RATING.filter(
                        (element) => element.value === data.fireRating,
                      )}
                    />
                  </FormInput>

                  {data.fireRating === "Other" && (
                    <FormInput label="FireRating" required>
                      <InputField
                        placeholder="FireRating"
                        name="fireRating"
                        value={data.fireRatingText}
                        onChange={onChangeHandler("fireRatingText")}
                        type="text"
                      />
                    </FormInput>
                  )}
                </Grid.Container>

                <Grid.Container lg={3} md={3}>
                  <FormInput label="Set">
                    <SelectField
                      options={SET}
                      getOptionLabel="name"
                      getOptionValue="value"
                      onChangeValue={(item) => {
                        onChangeHandler("set", item?.value)();
                      }}
                      isSearchable
                      placeholder="Select Type"
                      value={SET.filter(
                        (element) => element.value === data.set,
                      )}
                    />
                  </FormInput>

                  <FormInput label="Acting">
                    <SelectField
                      options={ACTING}
                      getOptionLabel="name"
                      getOptionValue="value"
                      onChangeValue={(item) => {
                        onChangeHandler("acting", item?.value)();
                      }}
                      isSearchable
                      placeholder="Select Type"
                      value={ACTING.filter(
                        (element) => element.value === data.acting,
                      )}
                    />
                  </FormInput>

                  <FormInput label="Lached">
                    <SelectField
                      options={LATCHED}
                      getOptionLabel="name"
                      getOptionValue="value"
                      onChangeValue={(item) => {
                        onChangeHandler("latched", item?.value)();
                      }}
                      isSearchable
                      placeholder="Select Type"
                      value={LATCHED.filter(
                        (element) => element.value === data.latched,
                      )}
                    />
                  </FormInput>
                </Grid.Container>

                <Grid.Container lg={3} md={3}>
                  <FormInput label="soHeight">
                    <InputField
                      placeholder="S.O. Height"
                      name="soHeight"
                      value={data.soHeight}
                      onChange={onChangeHandler("soHeight")}
                    />
                  </FormInput>

                  <FormInput label="soWidth">
                    <InputField
                      placeholder="S.O. Width"
                      name="soWidth"
                      value={data.soWidth}
                      onChange={onChangeHandler("soWidth")}
                    />
                  </FormInput>

                  <FormInput label="soDepth">
                    <InputField
                      placeholder="S.O. Depth"
                      name="soDepth"
                      value={data.soDepth}
                      onChange={onChangeHandler("soDepth")}
                    />
                  </FormInput>
                </Grid.Container>

                <Grid.Container lg={3} md={3}>
                  <FormInput label="Acoustic Rating (dB)">
                    <InputField
                      placeholder="Acoustic Rating (dB)"
                      name="acousticRating"
                      value={data.acousticRating}
                      onChange={onChangeHandler("acousticRating")}
                    />
                  </FormInput>

                  {/* <FormInput label="ironmongery">
                    <InputField
                      placeholder="Ironmongery Type/Number"
                      name="ironmongery"
                      value={data.ironmongery}
                      onChange={onChangeHandler("ironmongery")}
                      type="text"
                    />
                  </FormInput> */}
                  <FormInput label="Ironmongery Pack">
                    <SelectField
                      options={ironmongeryPackFormat}
                      isLoading={ironmongeryPackLoader}
                      getOptionLabel="name"
                      getOptionValue="value"
                      onChangeValue={(item) => {
                        onChangeHandler("ironmongeryPack", item?.value)();
                      }}
                      isSearchable
                      placeholder="Select Pack"
                      value={ironmongeryPackFormat.filter(
                        (element) => element.value === data.ironmongeryPack,
                      )}
                    />
                  </FormInput>

                  <FormInput label="Overpanel">
                    <Grid.Container
                      lg={2}
                      md={2}
                      sm={1}
                      flexBox
                      jCenter
                      alCenter>
                      <CheckBox
                        label="Yes"
                        name="yes"
                        check={check.yes}
                        handleCheckboxChange={toggleCheckBox}
                      />

                      <CheckBox
                        label="No"
                        name="no"
                        check={check.no}
                        handleCheckboxChange={toggleCheckBox}
                      />
                    </Grid.Container>
                  </FormInput>

                  <FormInput label="Side Lights">
                    <Grid.Container
                      lg={2}
                      md={2}
                      sm={1}
                      flexBox
                      jCenter
                      alCenter>
                      <CheckBox
                        label="1"
                        name="one"
                        check={sideLights.one}
                        handleCheckboxChange={toggleSideLights}
                      />

                      <CheckBox
                        label="2"
                        name="two"
                        check={sideLights.two}
                        handleCheckboxChange={toggleSideLights}
                      />
                    </Grid.Container>
                  </FormInput>
                </Grid.Container>

                <Box className="jobaction" flexBox jEnd pl={20}>
                  <PrimaryButton
                    lefticon={<MdSave style={{ marginRight: 10 }} />}
                    title="Save"
                    onClick={() => false}
                  />
                </Box>
              </form>
            </Box>
          )}

          <Box flex={1}>
            <DoorLogs {...{ doorDetail }} />
          </Box>
        </Box>
      </Card>
      <Box mb={20} />
      <Card>
        <Box flexBox alCenter jSpace>
          <Bold>Parts</Bold>
          {/* {!partList.length && (
            <Box>
              <DefaultButton
                title="Add Part"
                onClick={() => {
                  navigate({
                    pathname: routes["Add Part"].path,
                    pathname:   `${location.pathname}/part/add`,
                    state: { doorId: params.id },
                  });
                }}
              />
            </Box>
          )} */}
        </Box>
        <Box mt={20}>
          <Table
            columns={[
              {
                field: "part_name",
                name: "part_name",
              },
            ]}
            data={partList}
            dataLoader={partListLoader}
            actions
            onEditHandler={editHandler}
            onViewHandler={viewHandler}
          />
        </Box>
      </Card>
    </CompWrapper>
  );
};

export const DoorDetailPage = () => {
  const { params } = useNavigation();
  const dispatch = useDispatch();
  const { doorDetail } = useSelector((state) => state.door);

  useEffect(() => {
    dispatch(getDoorDetailAction(params.id));
  }, [dispatch, params.id]);

  return <>{!!doorDetail ? <DoorDetail /> : null}</>;
};
