import {
  SUPERADMIN_DASHBOARD,
  SUPERADMIN_PREVIOUOS_DASHBOARD,
} from "../actions";

const initalState = {
  loading: false,
  dashboardData: [],
  prevDataLoading: false,
  prevData: null,
};

export function dashboardReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUPERADMIN_DASHBOARD.LOADING:
      return {
        ...state,
        loading: true,
        // dashboardData: [],
      };
    case SUPERADMIN_DASHBOARD.SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: payload,
      };
    case SUPERADMIN_DASHBOARD.ERROR:
      return {
        ...state,
        loading: false,
        dashboardData: null,
      };

    case SUPERADMIN_PREVIOUOS_DASHBOARD.LOADING:
      return {
        ...state,
        prevDataLoading: true,
        prevData: null,
      };
    case SUPERADMIN_PREVIOUOS_DASHBOARD.SUCCESS:
      return {
        ...state,
        prevDataLoading: false,
        prevData: payload,
      };
    case SUPERADMIN_PREVIOUOS_DASHBOARD.ERROR:
      return {
        ...state,
        prevDataLoading: false,
        prevData: null,
      };

    default:
      return state;
  }
}
