import React from "react";

import { useDocTitle } from "../../../../hooks";
import { CompWrapper } from "../../../hoc";
import { Template } from "../components";

export const AddTemplatePage = () => {
  useDocTitle("Add Template");

  return (
    <CompWrapper>
      <Template />
    </CompWrapper>
  );
};
