import React, { useEffect } from "react";
import moment from "moment";
import { useNavigation } from "react-auth-navigation";
import { useSelector, useDispatch } from "react-redux";

import { getAllComplains } from "../../../actions";
import { useDocTitle } from "../../../hooks";
import { Box } from "../../common";
import AlternateTable from "../../common/table/AlternateTable.common";
import { Bold, Chip } from "../../generics";
import { CompWrapper, Card } from "../../hoc";

export const MaintenanceRequestPage = () => {
  useDocTitle("Maintenance Request");

  const dispatch = useDispatch();
  const { getLoader, complains, complainsCount } = useSelector(
    (state) => state.maintenanceRequest,
  );

  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getAllComplains(pageNumber));
  }, [dispatch, pageNumber]);

  const viewHandler = ({ complain_details: { id } }) => {
    navigate(`${routes["Maintenance Request"].path}/view/${id}`);
  };

  const editHandler = ({ complain_details: { id } }) => {
    // console.log(item.complain_details.id);
    navigate(`${routes["Maintenance Request"].path}/edit/${id}`);
  };

  const createHandler = (item) => {
    navigate({ pathname: routes["Create Maintenance Job"].path, state: item });
  };
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Maintenance Request</Bold>
          </Box>
          <Box mt={20}>
            <AlternateTable
              columns={[
                {
                  field: "site_details",
                  name: "Site Name",
                  render: ({ prop_name }) => prop_name,
                },
                {
                  field: "floor_details",
                  name: "Floor Name",
                  render: ({ floor_name }) => floor_name,
                },
                {
                  field: "door_details",
                  name: "Door Name",
                  render: ({ door_name }) => door_name,
                },
                {
                  field: "complain_details",
                  name: "Requested Date",
                  render: ({ complain_date }) =>
                    moment(complain_date).format("Do MMM, YYYY"),
                },
                {
                  field: "complained_by",
                  name: "Requested By",
                  render: ({ first_name, last_name }) =>
                    `${first_name} ${last_name}`,
                },
                {
                  field: "complain_details",
                  name: "Resolved",
                  render: ({ complain_status }) =>
                    complain_status ? (
                      <Chip style={{ backgroundColor: "#5b9540" }}>
                        Resolved
                      </Chip>
                    ) : (
                      <Chip style={{ backgroundColor: "#ff4343" }}>
                        Not Resolved
                      </Chip>
                    ),
                },
              ]}
              data={complains}
              dataLoader={getLoader}
              totalCount={complainsCount}
              actions
              // deleteLoader={deleteLoader}
              // onDeleteHandler={deleteHandler}
              // onEditHandler={editHandler}
              onViewHandler={viewHandler}
              onCreateHandler={createHandler}
              viewBug // viewbug
            />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};
