import React, { useState, useEffect } from "react";
import { BsArrowReturnRight, FaPlus, MdClose } from "react-icons/all";
import { useSelector } from "react-redux";
import { api, APIS } from "../../../../../config";
import { useComponentDidUpdate } from "../../../../../hooks";
import { useInput } from "../../../../../utils";
import { Box, FormInput, Grid, SelectField } from "../../../../common";
import { Title } from "../../../../generics";
import { DoorNPart } from "./DoorNPart";

export const InspectionPart = ({
  inspectionfloorNdoor,
  index,
  setInspectionFloorNdoor,
  onCloseFloor,
  isFloorOptionDisabled,
  isDoorOptionDisabled,
  isPartOptionDisabled,
  disableDoorAddition,
  inspectionPartArray,
}) => {
  const floorNdoorClone = [...inspectionfloorNdoor];
  const indexedObject = { ...floorNdoorClone[index] };

  const { allFloorList } = useSelector((state) => state.floor);

  const [doorList, setDoorList] = useState([]);
  const { data, onChangeHandler } = useInput({
    floor: inspectionfloorNdoor[index].floor,
  });

  useEffect(() => {
    if (!!inspectionfloorNdoor[index].floor) {
      (async () => {
        const res = await api(
          `${APIS.door}/floor/part/${inspectionfloorNdoor[index].floor}`,
        );
        const { success, data: resData } = res.data;
        if (success) {
          setDoorList(resData.data);
        }
      })();
    }
  }, [inspectionfloorNdoor[index].floor]);

  useComponentDidUpdate(() => {
    if (!!inspectionfloorNdoor[index].floor) {
      setTimeout(() => {
        indexedObject.doorNpart = [
          {
            door: "",
            part: "",
          },
        ];
        floorNdoorClone[index] = indexedObject;
        setInspectionFloorNdoor(floorNdoorClone);
      }, 1);
    }
  }, [inspectionfloorNdoor[index].floor]);

  const changeFloor = (value) => {
    onChangeHandler("floor", value)();

    indexedObject.floor = value;
    floorNdoorClone[index] = indexedObject;
    setInspectionFloorNdoor(floorNdoorClone);
  };

  const getValue = () => {
    return allFloorList.find(
      (item, id) => item.id == inspectionfloorNdoor[index].floor,
    );
  };

  const onAdd = () => {
    const newArray = [
      ...indexedObject["doorNpart"],
      {
        door: "",
        part: "",
      },
    ];

    indexedObject["doorNpart"] = newArray;

    floorNdoorClone[index] = indexedObject;

    setInspectionFloorNdoor(floorNdoorClone);
  };

  const onClose = (i) => {
    // const floorNdoorClone = [...inspectionfloorNdoor];
    // const indexedObject = floorNdoorClone[index];

    const newArray = [...indexedObject["doorNpart"]].filter(
      (item, index) => index !== i,
    );

    indexedObject["doorNpart"] = newArray;

    floorNdoorClone[index] = indexedObject;

    setInspectionFloorNdoor(floorNdoorClone);
  };

  return (
    <Box>
      <Grid.Container lg={2} sm={2} md={2}>
        <FormInput label=" Select Floor" required>
          <SelectField
            options={allFloorList}
            getOptionLabel="floor_name"
            getOptionValue="id"
            onChangeValue={(item) => {
              changeFloor(item?.id);
            }}
            isSearchable
            placeholder="Select Floor"
            value={getValue()}
            isOptionDisabled={isFloorOptionDisabled}
          />
        </FormInput>

        {inspectionfloorNdoor?.length > 1 && <MdClose onClick={onCloseFloor} />}
      </Grid.Container>
      <Box flexBox alCenter jSpace pl={100}>
        <Title>Door</Title>
        {!!disableDoorAddition === false && <FaPlus onClick={onAdd} />}
      </Box>
      {inspectionfloorNdoor[index]["doorNpart"].map((_, doorIndex) => {
        return (
          <Box key={doorIndex} flexBox alCenter pl={100}>
            <Box>
              <BsArrowReturnRight />
            </Box>
            <Box flex={1}>
              <DoorNPart
                doorList={doorList}
                inspectionfloorNdoor={inspectionfloorNdoor}
                mainIndex={index}
                doorIndex={doorIndex}
                setInspectionFloorNdoor={setInspectionFloorNdoor}
                isDoorOptionDisabled={isDoorOptionDisabled}
                isPartOptionDisabled={isPartOptionDisabled}
                inspectionPartArray={inspectionPartArray}
              />
            </Box>
            {inspectionfloorNdoor[index]["doorNpart"]?.length > 1 && (
              <MdClose onClick={() => onClose(doorIndex)} />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
