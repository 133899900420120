import { createActionSet } from "../helpers";

export const LOGIN = createActionSet("LOGIN");
export const LOGOUT = createActionSet("LOGOUT");
export const PASSWORD = createActionSet("PASSWORD");
export const USER = createActionSet("USER");
export const EDIT_USER_INFO = createActionSet("EDIT_USER_INFO");
export const RESET_PASSWORD = createActionSet("RESET_PASSWORD");

export const FLOOR = createActionSet("FLOOR");
export const EDIT_FLOOR = createActionSet("EDIT_FLOOR");
export const GET_FLOOR_LIST = createActionSet("GET_FLOOR_LIST");
export const GET_ALL_FLOOR_LIST = createActionSet("GET_ALL_FLOOR_LIST");
export const GET_FLOOR_BY_JOB = createActionSet("GET_FLOOR_BY_JOB");
export const GET_DOOR_PART_JOB_DOOR = createActionSet("GET_DOOR_PART_JOB_DOOR");
export const FLOOR_DETAIL = createActionSet("FLOOR_DETAIL");
export const DOORXFLOOR = createActionSet("DOORXFLOOR");
export const DELETE_FLOOR = createActionSet("DELETE_FLOOR");
export const GET_APP_ATTACHMENTS = createActionSet("GET_APP_ATTACHMENTS");

export const ADD_DOOR = createActionSet("ADD_DOOR");
export const ADD_BATCH_DOOR = createActionSet("ADD_BATCH_DOOR");
export const EDIT_DOOR = createActionSet("EDIT_DOOR");
export const GET_DOOR_LIST = createActionSet("GET_DOOR_LIST");
export const GET_DOOR_LIST_APPEND = createActionSet("GET_DOOR_LIST_APPEND");
export const DOOR_DETAIL = createActionSet("DOOR_DETAIL");
export const DOORMAPPED = createActionSet("DOORMAPPED");
export const DELETE_DOOR = createActionSet("DELETE_DOOR");
export const DOOR_TRASH_LIST = createActionSet("DOOR_TRASH_LIST");
export const RESTORE_DOOR_TRASH = createActionSet("RESTORE_DOOR_TRASH");

export const ADD_DOOR_PART = createActionSet("ADD_DOOR_PART");
export const GET_PART_OF_DOOR = createActionSet("GET_PART_OF_DOOR");
export const GET_DOORPART_BY_ID = createActionSet("GET_DOORPART_BY_ID");
export const EDIT_PART = createActionSet("EDIT_PART");

export const PAST_INSPECTION = createActionSet("PAST_INSPECTION");
export const PAST_INSPECTION_DETAIL = createActionSet("PAST_INSPECTION_DETAIL");

export const PLOT = createActionSet("PLOT");

export const FORGET_PASSWORD = createActionSet("FORGET_PASSWORD");
export const CHANGE_PASSWORD = createActionSet("CHANGE_PASSWORD");

export const CLIENTS_LIST = createActionSet("CLIENTS_LIST");
export const REQUESTED_CLIENTS_LIST = createActionSet("REQUESTED_CLIENTS_LIST");
export const REJECTED_CLIENTS_LIST = createActionSet("REJECTED_CLIENTS_LIST");
export const PREVIOUS_CLIENTS_LIST = createActionSet("PREVIOUS_CLIENTS_LIST");
export const TRIAL_CLIENTS_LIST = createActionSet("TRIAL_CLIENTS_LIST");
export const UNSUBSCRIBED_CLIENTS_LIST = createActionSet(
  "UNSUBSCRIBED_CLIENTS_LIST",
);
export const UPDATE_CLIENTS_REQUEST = createActionSet("UPDATE_CLIENTS_REQUEST");
export const UPDATE_CLIENTS_STATUS = createActionSet("UPDATE_CLIENTS_STATUS");
export const CLIENTS_DETAIL = createActionSet("CLIENTS_DETAIL");
export const ADD_CLIENTS = createActionSet("ADD_CLIENTS");
export const EDIT_CLIENTS = createActionSet("EDIT_CLIENTS");
export const DELETE_CLIENTS = createActionSet("DELETE_CLIENTS");
export const CLIENTS_PEOPLE = createActionSet("CLIENTS_PEOPLE");
export const CLIENTS_MY_PEOPLE = createActionSet("CLIENTS_MY_PEOPLE");
export const CLIENTS_COMPANIES = createActionSet("CLIENTS_COMPANIES");
export const CLIENTS_DASHBOARD = createActionSet("CLIENTS_DASHBOARD");
export const CLIENTS_REGISTER = createActionSet("CLIENTS_REGISTER");

export const BUILDING_LIST = createActionSet("BUILDING_LIST");
export const ALL_BUILDING_LIST = createActionSet("ALL_BUILDING_LIST");
export const BUILDING_DETAIL = createActionSet("BUILDING_DETAIL");
export const BUILDING_DETAIL_BY_POSTALCODE = createActionSet(
  "BUILDING_DETAIL_BY_POSTALCODE",
);
export const REQUEST_BUILDING_OWNERSHIP = createActionSet(
  "REQUEST_BUILDING_OWNERSHIP",
);
export const BUILDING_OWNERSHIP_REQUESTED_BY_ME = createActionSet(
  "BUILDING_OWNERSHIP_REQUESTED_BY_ME",
);
export const BUILDING_OWNERSHIP_REQUESTED_FOR_ME = createActionSet(
  "BUILDING_OWNERSHIP_REQUESTED_FOR_ME",
);
export const BUILDING_OWNERSHIP_REQUESTED_DETAIL = createActionSet(
  "BUILDING_OWNERSHIP_REQUESTED_DETAIL",
);

export const BUILDING_OWNERSHIP_RESPONSE = createActionSet(
  "BUILDING_OWNERSHIP_RESPONSE",
);

export const ADD_BUILDING = createActionSet("ADD_BUILDING");
export const EDIT_BUILDING = createActionSet("EDIT_BUILDING");
export const DELETE_BUILDING = createActionSet("DELETE_BUILDING");
export const BUILDING_VIEWER = createActionSet("BUILDING_VIEWER");
export const ADD_BUILDING_VIEWER = createActionSet("ADD_BUILDING_VIEWER");
export const UPDATE_VIEWER = createActionSet("UPDATE_VIEWER");

// export const ADMIN_LIST = createActionSet("ADMIN_LIST");
// export const ADMIN_DETAIL = createActionSet("ADMIN_DETAIL");
export const ADD_ADMIN = createActionSet("ADD_ADMIN");
export const EDIT_USER = createActionSet("EDIT_USER");

// template
export const ADD_TEMPLATE = createActionSet("ADD_TEMPLATE");
export const GET_ALL_TEMPLATE = createActionSet("GET_ALL_TEMPLATE");
export const GET_TEMPLATE = createActionSet("GET_TEMPLATE");
export const EDIT_TEMPLATE = createActionSet("EDIT_TEMPLATE");
export const DELETE_TEMPLATE = createActionSet("DELETE_TEMPLATE");

export const ADD_JOB = createActionSet("ADD_JOB");
export const EDIT_JOB = createActionSet("EDIT_JOB");
export const GET_JOB_LIST = createActionSet("GET_JOB_LIST");
export const JOB_DETAIL = createActionSet("JOB_DETAIL");
export const DELETE_JOB_PART = createActionSet("DELETE_JOB_PART");
export const UPDATE_WORKER = createActionSet("UPDATE_WORKER");
export const GET_JOB_LOGS = createActionSet("GET_JOB_LOGS");
export const GET_DOOR_LOGS = createActionSet("GET_DOOR_LOGS");
export const UPLOAD_ATTACHMENTS = createActionSet("UPLOAD_ATTACHMENTS");

export const USER_WORKER_LIST = createActionSet("USER_WORKER_LIST");
export const USER_DETAIL = createActionSet("USER_DETAIL");

// MAINTENANCE REQUEST
export const GET_ALL_COMPLAINS = createActionSet("GET_ALL_COMPLAINS");
export const GET_COMPLAIN = createActionSet("GET_COMPLAIN");

// SCHEDULE
export const GET_ALL_SCHEDULE = createActionSet("GET_ALL_SCHEDULE");
export const GET_SCHEDULE = createActionSet("GET_SCHEDULE");

//WORKER TASK LIST
export const WORKER_SCHEDULED_TASK = createActionSet("WORKER_SCHEDULED_TASK");
export const WORKER_COMPLETED_TASK = createActionSet("WORKER_COMPLETED_TASK");

export const ADD_IRONMONGERY_CATEGORY = createActionSet(
  "ADD_IRONMONGERY_CATEGORY",
);

export const UPDATE_IRONMONGERY_CATEGORY = createActionSet(
  "UPDATE_IRONMONGERY_CATEGORY",
);

export const GET_ALL_IRONMONGERY_CATEGORY = createActionSet(
  "GET_ALL_IRONMONGERY_CATEGORY",
);

export const GET_IRONMONGERY_CATEGORY_DETAIL = createActionSet(
  "GET_IRONMONGERY_CATEGORY_DETAIL",
);

export const DELETE_IRONMONGERY_CATEGORY = createActionSet(
  "DELETE_IRONMONGERY_CATEGORY",
);

export const GET_ALL_IRONMONGERY_PRODUCT = createActionSet(
  "GET_ALL_IRONMONGERY_PRODUCT",
);

export const GET_IRONMONGERY_PRODUCT_DETAIL = createActionSet(
  "GET_IRONMONGERY_PRODUCT_DETAIL",
);

export const ADD_IRONMONGERY_PRODUCT = createActionSet(
  "ADD_IRONMONGERY_PRODUCT",
);
export const EDIT_IRONMONGERY_PRODUCT = createActionSet(
  "EDIT_IRONMONGERY_PRODUCT",
);
export const DELETE_IRONMONGERY_PRODUCT = createActionSet(
  "DELETE_IRONMONGERY_PRODUCT",
);

export const ADD_IRONMONGERY_PACKS = createActionSet("ADD_IRONMONGERY_PACKS");

export const EDIT_IRONMONGERY_PACKS = createActionSet("EDIT_IRONMONGERY_PACKS");

export const GET_IRONMONGERY_PACKS = createActionSet("GET_IRONMONGERY_PACKS");

export const DELETE_IRONMONGERY_PACKS = createActionSet(
  "DELETE_IRONMONGERY_PACKS",
);

export const GET_IRONMONGERY_PACK_DETAIL = createActionSet(
  "GET_IRONMONGERY_PACK_DETAIL",
);

// Tier
export const ADD_TIER = createActionSet("ADD_TIER");
export const GET_ALL_TIER = createActionSet("GET_ALL_TIER");
export const GET_TIER_DETAIL = createActionSet("GET_TIER_DETAIL");
export const EDIT_TIER = createActionSet("EDIT_TIER");
export const DELETE_TIER = createActionSet("DELETE_TIER");

export const SUPERADMIN_DASHBOARD = createActionSet("SUPERADMIN_DASHBOARD");
export const SUPERADMIN_PREVIOUOS_DASHBOARD = createActionSet(
  "SUPERADMIN_PREVIOUOS_DASHBOARD",
);

export const SUBSCRIPTION_HISTORY = createActionSet("SUBSCRIPTION_HISTORY");
export const CLIENT_SUBSCRIPTION = createActionSet("CLIENT_SUBSCRIPTION");

export const MAKE_PAYMENT = createActionSet("MAKE_PAYMENT");
export const CUSTOMER_PORTAL = createActionSet("CUSTOMER_PORTAL");
export const START_TRIAL = createActionSet("START_TRIAL");
export const EXTEND_TRIAL = createActionSet("EXTEND_TRIAL");
