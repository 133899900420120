import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { isValid, useInput, validator } from "../../../../../utils";

import {
  DefaultButton,
  Box,
  FormInput,
  ImageUpload,
  Grid,
  DisplayFileName,
  SelectField,
  KeyValue,
} from "../../../../common";
import { ActivityIndicator, Card, CompWrapper } from "../../../../hoc";
import { postBatchDoorAction } from "../../../../../actions";
import { Title, Image, Underline } from "../../../../generics";

import UPLOAD from "../../../../../assets/icons/upload.png";
import BATCHDOORSAMPLE from "../../../../../assets/files/BatchDoor.xlsx";

const SYSTEM_FIELDS = [
  { label: "Door Number*", value: "fileDoorName" },
  { label: "Door Type*", value: "fileType" },
  { label: "Fire Rating*", value: "fileFireRating" },
  { label: "S.O. Height*", value: "fileSoHeight" },
  { label: "S.O. Width*", value: "fileSoWidth" },
  { label: "S.O. Depth*", value: "fileSoDepth" },
  { label: "Acoustic Rating (dB)", value: "fileAcousticRating" },
  { label: "Ironmongery Pack", value: "fileIronmongery" },
  { label: "Set", value: "fileSet" },
  { label: "Acting", value: "fileActing" },
  { label: "Latched", value: "fileLatched" },
  { label: "Overpanel", value: "fileOverpanel" },
  { label: "Side Lights", value: "fileSideLight" },
];

export const AddDoorBatchPage = () => {
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const { location, params, navigation } = useNavigation();
  const { addBatchDoorLoader } = useSelector((state) => state.door);
  const { floorBeadcrumb } = useSelector((state) => state.floor);
  const [csvFile, setCsvFile] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState(null);

  const { data, onChangeHandler } = useInput({
    fileDoorName: "",
    fileType: "",
    fileFireRating: "",
    fileSoHeight: "",
    fileSoWidth: "",
    fileSoDepth: "",
    fileAcousticRating: "",
    fileIronmongery: "",
    fileSet: "",
    fileActing: "",
    fileLatched: "",
    fileOverpanel: "",
    fileSideLight: "",
  });

  const changeImage = (data) => {
    if (data && data[0]) {
      setCsvFile(data[0]?.file);
      readXlsxFile(data[0]?.file).then((rows) => {
        setCsvHeaders(rows[0]);
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const {
      fileDoorName,
      fileType,
      fileFireRating,
      fileSoHeight,
      fileSoWidth,
      fileSoDepth,
      fileAcousticRating,
      fileIronmongery,
      fileSet,
      fileActing,
      fileLatched,
      fileOverpanel,
      fileSideLight,
    } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("fileDoorName", fileDoorName.length === 0, () => {
      showMessage("Door Number must be selected!");
    });
    validate("fileType", fileType.length === 0, () => {
      showMessage("Door Type must be selected!");
    });
    validate("fileFireRating", fileFireRating.length === 0, () => {
      showMessage("Fire Rating must be selected!");
    });
    validate("fileSoHeight", fileSoHeight.length === 0, () => {
      showMessage("S.O. Height must be selected!");
    });
    validate("fileSoWidth", fileSoWidth.length === 0, () => {
      showMessage("S.O. Width must be selected!");
    });
    validate("fileSoDepth", fileSoDepth.length === 0, () => {
      showMessage("S.O. Depth must be selected!");
    });

    if (!isValid(catchedErrors)) {
      return;
    }

    let formData = new FormData();

    await formData.append("floorId", params?.id);
    await formData.append("excelFile", csvFile);
    await formData.append("fileDoorName", fileDoorName);
    await formData.append("fileType", fileType);
    await formData.append("fileFireRating", fileFireRating);
    await formData.append("fileSoHeight", fileSoHeight);
    await formData.append("fileSoWidth", fileSoWidth);
    await formData.append("fileSoDepth", fileSoDepth);
    if (fileAcousticRating.length) {
      await formData.append("fileAcousticRating", fileAcousticRating);
    }
    if (fileIronmongery.length) {
      await formData.append("fileIronmongery", fileIronmongery);
    }
    if (fileSet.length) {
      await formData.append("fileSet", fileSet);
    }
    if (fileActing.length) {
      await formData.append("fileActing", fileActing);
    }
    if (fileActing.length) {
      await formData.append("fileActing", fileActing);
    }
    if (fileLatched.length) {
      await formData.append("fileLatched", fileLatched);
    }
    if (fileOverpanel.length) {
      await formData.append("fileOverpanel", fileOverpanel);
    }
    if (fileSideLight.length) {
      await formData.append("fileSideLight", fileSideLight);
    }

    dispatch(
      postBatchDoorAction(formData, toast, () =>
        navigation.navigate(location.pathname.replace("/adddoor-batch", "")),
      ),
    );
  };
  return (
    <CompWrapper
      breadCrumbData={Object.assign(floorBeadcrumb, {
        batch: { name: "Add Batch", id: "" },
      })}>
      <Card>
        <Box>
          <Box mb={20}>
            <Title style={{ fontSize: 20 }}>Batch Door Upload</Title>
          </Box>

          <Grid.Container lg={2}>
            <FormInput label="Excel File">
              <ImageUpload
                accept=".xlsx, .xls"
                onChange={(e) => changeImage(e)}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                buttonclick={
                  <Box>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box mb={10}>
                    <Grid.Container lg={2} md={2} sm={1}>
                      {imageData &&
                        imageData.map((item, index) => (
                          <DisplayFileName
                            key={index}
                            label={item?.file?.name}
                            onClick={() => {
                              onRemove(index);
                              setCsvFile(null);
                              setCsvHeaders(null);
                            }}
                          />
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>
            <Box>
              <Title mb={20}>Instructions and Data Types**</Title>
              <KeyValue
                data={{
                  "File Type": "Excel (.xlsx)",
                  "Sample File": (
                    <Underline
                      onClick={() => window.open(BATCHDOORSAMPLE)}
                      style={{ cursor: "pointer", color: "#0284a4" }}>
                      Sample File
                    </Underline>
                  ),
                  "Fire Rating": "NFR | FD30 | FD60 | FD90 | FD120",
                  Overpanel: "TRUE | FALSE",
                  "Side Light": "1 | 2",
                  "Door Set": "single | double | leaf",
                  Acting: "single | double",
                  Latched: "TRUE | FALSE",
                  "Ironmongery Pack": "Exact name of the Ironmongery Pack",
                  "Other Fields": "Any data type",
                }}
              />
            </Box>
          </Grid.Container>
          {csvHeaders && (
            <Box mt={20}>
              <Title style={{ fontSize: 20 }} mb={20}>
                Data Mapping
              </Title>
              <Grid.Container lg={2}>
                <Title mb={20}>System Fields</Title>
                <Title mb={20}>Your excel file fields</Title>
              </Grid.Container>
              <form onSubmit={submitHandler}>
                {SYSTEM_FIELDS.map((sysField, index) => {
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid #eaeaea",
                        paddingBottom: 10,
                        marginBottom: 20,
                      }}>
                      <Grid.Container lg={2}>
                        <Box>
                          <Title>{sysField.label}</Title>
                        </Box>
                        <Box>
                          <SelectField
                            options={csvHeaders?.map((item) => {
                              return { label: item, value: item };
                            })}
                            getOptionLabel="label"
                            getOptionValue="value"
                            onChangeValue={(item) => {
                              onChangeHandler(sysField.value, item?.value)();
                            }}
                            isSearchable
                            placeholder="Select Field"
                            // defaultValue={csvHeaders
                            //   ?.map((item) => {
                            //     return { label: item, value: item };
                            //   })
                            //   .filter((item) => item.value === sysField.label)}
                          />
                        </Box>
                      </Grid.Container>
                    </div>
                  );
                })}
                <ActivityIndicator animating={addBatchDoorLoader}>
                  <DefaultButton title="Submit" type="submit" />
                </ActivityIndicator>
              </form>
            </Box>
          )}
        </Box>
      </Card>
    </CompWrapper>
  );
};
