import React, { useEffect } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import { useSelector, useDispatch } from "react-redux";

import { deleteTemplateAction, getAllTemplate } from "../../../actions";
import { useDocTitle } from "../../../hooks";
import { DefaultButton, Box } from "../../common";
import Table from "../../common/table/Table.common";
import { Bold } from "../../generics";
import { CompWrapper, Card } from "../../hoc";

export const TemplatePage = () => {
  useDocTitle("Templates");

  const dispatch = useDispatch();
  const { toast } = useAuth();
  const { getLoader, templates, templatesCount, deleteLoader } = useSelector(
    (state) => state.template,
  );

  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getAllTemplate(pageNumber));
  }, [getAllTemplate, pageNumber]);

  const viewHandler = (id) => {
    navigate(`${routes["List Template"].path}/${id}`);
  };

  const deleteHandler = (id) => {
    dispatch(deleteTemplateAction(id, toast, pageNumber));
  };

  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Templates</Bold>
            <Box>
              <DefaultButton
                title="Add"
                onClick={() => {
                  navigate(routes["Add Template"].path);
                }}
              />
            </Box>
          </Box>
          <Box mt={20}>
            <Table
              columns={[
                {
                  field: "name",
                  name: "name",
                },
                {
                  field: "description",
                  name: "description",
                },
              ]}
              data={templates?.rows}
              dataLoader={getLoader}
              totalCount={templatesCount}
              actions
              deleteLoader={deleteLoader}
              onDeleteHandler={deleteHandler}
              // onEditHandler={editHandler}
              onViewHandler={viewHandler}
            />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};

export default TemplatePage;
