import React, { useState, useEffect } from "react";
import { Modal } from "react-uicomp";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { MdAdd, MdClose } from "react-icons/md";
import { isValid, useInput, validator } from "../../../../utils";
import { CompWrapper, Card, ActivityIndicator } from "../../../hoc";
import {
  Box,
  KeyValue,
  Tab,
  DefaultButton,
  FormInput,
  InputField,
  GooglePlaces,
  PrimaryButton,
  CheckBox,
  Grid,
} from "../../../common";
import {
  Image,
  Label,
  Title,
  Bold,
  Chip,
  Heading,
  Italic,
  Link,
  Paragraph,
  Underline,
  Br,
} from "../../../generics";
import { AdminTab, UsersTab, SitesTab } from "./component";
import {
  getClientDetailAction,
  getClientPeopleAction,
  getClientSubscription,
  openCustomerPortal,
  extendTrialAction,
} from "../../../../actions";
import { FILE_URL } from "../../../../config";
import { addAdminAction } from "../../../../actions";

export const ViewClientPage = () => {
  const { toast } = useAuth();
  const { navigation, params } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { detailloading, detail } = useSelector((state) => state.client);
  const [AddContracter, setAddContractor] = useState(false);
  const [extendVisible, setExtendVisible] = useState(false);
  const [locationName, setLocationName] = React.useState("");
  const { clientSubscription, clientSubscriptionLoader, extendTrialLoader } =
    useSelector((state) => state.subscription);

  const [phone, setPhone] = useState([""]);

  const [count, setCount] = useState(false);

  useEffect(() => {
    dispatch(getClientDetailAction(params?.id));
  }, [params?.id]);

  const { data, onChangeHandler } = useInput({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    // phone: "",
    extendedDays: "",
  });

  const submitExtendHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { extendedDays } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("extendedDays", extendedDays.length === 0, () => {
      showMessage("Days can't be empty!");
    });

    validate("extendedDays", extendedDays <= 0, () => {
      showMessage("Days can't be negative!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    dispatch(
      extendTrialAction(params.id, { days: extendedDays }, toast, () =>
        setExtendVisible(false),
      ),
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, firstName, lastName, jobTitle } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("firstName", firstName.length === 0, () => {
      showMessage("First Name musn't be empty!");
    });
    validate("lastName", lastName.length === 0, () => {
      showMessage("Last Name musn't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("Email musn't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("Address musn't be empty!");
    });
    validate("jobTitle", jobTitle.length === 0, () => {
      showMessage("Job Title musn't be empty!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    const body = {
      email: email,
      role: 2,
      companyId: params?.id,
      firstName: firstName,
      lastName: lastName,
      profileAddress: { coordinates: [1, 1], value: locationName },
      profilePhone: phone,
      profileJobTitle: jobTitle,
    };

    console.log("body", body);
    dispatch(
      addAdminAction(
        body,
        () => {
          setAddContractor(false);
          data.firstName = "";
          data.lastName = "";
          data.email = "";
          data.jobTitle = "";
          setPhone([""]);
          dispatch(getClientPeopleAction(params?.id, 2, 1));
        },
        toast,
      ),
    );
  };

  const changeHandle = (index) => {
    return function (e) {
      const phoneArray = [...phone];
      phoneArray[index] = e.target.value;
      setPhone(phoneArray);
    };
  };

  const onAdd = () => {
    setPhone([...phone, ""]);
  };

  const close = (index) => {
    const phoneClone = [...phone];

    phoneClone.splice(index, 1);
    setPhone(phoneClone);
  };

  const changeEmail = () => {
    if (count) {
      data.email = "";
    } else {
      data.email = detail?.company_details?.cont_comp_email;
    }
    setCount((prev) => !prev);
  };

  const customerPortalHandler = () => {
    dispatch(openCustomerPortal(toast, params?.id));
  };

  useEffect(() => {
    dispatch(getClientSubscription(params?.id));
  }, [params?.id]);

  const getSubscriptionStatus = (item) => {
    switch (item) {
      case "trialing":
        return "Trial";
      case "incomplete" || "incomplete_expired" || "past_due" || "unpaid":
        return "UnPaid";
      case "expired_trial":
        return "Trial Expired";

      case "unsubscribed":
        return "Not Subscribed";

      case "active":
        return "Active";

      default:
        return "Canceled";
    }
  };

  return (
    <CompWrapper>
      {detail && (
        <Card>
          <Box flexBox jEnd className={"add-contractor"}>
            <PrimaryButton
              title="Add Admin"
              onClick={() => {
                setAddContractor(true);
              }}
            />
            <DefaultButton
              style={{ marginLeft: 10 }}
              title="Edit Client"
              onClick={() => {
                navigate(routes["Client"].path + "/edit/" + params.id);
              }}
            />
          </Box>
          <Box className={"contractor-wrapper"} flexBox>
            <Box mr={30}>
              <Image
                style={{ width: 200 }}
                link={`${FILE_URL}/contractor/${detail?.company_details?.cont_comp_logo}`}
                alt="Contractor image"
                className="avatar"
              />
            </Box>
            <KeyValue
              data={{
                Name: detail?.company_details?.cont_comp_name,
                Email: detail?.company_details?.cont_comp_email,
                Phone: detail?.company_details?.cont_comp_phone?.join(", "),
                Address: detail?.company_details?.cont_comp_address?.value,
              }}
            />
          </Box>
        </Card>
      )}
      <Card containerStyle={{ marginTop: 20, marginBottom: 20 }}>
        <Box flexBox vertical>
          <Box>
            <Bold mb={10}>Subscription</Bold>
          </Box>

          <Grid.Container lg={4}>
            <Box flexBox vertical alStart>
              <Bold>Tier</Bold>
              <Paragraph>{clientSubscription?.tier_details?.name}</Paragraph>
            </Box>

            <Box flexBox vertical alStart>
              <Bold>Price</Bold>
              <Paragraph>
                £ {clientSubscription?.tier_details?.price?.amount}/
                {clientSubscription?.tier_details?.price?.interval}
              </Paragraph>
            </Box>

            <Box flexBox vertical alStart>
              <Bold>Status</Bold>
              <Paragraph>
                <Chip>
                  {getSubscriptionStatus(
                    clientSubscription?.subscription_details?.status,
                  )}
                </Chip>
              </Paragraph>
            </Box>
            <Box flexBox alEnd jEnd>
              <PrimaryButton
                title="Open Customer Portal"
                onClick={() => customerPortalHandler()}
              />
            </Box>
          </Grid.Container>
          <Grid.Container lg={4}>
            <Box flexBox vertical alStart>
              <Bold>Start date</Bold>
              <Paragraph>
                {clientSubscription?.subscription_details?.start_date}
              </Paragraph>
            </Box>

            <Box flexBox vertical alStart>
              <Bold>Expiry date</Bold>
              <Paragraph>
                {clientSubscription?.subscription_details?.expiry_date}
              </Paragraph>
            </Box>
            <Box></Box>
            {clientSubscription?.subscription_details?.status === "trialing" ||
            clientSubscription?.subscription_details?.status ===
              "expired_trial" ? (
              <Box flexBox alEnd jEnd>
                <DefaultButton
                  title="Extend Trial"
                  onClick={() => setExtendVisible(true)}
                />
              </Box>
            ) : null}
          </Grid.Container>
        </Box>
      </Card>
      <Modal
        visible={extendVisible}
        onOutsideClick={() => {
          setExtendVisible(false);
        }}>
        <Box>
          <Box flexBox jCenter mb={10}>
            <Title>Extend Trial</Title>
          </Box>
          <form onSubmit={submitExtendHandler}>
            <FormInput label="Number of days">
              <InputField
                placeholder="Number of days"
                name="extendedDays"
                value={data.extendedDays}
                onChange={onChangeHandler("extendedDays")}
                type="number"
              />
            </FormInput>

            <ActivityIndicator animating={extendTrialLoader}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Modal>
      <Modal
        visible={AddContracter}
        onOutsideClick={() => {
          setAddContractor(false);
        }}>
        <Box>
          <Box flexBox jCenter mb={10}>
            <Title>Add Admin</Title>
          </Box>
          <form onSubmit={submitHandler}>
            <Box flexBox alCenter jSpace>
              <FormInput label="First Name">
                <InputField
                  placeholder="First Name"
                  name="firstName"
                  value={data.firstName}
                  onChange={onChangeHandler("firstName")}
                  type="text"
                />
              </FormInput>
              <Box ml={10}>
                <FormInput label="Last Name">
                  <InputField
                    placeholder="Last Name"
                    name="lastName"
                    value={data.lastName}
                    onChange={onChangeHandler("lastName")}
                    type="text"
                  />
                </FormInput>
              </Box>
            </Box>
            <Box flexBox alCenter jSpace>
              <FormInput label="Address">
                <GooglePlaces setLocationName={setLocationName} />
              </FormInput>
              <Box ml={10}>
                <FormInput label="Job Title">
                  <InputField
                    placeholder="Job Title"
                    name="jobTitle"
                    value={data.jobTitle}
                    onChange={onChangeHandler("jobTitle")}
                    type="text"
                  />
                </FormInput>
              </Box>
            </Box>
            <FormInput
              label="Email"
              newElement={<Check onClick={changeEmail} checked={count} />}>
              <InputField
                placeholder="Email"
                name="email"
                value={data.email}
                onChange={onChangeHandler("email")}
                type="email"
              />
            </FormInput>

            <FormInput
              label="Phone"
              newElement={
                <PrimaryButton
                  onClick={onAdd}
                  style={{ cursor: "pointer" }}
                  title="Add Field"
                  type="button"
                />
              }>
              <Box>
                {phone.length > 0 &&
                  phone.map((item, index) => {
                    return (
                      <Box flexBox alCenter key={index} mb={10}>
                        <InputField
                          placeholder="Phone"
                          name="phone"
                          value={item}
                          // onChange={onChangeHandler("phone")}
                          onChange={changeHandle(index)}
                          type="number"
                          min="0"
                        />
                        {phone.length > 1 && (
                          <Box ml={20}>
                            <div
                              type="button"
                              className="close"
                              style={{ cursor: "pointer" }}
                              onClick={() => close(index)}>
                              <MdClose color="red" />
                            </div>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </FormInput>

            <DefaultButton title="Submit" type="submit" />
          </form>
        </Box>
      </Modal>
      <Box>
        <Tab
          panes={[
            {
              render: () => <AdminTab />,
              title: "Admin",
            },
            {
              render: () => <SitesTab />,
              title: "Building",
            },
            {
              render: () => <UsersTab />,
              title: "Users",
            },
          ]}
        />
      </Box>
    </CompWrapper>
  );
};

const Check = ({ onClick, checked }) => {
  return (
    <label style={{ cursor: "pointer" }}>
      {" "}
      <input type="checkbox" onChange={onClick} checked={checked} /> Use Company
      Email
    </label>
  );
};
