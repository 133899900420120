import React, { useEffect, useState } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { getIronmongeryPackDetail } from "../../../../actions";
import { Box } from "../../../common";
import { CompWrapper } from "../../../hoc";
import { ProductTemplate } from "../../ironmongeryPage/components/productTemplate.component";

export const IronmongerypackView = () => {
  const dispatch = useDispatch();
  const { params } = useNavigation();
  const { ironmongeryPackDetail, ironmongeryPackDetailLoader } = useSelector(
    (state) => state.ironmongeryPack,
  );
  const [formatedData, setFormatedData] = useState();

  useEffect(() => {
    refetch();
    // dispatch(getIronmongeryPackDetail(params.id));
  }, []);

  const refetch = () => {
    dispatch(getIronmongeryPackDetail(params.id));
  };

  useEffect(() => {
    if (!!ironmongeryPackDetail) {
      const newGroup = {
        name: ironmongeryPackDetail.group_details.name,
        description: ironmongeryPackDetail.group_details.description,
        groupList: ironmongeryPackDetail.product_list.map(
          ({ part_name, id, product_details, category_details }) => {
            return {
              partName: part_name,
              id: id,
              productId: product_details.id,
              ironmongeryCategory: category_details.id,
            };
          },
        ),
      };

      setFormatedData(newGroup);
    }
  }, [ironmongeryPackDetail]);

  return (
    <CompWrapper>
      {" "}
      {!!formatedData ? (
        <ProductTemplate
          id={params.id}
          reFetch={refetch}
          data={formatedData}
          isViewing
        />
      ) : (
        <Box>Loading...</Box>
      )}
    </CompWrapper>
  );
};
