import React from "react";
import { useNavigation } from "react-auth-navigation";
import { capitalizeFirstLetter } from "../../../../utils";
import { Box } from "../../../common";
import { Paragraph } from "../../../generics";

export const StatusLabel = ({ color, label, value, ...rest }) => {
  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;

  return (
    <Box
      {...rest}
      flexBox
      alCenter
      style={{ cursor: "pointer" }}
      className="dashboard-job-status">
      <Box className={`icon ${color}`}></Box>
      <Box ml={10} flex={1} flexBox alCenter jSpace>
        <Paragraph>{capitalizeFirstLetter(label)}</Paragraph>
        <Paragraph ml={20} className="lighttext">
          {value}
        </Paragraph>
      </Box>
    </Box>
  );
};
