import React, { useEffect } from "react";
import { Modal } from "react-uicomp";
import { MdAdd, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { isValid, useInput, validator } from "../../../../../../utils";
import {
  Box,
  DefaultButton,
  FormInput,
  InputField,
  GooglePlaces,
} from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { Title, Image, Bold, Paragraph } from "../../../../../generics";
import { ActivityIndicator, Card } from "../../../../../hoc";
import { getPastInspectionAction } from "../../../../../../actions";

export const PastInspectionSection = () => {
  const dispatch = useDispatch();
  const { params, navigation, location } = useNavigation();
  const { navigate, routes } = navigation;
  const pastInspection = useSelector((state) => state.pastInspection);
  const {
    pastInspectionList,
    pastInspectionLoader,
    pastInspectionListCount,
  } = pastInspection;

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getPastInspectionAction(params?.id, pageNumber));
  }, [params?.id, pageNumber]);

  const viewHandler = (item) => {
    // navigate(`/building/past-inspection/${item?.job_part_id}`);
    navigate(`${location.pathname}/past-inspection/${item?.job_part_id}`);
  };

  return (
    <Box>
      <Card>
        <Box flexBox alCenter jSpace>
          <Bold>Past Inspection</Bold>
        </Box>
        <Box mt={20}>
          <Table
            columns={[
              {
                field: "job_date",
                name: "Job Date",
                render: (rowData) => (
                  <Paragraph>{rowData?.slice(0, 10)}</Paragraph>
                ),
              },
              {
                field: "job_details",
                name: "Job Id",
                render: (rowData) => (
                  <Paragraph>{rowData?.job_ident_no}</Paragraph>
                ),
              },
              {
                field: "job_details",
                name: "Job Type",
                render: (rowData) => <Paragraph>{rowData?.job_type}</Paragraph>,
              },
              {
                field: "job_details",
                name: "Job Description",
                render: (rowData) => (
                  <Paragraph>{rowData?.job_description}</Paragraph>
                ),
              },
              {
                field: "job_details",
                name: "Job Status",
                render: (rowData) => (
                  <Paragraph>
                    {rowData?.job_status !== null
                      ? rowData?.job_status
                        ? "Completed"
                        : "OnGoing"
                      : "Not Started"}
                  </Paragraph>
                ),
              },
            ]}
            data={pastInspectionList}
            dataLoader={pastInspectionLoader}
            totalCount={pastInspectionListCount}
            actions
            // deleteLoader={false}
            // onDeleteHandler={deleteHandler}
            // onEditHandler={editHandler}
            onViewHandler={viewHandler}
            viewBug
          />
        </Box>
      </Card>
    </Box>
  );
};
