import React, { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import {
  LoginPage,
  NotFoundPage,
  ClientPage,
  TemplatePage,
  MaintenanceRequestPage,
  SchedulePage,
  FloorWebView,
  TierListPage,
  AddTierPage,
  EditTierPage,
  DashboardPage,
  RegistrationPage,
  SubscriptionPage,
} from "../pages";
import { BuildingPage } from "../pages/building/buildingPage";
import { AddClientPage } from "../pages/clientPage/add";
import { ViewClientPage } from "../pages/clientPage/view";
import { AddTemplatePage } from "../pages/templatePage/add";
import { ViewTemplatePage } from "../pages/templatePage/view";
import { EditClientPage } from "../pages/clientPage/edit";
import { ViewBuildingPage } from "../pages/building/buildingPage/buildingDetail";
import { AddBuildingPage } from "../pages/building/buildingPage/add";
import { EditBuildingPage } from "../pages/building/buildingPage/edit";
import { FloorPage } from "../pages/building/buildingPage/floor"; // NEW CHANGES HERE
// import { FloorPage } from "../pages/building/buildingPage/__old__/floor"; // OLD
import { JobsPage } from "../pages/building/jobsPage";
import { ClientDashboard } from "../pages/building/dashboardPage";
import { DoorDetailPage } from "../pages/building/buildingPage/door/DoorDetailPage";
import { AddDoorBatchPage } from "../pages/building/buildingPage/door/addDoorBatchPage";
import { AddJobPage } from "../pages/building/jobsPage/add";
import { AddDoorPartPage } from "../pages/building/buildingPage/door/addDoorPartPage";
import { EditPartPage } from "../pages/building/buildingPage/door/editPartPage";
import { EditJobPage } from "../pages/building/jobsPage/edit";
import { ViewMaintenanceRequestPage } from "../pages/maintenanceRequestPage/view";

import { PartsPage } from "../pages/building/buildingPage/part";
import { PastInspectionPage } from "../pages/building/buildingPage/pastInspection";
import { JobDetailPage } from "../pages/building/jobsPage/viewJobDetail";
import { EditMaintenancePage } from "../pages/maintenanceRequestPage/editMaintenancePage";
import { CreateJobFromSchedulePage } from "../pages/schedulePage/createJob";
import { CreateJobFromMaintenancePage } from "../pages/maintenanceRequestPage/createJob";
import { UsersPage } from "../pages/building/usersPage";
import { UserDetail } from "../pages/building/usersPage/userDetail";

// IRONMONGERY
import {
  IronmongeryCategory,
  IronmongeryList,
  IronmongeryCategoryDetail,
  AddIronmongery,
  IronmongeryDetail,
  EditIronmongery,
} from "../pages/ironmongeryPage";

// auth
import { VerifyToken, ForgetPassword } from "../pages/authPage/components";

import { AddCsv } from "../pages/addCsvPage/AddCsv.page";
// icons
import {
  MdDashboard,
  RiBuildingFill,
  IoHammer,
  RiTimerFill,
  FaTools,
  HiUsers,
  FaUsers,
  FaWpforms,
  FaBoxOpen,
} from "react-icons/all";
import { ClientListPage } from "../pages/clientPage/list";
import {
  IronmongerypackAdd,
  IronmongerypackList,
  IronmongerypackView,
} from "../pages/ironmongerypack";
import {
  RequestDetailPage,
  RequestForMePage,
  SentRequestListPage,
  TransferRequestPage,
} from "../pages/building/request";
import { NewUsersDashboard } from "../pages/newUsersDashboard/NewUsersDashboard.page";
import { ClientTabPage } from "../pages/clientPage";
import { PreviousPage } from "../pages/clientPage/previous";
import { ClientRequestPage } from "../pages/clientPage/request";
import { CurrentRequestPage } from "../pages/clientPage/request/currentRequestPage";
import { TrialPage } from "../pages/clientPage/trial";
import { RejectedRequestPage } from "../pages/clientPage/request/rejectedRequestPage";
import { UnsubscribedPage } from "../pages/clientPage/unSubscribed";
import PrivacyPage from "../pages/privacy/privacy.page";

const Redirect = ({ to }) => {
  const {
    navigation: { navigate },
  } = useNavigation();

  useEffect(() => {
    navigate(to);
  }, [to, navigate]);

  return null;
};

const RedirectCustom = () => {
  const {
    navigation: { navigate },
    location,
  } = useNavigation();
  const pathName = location.pathname.replace(
    location.pathname.substr(location.pathname.lastIndexOf("/")),
    "",
  );
  useEffect(() => {
    navigate(pathName);
  }, [navigate, pathName]);

  return null;
};

export const publicPaths = [
  {
    name: "Root",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    restricted: true,
  },
  {
    name: "Registration",
    path: "/registration/:id",
    component: RegistrationPage,
    restricted: true,
  },
  {
    name: "FloorMapWebView",
    path: "/floor/:id",
    component: FloorWebView,
    restricted: false,
  },
  {
    name: "Auth",
    path: "/auth",
    component: () => <Redirect to="/" />,
    restricted: true,
    subPaths: [
      {
        name: "Verify",
        path: "/verify",
        component: VerifyToken,
        restricted: true,
      },
      {
        name: "Forget",
        path: "/forget",
        component: ForgetPassword,
        restricted: true,
      },
    ],
  },
  {
    name: "Privacy",
    path: "/privacy",
    component: PrivacyPage,
    restricted: false,
  },

  // {
  //   name: "SignUp",
  //   path: "/signup",
  //   component: SigninPage,
  //   restricted: true,
  // },

  {
    path: null,
    component: NotFoundPage,
  },
];

export const privatePaths = [
  {
    name: "Dashboard",
    key: "SuperAdmin Dashboard",
    path: "/dashboard",
    component: DashboardPage,
    props: {
      icon: <MdDashboard />,
    },
  },
  {
    name: "Client",
    path: "/client",
    component: () => <Redirect to="/client/summary" />,
    // component: ClientTabPage,
    props: {
      icon: <FaUsers />,
    },

    subPaths: [
      {
        name: "Client Page",
        path: "/summary",
        // component: ClientPage,
        component: ClientTabPage,

        props: {
          group: "Client",
        },
        nestedPaths: [
          {
            name: "Client Root",
            path: "/",
            component: () => <Redirect to="/client/summary/current" />,
            visible: false,
          },
          {
            name: "Current Clients",
            path: "/current",
            component: ClientPage,
            props: {
              group: "Client Root",
            },
          },
          {
            name: "Previous Clients",
            path: "/previous",
            component: PreviousPage,
            props: {
              group: "Client Root",
            },
          },
          {
            name: "Trial Clients",
            path: "/trial",
            component: TrialPage,
            props: {
              group: "Client Root",
            },
          },
          {
            name: "Unsubscribed Clients",
            path: "/unsubscribed",
            component: UnsubscribedPage,
            props: {
              group: "Client Root",
            },
          },
        ],
        subPaths: [
          {
            name: "View Client",
            path: "/view/:id",
            component: ViewClientPage,
            // props: {
            //   group: "Client",
            // },
          },
        ],
        // subPaths: [
        //   {
        //     name: "List Client",
        //     path: "/list",
        //     component: ClientListPage,
        //     // props: {
        //     //   group: "Client",
        //     // },
        //     subPaths: [
        //       {
        //         name: "View Client",
        //         path: "/:id",
        //         component: ViewClientPage,
        //         // props: {
        //         //   group: "Client",
        //         // },
        //       },
        //     ],
        //   },
        // ],
      },

      {
        name: "Client Request",
        path: "/request",
        component: ClientRequestPage,
        props: {
          group: "Client",
        },
        nestedPaths: [
          {
            name: "Client Request Root",
            path: "/",
            component: () => <Redirect to="/client/request/current" />,
            visible: false,
          },
          {
            name: "Current Request",
            path: "/current",
            component: CurrentRequestPage,
            props: {
              group: "Client Requests",
            },
          },
          {
            name: "Rejected Request",
            path: "/rejected",
            component: RejectedRequestPage,
            props: {
              group: "Client Requests",
            },
          },
        ],
        subPaths: [
          {
            name: "View Client",
            path: "/view/:id",
            component: ViewClientPage,
            // props: {
            //   group: "Client",
            // },
          },
        ],
      },

      {
        name: "Add Client",
        path: "/add",
        component: AddClientPage,
        props: {
          group: "Client",
        },
      },
      {
        name: "Edit Contractor",
        path: "/edit/:id",
        component: EditClientPage,
        // props: {
        //   group: "Client",
        // },
      },
    ],
  },
  {
    name: "Template",
    path: "/template",
    component: () => <Redirect to="/template/list" />,
    props: {
      icon: <FaWpforms />,
    },
    subPaths: [
      {
        name: "List Template",
        path: "/list",
        component: TemplatePage,
        props: {
          group: "Template",
        },
        subPaths: [
          {
            name: "View Template",
            path: "/:id",
            component: ViewTemplatePage,
          },
        ],
      },
      {
        name: "Add Template",
        path: "/add",
        component: AddTemplatePage,
        props: {
          group: "Template",
        },
      },
    ],
  },

  // USER DASHBOARD -> For Unverified, Unpaid, Rejected User
  {
    name: "Dashboard",
    key: "User Dashboard",
    path: "/user-dashboard",
    component: NewUsersDashboard,
    props: {
      icon: <MdDashboard />,
    },
  },
  {
    name: "Dashboard",
    key: "Client Dashboard",
    path: "/client-dashboard",
    component: ClientDashboard,
    props: {
      icon: <MdDashboard />,
    },
  },
  {
    name: "Building",
    path: "/building",
    component: () => <Redirect to="/building/list" />,

    props: {
      icon: <RiBuildingFill />,
    },
    subPaths: [
      {
        name: "List Buildings",
        path: "/list",
        component: BuildingPage,
        props: {
          group: "Building",
        },
        exact: true,
      },
      {
        name: "Add Building",
        path: "/add",
        component: AddBuildingPage,
        props: {
          group: "Building",
        },
      },
      {
        name: "Transfer Requests",
        path: "/transfer-request",
        component: TransferRequestPage,
        // component: () => <Redirect to="/building/transfer-request/" />,
        props: {
          group: "Building",
        },
        subPaths: [
          {
            name: "View Ownership",
            path: "/view/:id",
            component: RequestDetailPage,
          },
        ],
        nestedPaths: [
          {
            name: "Transfer Requests Root",
            path: "/",
            component: () => <Redirect to="/building/transfer-request/inbox" />,
            visible: false,
          },
          {
            name: "Inbox",
            path: "/inbox",
            component: RequestForMePage,
            props: {
              group: "Transfer Requests",
            },
          },
          {
            name: "Sent",
            path: "/sent",
            component: SentRequestListPage,
            props: {
              group: "Transfer Requests",
            },
          },
        ],
      },
      {
        name: "Edit Building",
        path: "/edit/:id",
        component: EditBuildingPage,
      },
      {
        name: "View Building",
        path: "/detail/:id",
        component: ViewBuildingPage,
        subPaths: [
          {
            name: "Floor",
            path: "/floor",
            component: () => <RedirectCustom />,
            subPaths: [
              {
                name: "Floor Detail",
                path: "/:id",
                component: FloorPage,
                subPaths: [
                  {
                    name: "Door ",
                    path: "/door",
                    component: () => <RedirectCustom />,
                    subPaths: [
                      {
                        name: "Door Detail",
                        path: "/:id",
                        component: DoorDetailPage,
                        subPaths: [
                          {
                            name: "Part ",
                            path: "/part",
                            component: () => <RedirectCustom />,
                            subPaths: [
                              {
                                name: "Part Detail",
                                path: "/:id",
                                component: PartsPage,
                                subPaths: [
                                  {
                                    name: "Past Inspection",
                                    path: "/past-inspection/:id",
                                    component: PastInspectionPage,
                                  },
                                ],
                              },
                              {
                                name: "Add Part",
                                path: "/add",
                                component: AddDoorPartPage,
                              },
                            ],
                          },
                          {
                            name: "Edit Part",
                            path: "/editpart/:id",
                            component: EditPartPage,
                          },
                          {
                            name: "Door Batch",
                            path: "/adddoor-batch/:id",
                            component: AddDoorBatchPage,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Door Batch",
                    path: "/adddoor-batch",
                    component: AddDoorBatchPage,
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      //   name: "View Building",
      //   path: "/view/:id",
      //   component: ViewBuildingPage,
      // },
      // {
      //   name: "Floor",
      //   path: "/floor/:id",
      //   component: FloorPage,
      // },
      // {
      //   name: "Door",
      //   path: "/door/:id",
      //   component: DoorDetailPage,
      // },
      // {
      //   name: "Door Batch",
      //   path: "/adddoor-batch/:id",
      //   component: AddDoorBatchPage,
      // },
      // {
      //   name: "Add Part",
      //   path: "/door/addpart",
      //   component: AddDoorPartPage,
      // },
      // {
      //   name: "Edit Part",
      //   path: "/door/editpart/:id",
      //   component: EditPartPage,
      // },
      // {
      //   name: "Part",
      //   path: "/part/:id",
      //   component: PartsPage,
      // },
      // {
      //   name: "Past Inspection",
      //   path: "/past-inspection/:id",
      //   component: PastInspectionPage,
      // },
    ],
  },
  // {
  //   name: "Jobs",
  //   path: "/jobs",
  //   component: DashboardPage,
  // },

  {
    name: "Jobs",
    path: "/jobs",
    component: () => <Redirect to="/jobs/list" />,
    props: {
      icon: <IoHammer />,
    },
    subPaths: [
      {
        name: "List Jobs",
        path: "/list",
        component: JobsPage,
        props: {
          group: "Jobs",
        },
      },
      {
        name: "Add Job",
        path: "/add",
        component: AddJobPage,
        props: {
          group: "Jobs",
        },
      },
      {
        name: "Edit Jobs",
        path: "/edit/:id",
        component: EditJobPage,
      },
      {
        name: "View Job",
        path: "/detail/:id",
        component: JobDetailPage,
        subPaths: [
          {
            name: "Job Detail",
            path: "/past-inspection/:id",
            component: PastInspectionPage,
          },
        ],
      },
    ],
  },

  {
    name: "Schedule",
    path: "/schedule",
    component: SchedulePage,
    props: {
      icon: <RiTimerFill />,
    },
    subPaths: [
      {
        name: "Create Job",
        path: "/create-job",
        component: CreateJobFromSchedulePage,
      },
    ],
  },
  {
    name: "Maintenance Request",
    path: "/maintenance-request",
    component: MaintenanceRequestPage,
    props: {
      icon: <FaTools size={16} />,
    },
    subPaths: [
      {
        name: "View Maintenance Request",
        path: "/view/:id",
        component: ViewMaintenanceRequestPage,
      },
      {
        name: "Edit Maintenance Request",
        path: "/edit/:id",
        component: EditMaintenancePage,
      },
      {
        name: "Create Maintenance Job",
        path: "/create-job",
        component: CreateJobFromMaintenancePage,
      },
    ],
  },
  {
    name: "User Profile",
    key: "Users",
    path: "/users",
    component: UsersPage,
    props: {
      icon: <HiUsers />,
    },
    subPaths: [
      {
        name: "User Detail",
        key: "User Detail",
        path: "/detail/:id",
        component: UserDetail,
      },
    ],
  },
  {
    name: "Ironmongery",
    path: "/ironmongery",
    component: () => <Redirect to="/ironmongery/list" />,
    props: {
      icon: <FaBoxOpen />,
    },
    subPaths: [
      {
        name: "Ironmongery List",
        path: "/list",
        component: IronmongeryList,
        props: {
          group: "Ironmongery",
        },
      },
      {
        name: "Ironmongery add",
        path: "/product-add",
        component: AddIronmongery,
      },
      {
        name: "Ironmongery detail",
        path: "/product/:id",
        component: IronmongeryDetail,
      },
      {
        name: "Ironmongery edit",
        path: "/edit/:id",
        component: EditIronmongery,
      },
      {
        name: "Ironmongery Category",
        path: "/category",
        component: IronmongeryCategory,
        props: {
          group: "Ironmongery",
        },
        subPaths: [
          {
            name: "Ironmongery Category Detail",
            path: "/:id",
            component: IronmongeryCategoryDetail,
          },
        ],
      },
    ],
  },

  {
    name: "Ironmongery Pack",
    path: "/ironmongery-pack",
    component: () => <Redirect to="/ironmongery-pack/list" />,
    props: {
      icon: <FaWpforms />,
    },
    subPaths: [
      {
        name: "Ironmongery Packs",
        path: "/list",
        component: IronmongerypackList,
        props: {
          group: "Ironmongery Pack",
        },
        subPaths: [
          {
            name: "View Ironmongery Pack",
            path: "/:id",
            component: IronmongerypackView,
          },
        ],
      },
      {
        name: "Add Ironmongery Pack",
        path: "/add",
        component: IronmongerypackAdd,
        props: {
          group: "Ironmongery Pack",
        },
      },
    ],
  },

  {
    name: "Tier",
    path: "/tier",
    component: () => <Redirect to="/tier/list" />,
    // component: TierListPage,
    props: {
      icon: <FaUsers />,
    },
    subPaths: [
      {
        name: "List Tier",
        path: "/list",
        component: TierListPage,
        props: {
          group: "Tier",
        },
      },

      {
        name: "Add Tier",
        path: "/add",
        component: AddTierPage,
        props: {
          group: "Tier",
        },
      },
      {
        name: "Edit Tier",
        path: "/edit/:id",
        component: EditTierPage,
      },
    ],
  },
];
