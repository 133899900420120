import React, { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { getAllSchedule } from "../../../actions";
import { useDocTitle } from "../../../hooks";
import { Box } from "../../common";
import AlternateTable from "../../common/table/AlternateTable.common";
import { Bold, Chip } from "../../generics";
import { CompWrapper, Card } from "../../hoc";

export const SchedulePage = () => {
  useDocTitle("Schedule");

  const dispatch = useDispatch();
  const { getLoader, schedules, schedulesCount } = useSelector(
    (state) => state.schedule,
  );

  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getAllSchedule(pageNumber));
  }, [dispatch, pageNumber]);

  // const viewHandler = ({ schedule_details: { id } }) => {
  //   navigate(`${routes["Schedule"].path}/view/${id}`);
  // };

  const createHandler = (item) => {
    navigate({ pathname: routes["Create Job"].path, state: item });
  };
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Schedules</Bold>
          </Box>
          <Box mt={20}>
            <AlternateTable
              columns={[
                {
                  field: "site_details",
                  name: "Site Name",
                  render: ({ prop_name }) => prop_name,
                },
                {
                  field: "floor_details",
                  name: "Floor Name",
                  render: ({ floor_name }) => floor_name,
                },
                {
                  field: "door_details",
                  name: "Door Name",
                  render: ({ door_name }) => door_name,
                },
                {
                  field: "schedule_details",
                  name: "Schedule Time",
                  render: ({ sch_time }) =>
                    moment(sch_time).format("Do MMM, YYYY"),
                },
                {
                  field: "door_part_details",
                  name: "Part Name",
                  render: ({ part_name }) => part_name,
                },
                {
                  field: "schedule_details",
                  name: "Resolved",
                  render: ({ resolved }) =>
                    resolved ? (
                      <Chip style={{ backgroundColor: "#5b9540" }}>
                        Resolved
                      </Chip>
                    ) : (
                      <Chip style={{ backgroundColor: "#ff4343" }}>
                        Not Resolved
                      </Chip>
                    ),
                },
              ]}
              data={schedules}
              dataLoader={getLoader}
              totalCount={schedulesCount}
              actions
              // deleteLoader={deleteLoader}
              // onDeleteHandler={deleteHandler}
              // onEditHandler={editHandler}
              // onViewHandler={viewHandler}
              onCreateHandler={createHandler}
              viewBug
            />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};
