import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllIronmongeryCategory, getAllProduct } from "../../../../actions";
import { CompWrapper } from "../../../hoc";
import { ProductTemplate } from "../../ironmongeryPage/components/productTemplate.component";

export const IronmongerypackAdd = () => {
  const dispatch = useDispatch();

  return (
    <CompWrapper>
      <ProductTemplate />
    </CompWrapper>
  );
};
