import {
  FLOOR,
  GET_FLOOR_LIST,
  FLOOR_DETAIL,
  PLOT,
  DOORXFLOOR,
  EDIT_FLOOR,
  GET_ALL_FLOOR_LIST,
  GET_FLOOR_BY_JOB,
} from "../actions";

const initalState = {
  loader: false,
  editLoader: false,

  getFloorLoader: false,
  floorList: [],
  floorCount: null,
  plotLoader: false,

  getAllFloorLoader: false,
  allFloorList: [],

  floorByJobLoader: false,
  floorListByJob: [],

  floorDetail: null,
  floorDetailLoader: null,
  floorBeadcrumb: null,

  doorxFloorList: [],
  doorxFloorLoading: false,
};

export function floorReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case FLOOR.LOADING:
      return { ...state, loader: true };
    case FLOOR.SUCCESS:
      return { ...state, loader: false };
    case FLOOR.ERROR:
      return { ...state, loader: false };

    case EDIT_FLOOR.LOADING:
      return { ...state, editLoader: true };
    case EDIT_FLOOR.SUCCESS:
      return { ...state, editLoader: false };
    case EDIT_FLOOR.ERROR:
      return { ...state, editLoader: false };

    case GET_FLOOR_LIST.LOADING:
      return { ...state, getFloorLoader: true };
    case GET_FLOOR_LIST.SUCCESS:
      return {
        ...state,
        getFloorLoader: false,
        floorList: payload.rows,
        floorCount: payload.total,
      };
    case GET_FLOOR_LIST.ERROR:
      return { ...state, getFloorLoader: false };

    case GET_ALL_FLOOR_LIST.LOADING:
      return { ...state, getAllFloorLoader: true, allFloorList: [] };
    case GET_ALL_FLOOR_LIST.SUCCESS:
      return {
        ...state,
        getAllFloorLoader: false,
        allFloorList: payload,
      };
    case GET_ALL_FLOOR_LIST.ERROR:
      return { ...state, getAllFloorLoader: false };

    case GET_FLOOR_BY_JOB.LOADING:
      return { ...state, floorByJobLoader: true };
    case GET_FLOOR_BY_JOB.SUCCESS:
      return {
        ...state,
        floorByJobLoader: false,
        floorListByJob: payload,
      };
    case GET_FLOOR_BY_JOB.ERROR:
      return { ...state, floorByJobLoader: false };

    case FLOOR_DETAIL.CLEAR:
      return {
        ...state,
        floorDetail: null,
        floorBeadcrumb: null,
      };
    case FLOOR_DETAIL.LOADING:
      return { ...state, floorDetailLoader: true, floorDetail: null };
    case FLOOR_DETAIL.SUCCESS:
      return {
        ...state,
        floorDetailLoader: false,
        floorDetail: payload.details,
        floorBeadcrumb: payload.breadcrum,
      };
    case FLOOR_DETAIL.ERROR:
      return { ...state, floorDetailLoader: false };

    case DOORXFLOOR.LOADING:
      return { ...state, doorxFloorLoading: true };
    case DOORXFLOOR.SUCCESS:
      return {
        ...state,
        doorxFloorLoading: false,
        doorxFloorList: payload,
      };
    case DOORXFLOOR.ERROR:
      return { ...state, doorxFloorLoading: false };

    case PLOT.LOADING:
      return { ...state, plotLoader: true };
    case PLOT.SUCCESS:
      return { ...state, plotLoader: false };
    case PLOT.ERROR:
      return { ...state, plotLoader: false };

    default:
      return state;
  }
}
