import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  useComponentDidUpdate,
  useDebounceValue,
  useDocTitle,
} from "../../../../hooks";
import { Box, Grid, SearchField, SelectFieldBorderless } from "../../../common";
import { Bold, Image, Paragraph } from "../../../generics";
import {
  getClientListAction,
  deleteClientAction,
  updateClientStatusAction,
  getPreviousClientListAction,
} from "../../../../actions";
import { FILE_URL, TABLE_LIMIT } from "../../../../config";

// IMPORT HOCS
import { FilterChip } from "../../building/jobsPage";
import { Pagination } from "@material-ui/lab";
import { ClientCard } from "../components/clientCard.component";
import { ActivityIndicator } from "../../../hoc";

const TIER_OPTIONS = [
  { name: "Tier 1", value: "1" },
  { name: "Tier 2", value: "2" },
  { name: "Tier 3", value: "3" },
];

const PREV_OPTIONS = [
  { name: "0-3 Months", value: "green" },
  { name: "3-6 Months", value: "red" },
  { name: "6+ Months", value: "yellow" },
];

export const PreviousPage = () => {
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  useDocTitle("Client");

  const dispatch = useDispatch();
  const {
    previousList,
    previousListLoading,
    previousListTotalCount,
    deleteLoading,
  } = useSelector((state) => state.client);

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(() => {
    return query.get("page") || 1;
  });

  const [searchTerm, setSearchTerm] = useState(() => {
    return query.get("name") || "";
  });
  const searchCompany = useDebounceValue(searchTerm);

  const [prev, setPrev] = useState(() => {
    return PREV_OPTIONS.find(
      ({ value }) => value.toLowerCase() === query.get("prev")?.toLowerCase(),
    );
  });

  useEffect(() => {
    dispatch(getPreviousClientListAction(pageNumber, getFilter()));

    navigate(location.pathname + `?page=${pageNumber}${getFilter()}`);
  }, [pageNumber]);

  const getFilter = () => {
    let filter = "";
    if (!!searchCompany) {
      filter += `&name=${searchCompany}`;
    }
    if (!!prev?.value) {
      filter += `&prev=${prev?.value}`;
    }
    return filter ?? "";
  };

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      dispatch(getPreviousClientListAction(1, getFilter()));
      navigate(location.pathname + `?page=1${getFilter()}`);
    } else {
      setPageNumber(1);
    }
  }, [searchCompany, prev?.value]);

  const deleteHandler = (id) => {
    dispatch(deleteClientAction(id, toast, pageNumber));
  };

  const viewHandler = (company_details) => {
    // console.log("ididid", id);
    navigate(routes["Client Page"].path + "/view/" + company_details?.id);
  };

  const editHandler = (item) => {
    navigate(routes["Client"].path + "/edit/" + item.id);
  };

  const toggleHandler = (data, id) => {
    dispatch(
      updateClientStatusAction(
        id,
        { deactivate: !data },
        onSuccessHandler,
        toast,
      ),
    );
  };

  const onSuccessHandler = () => {
    dispatch(getClientListAction(pageNumber));
  };

  const clearFilterHandler = () => {
    setPrev(null);
    setSearchTerm("");
    navigate(location.pathname + `?page=1`);
  };

  const onChangePage = async (event, newPage = 1) => {
    setPageNumber(newPage);
    // navigate(location.pathname + `?page=` + Number(newPage));
  };
  return (
    // <CompWrapper>
    //   <Box>
    <Box>
      {/* <Box flexBox alCenter jSpace>
        <Bold>Clients</Bold>
        <Box>
          <DefaultButton
            title="Add"
            onClick={() => {
              navigate(routes["Add Client"].path);
            }}
          />
        </Box>
      </Box> */}
      <Box flexBox row jSpace>
        <Box flex={2}>
          <SearchField
            value={searchTerm}
            placeholder="Search..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box flex={2} />

        <Box flex={1} ml={30}>
          <SelectFieldBorderless
            placeholder="Select Prev Type"
            instanceId="prev"
            options={PREV_OPTIONS}
            value={prev}
            defaultValue={PREV_OPTIONS.find(
              ({ value }) =>
                value.toLowerCase() === query.get("prev")?.toLowerCase(),
            )}
            getOptionLabel="name"
            getOptionValue="value"
            onChangeValue={(item) => {
              console.log(item);
              setPrev(item);
            }}
          />
        </Box>
      </Box>

      <Box mt={20} flexBox alCenter>
        {searchTerm?.length ? <FilterChip title={searchTerm} /> : null}
        {/* {!!tier ? <FilterChip title={`${tier?.value}`} /> : null} */}
        {!!prev ? <FilterChip title={`${prev?.name}`} /> : null}

        {searchTerm?.length || !!prev ? (
          <Paragraph
            onClick={() => clearFilterHandler()}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#0284a4",
              fontSize: 14,
            }}>
            Clear all
          </Paragraph>
        ) : null}
      </Box>

      <Box mt={20}>
        <ActivityIndicator animating={previousListLoading}>
          <Grid.Container lg={3} md={2} sm={1}>
            {previousList?.length > 0 ? (
              previousList?.map(({ company_details, tier_details }) => {
                return (
                  <ClientCard
                    key={company_details?.id}
                    keyValue={{
                      name: company_details?.cont_comp_name,
                      email: company_details?.cont_comp_email,
                      address: company_details?.cont_comp_address?.value,
                      phone: company_details?.cont_comp_phone?.join(", "),
                    }}
                    link={`${FILE_URL}/contractor/${company_details?.cont_comp_logo}`}
                    onView={() => viewHandler(company_details)}
                  />
                );
              })
            ) : (
              <Box>No Data found</Box>
            )}
          </Grid.Container>
        </ActivityIndicator>

        <Box>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.ceil(previousListTotalCount / TABLE_LIMIT)}
            boundaryCount={1}
            page={Number(pageNumber)}
            variant="outlined"
            shape="rounded"
            onChange={onChangePage}
          />
        </Box>

        {/* <Table
              columns={[
                {
                  name: "logo",
                  field: "cont_comp_logo",
                  render: (rowData) =>
                    rowData && (
                      <Image
                        alt="Avatar"
                        link={`${FILE_URL}/contractor/${rowData}`}
                        style={{ height: 60, width: 60, borderRadius: 50 }}
                      />
                    ),
                },
                {
                  field: "cont_comp_name",
                  name: "name",
                },
                {
                  field: "cont_comp_email",
                  name: "email",
                },
                {
                  field: "cont_comp_address",
                  name: "address",
                  render: (rowData) =>
                    rowData && <Paragraph>{rowData?.value}</Paragraph>,
                },
                {
                  field: "cont_comp_phone",
                  name: "phone",
                  render: (rowData) =>
                    rowData && <Paragraph>{rowData?.join(", ")}</Paragraph>,
                },
              ]}
              data={list.map(({ company_details }) => company_details)}
              dataLoader={previousListLoading}
              totalCount={totalCount}
              actions
              deleteLoader={deleteLoading}
              onDeleteHandler={deleteHandler}
              onEditHandler={editHandler}
              onViewHandler={viewHandler}
            /> */}
      </Box>
    </Box>
    //   </Box>
    // </CompWrapper>
  );
};
