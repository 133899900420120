import React, { useState, useEffect } from "react";
import { Modal } from "react-uicomp";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, MdClose } from "react-icons/all";

import {
  addDoorPartAction,
  getAllTemplate,
  getTemplate,
} from "../../../../../actions";
import { Card, CompWrapper } from "../../../../hoc";
import { Box, DefaultButton } from "../../../../common";
import { Bold, Heading, Paragraph, Title } from "../../../../generics";
import { useAuth, useNavigation } from "react-auth-navigation";
import { isValid, validator } from "../../../../../utils";

const partsParser = (parts) => {
  const partsClone = [...parts];

  const modifiedParts = partsClone.map(({ details, ...rest }) => {
    const newDetails = [];

    details.forEach((val) => {
      newDetails.push({ name: val, value: "" });
    });

    return {
      details: newDetails,
      ...rest,
    };
  });

  return modifiedParts;
};

export const AddDoorPartPage = () => {
  console.log("useNavigation()", useNavigation());
  const { params, location, navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const { templates, template } = useSelector((state) => state.template);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const { addDoorPartLoader, partList, partListLoader } = useSelector(
    (state) => state.part,
  );
  const [parts, setParts] = useState(null);

  useEffect(() => {
    dispatch(getAllTemplate(1, true));
  }, [dispatch]);

  useEffect(() => {
    if (selectedTemplate !== "") {
      dispatch(getTemplate(Number(selectedTemplate)));
    }
  }, [dispatch, selectedTemplate]);

  useEffect(() => {
    if (!!template) {
      setParts(partsParser(template.parts));
    }
  }, [template]);

  const detailsChangeHandler = (partIndex, detailName) => {
    return function (e) {
      const partsClone = [...parts];
      const part = partsClone[partIndex];

      const i = part.details.indexOf(
        part.details.find(({ name }) => name === detailName),
      );
      const detailsObject = { ...part.details[i] };

      detailsObject.value = e.target.value;

      part.details[i] = detailsObject;
      partsClone[partIndex] = part;
      setParts(partsClone);
    };
  };

  const addInstallInspect = (partIndex, key) => {
    const partsClone = [...parts];
    const part = partsClone[partIndex];

    const data = [...part[key], ""];
    part[key] = data;
    partsClone[partIndex] = part;

    setParts(partsClone);
  };

  const changeInstallInspectHandler = (partIndex, key, index) => {
    return function (e) {
      const partsClone = [...parts];
      const part = partsClone[partIndex];

      const data = [...part[key]];
      data[index] = e.target.value;
      part[key] = data;
      partsClone[partIndex] = part;

      setParts(partsClone);
    };
  };

  const deleteInstallInspect = (partIndex, key, index) => {
    const partsClone = [...parts];
    const part = partsClone[partIndex];

    const data = [...part[key]].filter((_, i) => i !== index);
    part[key] = data;
    partsClone[partIndex] = part;

    setParts(partsClone);
  };

  const onSubmit = () => {
    console.log("RESULT", parts);

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };

    parts?.length > 0 &&
      parts.map((element) => {
        // element.details.map(({ value, name }) =>
        //   validate(name, value.length === 0, () => {
        //     showMessage(`${name} of ${element.name} musn't be empty!`);
        //   }),
        // );
        element.inspect.map((item) =>
          validate("inspect", item.length === 0, () => {
            showMessage(`inspect of ${element.name} musn't be empty!`);
          }),
        );
        element.install.map((item) =>
          validate("install", item.length === 0, () => {
            showMessage(`install of ${element.name} musn't be empty!`);
          }),
        );
      });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }
    const body = {
      doorId: location.state.doorId,
      templateId: selectedTemplate,
      parts: parts,
    };
    dispatch(
      addDoorPartAction(body, toast, () => {
        navigate(routes["Building"].path + "/door/" + location.state.doorId);
      }),
    );
  };

  return (
    <CompWrapper>
      <Card>
        <Box className="doordetail-container">
          {/* {selectedTemplate !== "" && (
            <Box flexBox>
              <DefaultButton title="Save" onClick={onSubmit} />
            </Box>
          )} */}

          <Box mt={20}>
            <select
              style={{ width: "auto" }}
              value={selectedTemplate}
              onChange={(e) => {
                setSelectedTemplate(e.target.value);
              }}>
              <option value="" disabled>
                Select template:
              </option>
              {templates.length > 0
                ? templates.map(({ id, name }, index) => (
                    <option key={index} value={id}>
                      {name}
                    </option>
                  ))
                : null}
            </select>
          </Box>

          {parts && (
            <Box mt={20}>
              <h2>
                <Bold>Parts</Bold>
              </h2>

              <Box pl={20}>
                {parts.map(({ name, details, install, inspect }, index) => {
                  return (
                    <Box pt={20} mb={20} className="parts-content" key={index}>
                      <Box pb={10} pl={10}>
                        <Bold>{name}</Bold>
                      </Box>

                      <Box pl={20} pb={20}>
                        <Paragraph pb={10}>Details</Paragraph>

                        {details.map(({ name, value }, i) => {
                          return (
                            <Box pl={20} mb={5} key={i} flexBox alCenter>
                              <Box pb={10}>
                                <Paragraph>{name}</Paragraph>
                              </Box>

                              <Box ml={5}>
                                <input
                                  type="text"
                                  name={name}
                                  value={value}
                                  onChange={detailsChangeHandler(index, name)}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>

                      <Box pl={20} pb={20}>
                        <Box flexBox alCenter>
                          <Paragraph pb={10}>Installation</Paragraph>

                          <Box ml={20}>
                            <button
                              type="button"
                              onClick={() =>
                                addInstallInspect(index, "install")
                              }>
                              <FaPlus />
                            </button>
                          </Box>
                        </Box>

                        {install.map((value, i, arr) => {
                          return (
                            <Box pl={20} key={i}>
                              <Box flexBox mb={10} alCenter>
                                <input
                                  type="text"
                                  name="install"
                                  value={value}
                                  onChange={changeInstallInspectHandler(
                                    index,
                                    "install",
                                    i,
                                  )}
                                />

                                {arr.length > 1 && (
                                  <Box ml={20}>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() =>
                                        deleteInstallInspect(
                                          index,
                                          "install",
                                          i,
                                        )
                                      }>
                                      <MdClose />
                                    </button>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>

                      <Box pl={20} pb={20}>
                        <Box flexBox alCenter>
                          <Paragraph pb={10}>Inspection</Paragraph>

                          <Box ml={20}>
                            <button
                              type="button"
                              onClick={() =>
                                addInstallInspect(index, "inspect")
                              }>
                              <FaPlus />
                            </button>
                          </Box>
                        </Box>

                        {inspect.map((value, i, arr) => {
                          return (
                            <Box pl={20} key={i}>
                              <Box flexBox mb={10} alCenter>
                                <input
                                  type="text"
                                  name="inspect"
                                  value={value}
                                  onChange={changeInstallInspectHandler(
                                    index,
                                    "inspect",
                                    i,
                                  )}
                                />

                                {arr.length > 1 && (
                                  <Box ml={20}>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() =>
                                        deleteInstallInspect(
                                          index,
                                          "inspect",
                                          i,
                                        )
                                      }>
                                      <MdClose />
                                    </button>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          {selectedTemplate !== "" && (
            <Box flexBox>
              <DefaultButton title="Save" onClick={onSubmit} />
            </Box>
          )}
        </Box>
      </Card>
    </CompWrapper>
  );
};
