import React from "react";
import { Auth } from "react-auth-navigation";
import { Box, TabAuth } from "../../../common";
// IMPORT HOCS
import { CompWrapper, Card } from "../../../hoc";

export const ClientRequestPage = () => {
  return (
    <CompWrapper>
      <Card>
        {/* <Box flexBox alCenter jSpace>
            <Bold>Clients</Bold>
          </Box> */}

        <TabAuth group="Client Requests" />
        <Box mt={20}>
          <Auth.Screens path="/client/request" />
        </Box>
      </Card>
    </CompWrapper>
  );
};
