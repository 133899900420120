import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
// import { GoogleApiWrapper } from "google-maps-react";

import { FaMapMarkerAlt } from "react-icons/fa";
import { api } from "../../../config/Api.config";
import axios from "axios";

const GOOGLEAPI = "AIzaSyAfajRSB31ocfghiKo3BLA7oM6rzAt70GQ";
export const GooglePlaces = ({
  defaultValue,
  setLocationName,
  disabled,
  getPostalCode,
  setPostalCode,
  //   setGoogleLoading,
}) => {
  const [address, setAddress] = useState(defaultValue || "");
  const handleSelect = async (value, place_id) => {
    // setGoogleLoading && setGoogleLoading(true);
    if (!!setPostalCode) {
      const response = await geocodeByPlaceId(place_id);
      let postalcode = "";
      response.length > 0 &&
        response[0]["address_components"].forEach((element) => {
          if (element.types?.[0] === "postal_code") {
            console.log("element.long_name", element.long_name);
            // setPostalCode(element.long_name);
            postalcode = element.long_name;
          }
        });

      setPostalCode(postalcode);
    }
    setAddress(value);
    setLocationName && setLocationName(value);
  };

  useEffect(() => {
    if (!!defaultValue) {
      setAddress(defaultValue);
    }
  }, [defaultValue]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      debounce={500}
      searchOptions={{
        componentRestrictions: { country: ["uk", "IE"] },
      }}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="googleplaces">
          <input
            className="inputfield"
            {...getInputProps({
              placeholder: "Search Places ...",
            })}
            disabled={disabled}
          />

          <div className="googleplaces-dropdown">
            {suggestions.map((suggestion, index) => {
              return (
                <InputField
                  key={index}
                  getSuggestionItemProps={getSuggestionItemProps}
                  suggestion={suggestion}
                  setPostalCode={setPostalCode}
                />
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const InputField = ({
  getSuggestionItemProps,
  suggestion,
  getPostalCode,
  setPostalCode,
}) => {
  const [postcode, setPostCode] = useState();
  useEffect(() => {
    if (!!setPostalCode) {
      geocodeByPlaceId(suggestion.placeId)
        .then((results) => {
          const postcode =
            results.length > 0 &&
            results[0]["address_components"].forEach((element) => {
              if (element.types?.[0] === "postal_code") {
                setPostCode && setPostCode(element.long_name);
                // console.log(element.long_name);
              }
            });
          return postcode;
        })
        .catch((error) => console.error(error));
    }
  }, []);

  return (
    <div
      className={`googleplaces-dropdown-items ${suggestion.active && "active"}`}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      {...getSuggestionItemProps(suggestion)}>
      <span>{suggestion.description}</span>
      {!!postcode && <span> {postcode}</span>}
    </div>
  );
};
