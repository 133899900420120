import { api, APIS, TABLE_LIMIT } from "../config";
import {
  ADD_DOOR_PART,
  GET_DOORPART_BY_ID,
  GET_PART_OF_DOOR,
  EDIT_PART,
} from "./ActionTypes.action";

export function addDoorPartAction(body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_DOOR_PART.LOADING });
      res = await api(APIS.doorPart, "POST", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: ADD_DOOR_PART.SUCCESS });
        // dispatch(getDoorPlan());
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: ADD_DOOR_PART.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_DOOR_PART.ERROR });
      toast({
        message: `Add part Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function getPartOfDoorAction(doorId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_PART_OF_DOOR.LOADING });
      res = await api(`${APIS.doorPart}/door/${doorId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_PART_OF_DOOR.SUCCESS, payload: data.data[0] });
        // dispatch(getDoorPlan());
      } else {
        dispatch({ type: GET_PART_OF_DOOR.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_PART_OF_DOOR.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getDoorPartByIdAction(doorId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_DOORPART_BY_ID.LOADING });
      res = await api(`${APIS.doorPart}/${doorId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_DOORPART_BY_ID.SUCCESS, payload: data.data });
        // dispatch(getDoorPlan());
      } else {
        dispatch({ type: GET_DOORPART_BY_ID.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_DOORPART_BY_ID.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function editDoorPart(partId, body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_PART.LOADING });
      res = await api(`${APIS.doorPart}/${partId}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: EDIT_PART.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: EDIT_PART.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_PART.ERROR });
      toast({
        message: `Edit part Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}
