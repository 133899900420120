import { api, APIS, TABLE_LIMIT } from "../config";
import { getCookie } from "../helpers";
import {
  WORKER_SCHEDULED_TASK,
  WORKER_COMPLETED_TASK,
} from "./ActionTypes.action";

//GET SCHEDULED TASK
export function getScheduledTask(id, page = 1) {
  return async function (dispatch) {
    let res;
    try {
      res = await api(
        `${APIS.workerTask}/${id}?page=${page}&limit=${TABLE_LIMIT}&status=scheduled`,
      );
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: WORKER_SCHEDULED_TASK.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: WORKER_SCHEDULED_TASK.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: WORKER_SCHEDULED_TASK.ERROR });
    }
  };
}

//GET COMPLETED TASK
export function getCompletedTask(id, page = 1) {
  return async function (dispatch) {
    let res;
    try {
      res = await api(
        `${APIS.workerTask}/${id}?page=${page}&limit=${TABLE_LIMIT}&status=completed`,
      );
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: WORKER_COMPLETED_TASK.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: WORKER_COMPLETED_TASK.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: WORKER_COMPLETED_TASK.ERROR });
    }
  };
}
