import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  useComponentDidUpdate,
  useDebounceValue,
  useDocTitle,
} from "../../../hooks";
import {
  DefaultButton,
  Box,
  SearchField,
  SelectFieldBorderless,
  KeyValue,
  Grid,
  IconButton,
} from "../../common";
import Table from "../../common/table/Table.common";
import { Bold, Image, Paragraph, Title } from "../../generics";
import {
  getClientListAction,
  deleteClientAction,
  updateClientStatusAction,
  getCompanyListAction,
  getDashboardSuperadmin,
} from "../../../actions";
import { FILE_URL, TABLE_LIMIT } from "../../../config";

// IMPORT HOCS
import { FilterChip } from "../building/jobsPage";
import { Pagination } from "@material-ui/lab";
import { ActivityIndicator, Card } from "../../hoc";
import { BsFillEyeFill, BsThreeDotsVertical } from "react-icons/bs";
import { ClientCard } from "./components/clientCard.component";

const ClientPageComponent = ({ TIER_OPTIONS }) => {
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  useDocTitle("Client");

  const dispatch = useDispatch();
  const { list, listloading, totalCount, deleteLoading } = useSelector(
    (state) => state.client,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(() => {
    return query.get("page") || 1;
  });

  const [searchTerm, setSearchTerm] = useState(() => {
    return query.get("name") || "";
  });
  const searchCompany = useDebounceValue(searchTerm);

  const [tier, setTier] = useState(() => {
    return TIER_OPTIONS.find(({ value }) => +value === +query.get("tier"));
  });

  const { dashboardData } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getCompanyListAction(pageNumber, getFilter()));
    // const TIER_OPTIONS = [
    //   { name: "Tier 1", value: "1" },
    //   { name: "Tier 2", value: "2" },
    //   { name: "Tier 3", value: "3" },
    // ];

    // const PREV_OPTIONS = [
    //   { name: "GREEN", value: "green" },
    //   { name: "RED", value: "red" },
    //   { name: "YELLOW", value: "yellow" },
    // ];
    navigate(location.pathname + `?page=${pageNumber}${getFilter()}`);
  }, [pageNumber]);

  const getFilter = () => {
    let filter = "";
    if (!!searchCompany) {
      filter += `&name=${searchCompany}`;
    }
    if (!!tier?.value) {
      filter += `&tier=${tier?.value}`;
    }
    // if (!!prev?.value) {
    //   filter += `&prev=${prev?.value}`;
    // }
    return filter ?? "";
  };

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      dispatch(getCompanyListAction(1, getFilter()));
      navigate(location.pathname + `?page=1${getFilter()}`);
    } else {
      setPageNumber(1);
    }
  }, [searchCompany, tier?.value]);

  const deleteHandler = (id) => {
    dispatch(deleteClientAction(id, toast, pageNumber));
  };

  const viewHandler = (company_details) => {
    // console.log("ididid", id);
    navigate(routes["Client Page"].path + "/view/" + company_details?.id);
  };

  const editHandler = (item) => {
    navigate(routes["Client"].path + "/edit/" + item.id);
  };

  const toggleHandler = (data, id) => {
    dispatch(
      updateClientStatusAction(
        id,
        { deactivate: !data },
        onSuccessHandler,
        toast,
      ),
    );
  };

  const onSuccessHandler = () => {
    dispatch(getClientListAction(pageNumber));
  };

  const clearFilterHandler = () => {
    // setPrev(null);
    setTier(null);
    setSearchTerm("");
    // navigate(routes["List Job".path]);
    // navigate(routes["Client Page"].path + `?page=${pageNumber}${getFilter()}`);
    navigate(location.pathname + `?page=1`);
  };

  const onChangePage = async (event, newPage = 1) => {
    setPageNumber(newPage);
    // navigate(location.pathname + `?page=` + Number(newPage));
  };
  return (
    <Box>
      <Box flexBox row jSpace>
        <Box flex={2}>
          <SearchField
            value={searchTerm}
            placeholder="Search..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box flex={2} />
        <Box flex={1} ml={30}>
          <SelectFieldBorderless
            placeholder="Select Tier"
            instanceId="tier"
            options={TIER_OPTIONS}
            value={tier}
            // defaultValue={TIER_OPTIONS.find(
            //   ({ value }) =>
            //     value?.toLowerCase() === query.get("tier")?.toLowerCase(),
            // )}
            getOptionLabel="name"
            getOptionValue="value"
            onChangeValue={(item) => {
              console.log(item);
              setTier(item);
            }}
          />
        </Box>
      </Box>

      <Box mt={20} flexBox alCenter>
        {searchTerm?.length ? <FilterChip title={searchTerm} /> : null}
        {!!tier ? <FilterChip title={`${tier?.name}`} /> : null}

        {searchTerm?.length || !!tier ? (
          <Paragraph
            onClick={() => clearFilterHandler()}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#0284a4",
              fontSize: 14,
            }}>
            Clear all
          </Paragraph>
        ) : null}
      </Box>

      <Box mt={20}>
        <ActivityIndicator animating={listloading}>
          <Grid.Container lg={3} md={2} sm={1}>
            {list?.length > 0 ? (
              list?.map(({ company_details, tier_details }) => {
                return (
                  <ClientCard
                    key={company_details?.id}
                    keyValue={{
                      name: company_details?.cont_comp_name,
                      email: company_details?.cont_comp_email,
                      address: company_details?.cont_comp_address?.value,
                      phone: company_details?.cont_comp_phone?.join(", "),
                    }}
                    link={`${FILE_URL}/contractor/${company_details?.cont_comp_logo}`}
                    onView={() => viewHandler(company_details)}
                  />
                );
              })
            ) : (
              <Box>No data found...</Box>
            )}
          </Grid.Container>
        </ActivityIndicator>
        <Box>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.ceil(totalCount / TABLE_LIMIT)}
            boundaryCount={1}
            page={Number(pageNumber)}
            variant="outlined"
            shape="rounded"
            onChange={onChangePage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const ClientPage = () => {
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardSuperadmin());
  }, []);

  const newData = dashboardData
    ?.map(
      ({ tier_details }) =>
        !!tier_details.id && {
          name: tier_details.name,
          value: tier_details.id,
        },
    )
    .filter(Boolean);

  console.log(
    ">>>>",

    newData,
  );
  return newData?.length > 0 ? (
    <ClientPageComponent TIER_OPTIONS={newData} />
  ) : null;
};

// export default ClientPage;
