import React, { useEffect } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import { useSelector, useDispatch } from "react-redux";

import {
  getIronmongeryPackList,
  deleteIronmongeryPack,
} from "../../../../actions";
import { useDocTitle } from "../../../../hooks";
import { DefaultButton, Box } from "../../../common";
import Table from "../../../common/table/Table.common";
import { Bold } from "../../../generics";
import { CompWrapper, Card } from "../../../hoc";

export const IronmongerypackList = () => {
  useDocTitle("Ironmongery Packs");

  const dispatch = useDispatch();
  const { toast } = useAuth();

  const {
    ironmongeryPackLoader,
    ironmongeryPacklist,
    ironmongeryPackTotalCount,
    deletePackLoader,
  } = useSelector((state) => state.ironmongeryPack);

  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getIronmongeryPackList(pageNumber));
  }, [pageNumber]);

  const viewHandler = (item) => {
    // console.log({ id });
    navigate(`${routes["Ironmongery Packs"].path}/${item.group_details.id}`);
  };

  const deleteHandler = (item) => {
    dispatch(deleteIronmongeryPack(item.group_details.id, toast, () => {}));
  };

  console.log({ ironmongeryPacklist });

  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Ironmongery Packs</Bold>
            <Box>
              <DefaultButton
                title="Add Ironmongery Pack"
                onClick={() => {
                  navigate(routes["Add Ironmongery Pack"].path);
                }}
              />
            </Box>
          </Box>
          <Box mt={20}>
            <Table
              columns={[
                {
                  field: "group_details",
                  name: "name",
                  render: (rowData) => rowData.name,
                },
                {
                  field: "group_details",
                  name: "description",
                  render: (rowData) => rowData.description,
                },
                {
                  field: "total_count",
                  name: "total_count",
                },
              ]}
              data={ironmongeryPacklist}
              dataLoader={ironmongeryPackLoader}
              totalCount={ironmongeryPackTotalCount}
              actions
              viewBug
              deleteLoader={deletePackLoader}
              onDeleteHandler={deleteHandler}
              // onEditHandler={editHandler}
              onViewHandler={viewHandler}
            />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};
