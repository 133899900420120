import React, { useEffect } from "react";
import { MdAttachFile } from "react-icons/all";
import { Modal } from "react-uicomp";
import { MdAdd, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { isValid, useInput, validator } from "../../../../../../utils";
import {
  Box,
  DefaultButton,
  FormInput,
  InputField,
  GooglePlaces,
  ToolTip,
} from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { Title, Image, Bold, Paragraph, Chip } from "../../../../../generics";
import { ActivityIndicator, Card } from "../../../../../hoc";
import { getPastInspectionAction } from "../../../../../../actions";
import { FILE_URL } from "../../../../../../config";

export const QuestionTable = ({ data, jobType }) => {
  const { params, navigation } = useNavigation();
  const { navigate, routes } = navigation;

  // const viewHandler = (item) => {
  //   navigate(`/building/past-inspection/${item?.job_details?.id}`);
  // };

  console.log("Doata", data);

  return (
    <Box>
      <Card>
        <Box flexBox alCenter jSpace>
          <Bold>Questions</Bold>
        </Box>
        <Box mt={20}>
          <Table
            columns={[
              {
                field: "question",
                name: "Question",
              },
              {
                field: "answer",
                name: jobType === "INSTALLATION" ? "Completed" : "Compliance",
                render: (rowData) =>
                  rowData !== null ? (
                    rowData ? (
                      <Chip
                        pr={20}
                        pl={20}
                        style={{ backgroundColor: "#5b9540" }}>
                        {jobType === "INSTALLATION" ? "Yes" : "Pass"}
                      </Chip>
                    ) : (
                      <Chip
                        pr={20}
                        pl={20}
                        style={{ backgroundColor: "#ff4343" }}>
                        {jobType === "INSTALLATION" ? "No" : "Fail"}
                      </Chip>
                    )
                  ) : (
                    <Chip
                      pr={20}
                      pl={20}
                      style={{ backgroundColor: "#989898" }}>
                      N/A
                    </Chip>
                  ),
              },
              {
                field: "remarks",
                name: "Comment",
              },
              {
                field: "uploads",
                name: "Attachments",
                render: (rowData) =>
                  rowData?.length ? (
                    rowData.map((item, index) => {
                      return (
                        <ToolTip
                          key={index}
                          text={item.substring(item.lastIndexOf("/") + 1)}>
                          <a
                            target="_blank"
                            href={`${FILE_URL}/response/${item}`}>
                            <MdAttachFile
                              size={20}
                              style={{ cursor: "pointer", marginRight: 8 }}
                            />
                          </a>
                        </ToolTip>
                      );
                    })
                  ) : (
                    <Paragraph>N/A</Paragraph>
                  ),
              },
            ]}
            data={data}
            dataLoader={false}
            // totalCount={pastInspectionCount}
            // actions
            // deleteLoader={false}
            // onDeleteHandler={deleteHandler}
            // onEditHandler={editHandler}
            // onViewHandler={viewHandler}
          />
        </Box>
      </Card>
    </Box>
  );
};
