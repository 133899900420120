import React from "react";
import { useDocTitle } from "../../../../hooks";
import { Box } from "../../../common";

import { Bold, Image, Paragraph } from "../../../generics";

// IMPORT HOCS
import { CompWrapper, Card } from "../../../hoc";
import { AddFormComponent } from "../component";

export const AddTierPage = () => {
  useDocTitle("Tier");
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox vertical>
            {/* <Bold>Add Tier</Bold> */}

            <AddFormComponent />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};
