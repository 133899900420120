import React, { useEffect } from "react";
import { Modal } from "react-uicomp";
import { RiCloseCircleFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { isValid, useInput, validator } from "../../../../../../utils";
import {
  Box,
  DefaultButton,
  FormInput,
  InputField,
  ImageUpload,
  Grid,
} from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { Title, Image, Bold } from "../../../../../generics";
import { ActivityIndicator, Card } from "../../../../../hoc";
import {
  deleteFloorAction,
  editFloorAction,
  getFloorList,
  postFloorAction,
} from "../../../../../../actions";
import UPLOAD from "../../../../../../assets/icons/upload.png";
import { FILE_URL } from "../../../../../../config";

export const FloorSection = () => {
  const { toast } = useAuth();

  const [showAddFloor, setShowAddFloor] = React.useState(false);

  const dispatch = useDispatch();
  const { location, params, navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const floor = useSelector((state) => state.floor);
  const { loader, floorList, getFloorLoader, floorCount } = floor;

  const [image, setImage] = React.useState([]);

  const [editData, setEditData] = React.useState();
  const changeImage = (data) => {
    setImage(data);
  };

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getFloorList(params?.id, pageNumber));
  }, [params?.id, pageNumber]);

  const { data, onChangeHandler } = useInput({
    floors: "",
    stairs: "",
  });

  const viewHandler = (id) => {
    // navigate(`/building/floor/${id}`);
    navigate(`${location.pathname}/floor/${id}`);
  };

  const deleteHandler = (id) => {
    dispatch(deleteFloorAction(id, toast, params?.id, pageNumber));
  };

  const editHandler = (item) => {
    // navigate(routes["View Client"].path);
    setShowAddFloor(true);
    setEditData(item);
    console.log("item", item);
    data.floors = item.floor_name;
    data.stairs = item.stairs;
    data.id = item.id;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, floors, stairs, jobTitle } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("floors", floors.length === 0, () => {
      showMessage("Floor musn't be empty!");
    });
    validate("stairs", stairs.length === 0, () => {
      showMessage("Stairs musn't be empty!");
    });

    !editData &&
      validate("image", image.length === 0, () => {
        showMessage("FloorPlan musn't be empty!");
      });

    if (!isValid(catchedErrors)) {
      return;
    }

    let formData = new FormData();
    await formData.append("floorName", floors);
    await formData.append("stairs", stairs);
    !editData && (await formData.append("siteId", params?.id));
    !editData && (await formData.append("pdfImg", image[0].file));

    !!editData
      ? dispatch(
          editFloorAction(
            editData.id,
            formData,
            () => {
              onClose();

              setEditData();
              dispatch(getFloorList(params?.id, pageNumber));
            },
            toast,
          ),
        )
      : dispatch(
          postFloorAction(
            formData,
            () => {
              onClose();
              dispatch(getFloorList(params?.id, pageNumber));
            },
            toast,
          ),
        );
  };

  const onClose = () => {
    setShowAddFloor(false);
    data.floors = "";
    data.stairs = "";
    setEditData();
  };
  return (
    <Box>
      <Card>
        <Box flexBox alCenter jSpace>
          <Bold>Floors</Bold>
          <Box>
            <DefaultButton
              title="Add Floor"
              onClick={() => {
                setShowAddFloor(true);
              }}
            />
          </Box>
        </Box>
        <Box mt={20}>
          <Table
            columns={[
              // {
              //   name: "Floor Map",
              //   field: "floor_map",
              //   render: (rowData) =>
              //     rowData && (
              //       <Image
              //         alt="Avatar"
              //         link={`${FILE_URL}/floor/${rowData}`}
              //         style={{ height: 60, width: 60, borderRadius: 50 }}
              //       />
              //     ),
              // },
              {
                field: "floor_name",
                name: "Floor Name",
              },
              {
                field: "stairs",
                name: "stairs",
              },
            ]}
            data={floorList}
            dataLoader={getFloorLoader}
            totalCount={floorCount}
            actions
            deleteLoader={false}
            onDeleteHandler={deleteHandler}
            onEditHandler={editHandler}
            onViewHandler={viewHandler}
          />
        </Box>
      </Card>

      <Modal visible={showAddFloor} onOutsideClick={onClose}>
        <Box>
          <Box flexBox jCenter mb={10}>
            <Title>Add Floor</Title>
          </Box>
          <form onSubmit={submitHandler}>
            <Box>
              <FormInput label="Floor">
                <InputField
                  placeholder="Floor"
                  name="floors"
                  value={data.floors}
                  onChange={onChangeHandler("floors")}
                  type="text"
                />
              </FormInput>

              <FormInput label="Stairs">
                <InputField
                  placeholder="Stairs"
                  name="stairs"
                  value={data.stairs}
                  onChange={onChangeHandler("stairs")}
                  type="text"
                />
              </FormInput>

              <FormInput label="Floor Plan">
                <ImageUpload
                  // fixedResolution={true}
                  accept="application/pdf"
                  onChange={changeImage}
                  buttonStyle={{ background: "red", display: "inline-block" }}
                  title="upload image"
                  multiple={false}
                  buttonclick={
                    image.length === 0 &&
                    !!editData === false && (
                      <Box>
                        <div
                          style={{
                            display: "inline-block",
                            border: "1px dashed #000",
                            padding: 50,
                            cursor: "pointer",
                            overflow: "hidden",
                          }}>
                          <Image
                            alt="Avatar"
                            link={UPLOAD}
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "contain",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      </Box>
                    )
                  }>
                  {({
                    onUploadImage,
                    imageData,
                    onRemove,
                    deleteAllHandler,
                  }) => (
                    <Box>
                      <Grid.Container lg={1} md={1} sm={1}>
                        {imageData &&
                          imageData.map((item, index) => (
                            <div key={index}>
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  boxShadow: "0px 2px 10px 0px #888888",
                                }}>
                                <Image
                                  alt="Avatar"
                                  link={item.url}
                                  style={{
                                    width: 200,
                                    height: 200,
                                    objectFit: "contain",
                                    overflow: "hidden",
                                  }}
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => onRemove(index)}>
                                  <RiCloseCircleFill color="red" size={25} />
                                </div>
                              </div>
                            </div>
                          ))}

                        {!!editData && (
                          <div>
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                                boxShadow: "0px 2px 10px 0px #888888",
                              }}>
                              <Image
                                alt="Avatar"
                                link={`${FILE_URL}/floor/${editData.floor_map}`}
                                style={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "contain",
                                  overflow: "hidden",
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Grid.Container>
                    </Box>
                  )}
                </ImageUpload>
              </FormInput>
            </Box>
            <ActivityIndicator animating={loader}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};
