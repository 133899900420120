import { GET_ALL_TIER, GET_TIER_DETAIL } from "../actions";

const initalState = {
  addLoader: false,
  tierListLoader: false,
  tierList: [],
  tierListCount: 0,
  // Get a template

  tierDetail: null,
  tierDetailLoader: false,
};

export function tierReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // case ADD_TEMPLATE.LOADING:
    //   return { ...state, addLoader: true };
    // case ADD_TEMPLATE.SUCCESS:
    //   return { ...state, addLoader: false };
    // case ADD_TEMPLATE.ERROR:
    //   return { ...state, addLoader: false };

    case GET_ALL_TIER.LOADING:
      return { ...state, tierListLoader: true };
    case GET_ALL_TIER.SUCCESS:
      return {
        ...state,
        tierListLoader: false,
        tierList: payload.rows,
        tierListCount: Number(payload.total),
      };
    case GET_ALL_TIER.ERROR:
      return { ...state, tierListLoader: false };

    case GET_TIER_DETAIL.LOADING:
      return { ...state, tierDetailLoader: true, tierDetail: null };
    case GET_TIER_DETAIL.SUCCESS:
      return { ...state, tierDetailLoader: false, tierDetail: payload };
    case GET_TIER_DETAIL.ERROR:
      return { ...state, tierDetailLoader: false };

    // case EDIT_TEMPLATE.LOADING:
    //   return { ...state, editLoader: true };
    // case EDIT_TEMPLATE.SUCCESS:
    //   return { ...state, editLoader: false };
    // case EDIT_TEMPLATE.ERROR:
    //   return { ...state, editLoader: false };

    // case DELETE_TEMPLATE.LOADING:
    //   return { ...state, deleteLoader: true };
    // case DELETE_TEMPLATE.SUCCESS:
    //   return { ...state, deleteLoader: false };
    // case DELETE_TEMPLATE.ERROR:
    //   return { ...state, deleteLoader: false };

    default:
      return state;
  }
}
