import React, { useEffect, useState } from "react";
import { Box } from "../../../../../common";
import { Card } from "../../../../../hoc";
import { Title } from "../../../../../generics";
import Table from "../../../../../common/table/Table.common";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoorTrashList,
  restoreDoorTrashAction,
} from "../../../../../../actions";
import { useAuth, useNavigation } from "react-auth-navigation";

const doorList = [
  { name: "aaa" },
  { name: "bbb" },
  { name: "ccc" },
  { name: "ddd" },
  { name: "eee" },
];
export const DoorTrashList = () => {
  const { location, params } = useNavigation();
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const { doorTrashList, doorTrashLoader, doorTrashCount } = useSelector(
    (state) => state.door,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getDoorTrashList(params?.id, pageNumber));
  }, [pageNumber, params.id]);
  const restoreHandler = (item, callback) => {
    console.log("item", item);
    // callback();

    dispatch(restoreDoorTrashAction(item.id, toast, callback));
  };

  console.log("doorTrashList", doorTrashList);
  return (
    <>
      <Card containerStyle={{ paddingRight: 10 }}>
        <Box flexBox vertical mb={20}>
          <Box>
            <Title>Doors Trash List</Title>
          </Box>

          <Box mt={20}>
            <Table
              columns={[
                {
                  field: "door_name",
                  name: "Door Name",
                },
                {
                  field: "door_ident_no",
                  name: "Door Id",
                },
              ]}
              data={doorTrashList}
              dataLoader={doorTrashLoader}
              totalCount={doorTrashCount}
              actions
              deleteLoader={false}
              onRestoreHandler={restoreHandler}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};
