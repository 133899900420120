import React, { useEffect, useState } from "react";
import moment from "moment";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaPlus, MdClose } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useHistory } from "react-router-dom";
import { useDocTitle } from "../../../../../hooks/useDocTitle.hook";
import { isValid, useInput, validator } from "../../../../../utils";

import { ActivityIndicator, Card, CompWrapper } from "../../../../hoc";
import {
  Box,
  DefaultButton,
  DisplayFileName,
  FormInput,
  GhostButton,
  Grid,
  ImageUpload,
  InputField,
  PrimaryButton,
  SelectField,
  TextArea,
} from "../../../../common";
import {
  addJobAction,
  deleteJobPartByIdAction,
  editJobAction,
  getAllBuildingListAction,
  getAllFloorList,
  getBuildingListAction,
  getFloorList,
  getJobDelailByIdAction,
  getWorkerAction,
} from "../../../../../actions";

import UPLOAD from "../../../../../assets/icons/upload.png";
import { Bold, Image, Paragraph, Title } from "../../../../generics";
import { api, APIS, FILE_URL } from "../../../../../config";
import { InspectionPart, InstallationPart } from "../component";
import { Modal } from "react-uicomp";

const JOB_TYPE = [
  { name: "INSTALLATION", value: "INSTALLATION" },
  { name: "INSPECTION", value: "INSPECTION" },
  { name: "MAINTENANCE", value: "MAINTENANCE" },
  { name: "MAINTAIN_INSTALL", value: "MAINTAIN_INSTALL" },
];

const EditComponent = () => {
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const history = useHistory();
  const dispatch = useDispatch();
  const { allListloading, allList } = useSelector((state) => state.building);
  const { userWorkerList } = useSelector((state) => state.user);
  const {
    jobDetail: {
      job_parts_list,
      site_details,
      assigned_to,
      added_by,
      job_details,
      job_meta_details,
      worker_list,
    },
    deleteJobLoader,
  } = useSelector((state) => state.job);

  const [doorArray, setDoorArray] = useState([]);

  const [inspectionPartArray, setInspectionPartArray] = useState([]);

  useDocTitle("Edit Job");

  const { data, onChangeHandler } = useInput({
    siteId: site_details.id,
    // assignedTo: assigned_to.id,
    jobDescription: job_details.job_description,
    startDate: moment(job_details.start_time).format("yyyy-MM-DD"),
    jobType: job_details.job_type,
    scope: job_meta_details.scope,
    limitations: job_meta_details.limitation,
    priorities: job_meta_details.recommendation,
  });
  const [floorNdoor, setFloorNdoor] = React.useState([
    {
      floor: "",
      door: "",
    },
  ]);

  const [inspectionfloorNdoor, setInspectionFloorNdoor] = React.useState([
    {
      floor: "",
      doorNpart: [
        {
          door: "",
          part: "",
        },
      ],
    },
  ]);

  // const [floorNdoor, setFloorNdoor] = React.useState(() => {
  //   if (job_details.job_type !== "INSTALLATION") return [];

  //   return job_parts_list.map(
  //     ({ floor_details, door_part_details, door_details }) => {
  //       return {
  //         floor: floor_details.id,
  //         door: [door_details.id],
  //       };
  //     },
  //   );
  // });

  // const [inspectionfloorNdoor, setInspectionFloorNdoor] = React.useState(() => {
  //   if (job_details.job_type === "INSTALLATION") return [];
  //   return job_parts_list.map(
  //     ({ floor_details, door_part_details, door_details }) => {
  //       return {
  //         floor: floor_details.id,
  //         doorNpart: [
  //           {
  //             door: door_details.id,
  //             part: [door_part_details.id],
  //           },
  //         ],
  //       };
  //     },
  //   );
  // });

  const [image, setImage] = React.useState([]);
  const [addMore, setAddMore] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [removedAttachment, setRemovedAttachment] = useState([]);
  const [activeJobPArt, setActiveJobPArt] = useState();

  const defaultpartlist = job_parts_list.map(
    ({ door_part_details }) => door_part_details.id,
  );
  const defaultWorkerList = worker_list.map(({ id }) => id);
  const [workerArray, setWorkArray] = useState(() =>
    worker_list?.map(({ id }) => id),
  );

  useEffect(() => {
    dispatch(getAllBuildingListAction());
  }, []);

  useEffect(() => {
    dispatch(getWorkerAction());
  }, []);

  useEffect(() => {
    !!data.siteId && dispatch(getAllFloorList(data.siteId));
  }, [data.siteId]);

  useEffect(() => {
    if (floorNdoor?.length > 0) {
      const newArray = [];
      floorNdoor.forEach(({ door }) => newArray.push(...door));

      // floorNdoor?.map(({ door }) => newArray.push(...door));

      setDoorArray(makeUniqueArray(newArray));
    }
  }, [floorNdoor]);

  useEffect(() => {
    if (inspectionfloorNdoor.length > 0) {
      const newArray = [];
      inspectionfloorNdoor.forEach(({ doorNpart }) => {
        doorNpart.map(({ part }) => newArray.push(...part));
      });
      setInspectionPartArray(makeUniqueArray(newArray));
    }
  }, [inspectionfloorNdoor]);

  const changeImage = (data) => {
    setImage(data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const removedworker = defaultWorkerList.filter(
      (val) => !workerArray.includes(val),
    );

    const newworker = workerArray.filter(
      (val) => !defaultWorkerList.includes(val),
    );

    // console.log("removed", removedworker);

    const workerBody = {};

    if (newworker.length > 0) workerBody["newWorker"] = newworker;
    if (removedworker.length > 0) workerBody["removedWorker"] = removedworker;

    console.log("workerBody", workerBody);
    const {
      siteId,
      assignedTo,
      jobDescription,
      startDate,
      jobType,
      scope,
      limitations,
      priorities,
    } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("jobDescription", jobDescription.length === 0, () => {
      showMessage("Job Description musn't be empty!");
    });

    if (!isValid(catchedErrors)) {
      return;
    }

    let formData = new FormData();
    await formData.append("jobDescription", jobDescription);
    !!scope && (await formData.append("scope", scope));
    !!limitations && (await formData.append("limitation", limitations));
    !!priorities && (await formData.append("recommendation", priorities));
    image.length > 0 &&
      image.forEach(async (element) => {
        await formData.append("pdfImgfile", element.file);
      });

    ////////////////////////////////
    let newPartBody = {};
    if (jobType === "INSPECTION" && inspectionPartArray.length > 0) {
      newPartBody["partIdList"] = inspectionPartArray;
    }
    if (doorArray.length > 0) {
      newPartBody["doorIdList"] = doorArray;
    }

    /////////////////////////////

    // FOR INTEGRATION
    dispatch(
      editJobAction(
        job_details.id,
        formData,
        workerBody,
        newPartBody,
        () => {
          // navigate(routes["Jobs"].path);
          history.goBack();
        },
        toast,
      ),
    );
  };

  const onAdd = () => {
    const newArray = [
      ...floorNdoor,
      {
        floor: "",
        door: "",
      },
    ];

    setFloorNdoor(newArray);
  };

  const onClose = (i) => {
    const newArray = [...floorNdoor].filter((item, index) => index !== i);

    setFloorNdoor(newArray);
  };

  const onInspectionAdd = () => {
    const newArray = [
      ...inspectionfloorNdoor,
      {
        floor: "",
        doorNpart: [
          {
            door: "",
            part: "",
          },
        ],
      },
    ];

    setInspectionFloorNdoor(newArray);
  };

  const onInspectionClose = (i) => {
    const newArray = [...inspectionfloorNdoor].filter(
      (item, index) => index !== i,
    );

    setInspectionFloorNdoor(newArray);
  };

  const makeUniqueArray = (array) => {
    return [...new Set(array)];
  };

  const onOpenDeleteModal = (id) => {
    setActiveJobPArt(id);
    setShowDeleteModal(true);
  };

  const onDeleteHandler = (e) => {
    e.preventDefault();
    dispatch(
      deleteJobPartByIdAction(activeJobPArt, toast, () => {
        onCloseModal();
        dispatch(getJobDelailByIdAction(job_details.id));
      }),
    );
  };

  const addMoreParts = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAddMore((prev) => !prev);
  };

  const onRemoveImage = (i) => {
    const removedArray = [...removedAttachment];
    removedArray.push(i);
    setRemovedAttachment(removedArray);
  };

  const onCloseModal = () => {
    setShowDeleteModal(false);
    setActiveJobPArt();
  };

  const getWorker = (item) => {
    const aaaa = worker_list.find(({ id }) => id === item);
    aaaa.profile_first_name = aaaa.first_name;
    aaaa.profile_last_name = aaaa.last_name;
    aaaa.full_name = aaaa.full_name;
    return aaaa;
  };
  return (
    <Card>
      <Box>
        <form onSubmit={submitHandler}>
          <FormInput label=" Select Building">
            <SelectField
              options={allList}
              getOptionLabel="prop_name"
              getOptionValue="id"
              onChangeValue={(item) => {
                onChangeHandler("siteId", item?.id)();
              }}
              isSearchable
              isLoading={allListloading}
              placeholder="Select building"
              value={allList.filter((item) => item.id === site_details.id)}
              isOptionDisabled={(option) => option.id !== site_details.id}
            />
          </FormInput>

          <FormInput label="Job Type">
            <SelectField
              options={JOB_TYPE}
              getOptionLabel="name"
              getOptionValue="value"
              onChangeValue={(item) => {
                onChangeHandler("jobType", item?.value)();
              }}
              isSearchable
              placeholder="Select Type"
              defaultValue={JOB_TYPE.filter(
                (element) => element.value === job_details.job_type,
              )}
              isOptionDisabled={(option) =>
                option.value !== job_details.job_type
              }
            />
          </FormInput>

          <FormInput label="Job Parts">
            <>
              <Box flexBox jSpace alCenter p={10}>
                <Bold>Floor </Bold>
                <Bold>Door </Bold>
                <Bold>Part </Bold>
                <Bold>Action </Bold>
              </Box>
              {job_parts_list.map(
                (
                  { door_details, door_part_details, floor_details, id },
                  index,
                ) => {
                  return (
                    <Box key={index} flexBox jSpace alCenter p={10}>
                      <Paragraph>{floor_details.floor_name} </Paragraph>
                      <Paragraph>{door_details.door_name} </Paragraph>
                      <Paragraph>{door_part_details.part_name} </Paragraph>

                      <MdClose
                        style={{ cursor: "pointer" }}
                        onClick={() => onOpenDeleteModal(id)}
                        color="red"
                      />
                    </Box>
                  );
                },
              )}
            </>
          </FormInput>

          <PrimaryButton
            title="Add More Parts"
            onClick={addMoreParts}
            style={{ marginBottom: 10 }}
          />

          {addMore && (
            <>
              {data.jobType === "INSTALLATION" && (
                <>
                  <Box flexBox alCenter jSpace>
                    <Title>Floor</Title>
                    <FaPlus onClick={onAdd} />
                  </Box>
                  {floorNdoor.map((_, index) => {
                    return (
                      <Box key={index} flexBox alCenter>
                        <Box flex={1}>
                          <InstallationPart
                            floorNdoor={floorNdoor}
                            index={index}
                            setFloorNdoor={setFloorNdoor}
                            onClose={onClose}
                          />
                        </Box>
                        {floorNdoor?.length > 1 && (
                          <MdClose onClick={() => onClose(index)} />
                        )}
                      </Box>
                    );
                  })}
                </>
              )}

              {(data.jobType === "INSPECTION" ||
                data.jobType === "MAINTENANCE") && (
                <>
                  <Box flexBox alCenter jSpace>
                    <Title>Floor</Title>
                    <FaPlus onClick={onInspectionAdd} />
                  </Box>
                  {inspectionfloorNdoor.map((_, index) => {
                    return (
                      <Box key={index} flexBox alCenter>
                        <Box flex={1}>
                          <InspectionPart
                            inspectionfloorNdoor={inspectionfloorNdoor}
                            index={index}
                            setInspectionFloorNdoor={setInspectionFloorNdoor}
                            onCloseFloor={() => onInspectionClose(index)}
                            inspectionPartArray={[
                              ...defaultpartlist,
                              ...inspectionPartArray,
                            ]}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </>
              )}
            </>
          )}
          <FormInput label=" Select User">
            <SelectField
              isMulti
              options={userWorkerList}
              getOptionLabel="full_name"
              getOptionValue="id"
              onChangeValue={(item) => {
                const newValue = item?.map((val) => {
                  return val.id;
                });
                console.log("[...newValue]", [...newValue]);

                setWorkArray([...newValue]);
              }}
              isSearchable
              placeholder="Select User"
              defaultValue={defaultWorkerList.map((item) => getWorker(item))}
            />
          </FormInput>

          <FormInput label="Start Date">
            <InputField
              placeholder="start Date"
              name="startDate"
              value={data.startDate}
              onChange={onChangeHandler("startDate")}
              type="date"
              // defaultValue="04-03-2020"
              // value="2018-07-22"
              defaultValue={job_details.startDate}
              disabled
            />
          </FormInput>

          <FormInput label="Job Description">
            <TextArea
              placeholder="Job Description"
              name="jobDescription"
              value={data.jobDescription}
              onChange={onChangeHandler("jobDescription")}
              defaultValue={job_details.job_description}
            />
          </FormInput>

          <FormInput label="Scope and Purpose of the Fire Door Inspection Report">
            <TextArea
              placeholder="Scope"
              name="scope"
              value={data.scope}
              onChange={onChangeHandler("scope")}
              defaultValue={job_meta_details.scope}
            />
          </FormInput>
          <FormInput label="Limitations of the Fire Door Assessment">
            <TextArea
              placeholder="Limitations of the Fire Door Assessment"
              name="limitations"
              value={data.limitations}
              onChange={onChangeHandler("limitations")}
              defaultValue={job_meta_details.limitation}
            />
          </FormInput>
          <FormInput label="Prioritisation of Recommendations and Remedial Works">
            <TextArea
              placeholder="Prioritisation of Recommendations and Remedial Works"
              name="priorities"
              value={data.priorities}
              onChange={onChangeHandler("priorities")}
              defaultValue={job_meta_details.recommendation}
            />
          </FormInput>

          <FormInput label="Attachment">
            <ImageUpload
              onChange={changeImage}
              buttonStyle={{ background: "red", display: "inline-block" }}
              title="upload image"
              multiple
              buttonclick={
                <Box mt={20}>
                  <div
                    style={{
                      display: "inline-block",
                      border: "1px dashed #000",
                      padding: 50,
                      cursor: "pointer",
                      overflow: "hidden",
                    }}>
                    <Image
                      alt="Avatar"
                      link={UPLOAD}
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                </Box>
              }>
              {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                <Box mb={10}>
                  <Grid.Container lg={4} md={2} sm={1}>
                    {job_details?.attachments?.length > 0 &&
                      job_details.attachments.map(
                        (item, index) =>
                          !removedAttachment.includes(index) && (
                            <DisplayFileName
                              key={index}
                              label={item}
                              onClick={() => onRemoveImage(index)}
                              old
                            />

                            // <div key={index}>
                            //   <div
                            //     style={{
                            //       position: "relative",
                            //       display: "inline-block",
                            //       boxShadow: "0px 2px 10px 0px #888888",
                            //     }}>
                            //     <Image
                            //       alt="Avatar"
                            //       link={`${FILE_URL}/job/${item}`}
                            //       style={{
                            //         width: 200,
                            //         height: 200,
                            //         objectFit: "contain",
                            //         overflow: "hidden",
                            //       }}
                            //     />
                            //     <div
                            //       style={{
                            //         position: "absolute",
                            //         top: 0,
                            //         right: 0,
                            //         cursor: "pointer",
                            //       }}
                            //       onClick={() => onRemoveImage(index)}>
                            //       <RiCloseCircleFill color="red" size={25} />
                            //     </div>
                            //   </div>
                            // </div>
                          ),
                      )}

                    {imageData &&
                      imageData.map((item, index) => (
                        // <div key={index}>
                        //   <div
                        //     style={{
                        //       position: "relative",
                        //       display: "inline-block",
                        //       boxShadow: "0px 2px 10px 0px #888888",
                        //     }}>
                        //     <Image
                        //       alt="Avatar"
                        //       link={item.url}
                        //       style={{
                        //         width: 200,
                        //         height: 200,
                        //         objectFit: "contain",
                        //         overflow: "hidden",
                        //       }}
                        //     />
                        //     <div
                        //       style={{
                        //         position: "absolute",
                        //         top: 0,
                        //         right: 0,
                        //         cursor: "pointer",
                        //       }}
                        //       onClick={() => onRemove(index)}>
                        //       <RiCloseCircleFill color="red" size={25} />
                        //     </div>
                        //   </div>
                        // </div>
                        <DisplayFileName
                          key={index}
                          label={item?.file?.name}
                          onClick={() => onRemove(index)}
                        />
                      ))}
                  </Grid.Container>
                </Box>
              )}
            </ImageUpload>
          </FormInput>

          <DefaultButton title="Submit" type="submit" />
        </form>
      </Box>

      <Modal visible={showDeleteModal} onOutsideClick={onCloseModal}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Are you sure you want to delete?</h3>
          <ActivityIndicator animating={deleteJobLoader}>
            <div
              style={{
                width: "100%",
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                float: "right",
              }}>
              <DefaultButton
                style={{
                  background: "#ff4343",
                  color: "white",
                  marginRight: 10,
                }}
                onClick={onDeleteHandler}
                title="Delete"
              />
              <GhostButton onClick={onCloseModal} title="Cancel" />
            </div>
          </ActivityIndicator>
        </div>
      </Modal>
    </Card>
  );
};

export const EditJobPage = () => {
  const { params } = useNavigation();
  const dispatch = useDispatch();
  const { jobDetail, jobDetailLoader } = useSelector((state) => state.job);
  useEffect(() => {
    dispatch(getJobDelailByIdAction(params.id));
  }, [params.id]);

  return (
    <CompWrapper>
      {!!jobDetail && !jobDetailLoader ? <EditComponent /> : <Box>Loading</Box>}
    </CompWrapper>
  );
};
