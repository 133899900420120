import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";

import { useDocTitle } from "../../../../hooks/useDocTitle.hook";
import { isValid, useInput, validator } from "../../../../utils";

import { ActivityIndicator, Card, CompWrapper } from "../../../hoc";
import {
  Box,
  DefaultButton,
  FormInput,
  GooglePlaces,
  Grid,
  ImageUpload,
  InputField,
  PrimaryButton,
  SelectField,
} from "../../../common";
import { addClientAction, getAllTierList } from "../../../../actions";
import { MdAdd, MdClose } from "react-icons/md";
import { Image } from "../../../generics";
import UPLOAD from "../../../../assets/icons/upload.png";
export const AddClientPage = () => {
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.client);
  useDocTitle("Add Client");
  const { data, onChangeHandler } = useInput({
    name: "",
    email: "",
    tierId: "",
    // phone: "",
  });

  const [image, setImage] = React.useState([]);
  const [locationName, setLocationName] = React.useState("");
  const [phone, setPhone] = useState([""]);

  const { tierList } = useSelector((state) => state.tier);
  const [tierListFormat, setTierListFormat] = useState([]);

  useEffect(() => {
    dispatch(getAllTierList());
  }, []);

  useEffect(() => {
    setTierListFormat(tierList.map(({ tier_details }) => tier_details));
  }, [tierList]);

  const changeImage = (data) => {
    setImage(data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, name, tierId } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("name", name.length === 0, () => {
      showMessage("name musn't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("email musn't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("address musn't be empty!");
    });

    validate("tierId", tierId.length === 0, () => {
      showMessage("Tier musn't be empty!");
    });
    // validate("phone", phone.length === 0, () => {
    //   showMessage("Phone musn't be empty!");
    // });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    let formData = new FormData();
    await formData.append("contractorName", name);
    await formData.append(
      "contractorAddress",
      JSON.stringify({
        coordinates: [1, 1],
        value: locationName,
      }),
    );

    await formData.append("contractorPhone", JSON.stringify(phone));
    await formData.append("contractorEmail", email);
    await formData.append("imageFile", image[0].file);

    // FOR INTEGRATION
    dispatch(
      addClientAction(
        tierId,
        formData,
        () => {
          navigate(routes["Client"].path);
        },
        toast,
      ),
    );
  };

  const changeHandle = (index) => {
    return function (e) {
      const phoneArray = [...phone];
      phoneArray[index] = e.target.value;
      setPhone(phoneArray);
    };
  };

  const onAdd = () => {
    setPhone([...phone, ""]);
  };

  const close = (index) => {
    const phoneClone = [...phone];

    phoneClone.splice(index, 1);
    setPhone(phoneClone);
  };

  return (
    <CompWrapper>
      <Card>
        <Box>
          <form onSubmit={submitHandler} required>
            <FormInput label="Name" required>
              <InputField
                placeholder="Name"
                name="name"
                value={data.name}
                onChange={onChangeHandler("name")}
                type="text"
              />
            </FormInput>

            <FormInput label="Email" required>
              <InputField
                placeholder="Email"
                name="email"
                value={data.email}
                onChange={onChangeHandler("email")}
                type="email"
              />
            </FormInput>

            <FormInput label="Address" required>
              <GooglePlaces setLocationName={setLocationName} />
            </FormInput>

            <FormInput label=" Select Subscription Tier" required>
              {tierListFormat?.length ? (
                <SelectField
                  options={tierListFormat}
                  getOptionLabel="name_price"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    onChangeHandler("tierId", item?.id)();
                  }}
                  isSearchable
                  placeholder="Select Subscription Tier"
                />
              ) : null}
            </FormInput>

            <FormInput
              label="Phone"
              newElement={
                <PrimaryButton
                  onClick={onAdd}
                  style={{ cursor: "pointer" }}
                  title="Add Field"
                  type="button"
                />
              }>
              <Box>
                {phone.length > 0 &&
                  phone.map((item, index) => {
                    return (
                      <Box flexBox alCenter key={index} mb={10}>
                        <InputField
                          placeholder="Phone"
                          name="phone"
                          value={item}
                          // onChange={onChangeHandler("phone")}
                          onChange={changeHandle(index)}
                        />
                        {phone.length > 1 && (
                          <Box ml={20}>
                            <div
                              type="button"
                              className="close"
                              style={{ cursor: "pointer" }}
                              onClick={() => close(index)}>
                              <MdClose color="red" />
                            </div>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </FormInput>

            <FormInput label="Logo">
              <ImageUpload
                onChange={changeImage}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                accept="image/*"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={1} md={1} sm={1}>
                      {imageData &&
                        imageData.map((item, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: "inline-block",
                                boxShadow: "0px 2px 10px 0px #888888",
                              }}>
                              <Image
                                alt="Avatar"
                                link={item.url}
                                style={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "contain",
                                  overflow: "hidden",
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>
            <ActivityIndicator animating={addLoading}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Card>
    </CompWrapper>
  );
};
