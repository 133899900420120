import { CLIENTS_REGISTER } from ".";
import { api, APIS, TABLE_LIMIT } from "../config";
import {
  ADD_CLIENTS,
  CLIENTS_DETAIL,
  CLIENTS_LIST,
  DELETE_CLIENTS,
  EDIT_CLIENTS,
  CLIENTS_PEOPLE,
  CLIENTS_COMPANIES,
  CLIENTS_MY_PEOPLE,
  ADD_ADMIN,
  EDIT_USER,
  UPDATE_CLIENTS_STATUS,
  CLIENTS_DASHBOARD,
  PREVIOUS_CLIENTS_LIST,
  REQUESTED_CLIENTS_LIST,
  REJECTED_CLIENTS_LIST,
  TRIAL_CLIENTS_LIST,
  UPDATE_CLIENTS_REQUEST,
  UNSUBSCRIBED_CLIENTS_LIST,
} from "./ActionTypes.action";

export function getClientListAction(page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_LIST.LOADING });
      res = await api(`${APIS.client}/all?page=${page}&limit=${TABLE_LIMIT}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENTS_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENTS_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_LIST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getCompanyListAction(page = 1, filter = "") {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_LIST.LOADING });
      res = await api(
        `${APIS.dashboard}/company/search?page=${page}&limit=${TABLE_LIMIT}${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENTS_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENTS_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_LIST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getRequestedCompanyListAction(page = 1, filter = "") {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: REQUESTED_CLIENTS_LIST.LOADING });
      res = await api(
        `${APIS.dashboard}/company/search?verified=new&page=${page}&limit=9${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: REQUESTED_CLIENTS_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: REQUESTED_CLIENTS_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: REQUESTED_CLIENTS_LIST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getRejectedCompanyListAction(page = 1, filter = "") {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: REJECTED_CLIENTS_LIST.LOADING });
      res = await api(
        `${APIS.dashboard}/company/search?page=${page}&limit=${TABLE_LIMIT}&verified=reject${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: REJECTED_CLIENTS_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: REJECTED_CLIENTS_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: REJECTED_CLIENTS_LIST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getPreviousClientListAction(page = 1, filter = "") {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: PREVIOUS_CLIENTS_LIST.LOADING });
      res = await api(
        `${APIS.dashboard}/company/search/old?page=${page}&limit=${TABLE_LIMIT}${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: PREVIOUS_CLIENTS_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: PREVIOUS_CLIENTS_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: PREVIOUS_CLIENTS_LIST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getTrialClientListAction(page = 1, filter = "") {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: TRIAL_CLIENTS_LIST.LOADING });
      res = await api(
        `${APIS.dashboard}/company/search?page=${page}&limit=${TABLE_LIMIT}${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: TRIAL_CLIENTS_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: TRIAL_CLIENTS_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: TRIAL_CLIENTS_LIST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getUnsubscribedClientListAction(page = 1, filter = "") {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: UNSUBSCRIBED_CLIENTS_LIST.LOADING });
      res = await api(
        `${APIS.dashboard}/company/search?page=${page}&limit=${TABLE_LIMIT}&status=unsubscribed${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({
          type: UNSUBSCRIBED_CLIENTS_LIST.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: UNSUBSCRIBED_CLIENTS_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: UNSUBSCRIBED_CLIENTS_LIST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function updateClientRequestAction(id, body, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: UPDATE_CLIENTS_REQUEST.LOADING });
      res = await api(`${APIS.client}/verify/${id}`, "PATCH", body);

      const { success, data, message } = res.data;

      if (success) {
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
        dispatch({ type: UPDATE_CLIENTS_REQUEST.SUCCESS, payload: id });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: UPDATE_CLIENTS_REQUEST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: UPDATE_CLIENTS_REQUEST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function updateClientStatusAction(id, body, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: UPDATE_CLIENTS_STATUS.LOADING });
      res = await api(`${APIS.client}/deactivate/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: UPDATE_CLIENTS_STATUS.SUCCESS });
        toast({
          message: "Client status successfully changed",
          type: "success",
        });
        callback && callback();
      } else {
        dispatch({ type: UPDATE_CLIENTS_STATUS.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: UPDATE_CLIENTS_STATUS.ERROR });
      console.error(message);
      toast({ message: "Error changing Client status", type: "error" });
      return 0;
    }
  };
}

export function getClientDetailAction(id = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_DETAIL.LOADING });
      res = await api(`${APIS.client}/${id}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENTS_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENTS_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addClientAction(tierId, formData, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_CLIENTS.LOADING });
      res = await api(`${APIS.client}/super/${tierId}`, "POST", formData, {
        file: true,
      });

      const { success, data, message } = res.data;
      console.log("@#@3232", data);

      if (success) {
        dispatch({ type: ADD_CLIENTS.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: ADD_CLIENTS.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_CLIENTS.ERROR });
      toast({
        message: `Adding Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function editClientAction(id, formData, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_CLIENTS.LOADING });
      res = await api(APIS.client + "/" + id, "PATCH", formData, {
        file: true,
      });

      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: EDIT_CLIENTS.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: EDIT_CLIENTS.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_CLIENTS.ERROR });
      toast({
        message: `Editing Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function deleteClientAction(id, toast, pageNumber) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_CLIENTS.LOADING });
      res = await api(`${APIS.client}/delete/${id}`, "PATCH");

      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: DELETE_CLIENTS.SUCCESS });
        dispatch(getClientListAction(pageNumber));
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: DELETE_CLIENTS.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_CLIENTS.ERROR });
      toast({
        message: `DELETING Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function getClientPeopleAction(id, role, page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_PEOPLE.LOADING });
      res = await api(
        `${APIS.client}/roles/${id}?role=${role}&page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENTS_PEOPLE.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENTS_PEOPLE.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_PEOPLE.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getClientCompaniesAction(id, page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_COMPANIES.LOADING });
      res = await api(
        `${APIS.contractorSite}/${id}?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENTS_COMPANIES.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENTS_COMPANIES.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_COMPANIES.ERROR });
      console.error(message);
      return 0;
    }
  };
}

// same as getWorkerAction but with pagination
export function getClientMyPeopleAction(role, page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_MY_PEOPLE.LOADING });
      res = await api(`${APIS.user}/${role}?page=${page}&limit=${TABLE_LIMIT}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENTS_MY_PEOPLE.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENTS_MY_PEOPLE.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_MY_PEOPLE.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addAdminAction(body, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_ADMIN.LOADING });
      res = await api(APIS.register, "POST", body);

      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: ADD_ADMIN.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: ADD_ADMIN.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_ADMIN.ERROR });
      toast({
        message: `Adding Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function editUserAction(id, body, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_USER.LOADING });
      res = await api(`${APIS.deactivateUser}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: EDIT_USER.SUCCESS });
        toast({
          message: "User status successfully changed",
          type: "success",
        });
        callback && callback();
      } else {
        dispatch({ type: EDIT_USER.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_USER.ERROR });
      console.error(message);
      toast({ message: "Error changing user status", type: "error" });
      return 0;
    }
  };
}

export function getClientDashboard() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_DASHBOARD.LOADING });
      res = await api(`${APIS.dashboard}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENTS_DASHBOARD.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENTS_DASHBOARD.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_DASHBOARD.ERROR });
      console.error(message);
      return 0;
    }
  };
}

// REGISTER CLIENT

export function registerClientAction(tierId, formData, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENTS_REGISTER.LOADING });
      res = await api(`${APIS.client}/${tierId}`, "POST", formData, {
        file: true,
      });

      const { success, data, message } = res.data;
      console.log("@#@3232 resgiter", data);

      if (success) {
        dispatch({ type: CLIENTS_REGISTER.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: CLIENTS_REGISTER.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENTS_REGISTER.ERROR });
      toast({
        message: `Adding Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}
