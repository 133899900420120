// import React, { useState } from "react";
// import { Modal } from "react-uicomp";
// import { useAuth, useNavigation } from "react-auth-navigation";

// import { useDispatch, useSelector } from "react-redux";

// import { loginAction, forgetPassword } from "../../../actions";
// import { InputField, DefaultButton } from "../../common";
// import { ActivityIndicator, Card } from "../../hoc";
// import { isValid, useInput, validator } from "../../../utils";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";

import { useDocTitle } from "../../../hooks/useDocTitle.hook";
import { isValid, useInput, validator } from "../../../utils";

import { ActivityIndicator, Card, CompWrapper } from "../../hoc";
import {
  Box,
  DefaultButton,
  FormInput,
  GooglePlaces,
  Grid,
  ImageUpload,
  InputField,
  PrimaryButton,
  SelectField,
} from "../../common";
import {
  addClientAction,
  getAllTierList,
  registerClientAction,
} from "../../../actions";
import { MdAdd, MdClose } from "react-icons/md";
import { Bold, Heading, Image } from "../../generics";
import UPLOAD from "../../../assets/icons/upload.png";

export const RegistrationPage = (props) => {
  const { params } = useNavigation();
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { registerLoading } = useSelector((state) => state.client);
  useDocTitle("Registration");
  const { tierList } = useSelector((state) => state.tier);
  const [tierListFormat, setTierListFormat] = useState([]);

  useEffect(() => {
    dispatch(getAllTierList());
  }, []);

  useEffect(() => {
    setTierListFormat(tierList.map(({ tier_details }) => tier_details));
  }, [tierList, params.id]);

  useEffect(() => {
    data.tierId =
      tierListFormat.find((element) => element.id === parseInt(params.id))
        ?.id ?? "";
  }, [tierListFormat, params.id]);

  const { data, onChangeHandler } = useInput({
    contractorName: "",
    contractorEmail: "",
    tierId: "",
    firstName: "",
    lastName: "",
    profileJobTitle: "",
    email: "",
    password: "",
  });

  console.log(data);

  const [image, setImage] = React.useState([]);
  const [locationName, setLocationName] = React.useState("");
  const [profileAddress, setProfileAddress] = React.useState("");
  const [phone, setPhone] = useState([""]);
  const [profilePhone, setProfilePhone] = useState([""]);
  const [count, setCount] = useState(false);

  const changeImage = (data) => {
    setImage(data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const {
      contractorName,
      contractorEmail,
      firstName,
      lastName,
      profileJobTitle,
      email,
      password,
      tierId,
    } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("contractorName", contractorName.length === 0, () => {
      showMessage("Company name can't be empty!");
    });

    validate("contractorEmail", contractorEmail.length === 0, () => {
      showMessage("Company Email can't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("Company Address can't be empty!");
    });

    validate("tierId", tierId.length === 0, () => {
      showMessage("Tier can't be empty!");
    });

    validate("firstName", firstName.length === 0, () => {
      showMessage("Admin first name can't be empty!");
    });

    validate("lastName", lastName.length === 0, () => {
      showMessage("Admin last name can't be empty!");
    });

    validate("address", profileAddress.length === 0, () => {
      showMessage("Admin Address can't be empty!");
    });

    validate("profileJobTitle", profileJobTitle.length === 0, () => {
      showMessage("Admin job title can't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("Admin email can't be empty!");
    });

    validate("password", password.length === 0, () => {
      showMessage("Admin password can't be empty!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    let formData = new FormData();
    // Company
    await formData.append("contractorName", contractorName);
    await formData.append(
      "contractorAddress",
      JSON.stringify({
        coordinates: [1, 1],
        value: locationName,
      }),
    );

    await formData.append("contractorPhone", JSON.stringify(phone));
    await formData.append("contractorEmail", contractorEmail);
    await formData.append("imageFile", image[0].file);

    // Admin
    await formData.append("firstName", firstName);
    await formData.append("lastName", lastName);
    await formData.append("profileJobTitle", profileJobTitle);
    await formData.append("email", email);
    await formData.append("password", password);
    await formData.append("profilePhone", JSON.stringify(profilePhone));
    await formData.append(
      "profileAddress",
      JSON.stringify({
        coordinates: [1, 1],
        value: profileAddress,
      }),
    );
    console.log(data);

    // FOR INTEGRATION
    dispatch(
      registerClientAction(
        tierId,
        formData,
        () => {
          navigate(routes["Login"].path);
        },
        toast,
      ),
    );
  };

  const changeHandlePhone = (index) => {
    return function (e) {
      const phoneArray = [...phone];
      phoneArray[index] = e.target.value;
      setPhone(phoneArray);
    };
  };

  const changeHandleProfilePhone = (index) => {
    return function (e) {
      const phoneArray = [...profilePhone];
      phoneArray[index] = e.target.value;
      setProfilePhone(phoneArray);
    };
  };

  const onAdd = () => {
    setPhone([...phone, ""]);
  };

  const onAddProfile = () => {
    setProfilePhone([...profilePhone, ""]);
  };

  const close = (index) => {
    const phoneClone = [...phone];

    phoneClone.splice(index, 1);
    setPhone(phoneClone);
  };

  const closeProfile = (index) => {
    const phoneClone = [...profilePhone];

    phoneClone.splice(index, 1);
    setProfilePhone(phoneClone);
  };

  const changeEmail = () => {
    if (count) {
      data.email = "";
    } else {
      data.email = data.contractorEmail ?? "";
    }
    setCount((prev) => !prev);
  };

  return (
    <div className="login-container">
      <div className="login">
        <Card>
          <div className="login-contents">
            <h1 className="login-head">Registration</h1>
            <form onSubmit={submitHandler}>
              <Box className="login-columns" flex={1}>
                <Box flex={1}>
                  <Bold
                    mb={30}
                    pb={10}
                    style={{
                      fontSize: 20,
                      borderBottom: "1px solid #e1e1e1",
                      width: "100%",
                    }}>
                    Company Details
                  </Bold>

                  <FormInput label="Company Name">
                    <InputField
                      placeholder="Company Name"
                      name="contractorName"
                      value={data.contractorName}
                      onChange={onChangeHandler("contractorName")}
                      type="text"
                    />
                  </FormInput>

                  <FormInput label="Company Email">
                    <InputField
                      placeholder="Company Email"
                      name="contractorEmail"
                      value={data.contractorEmail}
                      onChange={onChangeHandler("contractorEmail")}
                      type="email"
                    />
                  </FormInput>

                  <FormInput label="Company Address">
                    <GooglePlaces setLocationName={setLocationName} />
                  </FormInput>

                  <FormInput
                    label="Company Phone"
                    newElement={
                      <PrimaryButton
                        onClick={onAdd}
                        style={{ cursor: "pointer" }}
                        title="Add Field"
                        type="button"
                      />
                    }>
                    <Box>
                      {phone.length > 0 &&
                        phone.map((item, index) => {
                          return (
                            <Box flexBox alCenter key={index} mb={10}>
                              <InputField
                                placeholder="Company Phone"
                                name="contractorPhone"
                                value={item}
                                // onChange={onChangeHandler("phone")}
                                onChange={changeHandlePhone(index)}
                              />
                              {phone.length > 1 && (
                                <Box ml={20}>
                                  <div
                                    type="button"
                                    className="close"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => close(index)}>
                                    <MdClose color="red" />
                                  </div>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                  </FormInput>

                  <FormInput label=" Select Subscription Tier" required>
                    {tierListFormat?.length ? (
                      <SelectField
                        options={tierListFormat}
                        getOptionLabel="name_price"
                        getOptionValue="id"
                        onChangeValue={(item) => {
                          onChangeHandler("tierId", item?.id)();
                        }}
                        defaultValue={tierListFormat.filter(
                          ({ id }) => id === parseInt(params.id),
                        )}
                        isSearchable
                        placeholder="Select Subscription Tier"
                      />
                    ) : null}
                  </FormInput>

                  <FormInput label="Company Logo">
                    <ImageUpload
                      onChange={changeImage}
                      buttonStyle={{
                        background: "red",
                        display: "inline-block",
                      }}
                      title="upload image"
                      accept="image/*"
                      buttonclick={
                        <Box mt={20}>
                          <div
                            style={{
                              display: "inline-block",
                              border: "1px dashed #000",
                              padding: 50,
                              cursor: "pointer",
                              overflow: "hidden",
                            }}>
                            <Image
                              alt="Avatar"
                              link={UPLOAD}
                              style={{
                                width: 100,
                                height: 100,
                                objectFit: "contain",
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        </Box>
                      }>
                      {({
                        onUploadImage,
                        imageData,
                        onRemove,
                        deleteAllHandler,
                      }) => (
                        <Box>
                          <Grid.Container lg={1} md={1} sm={1}>
                            {imageData &&
                              imageData.map((item, index) => (
                                <div key={index}>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      boxShadow: "0px 2px 10px 0px #888888",
                                    }}>
                                    <Image
                                      alt="Avatar"
                                      link={item.url}
                                      style={{
                                        width: 200,
                                        height: 200,
                                        objectFit: "contain",
                                        overflow: "hidden",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                          </Grid.Container>
                        </Box>
                      )}
                    </ImageUpload>
                  </FormInput>
                </Box>

                <Box className="login-columns-right">
                  <Bold
                    mb={30}
                    pb={10}
                    style={{
                      fontSize: 20,
                      borderBottom: "1px solid #e1e1e1",
                      width: "100%",
                    }}>
                    Admin Details
                  </Bold>
                  <Box flexBox alCenter jSpace>
                    <FormInput label="First Name">
                      <InputField
                        placeholder="First Name"
                        name="firstName"
                        value={data.firstName}
                        onChange={onChangeHandler("firstName")}
                        type="text"
                      />
                    </FormInput>
                    <Box ml={10}>
                      <FormInput label="Last Name">
                        <InputField
                          placeholder="Last Name"
                          name="lastName"
                          value={data.lastName}
                          onChange={onChangeHandler("lastName")}
                          type="text"
                        />
                      </FormInput>
                    </Box>
                  </Box>
                  <Box flexBox alCenter jSpace>
                    <FormInput label="Address">
                      <GooglePlaces setLocationName={setProfileAddress} />
                    </FormInput>

                    <Box ml={10}>
                      <FormInput label="Job Title">
                        <InputField
                          placeholder="Job Title"
                          name="profileJobTitle"
                          value={data.profileJobTitle}
                          onChange={onChangeHandler("profileJobTitle")}
                          type="text"
                        />
                      </FormInput>
                    </Box>
                  </Box>

                  <FormInput
                    label="Phone"
                    newElement={
                      <PrimaryButton
                        onClick={onAddProfile}
                        style={{ cursor: "pointer" }}
                        title="Add Field"
                        type="button"
                      />
                    }>
                    <Box>
                      {profilePhone.length > 0 &&
                        profilePhone.map((item, index) => {
                          return (
                            <Box flexBox alCenter key={index} mb={10}>
                              <InputField
                                placeholder="Phone"
                                name="phone"
                                value={item}
                                // onChange={onChangeHandler("phone")}
                                onChange={changeHandleProfilePhone(index)}
                              />
                              {profilePhone.length > 1 && (
                                <Box ml={20}>
                                  <div
                                    type="button"
                                    className="close"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => closeProfile(index)}>
                                    <MdClose color="red" />
                                  </div>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                  </FormInput>

                  <FormInput
                    label="Email"
                    newElement={
                      <Check onClick={changeEmail} checked={count} />
                    }>
                    <InputField
                      placeholder="Email"
                      name="email"
                      value={data.email}
                      onChange={onChangeHandler("email")}
                      type="email"
                    />
                  </FormInput>

                  <FormInput label="Password">
                    <InputField
                      placeholder="Password"
                      name="password"
                      value={data.password}
                      onChange={onChangeHandler("password")}
                      type="password"
                    />
                  </FormInput>
                </Box>
              </Box>
              <ActivityIndicator animating={registerLoading}>
                <DefaultButton title="Register" type="submit" />
              </ActivityIndicator>
            </form>
          </div>
        </Card>
      </div>
    </div>
  );
};

const Check = ({ onClick, checked }) => {
  return (
    <label style={{ cursor: "pointer" }}>
      {" "}
      <input type="checkbox" onChange={onClick} checked={checked} /> Use Company
      Email
    </label>
  );
};
