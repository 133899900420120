import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { PieChart, Pie, Sector, Cell } from "recharts";

import { MdEdit, BsFillEyeFill } from "react-icons/all";
import {
  useComponentDidUpdate,
  useDebounceValue,
  useDocTitle,
} from "../../../../hooks";
import {
  DefaultButton,
  Box,
  PrimaryButton,
  Grid,
  SelectFieldBorderless,
  InputFieldBorderless,
  SearchField,
  DatePickerCustom,
  IconButton,
  ToolTip,
} from "../../../common";
import {
  Bold,
  Chip,
  Image,
  Paragraph,
  Heading,
  Title,
} from "../../../generics";
import Table from "../../../common/table/Table.common";
import { RippleButton } from "react-uicomp";
import moment from "moment";

// IMPORT HOCS
import { CompWrapper, Card, ActivityIndicator } from "../../../hoc";
import { useDispatch, useSelector } from "react-redux";
import { getJobList } from "../../../../actions";
import { Pagination } from "@material-ui/lab";
import { TABLE_LIMIT } from "../../../../config";

const pieData = [
  { name: "Group A", value: 151 },
  { name: "Group B", value: 211 },
  { name: "Group C", value: 41 },
];
const COLORS = ["#ff4343", "#ffc107", "#28a745"];

const JOB_TYPE = [
  { name: "INSTALLATION", value: "INSTALLATION" },
  { name: "INSPECTION", value: "INSPECTION" },
  { name: "MAINTENANCE", value: "MAINTENANCE" },
];

const JOB_STATUS = [
  { name: "Scheduled", value: "null" },
  { name: "Live/Open", value: "false" },
  { name: "Completed", value: "true" },
];

const getJobStatus = (item) => {
  switch (item) {
    case true:
      return "Completed";
    case false:
      return "Live/Open";

    case null:
      return "Scheduled";

    default:
      return "Scheduled";
  }
};

const getJobStatusColor = (item) => {
  switch (item) {
    case true:
      return "#28a745";
    case false:
      return "#ff4343";

    case null:
      return "#ffc107";

    default:
      return "#ffc107";
  }
};

export const FilterChip = ({ title }) => {
  return (
    <Chip
      mr={10}
      pl={10}
      pr={10}
      style={{ backgroundColor: "#b1b1b1", fontSize: 12 }}>
      {title}
    </Chip>
  );
};

export const JobCard = ({ data, onEdit, onView }) => {
  const { job_details, site_details, progress, scheduled, completed } = data;
  return (
    <Card>
      <Box flex={1} flexBox alEnd jEnd>
        <BsFillEyeFill
          onClick={() => onView(job_details)}
          style={{
            transform: "translateY(-5px)",
            cursor: "pointer",
            marginRight: 10,
          }}
        />
        <MdEdit
          style={{ cursor: "pointer" }}
          onClick={() => onEdit(job_details)}
          style={{ transform: "translateY(-5px)", cursor: "pointer" }}
        />
      </Box>

      <Box>
        <Title style={{ fontSize: 18 }} className="dashboard-card-title">
          {site_details?.prop_name}
        </Title>
        <Paragraph style={{ paddingTop: 4, fontSize: 14 }}>
          #{job_details?.job_ident_no}
        </Paragraph>
      </Box>
      <Box mt={20}>
        <Box flexBox alCenter className="dashboard-job-status">
          <Box flex={1} flexBox alCenter jSpace>
            <Bold>Job Type</Bold>
            <Paragraph className="lighttext">{job_details?.job_type}</Paragraph>
          </Box>
        </Box>
        <Box mt={5} flexBox alCenter className="dashboard-job-status">
          <Box flex={1} flexBox alCenter jSpace>
            <Bold>Job Start</Bold>
            <Paragraph className="lighttext">
              {moment(job_details?.start_time).format("Do MMM, YYYY")}
            </Paragraph>
          </Box>
        </Box>
        {/* <Box mt={5} flexBox alCenter className="dashboard-job-status">
          <Box flex={1} flexBox alCenter jSpace>
            <Bold>Job End</Bold>
            <Paragraph className="lighttext">3rd May 2020</Paragraph>
          </Box>
        </Box> */}

        {/* <Box mt={5} flexBox alCenter className="dashboard-job-status">
          <Box flex={1} flexBox alCenter jSpace>
            <Bold>Assigned to</Bold>
            <Paragraph className="lighttext">Steve Anderson</Paragraph>
          </Box>
        </Box> */}
      </Box>
      <Box mt={20}>
        <Box flexBox alCenter className="dashboard-job-status">
          <Box className="icon red"></Box>
          <Box ml={10} flex={0.5} flexBox alCenter jSpace>
            <Paragraph>Live/Open</Paragraph>
            <Paragraph ml={20} className="lighttext">
              {progress.toString()}
            </Paragraph>
          </Box>
        </Box>
        <Box mt={10} flexBox alCenter className="dashboard-job-status">
          <Box className="icon yellow"></Box>
          <Box ml={10} flex={0.5} flexBox alCenter jSpace>
            <Paragraph>Scheduled</Paragraph>
            <Paragraph ml={20} className="lighttext">
              {scheduled.toString()}
            </Paragraph>
          </Box>
        </Box>
        <Box mt={10} flexBox alCenter className="dashboard-job-status">
          <Box className="icon green"></Box>
          <Box ml={10} flex={0.5} flexBox alCenter jSpace>
            <Paragraph>Completed</Paragraph>
            <Paragraph ml={20} className="lighttext">
              {completed.toString()}
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <Box mt={-40} flexBox alEnd jSpace>
        <Box>
          <Chip
            pl={12}
            pr={12}
            style={{
              backgroundColor: getJobStatusColor(job_details?.job_status),
            }}>
            {getJobStatus(job_details?.job_status)}
          </Chip>
        </Box>
        <Box>
          <PieChart width={120} height={120}>
            <Pie
              data={[
                { name: "Live/Open", value: progress },
                { name: "Scheduled", value: scheduled },
                { name: "Completed", value: completed },
              ]}
              innerRadius={45}
              outerRadius={50}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value">
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </Box>
      </Box>
    </Card>
  );
};

export const JobsPage = () => {
  const dispatch = useDispatch();
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const job = useSelector((state) => state.job);
  const { jobList, jobCount, jobLoader } = job;
  const { navigate, routes } = navigation;

  useDocTitle("Job");

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(query.get("page") || 1);

  const [jobType, setJobType] = useState(() => {
    return JOB_TYPE.find(
      ({ value }) =>
        value.toLowerCase() === query.get("job_type")?.toLowerCase(),
    );
  });
  const [jobStatus, setJobStatus] = useState(() => {
    return JOB_STATUS.find(
      ({ value }) =>
        value.toLowerCase() === query.get("job_status")?.toLowerCase(),
    );
  });
  const [searchTerm, setSearchTerm] = useState(query.get("text") || "");
  const searchJob = useDebounceValue(searchTerm);
  const [startTime, setStartTime] = useState(query.get("start_time") || "");

  useEffect(() => {
    dispatch(
      getJobList(
        pageNumber,
        jobType?.value,
        jobStatus?.value,
        searchJob,
        startTime,
      ),
    );
    let filter = "";
    if (!!searchJob) {
      filter += `&text=${searchJob}`;
    }
    if (!!jobStatus?.value) {
      filter += `&job_status=${jobStatus?.value}`;
    }

    if (!!jobType?.value) {
      filter += `&job_type=${jobType?.value}`;
    }
    if (!!startTime) {
      filter += `&start_time=${startTime}`;
    }

    navigate(routes["List Jobs"].path + `?page=${pageNumber}${filter}`);
  }, [pageNumber]);

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      dispatch(
        getJobList(1, jobType?.value, jobStatus?.value, searchJob, startTime),
      );

      let filter = "";
      if (!!searchJob) {
        filter += `&text=${searchJob}`;
      }
      if (!!jobStatus?.value) {
        filter += `&job_status=${jobStatus?.value}`;
      }

      if (!!jobType?.value) {
        filter += `&job_type=${jobType?.value}`;
      }
      if (!!startTime) {
        filter += `&start_time=${startTime}`;
      }

      navigate(routes["List Jobs"].path + `?page=1${filter}`);
    } else {
      setPageNumber(1);
    }
  }, [jobType?.value, jobStatus?.value, searchJob, startTime]);

  const deleteHandler = (id) => {
    // dispatch(deleteServiceAction(id, toast, pageNumber));
  };

  const viewHandler = (item) => {
    navigate(routes["Jobs"].path + "/detail/" + item.id);
  };

  const editHandler = (item) => {
    if (!!item?.job_status === false) {
      navigate(routes["Jobs"].path + "/edit/" + item.id);
    } else {
      toast({
        message: `Job is already completed!! You can't Edit`,
        type: "error",
      });
    }
  };

  const getJobType = (item) => {
    switch (item) {
      case "MAINTAIN_INSPEC":
        return "Maintenance (Repair)";
      case "MAINTAIN_INSTALL":
        return "Maintenance (Install)";

      case "INSPECTION":
        return "Inspection";

      default:
        return "Installation";
    }
  };

  const clearFilterHandler = () => {
    setJobType(null);
    setJobStatus(null);
    setSearchTerm("");
    setStartTime("");
    navigate(routes["List Job".path]);
  };

  const page = async (event, newPage = 1) => {
    setPageNumber(newPage);
    // navigate(location.pathname + `?page=` + Number(newPage));
  };
  return (
    <CompWrapper breadcrumbData>
      <Box>
        <Box flexBox alCenter jSpace>
          <Heading>Jobs</Heading>
          <PrimaryButton
            title="Add Job"
            onClick={() => {
              navigate(routes["Add Job"].path);
            }}
          />
        </Box>
        <Box mt={20} flexBox alCenter jSpace>
          <Box flex={3}>
            <SearchField
              value={searchTerm}
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          <Box flex={1} />
          <Box ml={50} flex={5} flexBox alCenter jSpace>
            <Box flex={1} flexBox alEnd jEnd>
              <DatePickerCustom
                value={startTime ? moment(startTime).toDate() : startTime}
                onChange={(value) => {
                  setStartTime(moment(value).format("YYYY-MM-DD"));
                }}
              />
            </Box>
            <Box flex={1} ml={30}>
              <SelectFieldBorderless
                placeholder="Select Job Type"
                instanceId="jobType"
                options={JOB_TYPE}
                value={jobType}
                defaultValue={JOB_TYPE.find(
                  ({ value }) =>
                    value.toLowerCase() ===
                    query.get("job_type")?.toLowerCase(),
                )}
                getOptionLabel="name"
                getOptionValue="value"
                onChangeValue={(item) => {
                  console.log(item);
                  setJobType(item);
                }}
              />
            </Box>
            <Box flex={1} ml={30}>
              {/* <SelectFieldBorderless
                placeholder="Select Status"
                instanceId="status"
              /> */}
              <SelectFieldBorderless
                placeholder="Select Status"
                instanceId="status"
                options={JOB_STATUS}
                value={jobStatus}
                defaultValue={JOB_STATUS.find(
                  ({ value }) =>
                    value.toLowerCase() ===
                    query.get("job_status")?.toLowerCase(),
                )}
                getOptionLabel="name"
                getOptionValue="value"
                onChangeValue={(item) => {
                  setJobStatus(item);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box mt={20} flexBox alCenter>
          {searchTerm.length ? <FilterChip title={searchTerm} /> : null}
          {startTime.length ? <FilterChip title={startTime} /> : null}
          {!!jobType ? <FilterChip title={jobType?.value} /> : null}
          {!!jobStatus ? (
            <FilterChip
              title={
                jobStatus?.value === "true"
                  ? "Completed"
                  : jobStatus?.value === "false"
                  ? "Live/Open"
                  : "Scheduled"
              }
            />
          ) : null}

          {searchTerm.length || !!jobType || startTime.length || !!jobStatus ? (
            <Paragraph
              onClick={() => clearFilterHandler()}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#0284a4",
                fontSize: 14,
              }}>
              Clear all
            </Paragraph>
          ) : null}
        </Box>

        <Box mt={20}>
          <ActivityIndicator animating={jobLoader}>
            {jobList.length > 0 ? (
              <>
                <Grid.Container lg={3} md={2} sm={1}>
                  {jobList.map((item, i) => {
                    return (
                      <JobCard
                        key={i}
                        data={item}
                        onEdit={editHandler}
                        onView={viewHandler}
                      />
                    );
                  })}
                </Grid.Container>
                <Box>
                  <Pagination
                    style={{
                      marginLeft: "auto",
                      marginTop: 20,
                      display: "inline-block",
                    }}
                    count={Math.ceil(jobCount / 9)}
                    boundaryCount={1}
                    page={Number(pageNumber)}
                    variant="outlined"
                    shape="rounded"
                    onChange={page}
                  />
                </Box>
              </>
            ) : (
              <Box
                flexBox
                vertical
                alCenter
                style={{ width: "100%" }}
                jCenter
                p={100}>
                <Title>No result found</Title>
                <Paragraph mt={10}>
                  {" "}
                  Try adjusting your search or filter to find what you're
                  looking for
                </Paragraph>
              </Box>
            )}
          </ActivityIndicator>
        </Box>
      </Box>
    </CompWrapper>
  );
};

// import React, { useEffect } from "react";
// import { useAuth, useNavigation } from "react-auth-navigation";
// import { useDocTitle } from "../../../../hooks";
// import { DefaultButton, Box } from "../../../common";
// import { Bold, Chip, Image, Paragraph } from "../../../generics";
// import Table from "../../../common/table/Table.common";
// import { RippleButton } from "react-uicomp";
// import moment from "moment";

// // IMPORT HOCS
// import { CompWrapper, Card } from "../../../hoc";
// import { useDispatch, useSelector } from "react-redux";
// import { getJobList } from "../../../../actions";

// export const JobsPage = () => {
//   const dispatch = useDispatch();
//   const { location, navigation } = useNavigation();
//   const job = useSelector((state) => state.job);
//   const { jobList, jobCount, jobLoader } = job;
//   const { navigate, routes } = navigation;
//   useDocTitle("Job");

//   let query = useQuery();

//   function useQuery() {
//     return new URLSearchParams(location?.search);
//   }
//   const pageNumber = query.get("page") || 1;

//   useEffect(() => {
//     dispatch(getJobList(pageNumber));
//   }, [pageNumber]);
//   const deleteHandler = (id) => {
//     // dispatch(deleteServiceAction(id, toast, pageNumber));
//   };

//   const viewHandler = (item) => {
//     console.log(item);
//     // navigate("/building/view/1");
//     navigate(routes["Jobs"].path + "/view/" + item.job_details.id);
//   };

//   const editHandler = (item) => {
//     if (!item?.job_details?.job_status) {
//       navigate(routes["Jobs"].path + "/edit/" + item.job_details.id);
//     }
//   };

//   const getJobType = (item) => {
//     switch (item) {
//       case "MAINTAIN_INSPEC":
//         return "Maintenance (Repair)";
//       case "MAINTAIN_INSTALL":
//         return "Maintenance (Install)";

//       case "INSPECTION":
//         return "Inspection";

//       default:
//         return "Installation";
//     }
//   };

//   return (
//     <CompWrapper>
//       <Box>
//         <Card>
//           <Box flexBox alCenter jSpace>
//             <Bold>Job</Bold>
//             <Box>
//               <DefaultButton
//                 title="Add Job"
//                 onClick={() => {
//                   navigate(routes["Add Job"].path);
//                 }}
//               />
//             </Box>
//           </Box>
//           <Box mt={20}>
//             <Table
//               columns={[
//                 {
//                   field: "job_details",
//                   name: "Job Type",
//                   render: (rowData) =>
//                     rowData && (
//                       <Paragraph>{getJobType(rowData?.job_type)}</Paragraph>
//                     ),
//                 },
//                 {
//                   field: "site_details",
//                   name: "Prop Name",
//                   render: (rowData) =>
//                     rowData && <Paragraph>{rowData?.prop_name}</Paragraph>,
//                 },
//                 {
//                   field: "job_details",
//                   name: "Start Time",
//                   render: (rowData) =>
//                     moment(rowData?.start_time).format("Do MMM, YYYY"),
//                 },

//                 {
//                   field: "job_details",
//                   name: "Job Status",
//                   render: ({ job_status }) =>
//                     job_status !== null ? (
//                       job_status ? (
//                         <Chip style={{ backgroundColor: "#5b9540" }}>
//                           Completed
//                         </Chip>
//                       ) : (
//                         <Chip style={{ backgroundColor: "#11a7af" }}>
//                           On Going
//                         </Chip>
//                       )
//                     ) : (
//                       <Chip style={{ backgroundColor: "#ff4343" }}>
//                         Not Started
//                       </Chip>
//                     ),
//                 },
//                 {
//                   field: "assigned_to",
//                   name: "Assigned to",
//                   render: (rowData) =>
//                     rowData && (
//                       <Paragraph>
//                         {rowData?.first_name + " " + rowData?.last_name}
//                       </Paragraph>
//                     ),
//                 },
//               ]}
//               data={jobList}
//               dataLoader={jobLoader}
//               totalCount={jobCount}
//               actions
//               deleteLoader={false}
//               // onDeleteHandler={deleteHandler}
//               onEditHandler={editHandler}
//               onViewHandler={viewHandler}
//               viewBug
//             />
//           </Box>
//         </Card>
//       </Box>
//     </CompWrapper>
//   );
// };
