import { Breadcrumbs } from "@material-ui/core";
import React, { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { FLOOR_DETAIL } from "../../../../../actions";
import { Box, CustomBreadcrumb, Tab } from "../../../../common";
import { Paragraph } from "../../../../generics";

import { CompWrapper } from "../../../../hoc";
import { DoorList, DoorTrashList, FloorMap } from "./components";

const breadcrumb = {
  building: { name: "BuildingName", id: "22" },
  floor: { name: "Floor1", id: "2" },
};
// const { building, floor, door, part, job_part } = breadcrumb;
const sortedArray = ["building", "floor", "door", "part", "job_part"];

export const FloorPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { floorBeadcrumb } = useSelector((state) => state.floor);
  const {
    navigation: { navigate },
  } = useNavigation();

  return (
    <CompWrapper breadcrumbData={floorBeadcrumb}>
      <Tab
        panes={[
          {
            render: () => (
              <div className="door-list-container">
                <div className="door-list">
                  <DoorList />
                </div>

                <div className="floor-map">
                  <FloorMap />
                </div>
              </div>
            ),
            title: "Floor Plan",
          },
          {
            render: () => <DoorTrashList />,
            title: "Trash",
          },
        ]}
      />
    </CompWrapper>
  );
};
