import React, { useState } from "react";
import propTypes from "prop-types";

import { ActivityIndicator, CustomModal } from "../../hoc";
import {
  DefaultButton,
  GhostButton,
  PrimaryButton,
} from "../button/Button.common";
import { Box } from "../index";

export const ConfirmationModal = ({
  displayElement,
  label,
  onConfirmClick,
  onCancelClick,
  confirmLabel = "Delete",
  cancelLabel = "Cancel",
  loading,
  danger,
}) => {
  return (
    <CustomModal displayElement={displayElement}>
      {({ onCloseModalHandler }) => (
        <Box flexBox vertical>
          <Box flexBox>
            <h3>{label}</h3>
          </Box>
          <ActivityIndicator animating={loading}>
            <Box
              mt={20}
              flexBox
              jEnd
              alCenter
              style={{
                width: "100%",
                float: "right",
              }}>
              <PrimaryButton
                style={{
                  background: !!danger ? "#ff4343" : "",
                  color: "white",
                  marginRight: 10,
                }}
                type="button"
                onClick={() => {
                  // e.preventDefault();
                  onConfirmClick(onCloseModalHandler);
                }}
                title={confirmLabel}
              />
              <GhostButton
                onClick={() => {
                  // e.preventDefault();
                  onCloseModalHandler();
                }}
                type="button"
                title={cancelLabel}
              />
            </Box>
          </ActivityIndicator>
        </Box>
      )}
    </CustomModal>
  );
};

ConfirmationModal.propTypes = {
  displayElement: propTypes.element.isRequired,
  label: propTypes.string.isRequired,
  onConfirmClick: propTypes.func,
  onCancelClick: propTypes.func,
  confirmLabel: propTypes.string,
  cancelLabel: propTypes.string,
  loading: propTypes.bool.isRequired,
};
