import {
  ADD_DOOR_PART,
  GET_DOORPART_BY_ID,
  GET_PART_OF_DOOR,
  EDIT_PART,
} from "../actions";

const initalState = {
  addDoorPartLoader: false,
  partList: [],
  partListLoader: false,

  doorPartLoader: false,
  doorPart: null,
  doorPartBreadcrumb: null,

  editLoader: false,
};

export function partReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_DOOR_PART.LOADING:
      return { ...state, addDoorPartLoader: true };
    case ADD_DOOR_PART.SUCCESS:
      return {
        ...state,
        addDoorPartLoader: false,
      };
    case ADD_DOOR_PART.ERROR:
      return { ...state, addDoorPartLoader: false };

    case GET_PART_OF_DOOR.LOADING:
      return {
        ...state,
        partListLoader: true,
        partList: [],
      };
    case GET_PART_OF_DOOR.SUCCESS:
      return {
        ...state,
        partListLoader: false,
        partList: payload.door_part_list,
      };
    case GET_PART_OF_DOOR.ERROR:
      return { ...state, partListLoader: false };

    case GET_DOORPART_BY_ID.CLEAR:
      return {
        ...state,
        doorPart: null,
        doorPartBreadcrumb: null,
      };

    case GET_DOORPART_BY_ID.LOADING:
      return {
        ...state,

        doorPartLoader: true,
      };
    case GET_DOORPART_BY_ID.SUCCESS:
      return {
        ...state,
        doorPartLoader: false,
        doorPart: payload.details,
        doorPartBreadcrumb: payload.breadcrum,
      };
    case GET_DOORPART_BY_ID.ERROR:
      return { ...state, doorPartLoader: false };

    case EDIT_PART.LOADING:
      return { ...state, editLoader: true };
    case EDIT_PART.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_PART.ERROR:
      return { ...state, editLoader: false };

    default:
      return state;
  }
}
