import React, { useEffect } from "react";
import { Modal } from "react-uicomp";
import { MdAdd, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import {
  capitalizeFirstLetter,
  isValid,
  useInput,
  validator,
} from "../../../../../../utils";
import {
  Box,
  DefaultButton,
  FormInput,
  InputField,
  GooglePlaces,
} from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { Title, Image, Bold, Paragraph } from "../../../../../generics";
import { ActivityIndicator, Card } from "../../../../../hoc";
import {
  addBuildingViewerAction,
  getBuildingViewerAction,
  updateViewerAction,
} from "../../../../../../actions";

export const ViewerSection = () => {
  const { toast } = useAuth();
  const [show, setShow] = React.useState(false);

  const [showAddViewer, setShowAddViewer] = React.useState(false);
  const [locationName, setLocationName] = React.useState("");
  const [phone, setPhone] = React.useState([""]);

  const dispatch = useDispatch();
  const { location, params } = useNavigation();
  const {
    viewerLoading,
    viewerList,
    viewerCount,
    addViewerLoading,
  } = useSelector((state) => state.building);

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getBuildingViewerAction(params?.id, pageNumber));
  }, [params?.id, pageNumber]);

  const { data, onChangeHandler } = useInput({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
  });

  const deleteHandler = (id) => {
    // dispatch(deleteServiceAction(id, toast, pageNumber));
  };

  const editHandler = (item) => {
    // navigate(routes["View Client"].path);

    data.name = "asdas";
    data.email = "email";
    data.address = "address";
    data.phone = 4234234;

    setShow(true);
  };

  const toggleHandler = (data, id) => {
    dispatch(
      updateViewerAction(id, { deactivate: !data }, onSuccessHandler, toast),
    );
  };

  const onSuccessHandler = () => {
    dispatch(getBuildingViewerAction(params?.id, pageNumber));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, firstName, lastName, jobTitle } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("firstName", firstName.length === 0, () => {
      showMessage("First Name musn't be empty!");
    });
    validate("lastName", lastName.length === 0, () => {
      showMessage("Last Name musn't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("Email musn't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("Address musn't be empty!");
    });
    validate("jobTitle", jobTitle.length === 0, () => {
      showMessage("Job Title musn't be empty!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    const body = {
      email: email,
      firstName: capitalizeFirstLetter(firstName),
      lastName: capitalizeFirstLetter(lastName),
      profileAddress: { coordinates: [1, 1], value: locationName },
      profilePhone: phone,
      profileJobTitle: capitalizeFirstLetter(jobTitle),
    };

    dispatch(
      addBuildingViewerAction(
        params?.id,
        body,
        () => {
          setShowAddViewer(false);
          data.firstName = "";
          data.lastName = "";
          data.email = "";
          data.jobTitle = "";
          setPhone([""]);
          dispatch(getBuildingViewerAction(params?.id, pageNumber));
        },
        toast,
      ),
    );
  };

  const changeHandle = (index) => {
    return function (e) {
      const phoneArray = [...phone];
      phoneArray[index] = e.target.value;
      setPhone(phoneArray);
    };
  };

  const onAdd = () => {
    setPhone([...phone, ""]);
  };

  const close = (index) => {
    const phoneClone = [...phone];

    phoneClone.splice(index, 1);
    setPhone(phoneClone);
  };

  return (
    <Box>
      <Card>
        <Box flexBox alCenter jSpace>
          <Bold>Viewers</Bold>
          <Box>
            <DefaultButton
              title="Add Viewer"
              onClick={() => {
                setShowAddViewer(true);
              }}
            />
          </Box>
        </Box>
        <Box mt={20}>
          <Table
            columns={[
              {
                field: "profile_first_name",
                name: "first name",
              },
              {
                field: "profile_last_name",
                name: "last name",
              },
              {
                field: "email",
                name: "email",
              },
              {
                field: "profile_address",
                name: "address",
                render: (rowData) =>
                  rowData && <Paragraph>{rowData?.value}</Paragraph>,
              },
              {
                field: "profile_phone",
                name: "phone",
                render: (rowData) =>
                  rowData && <Paragraph>{rowData?.join(", ")}</Paragraph>,
              },
              {
                field: "profile_job_title",
                name: "Job Title",
              },
              {
                field: "deactivated",
                name: "active",
                render: (rowData, id) => (
                  <input
                    type="checkbox"
                    checked={!rowData}
                    onChange={() => toggleHandler(rowData, id)}
                  />
                ),
              },
            ]}
            data={viewerList}
            dataLoader={viewerLoading}
            totalCount={viewerCount}
            // actions
            // deleteLoader={false}
            // onDeleteHandler={deleteHandler}
            // onEditHandler={editHandler}
            // onViewHandler={viewHandler}
          />
        </Box>
      </Card>

      <Modal
        visible={showAddViewer}
        onOutsideClick={() => {
          setShowAddViewer(false);
        }}>
        <Box>
          <Box flexBox jCenter mb={10}>
            <Title>Add Viewer</Title>
          </Box>
          <form onSubmit={submitHandler}>
            <Box flexBox alCenter jSpace>
              <FormInput label="First Name">
                <InputField
                  placeholder="First Name"
                  name="firstName"
                  value={data.firstName}
                  onChange={onChangeHandler("firstName")}
                  type="text"
                />
              </FormInput>
              <Box ml={10}>
                <FormInput label="Last Name">
                  <InputField
                    placeholder="Last Name"
                    name="lastName"
                    value={data.lastName}
                    onChange={onChangeHandler("lastName")}
                    type="text"
                  />
                </FormInput>
              </Box>
            </Box>
            <Box flexBox alCenter jSpace>
              <FormInput label="Address">
                <GooglePlaces setLocationName={setLocationName} />
              </FormInput>
              <Box ml={10}>
                <FormInput label="Job Title">
                  <InputField
                    placeholder="Job Title"
                    name="jobTitle"
                    value={data.jobTitle}
                    onChange={onChangeHandler("jobTitle")}
                    type="text"
                  />
                </FormInput>
              </Box>
            </Box>
            <FormInput label="Email">
              <InputField
                placeholder="Email"
                name="email"
                value={data.email}
                onChange={onChangeHandler("email")}
                type="email"
              />
            </FormInput>

            <FormInput
              label="Phone"
              newElement={
                <div
                  type="button"
                  className="close"
                  style={{ cursor: "pointer" }}
                  onClick={onAdd}>
                  <MdAdd color="green" />
                </div>
              }>
              <Box>
                {phone.length > 0 &&
                  phone.map((item, index) => {
                    return (
                      <Box flexBox alCenter key={index} mb={10}>
                        <InputField
                          placeholder="Phone"
                          name="phone"
                          value={item}
                          onChange={changeHandle(index)}
                          type="number"
                          min="0"
                        />
                        {phone.length > 1 && (
                          <Box ml={20}>
                            <div
                              type="button"
                              className="close"
                              style={{ cursor: "pointer" }}
                              onClick={() => close(index)}>
                              <MdClose color="red" />
                            </div>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </FormInput>
            <ActivityIndicator animating={addViewerLoading}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};
