import React, { useEffect, useState } from "react";
import moment from "moment";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaPlus, MdClose } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDocTitle } from "../../../../../hooks/useDocTitle.hook";
import { isValid, useInput, validator } from "../../../../../utils";

import { Card, CompWrapper } from "../../../../hoc";
import {
  Box,
  DefaultButton,
  DisplayFileName,
  FormInput,
  Grid,
  ImageUpload,
  InputField,
  SelectField,
  TextArea,
} from "../../../../common";
import {
  addJobAction,
  getBuildingListAction,
  getAllFloorList,
  getWorkerAction,
  getAllBuildingListAction,
} from "../../../../../actions";

import UPLOAD from "../../../../../assets/icons/upload.png";
import { Image, Paragraph, Title } from "../../../../generics";
import { api, APIS } from "../../../../../config";
import { InspectionPart, InstallationPart } from "../component";

const JOB_TYPE = [
  { name: "INSTALLATION", value: "INSTALLATION" },
  { name: "INSPECTION", value: "INSPECTION" },
  // { name: "MAINTENANCE", value: "MAINTENANCE" },
];

export const AddJobPage = () => {
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { allListloading, allList } = useSelector((state) => state.building);

  const { userWorkerList } = useSelector((state) => state.user);

  const [doorArray, setDoorArray] = useState([]);

  const [inspectionPartArray, setInspectionPartArray] = useState([]);

  useDocTitle("Add Job");

  const { data, onChangeHandler } = useInput({
    siteId: "",
    assignedTo: "",
    jobDescription: "",
    startDate: "",
    jobType: "",
    scope: "",
    limitations: "",
    priorities: "",
  });
  const [floorNdoor, setFloorNdoor] = React.useState([
    {
      floor: "",
      door: "",
    },
  ]);

  const [inspectionfloorNdoor, setInspectionFloorNdoor] = React.useState([
    {
      floor: "",
      doorNpart: [
        {
          door: "",
          part: "",
        },
      ],
    },
  ]);

  const [image, setImage] = React.useState([]);

  useEffect(() => {
    dispatch(getAllBuildingListAction());
  }, []);

  useEffect(() => {
    dispatch(getWorkerAction());
  }, []);

  useEffect(() => {
    if (!!data.siteId) {
      dispatch(getAllFloorList(data.siteId));
      setFloorNdoor([]);
      setInspectionFloorNdoor([]);
      setTimeout(() => {
        setFloorNdoor([
          {
            floor: "",
            door: "",
          },
        ]);

        setInspectionFloorNdoor([
          {
            floor: "",
            doorNpart: [
              {
                door: "",
                part: "",
              },
            ],
          },
        ]);
      }, 1);
    }
  }, [data.siteId]);

  useEffect(() => {
    if (floorNdoor.length > 0) {
      const newArray = [];
      floorNdoor.map(({ door }) => newArray.push(...door));
      setDoorArray(makeUniqueArray(newArray));
    }
  }, [floorNdoor]);

  useEffect(() => {
    if (inspectionfloorNdoor.length > 0) {
      const newArray = [];
      const doorArray = [];
      inspectionfloorNdoor.forEach(({ doorNpart }) => {
        doorNpart.map(({ door, part }) => {
          !!door && doorArray.push(door);
          newArray.push(...part);
        });
      });

      setDoorArray(makeUniqueArray(doorArray));
      setInspectionPartArray(makeUniqueArray(newArray));
    }
  }, [inspectionfloorNdoor]);

  const changeImage = (data) => {
    setImage(data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const {
      siteId,
      assignedTo,
      jobDescription,
      startDate,
      jobType,
      scope,
      limitations,
      priorities,
    } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("siteId", siteId.length === 0, () => {
      showMessage("Building musn't be empty!");
    });

    validate("assignedTo", assignedTo.length === 0, () => {
      showMessage("assignedTo musn't be empty!");
    });

    validate("jobDescription", jobDescription.length === 0, () => {
      showMessage("jobDescription musn't be empty!");
    });
    validate("jobType", jobType.length === 0, () => {
      showMessage("jobType musn't be empty!");
    });
    validate("startDate", startDate.length === 0, () => {
      showMessage("startDate musn't be empty!");
    });

    // validate("scope", scope.length === 0, () => {
    //   showMessage("Scope musn't be empty!");
    // });
    // validate("limitations", limitations.length === 0, () => {
    //   showMessage("Limitations musn't be empty!");
    // });
    // validate("priorities", priorities.length === 0, () => {
    //   showMessage("Priorities musn't be empty!");
    // });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    let formData = new FormData();
    await formData.append("assignedTo", `[${assignedTo.join(",")}]`);
    await formData.append("jobType", jobType);
    await formData.append("siteId", siteId);
    await formData.append("jobDescription", jobDescription);
    !!scope && (await formData.append("scope", scope));
    !!limitations && (await formData.append("limitation", limitations));
    !!priorities && (await formData.append("recommendation", priorities));
    await formData.append("startTime", moment.utc(startDate).format());
    image.length > 0 &&
      image.forEach(async (element) => {
        await formData.append("pdfImgfile", element.file);
      });

    doorArray.length > 0 &&
      (await formData.append("doorIdList", `[${doorArray.join(",")}]`));

    jobType === "INSPECTION" &&
      inspectionPartArray.length > 0 &&
      (await formData.append(
        "partIdList",
        `[${inspectionPartArray.join(",")}]`,
      ));

    // jobType === "INSTALLATION" &&
    //   doorArray.length > 0 &&
    //   (await formData.append("idList", `[${doorArray.join(",")}]`));

    // FOR INTEGRATION
    dispatch(
      addJobAction(
        formData,
        () => {
          navigate(routes["Jobs"].path);
        },
        toast,
      ),
    );
  };

  const onAdd = () => {
    const newArray = [
      ...floorNdoor,
      {
        floor: "",
        door: "",
      },
    ];

    setFloorNdoor(newArray);
  };

  const onClose = (i) => {
    const newArray = [...floorNdoor].filter((item, index) => index !== i);

    setFloorNdoor(newArray);
  };

  const onInspectionAdd = () => {
    const newArray = [
      ...inspectionfloorNdoor,
      {
        floor: "",
        doorNpart: [
          {
            door: "",
            part: "",
          },
        ],
      },
    ];

    setInspectionFloorNdoor(newArray);
  };

  const onInspectionClose = (i) => {
    const newArray = [...inspectionfloorNdoor].filter(
      (item, index) => index !== i,
    );

    setInspectionFloorNdoor(newArray);
  };

  const makeUniqueArray = (array) => {
    return [...new Set(array)];
  };

  return (
    <CompWrapper>
      <Card>
        <Box>
          <form onSubmit={submitHandler}>
            <FormInput label=" Select Building" required>
              <SelectField
                options={allList}
                getOptionLabel="prop_name"
                getOptionValue="id"
                onChangeValue={(item) => {
                  onChangeHandler("siteId", item?.id)();
                }}
                isSearchable
                isLoading={allListloading}
                placeholder="Select building"
              />
            </FormInput>

            <FormInput label="Job Type" required>
              <SelectField
                options={JOB_TYPE}
                getOptionLabel="name"
                getOptionValue="value"
                onChangeValue={(item) => {
                  onChangeHandler("jobType", item?.value)();
                }}
                isSearchable
                placeholder="Select Type"
                defaultValue={JOB_TYPE.filter(
                  (element) => element.value === data.jobType,
                )}
              />
            </FormInput>

            {data.jobType === "INSTALLATION" && (
              <>
                <Box flexBox alCenter jSpace>
                  <Title>Floor</Title>
                  <FaPlus onClick={onAdd} />
                </Box>
                {floorNdoor.map((_, index) => {
                  return (
                    <Box key={index} flexBox alCenter>
                      <Box flex={1}>
                        <InstallationPart
                          floorNdoor={floorNdoor}
                          index={index}
                          setFloorNdoor={setFloorNdoor}
                          onClose={onClose}
                          doorArray={doorArray}
                        />
                      </Box>
                      {floorNdoor?.length > 1 && (
                        <MdClose onClick={() => onClose(index)} />
                      )}
                    </Box>
                  );
                })}
              </>
            )}

            {data.jobType === "INSPECTION" && (
              <>
                <Box flexBox alCenter jSpace>
                  <Title>Floor</Title>
                  <FaPlus onClick={onInspectionAdd} />
                </Box>
                {inspectionfloorNdoor.map((_, index) => {
                  return (
                    <Box key={index} flexBox alCenter>
                      <Box flex={1}>
                        <InspectionPart
                          inspectionfloorNdoor={inspectionfloorNdoor}
                          index={index}
                          setInspectionFloorNdoor={setInspectionFloorNdoor}
                          onCloseFloor={() => onInspectionClose(index)}
                          inspectionPartArray={inspectionPartArray}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}

            <FormInput label=" Select User" required>
              <SelectField
                isMulti
                options={userWorkerList}
                getOptionLabel="full_name"
                getOptionValue="id"
                onChangeValue={(item) => {
                  const newValue = item?.map((val) => {
                    return val.id;
                  });

                  onChangeHandler("assignedTo", [...newValue])();
                }}
                isSearchable
                placeholder="Select User"
              />
            </FormInput>

            <FormInput label="Start Date Time" required>
              <InputField
                placeholder="Start Date Time"
                name="startDate"
                value={data.startDate}
                onChange={onChangeHandler("startDate")}
                type="datetime-local"
              />
            </FormInput>

            <FormInput label="Job Description" required>
              <TextArea
                placeholder="Job Description"
                name="jobDescription"
                value={data.jobDescription}
                onChange={onChangeHandler("jobDescription")}
              />
            </FormInput>

            <FormInput label="Scope and Purpose of the Fire Door Inspection Report">
              <TextArea
                placeholder="Scope and Purpose of the Fire Door Inspection Report"
                name="scope"
                value={data.scope}
                onChange={onChangeHandler("scope")}
              />
            </FormInput>
            <FormInput label="Limitations of the Fire Door Assessment">
              <TextArea
                placeholder="Limitations of the Fire Door Assessment"
                name="limitations"
                value={data.limitations}
                onChange={onChangeHandler("limitations")}
              />
            </FormInput>
            <FormInput label="Prioritisation of Recommendations and Remedial Works">
              <TextArea
                placeholder="Prioritisation of Recommendations and Remedial Works"
                name="priorities"
                value={data.priorities}
                onChange={onChangeHandler("priorities")}
              />
            </FormInput>

            <FormInput label="Attachment">
              <ImageUpload
                onChange={changeImage}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                multiple
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box mb={10}>
                    <Grid.Container lg={4} md={2} sm={1}>
                      {imageData &&
                        imageData.map((item, index) => (
                          <DisplayFileName
                            key={index}
                            label={item?.file?.name}
                            onClick={() => onRemove(index)}
                          />
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>

            <DefaultButton title="Submit" type="submit" />
          </form>
        </Box>
      </Card>
    </CompWrapper>
  );
};
