import React from "react";
import { Auth } from "react-auth-navigation";
import { Box, TabAuth } from "../../../components/common";
import { Bold } from "../../../components/generics";
// IMPORT HOCS
import { CompWrapper, Card } from "../../../components/hoc";

export const ClientTabPage = () => {
  return (
    <CompWrapper>
      <Card>
        {/* <Box flexBox alCenter jSpace>
            <Bold>Clients</Bold>
          </Box> */}

        <TabAuth group="Client Root" />
        <Box mt={20}>
          <Auth.Screens path="/client/summary" />
        </Box>
      </Card>
    </CompWrapper>
  );
};
