import { api, APIS, TABLE_LIMIT } from "../config";
import {
  ADD_IRONMONGERY_CATEGORY,
  ADD_IRONMONGERY_PRODUCT,
  DELETE_IRONMONGERY_CATEGORY,
  DELETE_IRONMONGERY_PRODUCT,
  EDIT_IRONMONGERY_PRODUCT,
  GET_ALL_IRONMONGERY_CATEGORY,
  GET_ALL_IRONMONGERY_PRODUCT,
  GET_IRONMONGERY_CATEGORY_DETAIL,
  GET_IRONMONGERY_PRODUCT_DETAIL,
} from "./ActionTypes.action";

export function addIronmongeryCategory(body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_IRONMONGERY_CATEGORY.LOADING });
      res = await api(`${APIS.ironmongeryCategoy}`, "POST", body);

      const { success, data, message } = res.data;
      if (success) {
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch({
          type: ADD_IRONMONGERY_CATEGORY.SUCCESS,
          payload: data.data,
        });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: ADD_IRONMONGERY_CATEGORY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_IRONMONGERY_CATEGORY.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getAllIronmongeryCategory() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ALL_IRONMONGERY_CATEGORY.LOADING });
      res = await api(`${APIS.ironmongeryCategoy}/all`);

      const { success, data } = res.data;

      if (success) {
        dispatch({
          type: GET_ALL_IRONMONGERY_CATEGORY.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: GET_ALL_IRONMONGERY_CATEGORY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ALL_IRONMONGERY_CATEGORY.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function deleteIronmongeryCategory(id, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_IRONMONGERY_CATEGORY.LOADING });
      res = await api(`${APIS.ironmongeryCategoy}/${id}`, "DELETE");

      const { success, data, message } = res.data;
      console.log("delete", res);
      if (success) {
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch({
          type: DELETE_IRONMONGERY_CATEGORY.SUCCESS,
          payload: id,
        });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: DELETE_IRONMONGERY_CATEGORY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_IRONMONGERY_CATEGORY.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getIronmongeryCategoryDetail(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_IRONMONGERY_CATEGORY_DETAIL.LOADING });
      res = await api(`${APIS.ironmongeryCategoy}/${id}`);

      const { success, data } = res.data;

      console.log(">>>>", res);
      if (success) {
        dispatch({
          type: GET_IRONMONGERY_CATEGORY_DETAIL.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: GET_IRONMONGERY_CATEGORY_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_IRONMONGERY_CATEGORY_DETAIL.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function addIronmongeryProduct(formData, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_IRONMONGERY_PRODUCT.LOADING });
      res = await api(`${APIS.ironmongeryProduct}`, "POST", formData, {
        file: true,
      });

      const { success, data, message } = res.data;

      if (success) {
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch({
          type: ADD_IRONMONGERY_PRODUCT.SUCCESS,
          payload: data.data,
        });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: ADD_IRONMONGERY_PRODUCT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_IRONMONGERY_PRODUCT.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getAllProduct(page, category) {
  return async function (dispatch) {
    let res;
    let query = "";
    if (!!category) {
      query = `&catid=${category}`;
    }
    try {
      dispatch({ type: GET_ALL_IRONMONGERY_PRODUCT.LOADING });
      res = await api(
        `${APIS.ironmongeryProduct}/all?page=${page}&limit=${TABLE_LIMIT}${query}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({
          type: GET_ALL_IRONMONGERY_PRODUCT.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: GET_ALL_IRONMONGERY_PRODUCT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ALL_IRONMONGERY_PRODUCT.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getIronmongeryProductDetail(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_IRONMONGERY_PRODUCT_DETAIL.LOADING });
      res = await api(`${APIS.ironmongeryProduct}/${id}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({
          type: GET_IRONMONGERY_PRODUCT_DETAIL.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: GET_IRONMONGERY_PRODUCT_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_IRONMONGERY_PRODUCT_DETAIL.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function deleteIronmongeryProduct(id, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_IRONMONGERY_PRODUCT.LOADING });
      res = await api(`${APIS.ironmongeryProduct}/${id}`, "DELETE");

      const { success, data, message } = res.data;
      console.log("delete", res);
      if (success) {
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch({
          type: DELETE_IRONMONGERY_PRODUCT.SUCCESS,
          payload: id,
        });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: DELETE_IRONMONGERY_PRODUCT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_IRONMONGERY_PRODUCT.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function editIronmongeryProduct(id, formData, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_IRONMONGERY_PRODUCT.LOADING });
      res = await api(`${APIS.ironmongeryProduct}/${id}`, "PATCH", formData, {
        file: true,
      });

      const { success, data, message } = res.data;

      if (success) {
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch({
          type: EDIT_IRONMONGERY_PRODUCT.SUCCESS,
          payload: data.data,
        });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: EDIT_IRONMONGERY_PRODUCT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_IRONMONGERY_PRODUCT.ERROR });

      console.error(message);
      return 0;
    }
  };
}
