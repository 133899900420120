import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { useDocTitle } from "../../../../hooks";
import { DefaultButton, Box } from "../../../common";
import { Bold, Image, Paragraph } from "../../../generics";
import Table from "../../../common/table/Table.common";
import { RippleButton } from "react-uicomp";

// IMPORT HOCS
import { CompWrapper, Card } from "../../../hoc";

import {
  getBuildingListAction,
  deleteBuildingAction,
  getAllProduct,
  deleteIronmongeryProduct,
} from "../../../../actions";
import { FILE_URL } from "../../../../config";

export const IronmongeryCategoryDetail = () => {
  const { location, navigation, params } = useNavigation();
  const { toast } = useAuth();

  const { navigate, routes } = navigation;
  useDocTitle("Ironmongery");

  const dispatch = useDispatch();
  const {
    productLoader,
    deleteCategoryLoader,
    productlist,
    productTotalcount,
  } = useSelector((state) => state.ironmongery);

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getAllProduct(pageNumber, params.id));
  }, [pageNumber]);

  const deleteHandler = (item) => {
    console.log(item);
    dispatch(
      deleteIronmongeryProduct(item.product_details.id, toast, () => {}),
    );
  };

  const viewHandler = (item) => {
    navigate(`/ironmongery/product/${item.product_details.id}`);
  };

  const editHandler = (item) => {
    navigate(`/ironmongery/edit/${item.product_details.id}`);
  };
  console.log(productlist);
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Ironmongery List</Bold>
            <Box>
              <DefaultButton
                title="Add Ironmongery"
                onClick={() => {
                  navigate(routes["Add Building"].path);
                }}
              />
            </Box>
          </Box>
          <Box mt={20}>
            <Table
              columns={[
                {
                  name: "Photo",
                  field: "product_details",
                  render: (rowData) =>
                    rowData && (
                      <Image
                        alt="Avatar"
                        link={`${FILE_URL}/ig/${rowData.image}`}
                        style={{ height: 120, width: 120, borderRadius: 8 }}
                      />
                    ),
                },
                {
                  field: "product_details",
                  name: "Name",
                  render: (rowData) => rowData.name,
                },
                {
                  field: "product_details",
                  name: "Brand",
                  render: (rowData) => rowData.brand,
                },
              ]}
              data={productlist}
              dataLoader={productLoader}
              totalCount={productTotalcount}
              actions
              viewBug
              deleteLoader={deleteCategoryLoader}
              onDeleteHandler={deleteHandler}
              onEditHandler={editHandler}
              onViewHandler={viewHandler}
            />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};

export default IronmongeryCategoryDetail;
