import React from "react";
import { useSelector } from "react-redux";
import { CompWrapper } from "../../../../hoc";
import { PartDetail } from "./components";
import { PastInspectionSection } from "./components/pastInspectionTable";

export const PartsPage = () => {
  const { doorPartBreadcrumb } = useSelector((state) => state.part);
  return (
    <CompWrapper breadcrumbData={doorPartBreadcrumb}>
      <PartDetail />
      <PastInspectionSection />
    </CompWrapper>
  );
};
