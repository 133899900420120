import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigation } from "react-auth-navigation";

import { getTemplate } from "../../../../actions";
import { CompWrapper } from "../../../hoc";
import { Template } from "../components";

const mapStateToProps = (state) => {
  return {
    template: state.template,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTemplate,
    },
    dispatch,
  );
};

export const ViewTemplatePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const {
    getTemplate,
    template: { templateLoader, template },
  } = props;
  const { params } = useNavigation();

  const { id } = params;

  useEffect(() => {
    getTemplate(id);
  }, [id, getTemplate]);

  const reFetch = () => {
    getTemplate(id);
  };

  return (
    <CompWrapper>
      {templateLoader ? (
        <div>Loading template...</div>
      ) : (
        template && (
          <Template id={id} data={template} isViewing reFetch={reFetch} />
        )
      )}
    </CompWrapper>
  );
});
