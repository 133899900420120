import React, { Children } from "react";
import {
  Box,
  ConfirmationModal,
  DefaultButton,
  KeyValue,
} from "../../../common";
import { Image } from "../../../generics";

// IMPORT HOCS
import { Card } from "../../../hoc";
import { BsFillEyeFill, BsThreeDotsVertical } from "react-icons/bs";

export const ClientCard = ({
  data,
  keyValue,
  link,
  onView,
  onEdit,
  onAccept,
  children,
}) => {
  return (
    <Card>
      <Box flexBox row jSpace alStart mb={10}>
        <Image
          alt="Avatar"
          link={link}
          style={{ height: 60, width: 60, borderRadius: 50 }}
        />
        <Box flexBox row jEnd alCenter>
          <BsFillEyeFill
            onClick={onView}
            style={{
              cursor: "pointer",
              // marginRight: 10,
            }}
          />
          {/* <BsThreeDotsVertical
            style={{
              cursor: "pointer",
            }}
          /> */}
        </Box>
      </Box>

      <KeyValue data={keyValue} />
      {children}
    </Card>
  );
};
