import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { Box } from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { getClientPeopleAction } from "../../../../../../actions";
import { Paragraph } from "../../../../../generics";

export const UsersTab = () => {
  const dispatch = useDispatch();
  const { location, params } = useNavigation();
  const { peopleLoading, peopleList, peopleCount } = useSelector(
    (state) => state.client,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getClientPeopleAction(params?.id, 3, pageNumber)); // 3 => Role WORKER
  }, [params?.id, pageNumber]);

  const deleteHandler = (id) => {
    // dispatch(deleteServiceAction(id, toast, pageNumber));
  };

  const viewHandler = (id) => {
    // navigate(routes["View Client"].path);
  };

  return (
    <Box>
      <Table
        columns={[
          {
            field: "profile_first_name",
            name: "first name",
          },
          {
            field: "profile_last_name",
            name: "last name",
          },
          {
            field: "email",
            name: "email",
          },
          {
            field: "profile_address",
            name: "address",
            render: (rowData) =>
              rowData && <Paragraph>{rowData?.value}</Paragraph>,
          },
          {
            field: "profile_phone",
            name: "phone",
            render: (rowData) =>
              rowData && <Paragraph>{rowData?.join(", ")}</Paragraph>,
          },
        ]}
        data={peopleList}
        dataLoader={peopleLoading}
        totalCount={peopleCount}
        // actions
        // deleteLoader={peopleLoading}
        // onDeleteHandler={deleteHandler}
        // onEditHandler={editHandler}
        // onViewHandler={viewHandler}
      />
    </Box>
  );
};
