export function getModifiedData(previousData, newData) {
  const prevDataKeys = Object.keys(previousData);

  const result = [];
  prevDataKeys.forEach((key) => {
    result.push({
      key,
      from: previousData[key],
      to: newData[key],
      edited: !(
        (JSON.stringify(previousData[key]) === JSON.stringify(newData[key])) // compare with strings
      ),
    });
  });

  return result;
}

export function getOnlyEditedObject(previousData, newData) {
  const body = getModifiedData(previousData, newData);
  return body
    .filter(({ edited }) => edited)
    .map(({ edited, ...rest }) => ({ ...rest }));
}

// for formData
export function patchOnlyEditedData(formData, previousData, newData) {
  const body = getOnlyEditedObject(previousData, newData);

  body.forEach(({ key, to }) => {
    formData.append(key, to);
  });

  return body;
}

// for raw data
export function patchOnlyEditedDataRaw(bodyObj, previousData, newData) {
  const body = getOnlyEditedObject(previousData, newData);

  body.forEach(({ key, to }) => {
    bodyObj[key] = to;
  });

  return body;
}
