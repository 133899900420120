import { GET_ALL_SCHEDULE, GET_SCHEDULE } from "../actions";

const initalState = {
  getLoader: false,
  schedules: [],
  schedulesCount: 0,
  scheduleLoader: false,
  schedule: null,
};

export function scheduleReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SCHEDULE.LOADING:
      return { ...state, getLoader: true };
    case GET_ALL_SCHEDULE.SUCCESS:
      return {
        ...state,
        getLoader: false,
        schedules: payload.rows,
        schedulesCount: payload.total,
      };
    case GET_ALL_SCHEDULE.ERROR:
      return { ...state, getLoader: false };

    case GET_SCHEDULE.LOADING:
      return { ...state, scheduleLoader: true };
    case GET_SCHEDULE.SUCCESS:
      return { ...state, scheduleLoader: false, schedule: payload };
    case GET_SCHEDULE.ERROR:
      return { ...state, scheduleLoader: false };

    default:
      return state;
  }
}
