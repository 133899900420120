import React, { useEffect, useState } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import {
  MdInsertDriveFile,
  MdCloudUpload,
  ImCloudDownload,
  AiFillEye,
  MdSave,
  MdLibraryAdd,
  MdClose,
  FaPlus,
  RiCloseCircleFill,
} from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-uicomp";
import moment from "moment";

import { useDocTitle } from "../../../../../hooks";
import {
  getAllFloorByJob,
  getJobDelailByIdAction,
  getWorkerAction,
  getAppAttachments,
  getAllFloorList,
  editJobAction,
  uploadAttachments,
  deleteJobPartByIdAction,
} from "../../../../../actions";
import { FILE_URL, BASE_URL, APIS } from "../../../../../config";

import {
  Box,
  SelectField,
  Tab,
  FormInput,
  InputField,
  Grid,
  DefaultButton,
  PrimaryButton,
  GhostButton,
  TextArea,
  DisplayFileName,
  ImageUpload,
  ConfirmationModal,
} from "../../../../common";
import { CompWrapper, Card } from "../../../../hoc";
import { Title, Chip, Paragraph, Bold } from "../../../../generics";

import {
  FloorMap as Map,
  InspectionPart,
  InstallationPart,
  JobLogs,
} from "../component";
import {
  makeUniqueArray,
  useInput,
  validator,
  isValid,
  patchOnlyEditedData,
} from "../../../../../utils";

// Job type
const JOB_TYPE = [
  { name: "INSTALLATION", value: "INSTALLATION" },
  { name: "INSPECTION", value: "INSPECTION" },
  { name: "MAINTENANCE", value: "MAINTENANCE" },
  { name: "MAINTAIN INSTALL", value: "MAINTAIN_INSTALL" },
];

// Job status
const JOB_STATUS = {
  PENDING: null,
  STARTED: false,
  COMPLETED: true,
};

// Dashboad Tab Attachments
const DashboardAttachments = ({ jobDetail }) => {
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const [removedAttachment, setRemovedAttachment] = useState([]);
  const [image, setImage] = React.useState([]);

  const onRemoveImage = (i) => {
    const removedArray = [...removedAttachment];
    removedArray.push(i);
    setRemovedAttachment(removedArray);
  };

  const changeImage = (data) => {
    setImage(data);
  };

  const handleUploadAttachments = () => {
    let formData = new FormData();
    image.length > 0 &&
      image.forEach(async (element) => {
        await formData.append("pdfImgfile", element.file);
      });

    dispatch(
      uploadAttachments(jobDetail.job_details.id, formData, () => false, toast),
    );
  };

  return (
    <Box mt={20}>
      <ImageUpload
        onChange={changeImage}
        title="upload image"
        multiple
        style={{
          width: "100%",
        }}
        buttonclick={
          <div className="attachments-upload">
            <div className="upload-container">
              <MdCloudUpload size={80} />
              <div>Upload files here</div>
            </div>
          </div>
        }>
        {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
          <Box mb={10}>
            <Grid.Container lg={2} md={2} sm={1}>
              {jobDetail.job_details?.attachments?.length > 0
                ? jobDetail.job_details.attachments.map(
                    (item, index) =>
                      !removedAttachment.includes(index) && (
                        <div
                          style={{
                            border: "1px solid #e1e1e1",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: 4,
                            padding: "5px 10px",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                          onClick={() =>
                            window.open(`${FILE_URL}/job/${item}`)
                          }>
                          <div>{item}</div>
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 4,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onRemoveImage(index);
                            }}>
                            <RiCloseCircleFill color="red" size={25} />
                          </div>
                        </div>
                      ),
                  )
                : null}

              {imageData &&
                imageData.map((item, index) => (
                  <DisplayFileName
                    key={index}
                    label={item?.file?.name}
                    onClick={() => onRemove(index)}
                  />
                ))}

              {!(
                imageData.length > 0 ||
                jobDetail.job_details?.attachments?.length > 0
              ) && (
                <Box>
                  <Paragraph>Not available</Paragraph>
                </Box>
              )}
            </Grid.Container>
          </Box>
        )}
      </ImageUpload>

      {image.length > 0 && (
        <Box mt={20}>
          <DefaultButton
            title="Upload"
            onClick={handleUploadAttachments}
            type="button"
          />
        </Box>
      )}
    </Box>
  );
};

// App Tab Attachments
const AppAttachments = ({ appAttachments }) => {
  return (
    <div className="attachments-files">
      {appAttachments.length ? (
        appAttachments.map((file) => (
          <a
            style={{
              textDecoration: "none",
            }}
            key={file}
            target="_blank"
            rel="noopener noreferrer"
            href={`${FILE_URL}/response/${file}`}>
            <div className="file">
              <div className="file-icon">
                <MdInsertDriveFile />
              </div>
              <div className="file-name">{file}</div>
            </div>
          </a>
        ))
      ) : (
        <Box>
          <Paragraph>Not available</Paragraph>
        </Box>
      )}
    </div>
  );
};

// Job Detail tab
const JobDetail = () => {
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const { jobDetail, appAttachments, deleteJobLoader } = useSelector(
    (state) => state.job,
  );
  const { userWorkerList } = useSelector((state) => state.user);

  const defaultWorkerList = jobDetail.worker_list.map(({ id }) => id);
  const [workerArray, setWorkArray] = useState(() =>
    jobDetail.worker_list.map(({ id }) => id),
  );

  const prevData = {
    jobDescription: jobDetail.job_details.job_description,
    scope: jobDetail.job_meta_details.scope,
    limitations: jobDetail.job_meta_details.limitation,
    priorities: jobDetail.job_meta_details.recommendation,
  };

  const { data, onChangeHandler } = useInput({
    ...prevData,
  });

  // Add parts modal
  const [addPartModalVisible, setAddPartModalVisible] = useState(false);

  const [addMore, setAddMore] = useState(false);
  const [floorNdoor, setFloorNdoor] = useState([
    {
      floor: "",
      door: "",
    },
  ]);

  const [inspectionfloorNdoor, setInspectionFloorNdoor] = useState([
    {
      floor: "",
      doorNpart: [
        {
          door: "",
          part: "",
        },
      ],
    },
  ]);

  const defaultpartlist = jobDetail?.job_parts_list.map(
    ({ door_part_details }) => door_part_details.id,
  );

  const [doorArray, setDoorArray] = useState([]);

  const [inspectionPartArray, setInspectionPartArray] = useState([]);

  // Get worker list
  useEffect(() => {
    dispatch(getWorkerAction());
  }, [dispatch]);

  useDocTitle("Job Detail");

  //getAppAttachments
  useEffect(() => {
    if (jobDetail) {
      const jobId = jobDetail.job_details.id;

      // api call
      dispatch(getAppAttachments(jobId));
    }
  }, [jobDetail, dispatch]);

  //getAllFloorList
  useEffect(() => {
    !!jobDetail?.site_details?.id &&
      dispatch(getAllFloorList(jobDetail?.site_details?.id));
  }, [jobDetail?.site_details?.id]);

  // make unique floorNdoor array
  useEffect(() => {
    if (floorNdoor?.length > 0) {
      const newArray = [];
      floorNdoor.forEach(({ door }) => newArray.push(...door));

      // floorNdoor?.map(({ door }) => newArray.push(...door));

      setDoorArray(makeUniqueArray(newArray));
    }
  }, [floorNdoor]);

  //make unique inspectionfloorNdoor array
  useEffect(() => {
    if (inspectionfloorNdoor.length > 0) {
      const newArray = [];
      inspectionfloorNdoor.forEach(({ doorNpart }) => {
        doorNpart.map(({ part }) => newArray.push(...part));
      });
      setInspectionPartArray(makeUniqueArray(newArray));
    }
  }, [inspectionfloorNdoor]);

  //add new floor INSTALLATION
  const onAdd = () => {
    const newArray = [
      ...floorNdoor,
      {
        floor: "",
        door: "",
      },
    ];

    setFloorNdoor(newArray);
  };

  //DELETE floor INSTALLATION
  const onClose = (i) => {
    const newArray = [...floorNdoor].filter((item, index) => index !== i);

    setFloorNdoor(newArray);
  };

  //add new floor INSPECTION
  const onInspectionAdd = () => {
    const newArray = [
      ...inspectionfloorNdoor,
      {
        floor: "",
        doorNpart: [
          {
            door: "",
            part: "",
          },
        ],
      },
    ];

    setInspectionFloorNdoor(newArray);
  };

  //DELETE floor INSPECTION
  const onInspectionClose = (i) => {
    const newArray = [...inspectionfloorNdoor].filter(
      (item, index) => index !== i,
    );

    setInspectionFloorNdoor(newArray);
  };

  const addMoreParts = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAddMore((prev) => !prev);
  };

  // submit job details handler
  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { jobDescription } = data;

    validate("jobDescription", jobDescription.length === 0, () => {
      toast({ message: "Job Description musn't be empty!", type: "error" });
    });

    if (!isValid(catchedErrors)) {
      return;
    }

    const newworker = workerArray.filter(
      (val) => !defaultWorkerList.includes(val),
    );
    const removedworker = defaultWorkerList.filter(
      (val) => !workerArray.includes(val),
    );

    const workerBody = {};
    if (newworker.length > 0) workerBody["newWorker"] = newworker;
    if (removedworker.length > 0) workerBody["removedWorker"] = removedworker;

    let formData = new FormData();
    patchOnlyEditedData(formData, prevData, data); // appends only changed key value to formData by comparing previous and new data

    dispatch(
      editJobAction(
        jobDetail.job_details.id,
        formData,
        workerBody,
        () => false,
        toast,
      ),
    );
  };

  const getWorker = (item) => {
    const temp = jobDetail.worker_list.find(({ id }) => id === item);
    temp.profile_first_name = temp.first_name;
    temp.profile_last_name = temp.last_name;
    return temp;
  };

  const onDeleteHandler = (id, callback) => {
    dispatch(
      deleteJobPartByIdAction(id, toast, () => {
        callback();
      }),
    );
  };

  return (
    <>
      <Card containerStyle={{ padding: 0, marginTop: 20 }}>
        <Box flexBox alCenter className="jobdetail-header">
          <Box flex={1} className="jobdetail-header-left">
            <Title>Job Detail</Title>
          </Box>

          <Box className="jobdetail-header-right">
            {jobDetail && jobDetail.job_details.report ? (
              <Box flexBox alCenter>
                <DefaultButton
                  lefticon={<AiFillEye style={{ marginRight: 10 }} />}
                  title="View Report"
                  onClick={() =>
                    window.open(
                      `${FILE_URL}/job/${jobDetail.job_details.report}`,
                    )
                  }
                />
                <Box ml={20}>
                  <PrimaryButton
                    lefticon={<ImCloudDownload style={{ marginRight: 10 }} />}
                    title="Download Report"
                    onClick={() =>
                      window.open(
                        `${BASE_URL}${APIS.downloadReport}${jobDetail.job_details.report}`,
                      )
                    }
                  />
                </Box>

                <Box ml={20}>
                  <GhostButton
                    lefticon={<ImCloudDownload style={{ marginRight: 10 }} />}
                    title="Download Excel"
                    onClick={() =>
                      window.open(
                        `${BASE_URL}${APIS.downloadReport}${jobDetail.job_details.report_csv}`,
                      )
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                <Paragraph>
                  Report Not available! Job is not completed yet.
                </Paragraph>
              </Box>
            )}
          </Box>
        </Box>

        <Box flexBox>
          <Box flex={1}>
            <div className="jobdetail-attachments">
              <Tab
                panes={[
                  {
                    title: "Dashboard Attachments",
                    render: () => <DashboardAttachments {...{ jobDetail }} />,
                  },
                  {
                    title: "App Attachments",
                    render: () => <AppAttachments {...{ appAttachments }} />,
                  },
                ]}
              />
            </div>
          </Box>

          <Box flex={2}>
            <form onSubmit={submitHandler}>
              {jobDetail && (
                <div className="jobdetail-editables">
                  <div className="jobdetail">
                    <div className="jobdetail-title">
                      <div className="jobdetail-title-text">Job Details</div>
                      <div className="jobdetail-title-button">
                        <GhostButton
                          lefticon={
                            jobDetail.job_details.job_status ===
                            JOB_STATUS.COMPLETED ? (
                              <AiFillEye
                                style={{
                                  position: "relative",
                                  top: 1,
                                  marginRight: 10,
                                }}
                              />
                            ) : (
                              <MdLibraryAdd
                                style={{
                                  position: "relative",
                                  top: 1,
                                  marginRight: 10,
                                }}
                              />
                            )
                          }
                          title={
                            jobDetail.job_details.job_status ===
                            JOB_STATUS.COMPLETED
                              ? "View Parts"
                              : "Add Parts"
                          }
                          onClick={() => setAddPartModalVisible(true)}
                          type="button"
                        />
                      </div>
                    </div>

                    <div className="jobdetail-description">
                      <Grid.Container lg={2} md={2} sm={1}>
                        <FormInput label="Job Id">
                          <InputField
                            type="text"
                            value={jobDetail.job_details.job_ident_no}
                            disabled
                          />
                        </FormInput>

                        <FormInput label="Job Type">
                          <SelectField
                            options={JOB_TYPE}
                            getOptionLabel="name"
                            getOptionValue="value"
                            onChangeValue={(item) => false}
                            isSearchable
                            placeholder="Select Type"
                            defaultValue={JOB_TYPE.filter(
                              (element) =>
                                element.value ===
                                jobDetail.job_details.job_type,
                            )}
                            isOptionDisabled={() => true}
                          />
                        </FormInput>
                      </Grid.Container>

                      <Grid.Container lg={2} md={2} sm={1}>
                        <FormInput label="Building Name">
                          <InputField
                            type="text"
                            value={jobDetail.site_details.prop_name}
                            disabled
                          />
                        </FormInput>

                        <FormInput label="Address">
                          <InputField
                            type="text"
                            value={jobDetail.site_details.prop_address.value}
                            disabled
                          />
                        </FormInput>
                      </Grid.Container>

                      <FormInput label="Assigned to">
                        <SelectField
                          isMulti
                          options={userWorkerList}
                          defaultValue={defaultWorkerList.map((item) =>
                            getWorker(item),
                          )}
                          getOptionLabel="full_name"
                          getOptionValue="id"
                          onChangeValue={(item) => {
                            const newValue = item?.map((val) => {
                              return val.id;
                            });
                            setWorkArray([...newValue]);
                          }}
                          isSearchable
                          placeholder="Select User"
                          isOptionDisabled={() => {
                            // Job Type is not disabled on pending
                            return (
                              jobDetail.job_details.job_status !==
                                JOB_STATUS.PENDING &&
                              jobDetail.job_details.job_status !==
                                JOB_STATUS.STARTED
                            );
                          }}
                        />
                      </FormInput>

                      <Grid.Container lg={2} md={2} sm={1}>
                        <FormInput label="Start Time">
                          <InputField
                            type="text"
                            value={moment(
                              jobDetail.job_details.start_time,
                            ).format("Do MMM, YYYY")}
                            disabled
                          />
                        </FormInput>

                        <FormInput label="End Time">
                          {jobDetail.job_meta_details.end_time ? (
                            <InputField
                              type="text"
                              value={moment(
                                jobDetail.job_meta_details.end_time,
                              ).format("Do MMM, YYYY")}
                              disabled
                            />
                          ) : (
                            "Job is not completed yet"
                          )}
                        </FormInput>
                      </Grid.Container>

                      <Box flexBox alCenter>
                        <Box mr={10}>
                          <Title>Job Status:</Title>
                        </Box>
                        <Box>
                          {jobDetail.job_details?.job_status !== null ? (
                            jobDetail.job_details?.job_status ? (
                              <Chip style={{ backgroundColor: "#5b9540" }}>
                                Completed
                              </Chip>
                            ) : (
                              <Chip style={{ backgroundColor: "#11a7af" }}>
                                On Going
                              </Chip>
                            )
                          ) : (
                            <Chip style={{ backgroundColor: "#ff4343" }}>
                              Not Started
                            </Chip>
                          )}
                        </Box>
                      </Box>
                    </div>
                  </div>

                  <div className="jobdescription">
                    <div className="jobdescription-title">Job Description</div>

                    <div className="jobdescription-description">
                      <FormInput label="Job Description">
                        <TextArea
                          placeholder="Job Description"
                          name="jobDescription"
                          value={data.jobDescription}
                          onChange={onChangeHandler("jobDescription")}
                          defaultValue={jobDetail.job_details.job_description}
                        />
                      </FormInput>

                      <FormInput label="Scope and Purpose of the Fire Door Inspection Report">
                        <TextArea
                          placeholder="Scope"
                          name="scope"
                          value={data.scope}
                          onChange={onChangeHandler("scope")}
                          defaultValue={jobDetail.job_meta_details.scope}
                        />
                      </FormInput>

                      <FormInput label="Limitations of the Fire Door Assessment">
                        <TextArea
                          placeholder="Limitations of the Fire Door Assessment"
                          name="limitations"
                          value={data.limitations}
                          onChange={onChangeHandler("limitations")}
                          defaultValue={jobDetail.job_meta_details.limitation}
                        />
                      </FormInput>

                      <FormInput label="Prioritisation of Recommendations and Remedial Works">
                        <TextArea
                          placeholder="Prioritisation of Recommendations and Remedial Works"
                          name="priorities"
                          value={data.priorities}
                          onChange={onChangeHandler("priorities")}
                          defaultValue={
                            jobDetail.job_meta_details.recommendation
                          }
                        />
                      </FormInput>
                    </div>
                  </div>
                  {jobDetail.job_details.sign_off ? (
                    <div className="signoff">
                      <div className="signoff-title">Sign Off</div>

                      <div className="signoff-description">
                        <Grid.Container lg={2} md={2} sm={2}>
                          <FormInput label="Date">
                            {jobDetail.job_meta_details.end_time ? (
                              <InputField
                                type="text"
                                value={moment(
                                  jobDetail.job_meta_details.end_time,
                                ).format("Do MMM, YYYY")}
                                disabled
                              />
                            ) : (
                              "Job is not completed yet"
                            )}
                          </FormInput>

                          <FormInput label="Supervisor">
                            <InputField
                              type="text"
                              value={jobDetail.job_details.supervisor}
                              disabled
                            />
                          </FormInput>
                        </Grid.Container>

                        <Box flexBox alCenter>
                          <Box mr={10}>
                            <Title>Signature:</Title>
                          </Box>
                          <Box>
                            <img
                              alt="sign"
                              src={`${FILE_URL}/job/${jobDetail.job_details.sign_off}`}
                              width={180}
                            />
                          </Box>
                        </Box>
                      </div>
                    </div>
                  ) : null}

                  <Box
                    className="jobaction"
                    flexBox
                    jEnd
                    mb={20}
                    pl={20}
                    pr={20}>
                    <PrimaryButton
                      lefticon={<MdSave style={{ marginRight: 10 }} />}
                      title="Save"
                      type="submit"
                    />
                  </Box>
                </div>
              )}
            </form>
          </Box>

          <Box flex={1}>
            <JobLogs {...{ jobDetail }} />
          </Box>
        </Box>
      </Card>

      {/* ADD PARTS MODAL */}
      <Modal
        visible={addPartModalVisible}
        onOutsideClick={() => setAddPartModalVisible(false)}
        style={{ width: "70vw" }}>
        <Box>
          {jobDetail && (
            <FormInput label="Job Parts">
              <>
                <Box flexBox jSpace alCenter p={10}>
                  <Bold>Floor </Bold>
                  <Bold>Door </Bold>
                  <Bold>Part </Bold>
                  <Bold>Action </Bold>
                </Box>
                {jobDetail.job_parts_list.map(
                  (
                    { door_details, door_part_details, floor_details, id },
                    index,
                  ) => {
                    return (
                      <Box key={index} flexBox jSpace alCenter p={10}>
                        <Paragraph>{floor_details.floor_name} </Paragraph>
                        <Paragraph>{door_details.door_name} </Paragraph>
                        <Paragraph>{door_part_details.part_name} </Paragraph>

                        <ConfirmationModal
                          displayElement={
                            <MdClose
                              style={{ cursor: "pointer" }}
                              color="red"
                            />
                          }
                          label="Are you sure you want to remove this part?"
                          loading={deleteJobLoader}
                          onConfirmClick={(callback) =>
                            onDeleteHandler(id, callback)
                          }
                          danger
                        />
                      </Box>
                    );
                  },
                )}
              </>
            </FormInput>
          )}

          {!(jobDetail.job_details.job_status === JOB_STATUS.COMPLETED) && (
            <>
              <PrimaryButton
                title="Add More Parts"
                onClick={addMoreParts}
                style={{ marginBottom: 10 }}
              />
              {addMore && (
                <>
                  {jobDetail?.job_details?.job_type === "INSTALLATION" && ( //edit this data?.jobType
                    <>
                      <Box flexBox alCenter jSpace>
                        <Title>Floor</Title>
                        <FaPlus onClick={onAdd} />
                      </Box>
                      {floorNdoor.map((item, index) => {
                        return (
                          <Box key={index} flexBox alCenter>
                            <Box flex={1}>
                              <InstallationPart
                                floorNdoor={floorNdoor}
                                index={index}
                                setFloorNdoor={setFloorNdoor}
                                onClose={onClose}
                              />
                            </Box>
                            {floorNdoor?.length > 1 && (
                              <MdClose onClick={() => onClose(index)} />
                            )}
                          </Box>
                        );
                      })}
                    </>
                  )}

                  {(jobDetail?.job_details?.job_type === "INSPECTION" ||
                    jobDetail?.job_details?.job_type === "MAINTENANCE") && (
                    <>
                      <Box flexBox alCenter jSpace>
                        <Title>Floor</Title>
                        <FaPlus onClick={onInspectionAdd} />
                      </Box>
                      {inspectionfloorNdoor.map((_, index) => {
                        return (
                          <Box key={index} flexBox alCenter>
                            <Box flex={1}>
                              <InspectionPart
                                inspectionfloorNdoor={inspectionfloorNdoor}
                                index={index}
                                setInspectionFloorNdoor={
                                  setInspectionFloorNdoor
                                }
                                onCloseFloor={() => onInspectionClose(index)}
                                inspectionPartArray={[
                                  ...defaultpartlist,
                                  ...inspectionPartArray,
                                ]}
                              />
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

// Floor map tab
const FloorMapDetail = () => {
  const { params } = useNavigation();
  const dispatch = useDispatch();
  const { floorByJobLoader, floorListByJob } = useSelector(
    (state) => state.floor,
  );

  const [selectedFloor, setSelectedFloor] = React.useState(null);

  useEffect(() => {
    dispatch(getAllFloorByJob(params.id));
  }, [params.id, dispatch]);

  useEffect(() => {
    setSelectedFloor(floorListByJob[0]);
  }, [floorListByJob]);

  const formatOptionLabel = (data) => {
    return <p>{data.floor_details.floor_name}</p>;
  };

  return (
    <Card containerStyle={{ marginTop: 20 }}>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <div style={{ fontWeight: "bold", marginRight: 10 }}>Select Floor:</div>

        <div style={{ width: 200, zIndex: 100 }}>
          <SelectField
            isLoading={floorByJobLoader}
            options={floorListByJob}
            getOptionValue
            formatOptionLabel={formatOptionLabel}
            onChangeValue={(item) => setSelectedFloor(item)}
            value={selectedFloor}
          />
        </div>
      </div>
      {selectedFloor && (
        <Map
          jobId={params.job_id}
          job_status={selectedFloor.job_details.job_status}
          data={selectedFloor.floor_details.door_list}
          floorDetail={selectedFloor.floor_details}
          jobType={selectedFloor.job_details.job_type}
          image={`${FILE_URL}/floor/${selectedFloor.floor_details.floor_map}`}
        />
      )}
    </Card>
  );
};

export const JobDetailPage = () => {
  const { params } = useNavigation();
  const dispatch = useDispatch();

  const { jobDetail } = useSelector((state) => state.job);

  useEffect(() => {
    dispatch(getJobDelailByIdAction(params.id));
  }, [params.id, dispatch]);

  return (
    <CompWrapper>
      {!!jobDetail ? (
        <Tab
          panes={[
            { render: () => <JobDetail />, title: "Job Detail" },
            { render: () => <FloorMapDetail />, title: "Floor Map" },
          ]}
        />
      ) : (
        <Box>Loading</Box>
      )}
    </CompWrapper>
  );
};
