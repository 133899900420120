import {
  ADD_TEMPLATE,
  GET_ALL_TEMPLATE,
  GET_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
} from "../actions";

const initalState = {
  addLoader: false,
  getLoader: false,
  templates: [],
  // Get a template
  templateLoader: false,
  template: null,

  // edit
  editLoader: false,

  // delete
  deleteLoader: false,
};

export function templateReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_TEMPLATE.LOADING:
      return { ...state, addLoader: true };
    case ADD_TEMPLATE.SUCCESS:
      return { ...state, addLoader: false };
    case ADD_TEMPLATE.ERROR:
      return { ...state, addLoader: false };

    case GET_ALL_TEMPLATE.LOADING:
      return { ...state, getLoader: true };
    case GET_ALL_TEMPLATE.SUCCESS:
      return {
        ...state,
        getLoader: false,
        templates: payload,
        templatesCount: payload.total,
      };
    case GET_ALL_TEMPLATE.ERROR:
      return { ...state, getLoader: false };

    case GET_TEMPLATE.LOADING:
      return { ...state, templateLoader: true };
    case GET_TEMPLATE.SUCCESS:
      return { ...state, templateLoader: false, template: payload };
    case GET_TEMPLATE.ERROR:
      return { ...state, templateLoader: false };

    case EDIT_TEMPLATE.LOADING:
      return { ...state, editLoader: true };
    case EDIT_TEMPLATE.SUCCESS:
      return { ...state, editLoader: false };
    case EDIT_TEMPLATE.ERROR:
      return { ...state, editLoader: false };

    case DELETE_TEMPLATE.LOADING:
      return { ...state, deleteLoader: true };
    case DELETE_TEMPLATE.SUCCESS:
      return { ...state, deleteLoader: false };
    case DELETE_TEMPLATE.ERROR:
      return { ...state, deleteLoader: false };

    default:
      return state;
  }
}
