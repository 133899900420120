import { useState } from "react";

export const useInput = (fields) => {
  const [data, setData] = useState({
    ...fields,
  });

  const onChangeHandler = (name, value) => {
    return function (e) {
      setData((prev) => {
        if (value === undefined || value === null) {
          return { ...prev, [name]: e.target.value };
        } else {
          return { ...prev, [name]: value };
        }
      });
    };
  };

  return { data, onChangeHandler };
};
