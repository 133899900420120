import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { MdClose } from "react-icons/md";
import { useDocTitle } from "../../../../hooks/useDocTitle.hook";

import { isValid, useInput, validator } from "../../../../utils";
import { Card, CompWrapper } from "../../../hoc";
import {
  Box,
  DefaultButton,
  FormInput,
  GooglePlaces,
  Grid,
  ImageUpload,
  InputField,
  SelectField,
  PrimaryButton,
} from "../../../common";
import {
  editClientAction,
  getAllTierList,
  getClientDetailAction,
} from "../../../../actions";
import { Title, Image } from "../../../generics";
import { FILE_URL } from "../../../../config";
import UPLOAD from "../../../../assets/icons/upload.png";

const EditComponent = () => {
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.client);
  const {
    cont_comp_name,
    cont_comp_email,
    cont_comp_address,
    cont_comp_is_deleted,
    cont_comp_logo,
    cont_comp_phone,
    id,
  } = detail?.company_details;
  // console.log({ detail });
  const defaultData = {
    name: cont_comp_name,
    email: cont_comp_email,
    // address: cont_comp_address.value,
    // phone: 987654321,
  };
  const { data, onChangeHandler } = useInput({
    name: cont_comp_name,
    email: cont_comp_email,
    tierId: detail?.tier_details?.name_price,
  });

  const [image, setImage] = React.useState([]);
  const [locationName, setLocationName] = React.useState(
    cont_comp_address.value,
  );
  const [phone, setPhone] = useState(cont_comp_phone);

  const { tierList } = useSelector((state) => state.tier);
  const [tierListFormat, setTierListFormat] = useState([]);

  useEffect(() => {
    dispatch(getAllTierList());
  }, []);

  useEffect(() => {
    setTierListFormat(tierList.map(({ tier_details }) => tier_details));
  }, [tierList]);

  const changeImage = (data) => {
    setImage(data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, name } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("name", name.length === 0, () => {
      showMessage("name musn't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("email musn't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("address musn't be empty!");
    });
    // validate("phone", phone.length === 0, () => {
    //   showMessage("Phone musn't be empty!");
    // });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    let formData = new FormData();
    await formData.append("contractorName", name);
    await formData.append(
      "contractorAddress",
      JSON.stringify({
        coordinates: [1, 1],
        value: locationName,
      }),
    );

    await formData.append("contractorPhone", JSON.stringify(phone));
    // disabled
    // await formData.append("contractorEmail", email);

    image.length !== 0 && (await formData.append("imageFile", image[0].file));

    dispatch(
      editClientAction(
        id,
        formData,
        () => {
          navigate(routes["Client"].path);
        },
        toast,
      ),
    );
  };

  const changeHandle = (index) => {
    return function (e) {
      const phoneArray = [...phone];
      phoneArray[index] = e.target.value;
      setPhone(phoneArray);
    };
  };

  const onAdd = () => {
    setPhone([...phone, ""]);
  };

  const close = (index) => {
    const phoneClone = [...phone];

    phoneClone.splice(index, 1);
    setPhone(phoneClone);
  };

  // console.log({ phone });
  return (
    // <Box>
    <Card>
      <form onSubmit={submitHandler}>
        <FormInput label="Name" required>
          <InputField
            placeholder="Name"
            name="name"
            value={data.name}
            onChange={onChangeHandler("name")}
            type="text"
          />
        </FormInput>

        <FormInput label="Email" required>
          <InputField
            placeholder="Email"
            name="email"
            value={data.email}
            onChange={onChangeHandler("email")}
            type="email"
            disabled={true}
          />
        </FormInput>

        <FormInput label="Address" required>
          <GooglePlaces
            setLocationName={setLocationName}
            defaultValue={cont_comp_address.value}
          />
        </FormInput>

        <FormInput label="Subscription Tier" required>
          <InputField
            placeholder="Subscription Tier"
            name="tierId"
            value={data.tierId}
            onChange={onChangeHandler("tierId")}
            disabled={true}
          />
        </FormInput>

        <FormInput
          label="Phone"
          newElement={
            <PrimaryButton
              onClick={onAdd}
              style={{ cursor: "pointer" }}
              title="Add Field"
              type="button"
            />
          }>
          <Box>
            {phone.length > 0 &&
              phone.map((item, index) => {
                return (
                  <Box flexBox alCenter key={index} mb={10}>
                    <InputField
                      placeholder="Phone"
                      name="phone"
                      value={item}
                      onChange={changeHandle(index)}
                    />
                    {phone.length > 1 && (
                      <Box ml={20}>
                        <div
                          type="button"
                          className="close"
                          style={{ cursor: "pointer" }}
                          onClick={() => close(index)}>
                          <MdClose />
                        </div>
                      </Box>
                    )}
                  </Box>
                );
              })}
          </Box>
        </FormInput>

        <FormInput label="Logo">
          <ImageUpload
            onChange={changeImage}
            buttonStyle={{ background: "red", display: "inline-block" }}
            title="upload image"
            buttonclick={
              <Box mt={20}>
                <div
                  style={{
                    display: "inline-block",
                    border: "1px dashed #000",
                    padding: 50,
                    cursor: "pointer",
                  }}>
                  <Image
                    alt="Avatar"
                    link={UPLOAD}
                    style={{
                      width: 100,
                      height: 100,
                      objectFit: "contain",
                      overflow: "hidden",
                    }}
                  />
                </div>
              </Box>
            }>
            {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
              <Box>
                <Grid.Container lg={1} md={1} sm={1}>
                  {imageData &&
                    imageData.map((item, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "inline-block",
                            boxShadow: "0px 2px 10px 0px #888888",
                          }}>
                          <Image
                            alt="Avatar"
                            link={item.url}
                            style={{
                              width: 200,
                              height: 200,
                              objectFit: "contain",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      </div>
                    ))}

                  {cont_comp_logo && image.length == 0 && (
                    <Box>
                      <div
                        style={{
                          display: "inline-block",
                          boxShadow: "0px 2px 10px 0px #888888",
                        }}>
                        <Image
                          alt="Avatar"
                          link={`${FILE_URL}/contractor/${cont_comp_logo}`}
                          style={{
                            width: 200,
                            height: 200,
                            objectFit: "contain",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    </Box>
                  )}
                </Grid.Container>
              </Box>
            )}
          </ImageUpload>
        </FormInput>

        <DefaultButton title="Submit" type="submit" />
      </form>
    </Card>

    // </Box>
  );
};

export const EditClientPage = () => {
  const { params } = useNavigation();
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.client);

  useDocTitle("Edit Contractor");
  // FOR INTEGRATION

  useEffect(() => {
    if (!!params.id) {
      dispatch(getClientDetailAction(params.id));
    }
  }, [params.id]);

  return (
    <CompWrapper>
      {!!detail ? <EditComponent /> : <Title>Loading</Title>}
    </CompWrapper>
  );
};
