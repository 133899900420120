import {
  ADD_BUILDING,
  BUILDING_DETAIL,
  BUILDING_LIST,
  DELETE_BUILDING,
  EDIT_BUILDING,
  BUILDING_VIEWER,
  USER,
  ADD_BUILDING_VIEWER,
  ALL_BUILDING_LIST,
  BUILDING_DETAIL_BY_POSTALCODE,
  REQUEST_BUILDING_OWNERSHIP,
  BUILDING_OWNERSHIP_REQUESTED_BY_ME,
  BUILDING_OWNERSHIP_REQUESTED_DETAIL,
  BUILDING_OWNERSHIP_REQUESTED_FOR_ME,
  BUILDING_OWNERSHIP_RESPONSE,
} from "../actions";

const initalState = {
  allListloading: false,
  allList: [],

  listloading: false,
  list: [],
  totalCount: null,

  detail: null,
  detailloading: false,
  buildingBreadcrumb: null,

  addLoading: false,
  editLoading: false,
  deleteLoading: false,

  viewerList: [],
  viewerLoading: false,
  viewerCount: null,

  addViewerLoading: false,

  detailByPostalCode: null,
  detailByPostalCodeLoading: false,

  requestBuildingOwnershipLoading: false,
  requestBuildingOwnershipSuccess: false,
  requestBuildingOwnershipError: false,

  ownershipRequestedListloading: false,
  ownershipRequestedList: [],
  ownershipRequestedTotalCount: null,
  ownershipRequestedListisLast: false,

  ownershipRequestsforMeListloading: false,
  ownershipRequestsforMeList: [],
  ownershipRequestsforMeTotalCount: null,
  ownershipRequestsforMeListisLast: false,

  requestedDetail: null,
  requestedDetailLoading: false,

  responseSuccess: false,
  responseLoading: false,
};

export function buildingReducer(state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case BUILDING_LIST.CLEAR:
      return { ...state, listloading: true, list: [], isLast: false };
    case BUILDING_LIST.LOADING:
      return { ...state, listloading: true, list: [] };
    case BUILDING_LIST.SUCCESS:
      return {
        ...state,
        listloading: false,
        list: payload.rows,
        totalCount: payload.total,
      };
    case BUILDING_LIST.ERROR:
      return { ...state, listloading: false };

    case ALL_BUILDING_LIST.LOADING:
      return { ...state, allListloading: true };
    case ALL_BUILDING_LIST.SUCCESS:
      return {
        ...state,
        allListloading: false,
        allList: payload,
      };
    case ALL_BUILDING_LIST.ERROR:
      return { ...state, allListloading: false };

    case BUILDING_DETAIL.CLEAR:
      return {
        ...state,
        detail: null,
        buildingBreadcrumb: null,
      };
    case BUILDING_DETAIL.LOADING:
      return { ...state, detailloading: true, detail: null };
    case BUILDING_DETAIL.SUCCESS:
      return {
        ...state,
        detailloading: false,
        detail: payload.details,
        buildingBreadcrumb: payload.breadcrum,
      };
    case BUILDING_DETAIL.ERROR:
      return { ...state, detailloading: false };

    case BUILDING_DETAIL_BY_POSTALCODE.LOADING:
      return {
        ...state,
        detailByPostalCodeLoading: true,
        detailByPostalCode: null,
      };

    case BUILDING_DETAIL_BY_POSTALCODE.SUCCESS:
      return {
        ...state,
        detailByPostalCodeLoading: false,
        detailByPostalCode: payload,
      };
    case BUILDING_DETAIL_BY_POSTALCODE.ERROR:
      return { ...state, detailByPostalCodeLoading: false };

    case ADD_BUILDING.LOADING:
      return { ...state, addLoading: true };
    case ADD_BUILDING.SUCCESS:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_BUILDING.ERROR:
      return { ...state, addLoading: false };

    case EDIT_BUILDING.LOADING:
      return { ...state, editLoading: true };
    case EDIT_BUILDING.SUCCESS:
      return {
        ...state,
        editLoading: false,
      };
    case EDIT_BUILDING.ERROR:
      return { ...state, editLoading: false };

    case DELETE_BUILDING.LOADING:
      return { ...state, deleteLoading: true };
    case DELETE_BUILDING.SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };
    case DELETE_BUILDING.ERROR:
      return { ...state, deleteLoading: false };

    case BUILDING_VIEWER.LOADING:
      return { ...state, viewerLoading: true, viewerList: [] };
    case BUILDING_VIEWER.SUCCESS:
      return {
        ...state,
        viewerLoading: false,
        viewerList: payload.rows,
        viewerCount: payload.total,
      };
    case BUILDING_VIEWER.ERROR:
      return { ...state, viewerLoading: false };

    case ADD_BUILDING_VIEWER.LOADING:
      return { ...state, addViewerLoading: true };
    case ADD_BUILDING_VIEWER.SUCCESS:
      return {
        ...state,
        addViewerLoading: false,
      };
    case ADD_BUILDING_VIEWER.ERROR:
      return { ...state, addViewerLoading: false };

    case REQUEST_BUILDING_OWNERSHIP.CLEAR:
      return {
        ...state,
        requestBuildingOwnershipLoading: false,
        requestBuildingOwnershipSuccess: false,
        requestBuildingOwnershipError: false,
      };

    case REQUEST_BUILDING_OWNERSHIP.LOADING:
      return {
        ...state,
        requestBuildingOwnershipLoading: true,
        requestBuildingOwnershipSuccess: false,
        requestBuildingOwnershipError: false,
      };
    case REQUEST_BUILDING_OWNERSHIP.SUCCESS:
      return {
        ...state,

        requestBuildingOwnershipLoading: false,
        requestBuildingOwnershipSuccess: true,
        requestBuildingOwnershipError: false,
      };
    case REQUEST_BUILDING_OWNERSHIP.ERROR:
      return {
        ...state,
        requestBuildingOwnershipLoading: false,
        requestBuildingOwnershipSuccess: false,
        requestBuildingOwnershipError: true,
      };

    case BUILDING_OWNERSHIP_REQUESTED_BY_ME.CLEAR:
      return {
        ...state,
        ownershipRequestedListloading: true,
        ownershipRequestedList: [],
        ownershipRequestedListisLast: false,
      };
    case BUILDING_OWNERSHIP_REQUESTED_BY_ME.LOADING:
      return {
        ...state,
        ownershipRequestedListloading: true,
        ownershipRequestedList: [],
      };
    case BUILDING_OWNERSHIP_REQUESTED_BY_ME.SUCCESS:
      return {
        ...state,
        ownershipRequestedListloading: false,
        ownershipRequestedList: payload.rows,
        ownershipRequestedTotalCount: payload.total,
      };
    case BUILDING_OWNERSHIP_REQUESTED_BY_ME.ERROR:
      return { ...state, ownershipRequestedListloading: false };

    case BUILDING_OWNERSHIP_REQUESTED_FOR_ME.CLEAR:
      return {
        ...state,
        ownershipRequestsforMeListloading: true,
        ownershipRequestsforMeList: [],
        ownershipRequestsforMeListisLast: false,
      };
    case BUILDING_OWNERSHIP_REQUESTED_FOR_ME.LOADING:
      return {
        ...state,
        ownershipRequestsforMeListloading: true,
        ownershipRequestsforMeList: [],
      };
    case BUILDING_OWNERSHIP_REQUESTED_FOR_ME.SUCCESS:
      return {
        ...state,
        ownershipRequestsforMeListloading: false,
        ownershipRequestsforMeList: payload.rows,
        ownershipRequestsforMeTotalCount: payload.total,
      };
    case BUILDING_OWNERSHIP_REQUESTED_FOR_ME.ERROR:
      return { ...state, ownershipRequestsforMeListloading: false };

    case BUILDING_OWNERSHIP_REQUESTED_DETAIL.CLEAR:
      return { ...state, requestedDetail: null };
    case BUILDING_OWNERSHIP_REQUESTED_DETAIL.LOADING:
      return { ...state, requestedDetailLoading: true };
    case BUILDING_OWNERSHIP_REQUESTED_DETAIL.SUCCESS:
      return {
        ...state,
        requestedDetailLoading: false,
        requestedDetail: payload,
      };
    case BUILDING_OWNERSHIP_REQUESTED_DETAIL.ERROR:
      return { ...state, requestedDetailLoading: false };

    case BUILDING_OWNERSHIP_RESPONSE.LOADING:
      return { ...state, responseLoading: true };
    case BUILDING_OWNERSHIP_RESPONSE.SUCCESS: {
      let requestedDetailClone = { ...state.requestedDetail };
      if (!!state.requestedDetail) {
        requestedDetailClone.status = payload.status;
      } else {
        requestedDetailClone = null;
      }

      const ownershipRequestsforMeListClone = [
        ...state.ownershipRequestsforMeList,
      ];
      const index = ownershipRequestsforMeListClone.findIndex(
        ({ id }) => Number(id) === Number(payload.id),
      );
      if (typeof index === "number") {
        const reqObjectClone = { ...ownershipRequestsforMeListClone[index] };
        reqObjectClone.status = payload.status;
        ownershipRequestsforMeListClone[index] = reqObjectClone;
      }

      return {
        ...state,
        responseLoading: false,
        responseSuccess: true,
        requestedDetail: requestedDetailClone,
        ownershipRequestsforMeList: ownershipRequestsforMeListClone,
      };
    }
    case BUILDING_OWNERSHIP_RESPONSE.ERROR:
      return { ...state, responseLoading: false, responseSuccess: false };

    default:
      return state;
  }
}
