import React, { useEffect } from "react";
import moment from "moment";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { getPastInspectionDetailAction } from "../../../../../actions";
import { Box, KeyValue } from "../../../../common";
import { Chip, Title } from "../../../../generics";
import { Card, CompWrapper } from "../../../../hoc";
import { QuestionTable } from "./components";

export const PastInspectionPage = () => {
  const dispatch = useDispatch();

  const pastInspection = useSelector((state) => state.pastInspection);
  const {
    pastInspectionDetail,
    pastInspectionDetailLoader,
    pastInspectionBreadcrumb,
  } = pastInspection;

  const { navigation, params, location } = useNavigation();

  const parseLocation = location?.pathname?.split("/");

  parseLocation.includes("jobs");
  useEffect(() => {
    dispatch(
      getPastInspectionDetailAction(
        params?.id,
        parseLocation.includes("jobs") ? "job" : "doorpart",
      ),
    );
  }, [params?.id]);

  const detail = {
    id: 5,
    end_time: null,
    job_type: "INSTALLATION",
    sign_off: null,
    created_at: "2021-03-12T09:19:16.872+05:45",
    job_status: false,
    start_time: "2021-03-15T08:31:39+05:45",
    attachments: null,
    job_ident_no: "UPD-1615520056854",
    job_description: "This is job description",
  };

  const getJobType = (item) => {
    switch (item) {
      case "MAINTAIN_INSPEC":
        return "Maintenance (Repair)";
      case "MAINTAIN_INSTALL":
        return "Maintenance (Install)";

      case "INSPECTION":
        return "Inspection";

      default:
        return "Installation";
    }
  };

  return (
    <CompWrapper breadcrumbData={pastInspectionBreadcrumb}>
      {pastInspectionDetail && (
        <Card>
          <Box flexBox jSpace>
            <Box>
              <Title mb={10}>Door Part Details</Title>
              <KeyValue
                data={{
                  "Part Name":
                    pastInspectionDetail?.door_part_details?.part_name,
                  "Schedule Interval":
                    pastInspectionDetail?.door_part_details?.schedule_time
                      ?.duration +
                    " " +
                    pastInspectionDetail?.door_part_details?.schedule_time
                      ?.unit,
                }}
              />
            </Box>
            <Box>
              <Title mb={10}>Job Details</Title>
              <KeyValue
                data={{
                  "Job Id": pastInspectionDetail?.job_details?.job_ident_no,
                  "Job Type": getJobType(
                    pastInspectionDetail?.job_details?.job_type,
                  ),
                  "Start Time": moment(
                    pastInspectionDetail?.job_details?.start_time,
                  ).format("Do MMM, YYYY"),
                  "Job Status":
                    pastInspectionDetail?.job_details?.job_status !== null ? (
                      pastInspectionDetail?.job_details?.job_status ? (
                        <Chip style={{ backgroundColor: "#5b9540" }}>
                          Completed
                        </Chip>
                      ) : (
                        <Chip style={{ backgroundColor: "#11a7af" }}>
                          On Going
                        </Chip>
                      )
                    ) : (
                      <Chip style={{ backgroundColor: "#ff4343" }}>
                        Not Started
                      </Chip>
                    ),
                  // Description:
                  //   pastInspectionDetail?.job_details?.job_description,
                }}
              />
            </Box>
          </Box>
        </Card>
      )}
      <Box mt={20}>
        <QuestionTable
          data={pastInspectionDetail?.response_list}
          jobType={pastInspectionDetail?.job_details?.job_type}
        />
      </Box>
    </CompWrapper>
  );
};
