import React, { useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch } from "react-redux";
import { useDocTitle } from "../../../../hooks";
import {
  Box,
  FormInput,
  InputField,
  TextArea,
  PrimaryButton,
  Grid,
  CheckBox,
} from "../../../common";
import { addTierAction, editTierAction } from "../../../../actions";

// IMPORT HOCS
import {
  capitalizeFirstLetter,
  useInput,
  patchOnlyEditedDataRaw,
} from "../../../../utils";
import { Bold } from "../../../generics";

export const AddFormComponent = (props) => {
  const { tierDetail } = props;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  useDocTitle("Tier");
  const [check, setCheck] = useState(() => {
    return tierDetail?.permission?.is_max ? { yes: true } : { no: true };
  });

  const prevData = {
    name: tierDetail?.name ?? "",
    description: tierDetail?.description ?? "",
    amount: tierDetail?.price?.amount ?? "",
    currency: tierDetail?.price?.currency ?? "GBP",
    interval: tierDetail?.price?.interval ?? "month",
    limit: tierDetail?.permission?.limit ?? "",
  };
  const { data, onChangeHandler } = useInput({
    ...prevData,
  });

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    const { name, description, amount, currency, interval, limit } = data;

    const permission = {};
    if (!!(Object.keys(check)[0] === "no")) {
      permission.limit = +limit;
      permission.is_max = false;
    } else {
      permission.limit = 0;
      permission.is_max = true;
    }

    const body = {
      name: capitalizeFirstLetter(name),
      description: capitalizeFirstLetter(description),
      price: {
        amount: +amount,
        currency,
        interval,
      },
      permission,
    };

    if (!!tierDetail) {
      delete body.price;
      // body.price = price;
    }

    let bodyObj = {};
    const patchData = patchOnlyEditedDataRaw(bodyObj, prevData, body);

    !!tierDetail
      ? patchData.length
        ? dispatch(
            editTierAction(tierDetail?.id, body, toast, () => {
              navigate(routes["Tier"].path);
            }),
          )
        : console.log("no data")
      : dispatch(
          addTierAction(body, toast, () => {
            navigate(routes["Tier"].path);
          }),
        );
  };

  const getDenomination = (value) => {
    switch (value) {
      case "GBP":
        return "£";
      case "USD":
        return "$";
      default:
        return "$";
    }
  };

  const toggle = (data) => {
    setCheck({ [`${data}`]: !check[data] });
  };
  return (
    <form onSubmit={submitHandler}>
      <FormInput label="Name">
        <InputField
          placeholder="Name"
          name="name"
          value={data.name}
          onChange={onChangeHandler("name")}
          type="text"
          required
        />
      </FormInput>

      <FormInput label="Description">
        <TextArea
          placeholder="description"
          name="description"
          value={data.description}
          onChange={onChangeHandler("description")}
          required
        />
      </FormInput>

      <FormInput label="Price">
        {!!tierDetail ? (
          <Bold>
            {getDenomination(data.currency)} {data.amount} / {data.interval}
          </Bold>
        ) : (
          <Box flexBox row style={{ columnGap: 20 }}>
            <InputField
              placeholder="Amount"
              name="amount"
              value={data.amount}
              onChange={onChangeHandler("amount")}
              type="number"
              required
            />
            <InputField
              placeholder="Currency"
              name="currency"
              value={data.currency}
              // onChange={onChangeHandler("currency")}
              type="text"
              readOnly
            />

            <InputField
              placeholder="Interval"
              name="interval"
              value={data.interval}
              // onChange={onChangeHandler("interval")}
              type="text"
              readOnly
            />
          </Box>
        )}
      </FormInput>

      <FormInput label="Have Unlimited users?">
        <Grid.Container lg={2} md={2} sm={1} flexBox jCenter alCenter>
          <CheckBox
            label="Yes"
            name="yes"
            check={!!check?.yes}
            handleCheckboxChange={toggle}
          />

          <CheckBox
            label="No"
            name="no"
            check={!!check?.no}
            handleCheckboxChange={toggle}
          />
        </Grid.Container>
      </FormInput>
      {!!(Object.keys(check)[0] === "no") && (
        <FormInput label="Users Limit">
          <InputField
            placeholder="Limit"
            name="limit"
            value={data.limit}
            onChange={onChangeHandler("limit")}
            type="number"
            required
          />
        </FormInput>
      )}
      <PrimaryButton title="submit" type="submit" />
    </form>
  );
};
