export const userType = {
  ADMIN: "SUPERADMIN",
  HALF_CONTRACTOR: "HALF_ADMIN",
  CONTRACTOR: "ADMIN",
  USER: "user",
};

export const userRoles = {
  [userType.ADMIN]: {
    access: [
      "/",
      "/log-in",
      "/registration/*",
      "/change-password",
      "/dashboard",
      "/client/*",
      "/template/*",
      // "/ironmongery/*",
      // "/ironmongery-pack/*",
      "/floor/*",
      "/tier/*",
    ],
  },
  [userType.HALF_CONTRACTOR]: {
    access: [
      "/",
      "/log-in",
      "/registration/*",
      "/change-password",
      "/user-dashboard",
      "/users/*",
    ],
  },
  [userType.CONTRACTOR]: {
    access: [
      "/",
      "/log-in",
      "/registration/*",
      "/change-password",
      "/client-dashboard",
      "/schedule/*",
      "/maintenance-request/*",
      "/worker",
      "/building/*",
      "/jobs/*",
      "/users/*",
      "/ironmongery/*",
      "/ironmongery-pack/*",
      "/floor/*",
      "/subscription",
    ],
  },
  [userType.USER]: {
    access: [
      "/",
      "/log-in",
      "/registration/*",
      "/change-password",
      "/auth/*",
      "/floor/*",
      "/privacy/*",
    ],
  },
};
