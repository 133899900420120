import React from "react";
import { useNavigation } from "react-auth-navigation";
import { MdEdit, MdDelete } from "react-icons/all";

import { ToolTip } from "../../../../../common";

export const DoorCard = (props) => {
  const {
    setSelectedDoor,
    setDeleteModal,
    setEditModal,
    editDoorHandler,
    ...rest
  } = props;

  const { id, door_name, door_ident_no, fire_rating } = rest;
  const {
    navigation: { navigate },
    location,
  } = useNavigation();

  return (
    <div
      className="doorcard"
      onClick={() =>
        // navigate(`/building/door/${id}`)
        navigate(`${location.pathname}/door/${id}`)
      }>
      <div className="doorcard-header">
        <div className="doorcard-header-name">{door_name}</div>

        <div className="doorcard-header-actions">
          <ToolTip text="Edit">
            <button
              className="door-button edit"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDoor(id);
                editDoorHandler(rest);
                setEditModal(true);
              }}>
              <MdEdit size={20} />
            </button>
          </ToolTip>

          <ToolTip text="Delete">
            <button
              className="door-button delete"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDoor(id);
                setDeleteModal(true);
              }}>
              <MdDelete size={20} />
            </button>
          </ToolTip>
        </div>
      </div>

      <div className="doorcard-body">
        <div className="body-info">
          <div className="body-info-title">Door Ident No.</div>
          <div className="body-info-data">{door_ident_no}</div>
        </div>

        <div className="body-info">
          <div className="body-info-title">Fire Rating</div>
          <div className="body-info-data">{fire_rating}</div>
        </div>
      </div>
    </div>
  );
};
