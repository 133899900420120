import { PAST_INSPECTION, PAST_INSPECTION_DETAIL } from "../actions";

const initalState = {
  pastInspectionList: [],
  pastInspectionListCount: 0,
  pastInspectionLoader: false,
  pastInspectionBreadcrumb: null,

  pastInspectionDetail: null,
  pastInspectionDetailLoader: false,
};

export function pastInspectionReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case PAST_INSPECTION.LOADING:
      return { ...state, pastInspectionLoader: true };
    case PAST_INSPECTION.SUCCESS:
      return {
        ...state,
        pastInspectionLoader: false,
        pastInspectionList: payload.rows,
        pastInspectionListCount: payload.total,
      };
    case PAST_INSPECTION.ERROR:
      return { ...state, pastInspectionLoader: false };

    case PAST_INSPECTION_DETAIL.CLEAR:
      return {
        ...state,
        pastInspectionDetail: null,
        pastInspectionBreadcrumb: null,
      };

    case PAST_INSPECTION_DETAIL.LOADING:
      return { ...state, pastInspectionDetailLoader: true };
    case PAST_INSPECTION_DETAIL.SUCCESS:
      return {
        ...state,
        pastInspectionDetailLoader: false,
        pastInspectionDetail: payload.details,
        pastInspectionBreadcrumb: payload.breadcrum,
      };
    case PAST_INSPECTION_DETAIL.ERROR:
      return { ...state, pastInspectionDetailLoader: false };

    default:
      return state;
  }
}
