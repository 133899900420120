import React from "react";
import { useNavigation } from "react-auth-navigation";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Bold, Paragraph } from "../../generics";

import Link from "@material-ui/core/Link";

export const CustomBreadcrumb = ({ data }) => {
  console.log("data", data);
  const {
    navigation: { navigate },
    location,
  } = useNavigation();
  const sortedArray = [
    "building",
    "floor",
    "door",
    "part",
    "jobs",
    "door_part",
    "batch",
  ];
  if (!data) return <div></div>;
  const breadcrumbArray = sortedArray
    .map((item) => data?.hasOwnProperty(item) && item)
    .filter(Boolean);

  const onNavigate = (arr, index) => {
    if (data) {
      const url = arr.map((element, i) => {
        let detail = "";
        if (element === "building" || element === "jobs") {
          detail = "detail/";
        }
        return i <= index && `/${element}/${detail}${data[element].id}`;
      });
      navigate(url.filter(Boolean).join(""));
    }
  };
  const parseLocation = location?.pathname?.split("/");

  console.log("parseLocation", parseLocation);

  return (
    <Breadcrumbs>
      {parseLocation.includes("jobs") ? (
        <Link
          href="/jobs"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/jobs");
          }}>
          Jobs
        </Link>
      ) : (
        <Link
          href="/building"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/building");
          }}>
          Buildings
        </Link>
      )}
      {breadcrumbArray.length > 0 &&
        breadcrumbArray.map(
          (item, index, arr) =>
            index !== arr.length - 1 && (
              <Link
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => onNavigate(arr, index)}>
                {data[item]?.name}
              </Link>
            ),
        )}
      {data[breadcrumbArray[breadcrumbArray.length - 1]]?.name && (
        <Typography color="textPrimary" className="breadcrumb-link">
          {data[breadcrumbArray[breadcrumbArray.length - 1]]?.name}
        </Typography>
      )}
    </Breadcrumbs>
  );
};
