import React, { useEffect, useState } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-uicomp";
import { MdAdd, SiMicrosoftexcel } from "react-icons/all";

import {
  DefaultButton,
  PrimaryButton,
  Box,
  Grid,
  FormInput,
  InputField,
  SelectField,
  CheckBox,
} from "../../../../../common";
import { Card, ActivityIndicator } from "../../../../../hoc";
import { Title } from "../../../../../generics";
import { DoorCard } from ".";

import {
  getDoorListLoadMore,
  deleteDoorAction,
  editDoorAction,
  postDoorAction,
  getDoorWithoutMap,
  getIronmongeryPackList,
} from "../../../../../../actions";
import { TABLE_LIMIT } from "../../../../../../config";
import {
  useInput,
  validator,
  isValid,
  capitalizeFirstLetter,
} from "../../../../../../utils";

export const FIRE_RATING_ACTUAL = ["NFR", "FD30", "FD60", "FD90", "FD120"];

export const FIRE_RATING = [
  { name: "NFR", value: "NFR" },
  { name: "FD30s", value: "FD30" },
  { name: "FD60s", value: "FD60" },
  { name: "FD90s", value: "FD90" },
  { name: "FD120s", value: "FD120" },
  { name: "Other", value: "Other" },
];

export const SET = [
  { name: "Single", value: "single" },
  { name: "Double", value: "double" },
  { name: "Leaf and Half", value: "leafNhalf" },
];

export const ACTING = [
  { name: "Single Acting", value: "single" },
  { name: "Double Acting", value: "double" },
];

export const LATCHED = [
  { name: "Latched", value: true },
  { name: "Unlatched", value: false },
];

// Delete modal
const DeleteDoorModal = ({ deleteModal, closeDeleteModal, deleteDoor }) => (
  <Modal visible={deleteModal} onOutsideClick={closeDeleteModal}>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3>Are you sure you want to delete this door ?</h3>
      <div
        style={{
          width: "100%",
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          float: "right",
        }}>
        <DefaultButton
          style={{
            background: "#ff4343",
            color: "white",
            marginRight: 10,
          }}
          onClick={deleteDoor}
          title="Delete"
        />
        <PrimaryButton onClick={closeDeleteModal} title="Cancel" />
      </div>
    </div>
  </Modal>
);

export const DoorList = () => {
  const { params, navigation, location } = useNavigation();
  const { toast } = useAuth();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const door = useSelector((state) => state.door);
  const { doorListAppendLoader, doorListAppend, doorListCount } = door;

  const totalPages = Math.ceil(doorListCount / TABLE_LIMIT);
  const canLoadMore = pageNumber < totalPages;

  const [selectedDoor, setSelectedDoor] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [check, setCheck] = useState({});
  const [sideLights, setSideLights] = useState({});
  const [editData, setEditData] = React.useState(null);
  const { data, onChangeHandler } = useInput({
    doorName: "",
    doorType: "",
    fireRating: "",
    soHeight: "",
    soWidth: "",
    soDepth: "",
    acousticRating: "",
    ironmongery: "",
    set: "",
    acting: "",
    latched: "",
    fireRatingText: "",
    ironmongeryPack: "",
  });
  const { addViewerLoading } = useSelector((state) => state.building);
  const { ironmongeryPackLoader, ironmongeryPacklist } = useSelector(
    (state) => state.ironmongeryPack,
  );

  const [ironmongeryPackFormat, setIronmongeryPackFormat] = useState([]);

  useEffect(() => {
    setIronmongeryPackFormat(
      ironmongeryPacklist.map((item) => {
        return {
          name: item.group_details.name,
          value: item.group_details.id,
        };
      }),
    );
  }, [ironmongeryPacklist]);

  useEffect(() => {
    dispatch(getIronmongeryPackList());
  }, []);

  const handleLoadMore = () => {
    setPageNumber((prev) => {
      const nextPageNumber = prev + 1;

      if (nextPageNumber <= totalPages) {
        return nextPageNumber;
      } else {
        return prev;
      }
    });
  };

  // unset selected door and close modal
  const closeDeleteModal = () => {
    // setSelectedDoor(null);
    setDeleteModal(false);
  };

  // dispatch delete action
  const deleteDoor = () => {
    dispatch(
      deleteDoorAction(selectedDoor, toast, params?.id, pageNumber, () =>
        closeDeleteModal(),
      ),
    );
  };

  // unset selected door and close modal
  const closeEditModal = () => {
    // setSelectedDoor(null);
    setEditModal(false);
    data.doorName = "";
    data.fireRating = "";
    data.set = "";
    data.acting = "";
    data.latched = "";
    data.fireRatingText = "";
    data.doorType = "";
    data.soHeight = "";
    data.soWidth = "";
    data.soDepth = "";
    data.acousticRating = "";
    // data.ironmongery = "";
    data.ironmongeryPack = "";
    delete data.id;
    setCheck({});
    setEditData(null);
  };

  const toggleCheckBox = (data) => {
    setCheck({ [`${data}`]: !check[data] });
  };

  const toggleSideLights = (data) => {
    setSideLights({ [`${data}`]: !check[data] });
  };

  // Show toast
  const showMessage = (message) => {
    toast({ message, type: "error" });
  };

  const editDoorHandler = (item) => {
    setEditData(item);

    if (item.overpanel) {
      setCheck({ yes: true });
    } else if (item.overpanel === false) {
      setCheck({ no: true });
    } else {
      setCheck({});
    }
    if (item.side_light === "1") {
      setSideLights({ one: true });
    } else if (item.side_light === "2") {
      setSideLights({ two: true });
    } else {
      setSideLights({});
    }
    data.id = item.id;
    data.doorName = item.door_name;
    data.doorType = item?.config_type?.type ?? "";
    data.fireRating = FIRE_RATING_ACTUAL.includes(item.fire_rating)
      ? item.fire_rating
      : "Other";
    data.fireRatingText = item.fire_rating;
    data.soHeight = item.so_height ?? "";
    data.soWidth = item.so_width ?? "";
    data.soDepth = item.so_width ?? "";
    data.acousticRating = item.acoustic_rating ?? "";
    // data.ironmongery = item.ironmongery;
    data.set = item.config_type.set;
    data.acting = item.config_type.acting;
    data.latched = item.config_type.latched;
    data.ironmongeryPack = item.im_group_id;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const {
      doorName,
      doorType,
      fireRating,
      fireRatingText,
      soHeight,
      soWidth,
      soDepth,
      acousticRating,
      // ironmongery,
      set,
      acting,
      latched,
      ironmongeryPack,
    } = data;

    validate("doorName", doorName.length === 0, () => {
      showMessage("Door name can't be empty!");
    });
    validate("doorType", doorType.length === 0, () => {
      showMessage("Door type can't be empty!");
    });
    validate("fireRating", fireRating.length === 0, () => {
      showMessage("Fire rating can't be empty!");
    });

    validate("soHeight", soHeight.length === 0, () => {
      showMessage("S.O. Height can't be empty!");
    });
    validate("soWidth", soWidth.length === 0, () => {
      showMessage("S.O. Width can't be empty!");
    });
    validate("soDepth", soDepth.length === 0, () => {
      showMessage("S.O. Depth can't be empty!");
    });

    if (!isValid(catchedErrors)) {
      return;
    }

    const body = {
      doorName: capitalizeFirstLetter(doorName),
      fireRating: fireRating,
      configType: {
        type: capitalizeFirstLetter(doorType),
      },
      soHeight: soHeight,
      soWidth: soWidth,
      soDepth: soDepth,
    };

    if (!editData) {
      body["floorId"] = params.id;
    }

    if (data.fireRating === "Other") {
      body["fireRating"] = fireRatingText;
    }

    if (acousticRating) {
      body["acousticRating"] = capitalizeFirstLetter(acousticRating);
    }

    // if (ironmongery) {
    //   body["ironmongery"] = capitalizeFirstLetter(ironmongery);
    // }

    if (set) {
      body["configType"]["set"] = set;
    }

    if (acting) {
      body["configType"]["acting"] = acting;
    }

    if (latched) {
      body["configType"]["latched"] = latched;
    }

    if (ironmongeryPack) {
      body["imGroupId"] = ironmongeryPack;
    }

    if (Object.keys(check).length) {
      body["overpanel"] = Object.keys(check)[0] === "yes" ? true : false;
    }

    if (Object.keys(sideLights).length) {
      body["sideLight"] = Object.keys(sideLights)[0] === "one" ? 1 : 2;
    }

    !!editData
      ? dispatch(
          editDoorAction(
            data.id,
            body,
            () => {
              closeEditModal();
              setSelectedDoor(null);
              dispatch(getDoorListLoadMore(params?.id, 1));
              dispatch(getDoorWithoutMap(params?.id));
            },
            toast,
          ),
        )
      : dispatch(
          postDoorAction(
            body,
            () => {
              closeEditModal();
              setSelectedDoor(null);
              dispatch(getDoorListLoadMore(params?.id, 1));
              dispatch(getDoorWithoutMap(params?.id));
            },
            toast,
          ),
        );
  };

  useEffect(() => {
    dispatch(getDoorListLoadMore(params?.id, pageNumber));
  }, [params.id, pageNumber, dispatch]);

  return (
    <>
      <Card containerStyle={{ paddingRight: 10 }}>
        <Box flexBox jSpace alCenter mb={20}>
          <Box>
            <Title>Doors List</Title>
          </Box>

          <Box flexBox alCenter jSpace>
            <button
              className="door-button add"
              onClick={() => setEditModal(true)}>
              <MdAdd size={20} />
            </button>
            <Box ml={10}>
              <button
                className="door-button add"
                onClick={() => {
                  navigation.navigate(
                    // `/building/${params?.id}/adddoor-batch/${params?.id}`,
                    `${location.pathname}/adddoor-batch`,
                  );
                }}>
                <SiMicrosoftexcel size={20} />
              </button>
            </Box>
          </Box>
        </Box>

        <div className="door-list-cards">
          {doorListAppend.map((props, i) => (
            <DoorCard
              key={i}
              {...props}
              {...{
                setSelectedDoor,
                setDeleteModal,
                setEditModal,
                editDoorHandler,
              }}
            />
          ))}

          {canLoadMore && (
            <ActivityIndicator animating={doorListAppendLoader}>
              <div className="door-list-loadmore">
                <button className="loadmore-button" onClick={handleLoadMore}>
                  Load More
                </button>
              </div>
            </ActivityIndicator>
          )}
        </div>
      </Card>

      {/* Delete modal */}
      <DeleteDoorModal {...{ deleteModal, deleteDoor, closeDeleteModal }} />

      {/* Edit modal */}
      <Modal
        style={{ width: "70vw" }}
        visible={editModal}
        onOutsideClick={closeEditModal}>
        <Box>
          <Box mb={10}>
            <Title style={{ color: "#989898" }}>
              {editData ? "Edit Door" : "Add Door"}
            </Title>
          </Box>

          <div
            style={{ borderBottom: "1px solid #f1f1f1", marginBottom: 20 }}
          />

          <form onSubmit={submitHandler}>
            <Box>
              <Grid.Container lg={4} md={2} sm={1}>
                <FormInput label="Door Number" required>
                  <InputField
                    placeholder="Door Number"
                    name="doorName"
                    value={data.doorName}
                    onChange={onChangeHandler("doorName")}
                    type="text"
                  />
                </FormInput>

                <FormInput label="Door Type" required>
                  <InputField
                    placeholder="Door Type"
                    name="doorType"
                    value={data.doorType}
                    onChange={onChangeHandler("doorType")}
                    type="text"
                  />
                </FormInput>
                <FormInput label="Fire Rating" required>
                  <SelectField
                    options={FIRE_RATING}
                    getOptionLabel="name"
                    getOptionValue="value"
                    onChangeValue={(item) => {
                      onChangeHandler("fireRating", item?.value)();
                    }}
                    isSearchable
                    placeholder="Select Type"
                    defaultValue={FIRE_RATING.filter(
                      (element) => element.value === data.fireRating,
                    )}
                  />
                </FormInput>
                {data.fireRating === "Other" && (
                  <FormInput label="FireRating" required>
                    <InputField
                      placeholder="FireRating"
                      name="fireRating"
                      value={data.fireRatingText}
                      onChange={onChangeHandler("fireRatingText")}
                      type="text"
                    />
                  </FormInput>
                )}
                <FormInput label="S.O. Height" required>
                  <InputField
                    placeholder="S.O. Height"
                    name="soHeight"
                    value={data.soHeight}
                    onChange={onChangeHandler("soHeight")}
                    // type="number"
                    // min="0"
                  />
                </FormInput>

                <FormInput label="S.O. Width" required>
                  <InputField
                    placeholder="S.O. Width"
                    name="soWidth"
                    value={data.soWidth}
                    onChange={onChangeHandler("soWidth")}
                    // type="number"
                    // min="0"
                  />
                </FormInput>
                <FormInput label="S.O. Depth" required>
                  <InputField
                    placeholder="S.O. Depth"
                    name="soDepth"
                    value={data.soDepth}
                    onChange={onChangeHandler("soDepth")}
                    // type="number"
                    // min="0"
                  />
                </FormInput>

                <FormInput label="Acoustic Rating (dB)">
                  <InputField
                    placeholder="Acoustic Rating (dB)"
                    name="acousticRating"
                    value={data.acousticRating}
                    onChange={onChangeHandler("acousticRating")}
                    // type="number"
                    // min="0"
                  />
                </FormInput>
                {/* <FormInput label="Ironmongery Type/Number">
                  <InputField
                    placeholder="Ironmongery Type/Number"
                    name="ironmongery"
                    value={data.ironmongery}
                    onChange={onChangeHandler("ironmongery")}
                    type="text"
                  />
                </FormInput> */}
                <FormInput label="Set">
                  <SelectField
                    options={SET}
                    getOptionLabel="name"
                    getOptionValue="value"
                    onChangeValue={(item) => {
                      onChangeHandler("set", item?.value)();
                    }}
                    isSearchable
                    placeholder="Select Type"
                    defaultValue={SET.filter(
                      (element) => element.value === data.set,
                    )}
                  />
                </FormInput>

                <FormInput label="Acting">
                  <SelectField
                    options={ACTING}
                    getOptionLabel="name"
                    getOptionValue="value"
                    onChangeValue={(item) => {
                      onChangeHandler("acting", item?.value)();
                    }}
                    isSearchable
                    placeholder="Select Type"
                    defaultValue={ACTING.filter(
                      (element) => element.value === data.acting,
                    )}
                  />
                </FormInput>

                <FormInput label="Latched">
                  <SelectField
                    options={LATCHED}
                    getOptionLabel="name"
                    getOptionValue="value"
                    onChangeValue={(item) => {
                      onChangeHandler("latched", item?.value)();
                    }}
                    isSearchable
                    placeholder="Select Type"
                    defaultValue={LATCHED.filter(
                      (element) => element.value === data.latched,
                    )}
                  />
                </FormInput>

                <FormInput label="Ironmongery Pack">
                  <SelectField
                    options={ironmongeryPackFormat}
                    isLoading={ironmongeryPackLoader}
                    getOptionLabel="name"
                    getOptionValue="value"
                    onChangeValue={(item) => {
                      onChangeHandler("ironmongeryPack", item?.value)();
                    }}
                    isSearchable
                    placeholder="Select Pack"
                    defaultValue={ironmongeryPackFormat.filter(
                      (element) => element.value === data.ironmongeryPack,
                    )}
                  />
                </FormInput>
              </Grid.Container>
            </Box>

            <Box>
              <Grid.Container lg={4} md={2} sm={1}>
                <FormInput label="Overpanel / Fan Light ">
                  <Grid.Container lg={2} md={2} sm={1} flexBox jCenter alCenter>
                    <CheckBox
                      label="Yes"
                      name="yes"
                      check={check.yes}
                      handleCheckboxChange={toggleCheckBox}
                    />

                    <CheckBox
                      label="No"
                      name="no"
                      check={check.no}
                      handleCheckboxChange={toggleCheckBox}
                    />
                  </Grid.Container>
                </FormInput>

                <FormInput label="Side Lights">
                  <Grid.Container lg={2} md={2} sm={1} flexBox jCenter alCenter>
                    <CheckBox
                      label="1"
                      name="one"
                      check={sideLights.one}
                      handleCheckboxChange={toggleSideLights}
                    />

                    <CheckBox
                      label="2"
                      name="two"
                      check={sideLights.two}
                      handleCheckboxChange={toggleSideLights}
                    />
                  </Grid.Container>
                </FormInput>
              </Grid.Container>
            </Box>

            <ActivityIndicator animating={addViewerLoading}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Modal>
    </>
  );
};
