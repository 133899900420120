// GENERATES TEMPLATE PART
export const getTemplatePart = () => {
  return {
    name: "",
    install: [""],
    inspect: [""],
    details: [""],
    schedule: {
      unit: "month",
      duration: 10,
    },
  };
};

// GENERATES TEMPLATE
export const getTemplate = (template) => {
  if (!template) {
    return {
      name: "",
      // templateOf: "door",
      description: "",
      parts: [{ ...getTemplatePart() }],
    };
  }

  return {
    name: template.name,
    description: template.description,
    parts: template.parts,
  };
};

export function templateParser(template) {
  return getTemplate(template);
}
