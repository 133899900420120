import {
  CLIENT_SUBSCRIPTION,
  EXTEND_TRIAL,
  SUBSCRIPTION_HISTORY,
} from "../actions";

const initalState = {
  subscriptionHistoryLoading: false,
  subscriptionHistoryList: [],
  subscriptionHistoryTotalCount: 0,
  subscriptionHistoryIsLast: false,

  clientSubscription: null,
  clientSubscriptionLoader: false,

  extendTrialLoader: false,
};

export function subscriptionReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBSCRIPTION_HISTORY.LOADING:
      return {
        ...state,
        subscriptionHistoryLoading: true,
        subscriptionHistoryList: [],
      };
    case SUBSCRIPTION_HISTORY.SUCCESS:
      return {
        ...state,
        subscriptionHistoryLoading: false,
        subscriptionHistoryList: payload.rows,
        subscriptionHistoryTotalCount: payload.total,
      };
    case SUBSCRIPTION_HISTORY.ERROR:
      return {
        ...state,
        subscriptionHistoryLoading: false,
        subscriptionHistoryList: [],
      };

    case CLIENT_SUBSCRIPTION.LOADING:
      return {
        ...state,
        clientSubscriptionLoader: true,
        clientSubscription: null,
      };
    case CLIENT_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        clientSubscriptionLoader: false,
        clientSubscription: payload,
      };
    case CLIENT_SUBSCRIPTION.ERROR:
      return {
        ...state,
        clientSubscriptionLoader: false,
        clientSubscription: null,
      };

    case EXTEND_TRIAL.LOADING:
      return {
        ...state,
        extendTrialLoader: true,
      };
    case EXTEND_TRIAL.SUCCESS:
      return {
        ...state,
        extendTrialLoader: false,
      };
    case EXTEND_TRIAL.ERROR:
      return {
        ...state,
        extendTrialLoader: false,
      };

    default:
      return state;
  }
}
