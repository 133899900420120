/* eslint-disable no-multi-str */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import {
  MapContainer,
  ImageOverlay,
  Marker,
  useMapEvents,
  Popup,
} from "react-leaflet";
import { Modal } from "react-uicomp";

import { Box, DefaultButton, PrimaryButton } from "../../../../../common";
import { Title, Paragraph } from "../../../../../generics";
import { Card, ActivityIndicator } from "../../../../../hoc";

import {
  getFloorDetailAction,
  getDoorWithoutMap,
  getDoorWithMap,
  plotAction,
  FLOOR_DETAIL,
} from "../../../../../../actions";
import { FILE_URL } from "../../../../../../config";

import "../../../../../../libraries/zoomify.library";

export const MAP_WIDTH = 1920;
export const MAP_HEIGHT = 1080;
export const CENTER = [MAP_HEIGHT / 2, MAP_WIDTH / 2]; // Here latLng is (y,x);
export const BOUNDS = [
  [0, 0],
  [MAP_HEIGHT, MAP_WIDTH],
];

export const getXY = function (x, y) {
  return [MAP_HEIGHT - y, x]; // reverting y
};

const TileMap = ({
  floorDetail,
  onClick,
  doorMappedList,
  setSelectedMarker,
}) => {
  const mapRef = useRef(null);
  const layerRef = useRef(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    mapRef.current = L.map("map", {
      crs: L.CRS.Simple,
    });
    layerRef.current = L.layerGroup().addTo(mapRef.current);
  }, []);

  // setup leaflet tile layer
  useEffect(() => {
    const tileLayer = L.tileLayer
      .zoomify(`${FILE_URL}/floor/${floorDetail?.id}/fptiles/{z}/{x}/{y}.png`, {
        width: 30000,
        height: 30000,
      })
      .addTo(mapRef.current);

    mapRef.current.fitBounds(tileLayer.getBounds());
  }, [floorDetail]);

  useEffect(() => {
    mapRef.current.on("click", function ({ latlng: { lat, lng } }) {
      if (!isPopUpOpen) {
        onClick && onClick(lat, lng);
      }
    });
  }, []);

  useEffect(() => {
    mapRef.current.on("popupopen", function () {
      setIsPopUpOpen(true);
      const button = document.getElementById("unlinkbutton");
      button.onclick = function () {
        const id = this.getAttribute("data-id");

        setSelectedMarker(id);
      };
    });

    mapRef.current.on("popupclose", function () {
      setIsPopUpOpen(false);
    });
  }, [setSelectedMarker]);

  // markers
  useEffect(() => {
    if (doorMappedList) {
      layerRef.current.clearLayers(); // clear all layers of marker before adding new one

      doorMappedList.forEach((data) => {
        const { id, location, door_name, door_ident_no, fire_rating } = data;

        const popupContent =
          '<div class="floormap-title">' +
          door_name +
          '</div>\
          <div class="floormap-popup">\
            <div class="popup-info">\
              <div class="popup-info-title">Door Ident No.</div>\
              <div class="popup-info-data">' +
          door_ident_no +
          '</div>\
            </div>\
            <div class="popup-info">\
              <div class="popup-info-title">Fire Rating</div>\
              <div class="popup-info-data">' +
          fire_rating +
          '</div>\
            </div>\
          </div>\
          <button id="unlinkbutton" class="unlinkdoorbtn" data-id="' +
          id +
          '">Unlink Door</button>';
        L.marker(location).addTo(layerRef.current).bindPopup(popupContent);
      });
    }
  }, [doorMappedList]);

  return <div id="map" style={{ height: 780, zIndex: 1 }} />;
};

const FloorMapImageOverlay = ({
  floorDetail,
  doorMappedList,
  onClick,
  setSelectedMarker,
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useMapEvents({
    click({ latlng: { lat, lng } }) {
      if (!isPopUpOpen) {
        onClick && onClick(lat, lng);
      }
    },
    popupopen() {
      setIsPopUpOpen(true);
    },
    popupclose() {
      setIsPopUpOpen(false);
    },
  });

  return (
    <>
      {floorDetail && (
        <ImageOverlay
          url={`${FILE_URL}/floor/${floorDetail?.floor_map}`}
          bounds={BOUNDS}
        />
      )}

      {/* MARKERS */}
      {doorMappedList.map((data, index) => {
        const { id, location, door_name, door_ident_no, fire_rating } = data;

        return (
          <Marker key={index} position={location}>
            <Popup>
              <div className="floormap-title">{door_name}</div>

              <div className="floormap-popup">
                <div className="popup-info">
                  <div className="popup-info-title">Door Ident No.</div>
                  <div className="popup-info-data">{door_ident_no}</div>
                </div>

                <div className="popup-info">
                  <div className="popup-info-title">Fire Rating</div>
                  <div className="popup-info-data">{fire_rating}</div>
                </div>
              </div>

              <Box mt={10} flexBox jCenter>
                <Box>
                  <DefaultButton
                    style={{
                      background: "#ff4343",
                      color: "white",
                      marginRight: 10,
                      fontSize: 14,
                    }}
                    onClick={() => setSelectedMarker(id)}
                    title="Unlink Door"
                  />
                </Box>
              </Box>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
};

export const FloorMap = () => {
  const { toast } = useAuth();
  const { params } = useNavigation();
  const dispatch = useDispatch();

  // plot modal
  const [plotModal, setPlotModal] = useState(false); // Modal to add new marker with available door list
  const [selectedDoor, setSelectedDoor] = useState(null); // Is any door selected from select option in modal
  const [marker, setMarker] = useState({ x: null, y: null }); // Marker position to set
  const [selectedMarker, setSelectedMarker] = useState(null); // Any marker currently selected
  const [unlinkModal, setUnlinkModal] = useState(false); // Unlink door modal

  const { floorDetail, doorxFloorList, plotLoader } = useSelector(
    (state) => state.floor,
  );
  const { doorMappedList } = useSelector((state) => state.door);

  const refetch = useCallback(() => {
    dispatch(getFloorDetailAction(params?.id));
    dispatch(getDoorWithoutMap(params?.id));
    dispatch(getDoorWithMap(params?.id));
  }, [params.id, dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const plotHandler = () => {
    if (selectedDoor) {
      dispatch(
        plotAction(
          selectedDoor,
          {
            location: [marker?.x, marker?.y],
          },
          toast,
          callBack,
        ),
      );
    } else {
      toast({
        message: `Please select a door.`,
        type: "error",
      });
    }
  };

  const callBack = () => {
    refetch();

    setPlotModal(false);
    setSelectedDoor(null);
    setMarker({ x: null, y: null });
    setSelectedMarker(null);
  };

  const onClick = (x, y) => {
    setMarker({ x, y });
    setPlotModal(true);
  };

  const unlinkMarker = () => {
    dispatch(
      plotAction(
        selectedMarker,
        {
          location: null,
        },
        toast,
        callBack,
      ),
    );
  };

  useEffect(() => {
    if (selectedMarker !== null) {
      setUnlinkModal(true);
    }
  }, [selectedMarker]);

  const closeUnlinkModal = () => {
    setSelectedMarker(null);
    setUnlinkModal(false);
  };

  return (
    <Card containerStyle={{ padding: 0 }}>
      <div style={{ height: 780, overflow: "hidden" }}>
        {floorDetail && (
          <>
            {floorDetail.from_pdf === null ? (
              floorDetail.has_error ? (
                <div className="floor-map-message error">
                  Error! Can't process floor plan, please re-upload the floor
                  plan.
                </div>
              ) : (
                <div className="floor-map-message processing">
                  Floor plan is processing... please comeback after a while.
                </div>
              )
            ) : floorDetail.from_pdf === true ? (
              <TileMap
                {...{ floorDetail, doorMappedList, onClick, setSelectedMarker }}
              />
            ) : (
              <MapContainer
                center={CENTER}
                zoom={-1}
                minZoom={-5}
                bounds={BOUNDS}
                crs={L.CRS.Simple}
                attributionControl={false}
                zoomControl={true}
                style={{ height: "100%", width: "100%", zIndex: 1 }}>
                <FloorMapImageOverlay
                  {...{
                    floorDetail,
                    doorMappedList,
                    onClick,
                    setSelectedMarker,
                  }}
                />
              </MapContainer>
            )}
          </>
        )}
      </div>

      {/* Plot modal */}
      <Modal
        visible={plotModal}
        onOutsideClick={() => {
          setPlotModal(false);
        }}>
        <div style={{ maxHeight: 500 }}>
          <Box flexBox jSpace alCenter mb={20}>
            <Title>Link doors</Title>
            <ActivityIndicator animating={plotLoader}>
              <DefaultButton title="Link" onClick={() => plotHandler()} />
            </ActivityIndicator>
          </Box>
          <Box>
            {doorxFloorList.length ? (
              doorxFloorList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      borderBottom: "1px solid #e1e1e1",
                      marginBottom: 16,
                    }}>
                    <Box flexBox jSpace alCenter pb={10}>
                      <Paragraph key={index}>{item?.door_name}</Paragraph>
                      <input
                        type="checkbox"
                        checked={selectedDoor === item.id ? 1 : 0}
                        onChange={() => setSelectedDoor(item.id)}
                      />
                    </Box>
                  </div>
                );
              })
            ) : (
              <Paragraph>No doors found.</Paragraph>
            )}
          </Box>
        </div>
      </Modal>

      {/* Unlink Modal */}
      <Modal visible={unlinkModal} onOutsideClick={closeUnlinkModal}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Are you sure you want to unlink this door ?</h3>
          <ActivityIndicator animating={plotLoader}>
            <div
              style={{
                width: "100%",
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                float: "right",
              }}>
              <DefaultButton
                style={{
                  background: "#ff4343",
                  color: "white",
                  marginRight: 10,
                }}
                onClick={() => {
                  unlinkMarker();
                  setUnlinkModal(false);
                }}
                title="Unlink"
              />
              <PrimaryButton onClick={closeUnlinkModal} title="Cancel" />
            </div>
          </ActivityIndicator>
        </div>
      </Modal>
    </Card>
  );
};
