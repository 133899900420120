import { api, APIS, TABLE_LIMIT } from "../config";
import { GET_ALL_COMPLAINS, GET_COMPLAIN } from ".";

export function getAllComplains(page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ALL_COMPLAINS.LOADING });
      res = await api(`${APIS.complain}?page=${page}&limit=${TABLE_LIMIT}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_ALL_COMPLAINS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_ALL_COMPLAINS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ALL_COMPLAINS.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getComplain(complainId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_COMPLAIN.LOADING });
      res = await api(`${APIS.complain}/${complainId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_COMPLAIN.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_COMPLAIN.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_COMPLAIN.ERROR });
      console.error(message);
      return 0;
    }
  };
}
