import { api, APIS, TABLE_LIMIT } from "../config";
import {
  ADD_IRONMONGERY_PACKS,
  GET_IRONMONGERY_PACKS,
  GET_IRONMONGERY_PACK_DETAIL,
  EDIT_IRONMONGERY_PACKS,
  DELETE_IRONMONGERY_PACKS,
} from "./ActionTypes.action";

export function addIronmongeryPack(body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_IRONMONGERY_PACKS.LOADING });
      res = await api(`${APIS.ironmongeryGroup}`, "POST", body);

      const { success, data, message } = res.data;
      if (success) {
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch({
          type: ADD_IRONMONGERY_PACKS.SUCCESS,
          payload: data.data,
        });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: ADD_IRONMONGERY_PACKS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_IRONMONGERY_PACKS.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function editIronmongeryPack(id, body, updatedList, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_IRONMONGERY_PACKS.LOADING });

      function doSomethingAsync(id, partName, productId) {
        return new Promise(async (resolve, reject) => {
          try {
            let res = await api(
              `${APIS.ironmongeryGroup}/item/${id}`,
              "PATCH",
              { partName, productId },
            );
            console.log("res>>>>", res);
            const { success, message } = res.data;
            if (success) {
              resolve();
            } else {
              toast({
                message: message,
                type: "error",
              });
            }
          } catch (e) {
            console.log(e);
            reject();
          }
        });
      }

      const promises = [];

      updatedList.forEach(({ id, partName, productId }) => {
        promises.push(doSomethingAsync(id, partName, productId));
      });

      Promise.all(promises)
        .then(async () => {
          res = await api(`${APIS.ironmongeryGroup}/${id}`, "PATCH", body);

          const { success, data, message } = res.data;
          if (success) {
            toast({
              message: data.message,
              type: "success",
            });
            callback && callback();
            dispatch({
              type: EDIT_IRONMONGERY_PACKS.SUCCESS,
              payload: data.data,
            });
          } else {
            toast({
              message: message,
              type: "error",
            });
            dispatch({ type: EDIT_IRONMONGERY_PACKS.ERROR });
          }
        })
        .catch((e) => {
          // Handle errors here
        });
    } catch ({ message }) {
      dispatch({ type: EDIT_IRONMONGERY_PACKS.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getIronmongeryPackList(page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_IRONMONGERY_PACKS.LOADING });
      res = await api(`${APIS.ironmongeryGroup}/all`);

      const { success, data } = res.data;

      if (success) {
        dispatch({
          type: GET_IRONMONGERY_PACKS.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: GET_IRONMONGERY_PACKS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_IRONMONGERY_PACKS.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function getIronmongeryPackDetail(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_IRONMONGERY_PACK_DETAIL.LOADING });
      res = await api(`${APIS.ironmongeryGroup}/${id}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({
          type: GET_IRONMONGERY_PACK_DETAIL.SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({ type: GET_IRONMONGERY_PACK_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_IRONMONGERY_PACK_DETAIL.ERROR });

      console.error(message);
      return 0;
    }
  };
}

export function deleteIronmongeryPack(id, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_IRONMONGERY_PACKS.LOADING });
      res = await api(`${APIS.ironmongeryGroup}/${id}`, "DELETE");

      const { success, data, message } = res.data;
      console.log("delete", res);
      if (success) {
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch({
          type: DELETE_IRONMONGERY_PACKS.SUCCESS,
          payload: id,
        });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: DELETE_IRONMONGERY_PACKS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_IRONMONGERY_PACKS.ERROR });

      console.error(message);
      return 0;
    }
  };
}
