import {
  ADD_DOOR,
  GET_DOOR_LIST,
  ADD_BATCH_DOOR,
  EDIT_DOOR,
  DOOR_DETAIL,
  DOORMAPPED,
  getDoorWithoutMap,
} from ".";
import { api, APIS, TABLE_LIMIT } from "../config";
import {
  DELETE_DOOR,
  DOOR_TRASH_LIST,
  GET_DOOR_LIST_APPEND,
  GET_DOOR_LOGS,
  PLOT,
  RESTORE_DOOR_TRASH,
} from "./ActionTypes.action";

export function postDoorAction(body, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_DOOR.LOADING });
      res = await api(APIS.door, "POST", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: ADD_DOOR.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: ADD_DOOR.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_DOOR.ERROR });
      toast({
        message: `Image upload Failed!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function editDoorAction(DoorId, body, callback, toast) {
  return async function (dispatch) {
    console.log("edit");
    let res;
    try {
      dispatch({ type: EDIT_DOOR.LOADING });
      res = await api(`${APIS.door}/${DoorId}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: EDIT_DOOR.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: EDIT_DOOR.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_DOOR.ERROR });
      toast({
        message: `Image upload Failed!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function getDoorList(floorId, page = 1) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: GET_DOOR_LIST.LOADING });

      res = await api(
        `${APIS.door}/floor/${floorId}?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_DOOR_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_DOOR_LIST.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: GET_DOOR_LIST.ERROR });
      return 0;
    }
  };
}

export function getDoorListLoadMore(floorId, page = 1) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: GET_DOOR_LIST_APPEND.LOADING });

      res = await api(
        `${APIS.door}/floor/${floorId}?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (page === 1) {
        dispatch({ type: GET_DOOR_LIST_APPEND.CLEAR });
      }

      if (success) {
        dispatch({ type: GET_DOOR_LIST_APPEND.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_DOOR_LIST_APPEND.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: GET_DOOR_LIST_APPEND.ERROR });
      return 0;
    }
  };
}

export function getDoorDetailAction(id = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DOOR_DETAIL.LOADING });
      res = await api(`${APIS.door}/${id}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DOOR_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: DOOR_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DOOR_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getDoorWithMap(DoorId) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: DOORMAPPED.LOADING });

      res = await api(`${APIS.door}/floor/map/${DoorId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DOORMAPPED.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: DOORMAPPED.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: DOORMAPPED.ERROR });
      return 0;
    }
  };
}

export function plotAction(body, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: PLOT.LOADING });
      res = await api(`${APIS.door}/10`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: PLOT.SUCCESS });
        // dispatch(getDoorPlan());
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: PLOT.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: PLOT.ERROR });
      toast({
        message: `Plot Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function deleteDoorAction(id, toast, floor, page, callback) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: DELETE_DOOR.LOADING });

      res = await api(`${APIS.door}/delete/${id}`, "PATCH");

      const { success } = res.data;

      if (success) {
        dispatch({ type: DELETE_DOOR.SUCCESS });
        dispatch({ type: GET_DOOR_LIST_APPEND.CLEAR });
        dispatch(getDoorList(floor, page));
        dispatch(getDoorListLoadMore(floor, page));

        dispatch(getDoorWithMap(floor));
        dispatch(getDoorWithoutMap(floor));
        toast({
          message: "Successfully deleted door",
          type: "success",
        });

        !!callback && callback();
      } else {
        dispatch({ type: DELETE_DOOR.ERROR });
        toast({
          message: "Error deleting door",
          type: "error",
        });
        !!callback && callback();
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: DELETE_DOOR.ERROR });
      toast({
        message: "Error deleting door",
        type: "error",
      });
      return 0;
    }
  };
}

export function postBatchDoorAction(body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_BATCH_DOOR.LOADING });
      res = await api(`${APIS.door}/batch`, "POST", body);

      const { success, data } = res.data;
      console.log("door batch door data", data);
      if (success) {
        dispatch({ type: ADD_BATCH_DOOR.SUCCESS });
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
      } else {
        dispatch({ type: ADD_BATCH_DOOR.ERROR });
        toast({
          message:
            "Batch Upload Failed!! Please Check if all the fields are mapped correctly or not. And Also make sure to follow the instructions wile filling up the excel sheet",
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_BATCH_DOOR.ERROR });
      toast({
        message: `Batch upload Failed!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

// ACTIVITY LOG OF DOOR
export function getLogOfDoor(doorId, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_DOOR_LOGS.LOADING });

      if (page === 1) {
        dispatch({ type: GET_DOOR_LOGS.CLEAR });
      }

      res = await api(
        `${APIS.activity}/door/${doorId}?page=${page}&limit=${TABLE_LIMIT}`,
      );
      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_DOOR_LOGS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_DOOR_LOGS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_DOOR_LOGS.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getDoorTrashList(id, page = 1) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: DOOR_TRASH_LIST.LOADING });

      res = await api(
        `${APIS.door}/floor/deleted/${id}?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DOOR_TRASH_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: DOOR_TRASH_LIST.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: DOOR_TRASH_LIST.ERROR });
      return 0;
    }
  };
}

export function restoreDoorTrashAction(id, toast, callback) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: RESTORE_DOOR_TRASH.LOADING });
      res = await api(`${APIS.door}/restore/${id}`, "PATCH");

      const { success, data, message } = res.data;

      if (success) {
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
        dispatch({ type: RESTORE_DOOR_TRASH.SUCCESS, payload: id });
      } else {
        toast({
          message: data.message,
          type: "error",
        });
        dispatch({ type: RESTORE_DOOR_TRASH.ERROR });
      }

      return 1;
    } catch ({ message }) {
      toast({
        message: "Restoring Failed",
        type: "error",
      });
      dispatch({ type: RESTORE_DOOR_TRASH.ERROR });
      return 0;
    }
  };
}
