import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";

import { useDocTitle } from "../../../../hooks/useDocTitle.hook";
import {
  capitalizeFirstLetter,
  isValid,
  useInput,
  validator,
} from "../../../../utils";

import { ActivityIndicator, Card, CompWrapper } from "../../../hoc";
import {
  Box,
  DefaultButton,
  DisplayFileName,
  FormInput,
  GooglePlaces,
  Grid,
  ImageUpload,
  InputField,
  SelectField,
  TextArea,
} from "../../../common";
import {
  addBuildingAction,
  addIronmongeryProduct,
  getAllIronmongeryCategory,
} from "../../../../actions";
import UPLOAD from "../../../../assets/icons/upload.png";
import { Image } from "../../../generics";
import { RiCloseCircleFill } from "react-icons/ri";

export const AddIronmongery = () => {
  const { toast } = useAuth();
  const { navigation, history } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();

  const { categorylist, addProductLoader } = useSelector(
    (state) => state.ironmongery,
  );

  useDocTitle("Add Ironmongery");
  const { data, onChangeHandler } = useInput({
    name: "",
    brand: "",
    description: "",
    categoryId: "",
  });

  const [productImage, setProductImage] = React.useState([]);
  const [specFiles, setSpecFiles] = React.useState([]);
  const [dataFiles, setDataFiles] = React.useState([]);

  useEffect(() => {
    dispatch(getAllIronmongeryCategory());
  }, []);
  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { name, brand, categoryId, description } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("name", name.length === 0, () => {
      showMessage("Name can't be empty!");
    });

    validate("brand", brand.length === 0, () => {
      showMessage("brand can't be empty!");
    });

    validate("description", description.length === 0, () => {
      showMessage("Description can't be empty!");
    });

    let formData = new FormData();
    await formData.append("name", capitalizeFirstLetter(name));
    await formData.append("categoryId", categoryId);
    await formData.append("brand", capitalizeFirstLetter(brand));
    await formData.append("description", capitalizeFirstLetter(description));

    productImage.length > 0 &&
      productImage.forEach(async (element) => {
        await formData.append("imageFile", element.file);
      });

    specFiles.length > 0 &&
      specFiles.forEach(async (element) => {
        await formData.append("specFile", element.file);
      });

    dataFiles.length > 0 &&
      dataFiles.forEach(async (element) => {
        await formData.append("dataFile", element.file);
      });

    dispatch(
      addIronmongeryProduct(formData, toast, () => {
        history.goBack();
      }),
    );

    if (!isValid(catchedErrors)) {
      return;
    }
  };

  return (
    <CompWrapper>
      <Card>
        <Box>
          <form onSubmit={submitHandler}>
            <FormInput label="Name" required>
              <InputField
                placeholder="Name"
                name="name"
                value={data.name}
                onChange={onChangeHandler("name")}
                type="text"
              />
            </FormInput>

            <FormInput label="Brand" required>
              <InputField
                placeholder="Brand"
                name="brand"
                value={data.brand}
                onChange={onChangeHandler("brand")}
                type="text"
              />
            </FormInput>

            <FormInput label="Ironmongery Category" required>
              <SelectField
                options={categorylist}
                getOptionValue="id"
                getOptionLabel="name"
                onChangeValue={(item) => {
                  onChangeHandler("categoryId", item?.id)();
                }}
              />
            </FormInput>

            <FormInput label="Description" required>
              <TextArea
                placeholder="Description"
                name="description"
                value={data.description}
                onChange={onChangeHandler("description")}
                type="text"
              />
            </FormInput>

            <FormInput label="Product Image" required>
              <ImageUpload
                onChange={setProductImage}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                accept="image/*"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={4} md={2} sm={1}>
                      {imageData &&
                        imageData.map((item, index) => (
                          <div key={index}>
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                                boxShadow: "0px 2px 10px 0px #888888",
                              }}>
                              <Image
                                alt="Avatar"
                                link={item.url}
                                style={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "contain",
                                  overflow: "hidden",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  cursor: "pointer",
                                }}
                                onClick={() => onRemove(index)}>
                                <RiCloseCircleFill color="red" size={25} />
                              </div>
                            </div>
                          </div>
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>

            <FormInput label="Specifications" required>
              <ImageUpload
                onChange={setSpecFiles}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                multiple
                accept="image/*"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={4} md={2} sm={1}>
                      {imageData &&
                        imageData.map((item, index) => (
                          <DisplayFileName
                            key={index}
                            label={item?.file?.name}
                            onClick={() => onRemove(index)}
                          />
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>

            <FormInput label="Technical data files" required>
              <ImageUpload
                onChange={setDataFiles}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                multiple
                accept="image/*"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={4} md={2} sm={1}>
                      {imageData &&
                        imageData.map((item, index) => (
                          <DisplayFileName
                            key={index}
                            label={item?.file?.name}
                            onClick={() => onRemove(index)}
                          />
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>

            <ActivityIndicator animating={addProductLoader}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Card>
    </CompWrapper>
  );
};
