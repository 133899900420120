import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { Card, CompWrapper } from "../../../hoc";
import {
  Box,
  DefaultButton,
  DisplayFileName,
  FormInput,
  Grid,
  ImageUpload,
  InputField,
  SelectField,
  TextArea,
} from "../../../common";
import { Image, Title } from "../../../generics";
import { FaPlus, RiCloseCircleFill } from "react-icons/all";
import { isValid, useInput, validator } from "../../../../utils";
import moment from "moment";
import {
  addJobAction,
  getAllBuildingListAction,
  getAllFloorList,
  getBuildingListAction,
  getWorkerAction,
} from "../../../../actions";
import { InspectionPart } from "../../building/jobsPage/component";

import UPLOAD from "../../../../assets/icons/upload.png";

const JOB_TYPE = [
  { name: "INSTALLATION", value: "INSTALLATION" },
  { name: "INSPECTION", value: "INSPECTION" },
];

export const CreateJob = ({ defaultData }) => {
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  const {
    schedule_details,
    floor_details,
    site_details,
    door_details,
    door_part_details,
  } = defaultData;
  const dispatch = useDispatch();

  const { allList, allListloading } = useSelector((state) => state.building);

  const { userWorkerList } = useSelector((state) => state.user);

  const [inspectionfloorNdoor, setInspectionFloorNdoor] = React.useState([
    {
      floor: floor_details.id,
      doorNpart: [
        {
          door: door_details.id,
          part: [door_part_details.id],
        },
      ],
    },
  ]);

  const [image, setImage] = React.useState([]);

  const [doorArray, setDoorArray] = useState([]);

  const [inspectionPartArray, setInspectionPartArray] = useState([]);

  const { data, onChangeHandler } = useInput({
    siteId: site_details.id,
    assignedTo: "",
    jobDescription: "",
    startDate: "",
    jobType: "INSPECTION",
    scope: "",
    limitations: "",
    priorities: "",
  });

  useEffect(() => {
    dispatch(getAllBuildingListAction());
  }, []);

  useEffect(() => {
    dispatch(getWorkerAction());
  }, []);

  useEffect(() => {
    !!data.siteId && dispatch(getAllFloorList(data.siteId));
  }, [data.siteId]);

  useEffect(() => {
    if (inspectionfloorNdoor.length > 0) {
      const newArray = [];
      const doorArray = [];
      inspectionfloorNdoor.forEach(({ doorNpart }) => {
        doorNpart.map(({ part, door }) => {
          !!door && doorArray.push(door);
          newArray.push(...part);
        });
      });
      setDoorArray(makeUniqueArray(doorArray));
      setInspectionPartArray(makeUniqueArray(newArray));
    }
  }, [inspectionfloorNdoor]);

  const makeUniqueArray = (array) => {
    return [...new Set(array)];
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const {
      siteId,
      assignedTo,
      jobDescription,
      startDate,
      jobType,
      scope,
      limitations,
      priorities,
    } = data;
    console.log("haha", data);
    const showMessage = (message) => {
      toast({ message, type: "error" });
    };

    validate("assignedTo", assignedTo.length === 0, () => {
      showMessage("Worker musn't be empty!");
    });

    validate("startDate", startDate.length === 0, () => {
      showMessage("Start Date musn't be empty!");
    });

    validate("jobDescription", jobDescription.length === 0, () => {
      showMessage("Job Description musn't be empty!");
    });

    // validate("scope", scope.length === 0, () => {
    //   showMessage("Scope musn't be empty!");
    // });
    // validate("limitations", limitations.length === 0, () => {
    //   showMessage("Limitations musn't be empty!");
    // });
    // validate("priorities", priorities.length === 0, () => {
    //   showMessage("Priorities musn't be empty!");
    // });

    if (!isValid(catchedErrors)) {
      return;
    }

    let formData = new FormData();

    await formData.append("assignedTo", `[${assignedTo.join(",")}]`);
    await formData.append("jobType", jobType);
    await formData.append("siteId", siteId);
    await formData.append("jobDescription", jobDescription);

    !!scope && (await formData.append("scope", scope));
    !!limitations && (await formData.append("limitation", limitations));
    !!priorities && (await formData.append("recommendation", priorities));

    await formData.append("startTime", moment.utc(startDate).format());
    image.length > 0 &&
      image.forEach(async (element) => {
        await formData.append("pdfImgfile", element.file);
      });

    doorArray.length > 0 &&
      (await formData.append("doorIdList", `[${doorArray.join(",")}]`));

    jobType === "INSPECTION" &&
      inspectionPartArray.length > 0 &&
      (await formData.append(
        "partIdList",
        `[${inspectionPartArray.join(",")}]`,
      ));

    // FOR INTEGRATION
    dispatch(
      addJobAction(
        formData,
        () => {
          navigate(routes["Schedule"].path);
        },
        toast,
      ),
    );
  };

  const changeImage = (data) => {
    setImage(data);
  };

  return (
    <Card>
      <Box>
        <form onSubmit={submitHandler}>
          <FormInput label=" Select Building" required>
            <SelectField
              options={allList}
              getOptionLabel="prop_name"
              getOptionValue="id"
              onChangeValue={(item) => {
                onChangeHandler("siteId", item?.id)();
              }}
              isSearchable
              isLoading={allListloading}
              placeholder="Select building"
              value={allList.filter((item) => item.id === site_details.id)}
              isOptionDisabled={(option) => option.id !== site_details.id}
            />
          </FormInput>

          <FormInput label="Job Type" required>
            <SelectField
              options={JOB_TYPE}
              getOptionLabel="name"
              getOptionValue="value"
              onChangeValue={(item) => {
                onChangeHandler("jobType", item?.value)();
              }}
              isSearchable
              placeholder="Select Type"
              defaultValue={JOB_TYPE.filter(
                (element) => element.value === "INSPECTION",
              )}
              isOptionDisabled={(option) => option.value !== "INSPECTION"}
            />
          </FormInput>

          <Box flexBox alCenter jSpace>
            <Title>Floor</Title>
            {/* <FaPlus onClick={onInspectionAdd} /> */}
          </Box>
          {inspectionfloorNdoor.map((_, index) => {
            return (
              <Box key={index} flexBox alCenter>
                <Box flex={1}>
                  <InspectionPart
                    inspectionfloorNdoor={inspectionfloorNdoor}
                    index={index}
                    setInspectionFloorNdoor={setInspectionFloorNdoor}
                    // onCloseFloor={() => onInspectionClose(index)}
                    disableDoorAddition
                    isFloorOptionDisabled={(option) =>
                      option.id !== floor_details.id
                    }
                    isDoorOptionDisabled={(option) =>
                      option.id !== door_details.id
                    }
                    isPartOptionDisabled={(option) =>
                      option.id !== door_part_details.id
                    }
                    inspectionPartArray={inspectionPartArray}
                  />
                </Box>
              </Box>
            );
          })}

          <FormInput label=" Select User" required>
            <SelectField
              isMulti
              options={userWorkerList}
              getOptionLabel="full_name"
              getOptionValue="id"
              onChangeValue={(item) => {
                const newValue = item?.map((val) => {
                  return val.id;
                });

                onChangeHandler("assignedTo", [...newValue])();
              }}
              isSearchable
              placeholder="Select Worker"
            />
          </FormInput>

          <FormInput label="Start Date" required>
            <InputField
              placeholder="start Date"
              name="startDate"
              value={data.startDate}
              onChange={onChangeHandler("startDate")}
              type="datetime-local"
            />
          </FormInput>

          <FormInput label="Job Description" required>
            <TextArea
              placeholder="Job Description"
              name="jobDescription"
              value={data.jobDescription}
              onChange={onChangeHandler("jobDescription")}
              //   type="number"
            />
          </FormInput>

          <FormInput label="Scope and Purpose of the Fire Door Inspection Report">
            <TextArea
              placeholder="Scope and Purpose of the Fire Door Inspection Report"
              name="scope"
              value={data.scope}
              onChange={onChangeHandler("scope")}
            />
          </FormInput>
          <FormInput label="Limitations of the Fire Door Assessment">
            <TextArea
              placeholder="Limitations of the Fire Door Assessment"
              name="limitations"
              value={data.limitations}
              onChange={onChangeHandler("limitations")}
            />
          </FormInput>
          <FormInput label="Prioritisation of Recommendations and Remedial Works">
            <TextArea
              placeholder="Prioritisation of Recommendations and Remedial Works"
              name="priorities"
              value={data.priorities}
              onChange={onChangeHandler("priorities")}
            />
          </FormInput>
          <FormInput label="Attachment">
            <ImageUpload
              onChange={changeImage}
              buttonStyle={{ background: "red", display: "inline-block" }}
              title="upload image"
              multiple
              buttonclick={
                <Box mt={20}>
                  <div
                    style={{
                      display: "inline-block",
                      border: "1px dashed #000",
                      padding: 50,
                      cursor: "pointer",
                      overflow: "hidden",
                    }}>
                    <Image
                      alt="Avatar"
                      link={UPLOAD}
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                </Box>
              }>
              {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                <Box mb={10}>
                  <Grid.Container lg={4} md={2} sm={1}>
                    {imageData &&
                      imageData.map((item, index) => (
                        <DisplayFileName
                          key={index}
                          label={item?.file?.name}
                          onClick={() => onRemove(index)}
                        />
                      ))}
                  </Grid.Container>
                </Box>
              )}
            </ImageUpload>
          </FormInput>

          <DefaultButton title="Submit" type="submit" />
        </form>
      </Box>
    </Card>
  );
};

export const CreateJobFromSchedulePage = () => {
  const { navigation, location } = useNavigation();
  const { navigate, routes } = navigation;
  !!location.state == false && navigate(routes["Schedule"].path);
  return (
    <CompWrapper>
      {!!location.state ? (
        <CreateJob defaultData={location.state} />
      ) : (
        <div>loading</div>
      )}
    </CompWrapper>
  );
};
