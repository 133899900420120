import { api, APIS, TABLE_LIMIT } from "../config";
import {
  ADD_TIER,
  GET_ALL_TIER,
  GET_TIER_DETAIL,
  EDIT_TIER,
  DELETE_TIER,
} from ".";
import { templateParser } from "../utils";

export function getAllTierList(page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ALL_TIER.LOADING });
      res = await api(`${APIS.tier}?page=${page}&limit=${TABLE_LIMIT}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_ALL_TIER.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_ALL_TIER.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ALL_TIER.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addTierAction(body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_TIER.LOADING });
      res = await api(APIS.tier, "POST", body);

      const { success, data, message } = res.data;

      if (success) {
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
        dispatch({ type: ADD_TIER.SUCCESS, payload: data.data });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: ADD_TIER.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_TIER.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editTierAction(id, body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_TIER.LOADING });
      res = await api(`${APIS.tier}/${id}`, "PATCH", body);

      const { success, data, message } = res.data;

      if (success) {
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
        dispatch({ type: EDIT_TIER.SUCCESS, payload: data.data });
      } else {
        toast({
          message: message,
          type: "error",
        });
        dispatch({ type: EDIT_TIER.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_TIER.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getTierById(tierId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_TIER_DETAIL.LOADING });
      res = await api(`${APIS.tier}/${tierId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_TIER_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_TIER_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_TIER_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

// export function addTemplate(body, toast) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: ADD_TEMPLATE.LOADING });
//       res = await api(APIS.template, "POST", body);

//       const { success, data, message } = res.data;

//       if (success) {
//         dispatch({ type: ADD_TEMPLATE.SUCCESS });
//         !!toast &&
//           toast({
//             message: data.message,
//             type: "success",
//           });
//       } else {
//         dispatch({ type: ADD_TEMPLATE.ERROR });
//         !!toast &&
//           toast({
//             message: message,
//             type: "error",
//           });
//       }
//     } catch ({ message }) {
//       dispatch({ type: ADD_TEMPLATE.ERROR });
//       !!toast &&
//         toast({
//           message: `Adding template failed!!`,
//           type: "error",
//         });
//       console.error(message);
//       return 0;
//     }
//   };
// }

// export function editTemplate(templateId, body, toast, callback) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: EDIT_TEMPLATE.LOADING });

//       res = await api(`${APIS.template}/${templateId}`, "PATCH", body);

//       const { success, data, message } = res.data;

//       if (success) {
//         dispatch({ type: EDIT_TEMPLATE.SUCCESS });
//         !!toast &&
//           toast({
//             message: data.message,
//             type: "success",
//           });
//         callback && callback();
//       } else {
//         dispatch({ type: EDIT_TEMPLATE.ERROR });
//         !!toast &&
//           toast({
//             message: message,
//             type: "error",
//           });
//       }
//     } catch ({ message }) {
//       dispatch({ type: EDIT_TEMPLATE.ERROR });
//       console.error(message);
//       return 0;
//     }
//   };
// }

// export function deleteTemplateAction(id, toast, pageNumber) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: DELETE_TEMPLATE.LOADING });
//       res = await api(`${APIS.template}/delete/${id}`, "PATCH");

//       const { success, data, message } = res.data;

//       if (success) {
//         dispatch({ type: DELETE_TEMPLATE.SUCCESS });
//         dispatch(getAllTemplate(pageNumber));
//         toast({
//           message: data.message,
//           type: "success",
//         });
//       } else {
//         dispatch({ type: DELETE_TEMPLATE.ERROR });
//         toast({
//           message: message,
//           type: "error",
//         });
//       }
//     } catch ({ message }) {
//       dispatch({ type: DELETE_TEMPLATE.ERROR });
//       toast({
//         message: `DELETING Failed`,
//         type: "error",
//       });
//       console.error(message);
//       return 0;
//     }
//   };
// }
