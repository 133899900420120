import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { PieChart, Pie, Cell } from "recharts";
import { Box, Grid, PrimaryButton } from "../../../common";
import { Title, Heading, Paragraph, Bold } from "../../../generics";
import { CompWrapper, Card, ActivityIndicator } from "../../../hoc";
import {
  FaBuilding,
  FaUsers,
  FaUserPlus,
  AiFillFileAdd,
} from "react-icons/all";

import {
  capitalizeFirstLetter,
  isValid,
  useInput,
  validator,
} from "../../../../utils";
import { useDocTitle } from "../../../../hooks";
import {
  editUserInfoAction,
  getClientDashboard,
  getUserAction,
} from "../../../../actions";

const pieData = [
  { name: "Group A", value: 151 },
  { name: "Group B", value: 211 },
  { name: "Group C", value: 41 },
];
const COLORS = ["#ff4343", "#ffc107", "#28a745"];

export const DashboardCard = ({ data }) => {
  const { job_type, progress, scheduled, completed, total, overdue } = data;
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  return (
    <Card>
      <Title style={{ fontSize: 20 }} className="dashboard-card-title">
        {capitalizeFirstLetter(job_type.toLowerCase())}
      </Title>
      <Box mt={20}>
        <Box flexBox alCenter className="dashboard-job-status">
          <Box className="icon red"></Box>
          <Box ml={10} flex={1} flexBox alCenter jSpace>
            <Paragraph
              style={{ cursor: !!progress ? "pointer" : "default" }}
              onClick={() =>
                !!progress &&
                navigate(
                  routes["List Jobs"].path +
                    `?job_status=false&job_type=${job_type}`,
                )
              }>
              Live/Open
            </Paragraph>
            <Paragraph ml={20} className="lighttext">
              {progress.toString()}
            </Paragraph>
          </Box>
        </Box>
        <Box mt={10} flexBox alCenter className="dashboard-job-status">
          <Box className="icon yellow"></Box>
          <Box ml={10} flex={1} flexBox alCenter jSpace>
            <Paragraph
              style={{ cursor: !!scheduled ? "pointer" : "default" }}
              onClick={() =>
                !!scheduled &&
                navigate(
                  routes["List Jobs"].path +
                    `?job_status=null&job_type=${job_type}`,
                )
              }>
              Scheduled
            </Paragraph>
            <Paragraph ml={20} className="lighttext">
              {scheduled.toString()}
            </Paragraph>
          </Box>
        </Box>
        <Box mt={10} flexBox alCenter className="dashboard-job-status">
          <Box className="icon green"></Box>
          <Box ml={10} flex={1} flexBox alCenter jSpace>
            <Paragraph
              style={{ cursor: !!completed ? "pointer" : "default" }}
              onClick={() =>
                !!completed &&
                navigate(
                  routes["List Jobs"].path +
                    `?job_status=true&job_type=${job_type}`,
                )
              }>
              Completed
            </Paragraph>
            <Paragraph ml={20} className="lighttext">
              {completed.toString()}
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <Box className="dashboard-job-numbers" mt={20} flexBox alCenter jSpace>
        <Box flexBox alCenter>
          <Box flexBox vertical alCenter jSpace>
            <h1>{total}</h1>
            <p>Jobs</p>
          </Box>
          <Box ml={30} flexBox vertical alCenter jSpace>
            <h1 className="lighttext">{overdue}</h1>
            <p className="lighttext">Overdue</p>
          </Box>
        </Box>
        <Box>
          <PieChart width={160} height={160}>
            <Pie
              data={[
                { name: "Live/Open", value: progress },
                { name: "Scheduled", value: scheduled },
                { name: "Completed", value: completed },
              ]}
              innerRadius={60}
              outerRadius={70}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value">
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </Box>
      </Box>
    </Card>
  );
};

export const ClientDashboard = () => {
  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { toast } = useAuth();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const client = useSelector((state) => state.client);
  const { data: userData } = user?.user;
  const { dashboardListloading, dashboardList } = client;
  const { profile_details, user_details, company_details } = userData;
  useDocTitle("Client");

  const [showEditInfo, setShowEditInfo] = React.useState(false);
  const [locationName, setLocationName] = React.useState(
    profile_details.profile_address.value,
  );
  const [phone, setPhone] = React.useState(profile_details.profile_phone);

  const { data, onChangeHandler } = useInput({
    firstName: profile_details.profile_first_name ?? "default",
    lastName: profile_details.profile_last_name ?? "default",
    email: user_details.email ?? "default",
    jobTitle: profile_details.profile_job_title ?? "default",
  });

  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, firstName, lastName, jobTitle } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("firstName", firstName.length === 0, () => {
      showMessage("First Name musn't be empty!");
    });
    validate("lastName", lastName.length === 0, () => {
      showMessage("Last Name musn't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("Email musn't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("Address musn't be empty!");
    });
    validate("jobTitle", jobTitle.length === 0, () => {
      showMessage("Job Title musn't be empty!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    const body = {
      firstName: firstName,
      lastName: lastName,
      profileAddress: { coordinates: [1, 1], value: locationName },
      profilePhone: phone,
      profileJobTitle: jobTitle,
    };

    dispatch(
      editUserInfoAction(
        body,
        () => {
          setShowEditInfo(false);

          dispatch(getUserAction());
        },
        toast,
      ),
    );
  };

  const changeHandle = (index) => {
    return function (e) {
      const phoneArray = [...phone];
      phoneArray[index] = e.target.value;
      setPhone(phoneArray);
    };
  };

  const onAdd = () => {
    setPhone([...phone, ""]);
  };

  const close = (index) => {
    const phoneClone = [...phone];

    phoneClone.splice(index, 1);
    setPhone(phoneClone);
  };

  useEffect(() => {
    dispatch(getClientDashboard());
  }, []);

  const QUICKLINKS = [
    {
      name: "Buildings",
      icon: <FaBuilding />,
      navigate: () => {
        navigate(routes["List Buildings"].path);
      },
    },
    {
      name: "Add Building",
      icon: <AiFillFileAdd />,
      navigate: () => {
        navigate(routes["Add Building"].path);
      },
    },
    {
      name: "Employees/Users",
      icon: <FaUsers />,
      navigate: () => {
        navigate(routes["Users"].path);
      },
    },
    {
      name: "Add Employee",
      icon: <FaUserPlus />,
      navigate: () => {
        navigate(routes["Users"].path + `?user_tab=1&open_modal=true`);
      },
    },
  ];

  return (
    <CompWrapper>
      <ActivityIndicator animating={dashboardListloading}>
        <Box>
          <Box flexBox alCenter jSpace>
            <Heading>Jobs</Heading>
            <PrimaryButton
              title="Add Job"
              onClick={() => {
                navigate(routes["Add Job"].path);
              }}
            />
          </Box>
          <Box mt={20}>
            <Grid.Container lg={3} md={2} sm={1}>
              {dashboardList.map((item, index) => {
                return <DashboardCard key={index} data={item} />;
              })}
            </Grid.Container>
          </Box>
        </Box>

        <Box mt={30}>
          <Heading mb={10}>Quick Links</Heading>

          <Grid.Container lg={4} md={3} sm={2}>
            {QUICKLINKS.map((item, index) => {
              return (
                <Card
                  containerStyle={{ cursor: "pointer", padding: 0 }}
                  key={index}>
                  <Box
                    pt={40}
                    pb={40}
                    p={20}
                    flexBox
                    vertical
                    alCenter
                    jCenter
                    onClick={item.navigate}>
                    <Box
                      flexBox
                      vertical
                      alCenter
                      jCenter
                      className="quicklinks">
                      {item.icon}
                    </Box>

                    <Bold mt={12} style={{ fontSize: 20 }}>
                      {item.name}
                    </Bold>
                  </Box>
                </Card>
              );
            })}
          </Grid.Container>
        </Box>
      </ActivityIndicator>
    </CompWrapper>
  );
};

// import React, { useEffect } from "react";
// import { Modal } from "react-uicomp";
// import { MdAdd, MdClose } from "react-icons/md";
// import { useAuth, useNavigation } from "react-auth-navigation";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Box,
//   KeyValue,
//   DefaultButton,
//   IconButton,
//   ToolTip,
//   Tab,
//   FormInput,
//   InputField,
//   GooglePlaces,
// } from "../../../common";
// import { Bold, Image, Title } from "../../../generics";
// // import { ViewerSection } from "./component/viewerSection";
// import { FILE_URL } from "../../../../config";
// import { CompWrapper, Card, ActivityIndicator } from "../../../hoc";

// import { MdEdit } from "react-icons/all";
// import { UserSection } from "./component/userSection";
// import { AdminSection } from "./component/adminSection";
// import { isValid, useInput, validator } from "../../../../utils";
// import { useDocTitle } from "../../../../hooks";
// import {
//   editUserInfoAction,
//   getUserAction,
//   userAuthAction,
// } from "../../../../actions";

// export const ClientDashboard = () => {
//   const { toast } = useAuth();
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user);
//   const { data: userData } = user?.user;
//   const { profile_details, user_details, company_details } = userData;
//   useDocTitle("Client");

//   const [showEditInfo, setShowEditInfo] = React.useState(false);
//   const [locationName, setLocationName] = React.useState(
//     profile_details.profile_address.value,
//   );
//   const [phone, setPhone] = React.useState(profile_details.profile_phone);

//   const { data, onChangeHandler } = useInput({
//     firstName: profile_details.profile_first_name ?? "default",
//     lastName: profile_details.profile_last_name ?? "default",
//     email: user_details.email ?? "default",
//     jobTitle: profile_details.profile_job_title ?? "default",
//   });

//   const submitHandler = (e) => {
//     e.preventDefault();

//     const catchedErrors = {};
//     const validate = validator(catchedErrors);

//     const { email, firstName, lastName, jobTitle } = data;

//     const showMessage = (message) => {
//       toast({ message, type: "error" });
//     };
//     validate("firstName", firstName.length === 0, () => {
//       showMessage("First Name musn't be empty!");
//     });
//     validate("lastName", lastName.length === 0, () => {
//       showMessage("Last Name musn't be empty!");
//     });

//     validate("email", email.length === 0, () => {
//       showMessage("Email musn't be empty!");
//     });

//     validate("address", locationName.length === 0, () => {
//       showMessage("Address musn't be empty!");
//     });
//     validate("jobTitle", jobTitle.length === 0, () => {
//       showMessage("Job Title musn't be empty!");
//     });

//     if (!isValid(catchedErrors)) {
//       //   console.error(catchedErrors);
//       return;
//     }

//     const body = {
//       firstName: firstName,
//       lastName: lastName,
//       profileAddress: { coordinates: [1, 1], value: locationName },
//       profilePhone: phone,
//       profileJobTitle: jobTitle,
//     };

//     dispatch(
//       editUserInfoAction(
//         body,
//         () => {
//           setShowEditInfo(false);

//           dispatch(getUserAction());
//         },
//         toast,
//       ),
//     );
//   };

//   const changeHandle = (index) => {
//     return function (e) {
//       const phoneArray = [...phone];
//       phoneArray[index] = e.target.value;
//       setPhone(phoneArray);
//     };
//   };

//   const onAdd = () => {
//     setPhone([...phone, ""]);
//   };

//   const close = (index) => {
//     const phoneClone = [...phone];

//     phoneClone.splice(index, 1);
//     setPhone(phoneClone);
//   };

//   return (
//     <CompWrapper>
//       {userData && (
//         <Card>
//           <Box flexBox alStart jSpace>
//             <Box>
//               <Bold mb={10}>Client Company</Bold>
//               <Box className={"client-wrapper"} flexBox alCenter>
//                 <Box mr={30}>
//                   <Image
//                     link={`${FILE_URL}/contractor/${company_details?.cont_comp_logo}`}
//                     alt="Contractor image"
//                     className="avatar"
//                   />
//                 </Box>
//                 <KeyValue
//                   data={{
//                     Name: company_details?.cont_comp_name,
//                     Email: company_details?.cont_comp_email,
//                     Phone: company_details?.cont_comp_phone?.join(", "),
//                     Address: company_details?.cont_comp_address?.value,
//                   }}
//                 />
//               </Box>
//             </Box>

//             <Box>
//               <Box flexBox alCenter>
//                 <Box>
//                   <Bold mb={10}>My Info</Bold>
//                 </Box>
//                 <Box mt={-5}>
//                   <IconButton
//                     icon={
//                       <ToolTip text="Edit" down>
//                         <MdEdit />
//                       </ToolTip>
//                     }
//                     onClick={() => {
//                       setShowEditInfo(true);
//                     }}
//                   />
//                 </Box>
//               </Box>
//               <Box className={"client-wrapper"}>
//                 <KeyValue
//                   data={{
//                     "First Name": profile_details?.profile_first_name,
//                     "Last name": profile_details?.profile_last_name,
//                     Email: user_details?.email,
//                     Phone: profile_details?.profile_phone?.join(", "),
//                     Address: profile_details?.profile_address?.value,
//                     Role: user_details?.role,
//                   }}
//                 />
//               </Box>
//             </Box>
//           </Box>
//         </Card>
//       )}
//       <Box mt={20}>
//         <Tab
//           panes={[
//             {
//               render: () => <AdminSection />,
//               title: "Admins",
//             },
//             {
//               render: () => <UserSection />,
//               title: "Users",
//             },
//           ]}
//         />
//         {/* <ViewerSection /> */}
//       </Box>

//       <Modal
//         visible={showEditInfo}
//         onOutsideClick={() => {
//           setShowEditInfo(false);
//         }}>
//         <Box>
//           <Box flexBox jCenter mb={10}>
//             <Title>Edit My Info</Title>
//           </Box>
//           <form onSubmit={submitHandler}>
//             <Box flexBox alCenter jSpace>
//               <FormInput label="First Name">
//                 <InputField
//                   placeholder="First Name"
//                   name="firstName"
//                   value={data.firstName}
//                   onChange={onChangeHandler("firstName")}
//                   type="text"
//                 />
//               </FormInput>
//               <Box ml={10}>
//                 <FormInput label="Last Name">
//                   <InputField
//                     placeholder="Last Name"
//                     name="lastName"
//                     value={data.lastName}
//                     onChange={onChangeHandler("lastName")}
//                     type="text"
//                   />
//                 </FormInput>
//               </Box>
//             </Box>
//             <Box flexBox alCenter jSpace>
//               <FormInput label="Address">
//                 <GooglePlaces
//                   setLocationName={setLocationName}
//                   defaultValue={profile_details.profile_address.value}
//                 />
//               </FormInput>
//               <Box ml={10}>
//                 <FormInput label="Job Title">
//                   <InputField
//                     placeholder="Job Title"
//                     name="jobTitle"
//                     value={data.jobTitle}
//                     onChange={onChangeHandler("jobTitle")}
//                     type="text"
//                   />
//                 </FormInput>
//               </Box>
//             </Box>
//             <FormInput label="Email">
//               <InputField
//                 placeholder="Email"
//                 name="email"
//                 value={data.email}
//                 onChange={onChangeHandler("email")}
//                 type="email"
//                 disabled
//               />
//             </FormInput>

//             <FormInput
//               label="Phone"
//               newElement={
//                 <div
//                   type="button"
//                   className="close"
//                   style={{ cursor: "pointer" }}
//                   onClick={onAdd}>
//                   <MdAdd color="green" />
//                 </div>
//               }>
//               <Box>
//                 {phone.length > 0 &&
//                   phone.map((item, index) => {
//                     return (
//                       <Box flexBox alCenter key={index} mb={10}>
//                         <InputField
//                           placeholder="Phone"
//                           name="phone"
//                           value={item}
//                           onChange={changeHandle(index)}
//                           type="number"
//                           min="0"
//                         />
//                         {phone.length > 1 && (
//                           <Box ml={20}>
//                             <div
//                               type="button"
//                               className="close"
//                               style={{ cursor: "pointer" }}
//                               onClick={() => close(index)}>
//                               <MdClose color="red" />
//                             </div>
//                           </Box>
//                         )}
//                       </Box>
//                     );
//                   })}
//               </Box>
//             </FormInput>
//             <ActivityIndicator animating={false}>
//               <DefaultButton title="Submit" type="submit" />
//             </ActivityIndicator>
//           </form>
//         </Box>
//       </Modal>
//     </CompWrapper>
//   );
// };
