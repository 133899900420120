import React, { useEffect } from "react";
import { Box } from "../../../common";
import { Bold, Chip, Paragraph, Title } from "../../../generics";

// IMPORT HOCS
import { CompWrapper, Card } from "../../../hoc";
import { BsFillEyeFill } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { Cell, Pie, PieChart } from "recharts";
import { StatusLabel } from "./statusLabel.component";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardSuperadmin } from "../../../../actions";
import { useNavigation } from "react-auth-navigation";

const pieData = [
  { name: "Group A", value: 151 },
  { name: "Group B", value: 211 },
  { name: "Group C", value: 41 },
];
const COLORS = ["#ff4343", "#ffc107", "#28a745"];

export const CurrentClientCard = ({ data, onView, onEdit }) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(getDashboardSuperadmin());
  }, []);
  const onNavigate = (tier_details) => {
    !!tier_details?.id
      ? navigate(
          routes["Current Clients"].path + `?page=1&tier=${tier_details.id}`,
        )
      : tier_details?.name == "trialing"
      ? navigate(routes["Trial Clients"].path + `?page=1&trial=active`)
      : navigate(routes["Unsubscribed Clients"].path + `?page=1`);
  };
  return (
    <Card>
      <Box>
        <Title
          style={{ fontSize: 18, cursor: "pointer" }}
          className="dashboard-card-title"
          onClick={() => navigate(routes["Current Clients"].path)}>
          Current Clients
        </Title>
      </Box>
      <Box mt={20} flexBox vertical style={{ rowGap: 10 }}>
        {dashboardData?.length > 0 &&
          dashboardData.map(({ tier_details, total_count }, index) => {
            return (
              <StatusLabel
                key={index}
                color="red"
                label={tier_details.name}
                value={total_count}
                onClick={() => onNavigate(tier_details)}
              />
            );
          })}
      </Box>
      {/* <Box flexBox alEnd jSpace>
        <Box>
          <Chip
            pl={12}
            pr={12}
            style={{
              backgroundColor: "red",
            }}>
            getJobStatus
          </Chip>
        </Box>
        <Box>
          <PieChart width={120} height={120}>
            <Pie
              data={[
                { name: "Live/Open", value: 2 },
                { name: "Scheduled", value: 3 },
                { name: "Completed", value: 5 },
              ]}
              innerRadius={45}
              outerRadius={50}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value">
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </Box>
      </Box> */}
    </Card>
  );
};
