import { api, APIS, TABLE_LIMIT } from "../config";
import {
  ADD_TEMPLATE,
  GET_ALL_TEMPLATE,
  GET_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
} from ".";
import { templateParser } from "../utils";

export function getAllTemplate(page = 1, allData) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ALL_TEMPLATE.LOADING });
      res = await api(
        allData
          ? `${APIS.template}/all`
          : `${APIS.template}/all?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_ALL_TEMPLATE.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_ALL_TEMPLATE.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ALL_TEMPLATE.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getTemplate(templateId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_TEMPLATE.LOADING });
      res = await api(`${APIS.template}/${templateId}`);

      const { success, data } = res.data;

      if (success) {
        const payload = templateParser(data.data);

        dispatch({ type: GET_TEMPLATE.SUCCESS, payload });
      } else {
        dispatch({ type: GET_TEMPLATE.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_TEMPLATE.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addTemplate(body, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_TEMPLATE.LOADING });
      res = await api(APIS.template, "POST", body);

      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: ADD_TEMPLATE.SUCCESS });
        !!toast &&
          toast({
            message: data.message,
            type: "success",
          });
      } else {
        dispatch({ type: ADD_TEMPLATE.ERROR });
        !!toast &&
          toast({
            message: message,
            type: "error",
          });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_TEMPLATE.ERROR });
      !!toast &&
        toast({
          message: `Adding template failed!!`,
          type: "error",
        });
      console.error(message);
      return 0;
    }
  };
}

export function editTemplate(templateId, body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_TEMPLATE.LOADING });

      res = await api(`${APIS.template}/${templateId}`, "PATCH", body);

      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: EDIT_TEMPLATE.SUCCESS });
        !!toast &&
          toast({
            message: data.message,
            type: "success",
          });
        callback && callback();
      } else {
        dispatch({ type: EDIT_TEMPLATE.ERROR });
        !!toast &&
          toast({
            message: message,
            type: "error",
          });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_TEMPLATE.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function deleteTemplateAction(id, toast, pageNumber) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_TEMPLATE.LOADING });
      res = await api(`${APIS.template}/delete/${id}`, "PATCH");

      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: DELETE_TEMPLATE.SUCCESS });
        dispatch(getAllTemplate(pageNumber));
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: DELETE_TEMPLATE.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_TEMPLATE.ERROR });
      toast({
        message: `DELETING Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}
