import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import moment from "moment";
import { Card } from "../../../../../hoc";
import {
  Box,
  DefaultButton,
  FormInput,
  GooglePlaces,
  InputField,
  PrimaryButton,
} from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { Paragraph, Title, Bold, Chip } from "../../../../../generics";
import { getCompletedTask, getScheduledTask } from "../../../../../../actions";

export const PastTask = ({ id }) => {
  const dispatch = useDispatch();
  const { location, params, navigation } = useNavigation();
  const { navigate } = navigation;
  const completedTask = useSelector((state) => state.workerTask);
  const { completedTaskList, loadingCompletedTask, completedCount } =
    completedTask;
  let query = useQuery();
  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getCompletedTask(id, pageNumber));
  }, [id, pageNumber]);

  const viewHandler = (rowData) => {
    const id = rowData?.job_details?.id;
    navigate(`/jobs/view/${id}`);
  };

  return (
    <Card>
      <Box flexBox alCenter jSpace>
        <Bold>Completed Task</Bold>
      </Box>
      <Box mt={20}>
        <Table
          columns={[
            {
              field: "job_details",
              name: "Job",
              render: (rowData) =>
                rowData && <Paragraph>{rowData?.job_type}</Paragraph>,
            },
            {
              field: "site_details",
              name: "Site Name",
              render: (rowData) =>
                rowData && <Paragraph>{rowData?.prop_name}</Paragraph>,
            },
            {
              field: "job_details",
              name: "start date",
              render: (rowData) =>
                rowData && (
                  <Paragraph>
                    {moment(rowData?.start_time).format("Do MMM, YYYY")}
                  </Paragraph>
                ),
            },
            {
              field: "job_details",
              name: "status",
              render: ({ job_status }) =>
                job_status !== null ? (
                  job_status ? (
                    <Chip style={{ backgroundColor: "#5b9540" }}>
                      Completed
                    </Chip>
                  ) : (
                    <Chip style={{ backgroundColor: "#11a7af" }}>On Going</Chip>
                  )
                ) : (
                  <Chip style={{ backgroundColor: "#ff4343" }}>
                    Not Started
                  </Chip>
                ),
            },
          ]}
          data={completedTaskList}
          dataLoader={loadingCompletedTask}
          totalCount={completedCount}
          actions
          // deleteLoader={false}
          // onDeleteHandler={deleteHandler}
          // onEditHandler={editHandler}
          onViewHandler={viewHandler}
          viewBug
        />
      </Box>
    </Card>
  );
};
