import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { useDocTitle } from "../../../../hooks";
import { DefaultButton, Box, KeyValue, Tab } from "../../../common";
import { Bold, Image, Paragraph } from "../../../generics";
import Table from "../../../common/table/Table.common";
import { RippleButton } from "react-uicomp";

// IMPORT HOCS
import { CompWrapper, Card } from "../../../hoc";

import {
  getBuildingListAction,
  deleteBuildingAction,
  getIronmongeryProductDetail,
} from "../../../../actions";
import { FILE_URL } from "../../../../config";
import { AiFillFile } from "react-icons/ai";

export const Specifications = ({ data }) => {
  return (
    <Card>
      <div className="mr-attachment-container">
        {data.length ? (
          data.map((file, index) => {
            return (
              <a
                key={index}
                target="_blank"
                href={`${FILE_URL}/ig/${file}`}
                className="mr-attachment">
                <div style={{ marginRight: 10 }}>
                  <AiFillFile />
                </div>
                <div>{file}</div>
              </a>
            );
          })
        ) : (
          <Box>
            <Paragraph>Attachment is not Available</Paragraph>
          </Box>
        )}
      </div>
    </Card>
  );
};

export const TechnicalData = ({ data }) => {
  return (
    <Card>
      <div className="mr-attachment-container">
        {data.length ? (
          data.map((file, index) => {
            return (
              <a
                key={index}
                target="_blank"
                href={`${FILE_URL}/ig/${file}`}
                className="mr-attachment">
                <div style={{ marginRight: 10 }}>
                  <AiFillFile />
                </div>
                <div>{file}</div>
              </a>
            );
          })
        ) : (
          <Box>
            <Paragraph>Attachment is not Available</Paragraph>
          </Box>
        )}
      </div>
    </Card>
  );
};

export const IronmongeryDetail = () => {
  const { location, navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  useDocTitle("Ironmongery");

  const dispatch = useDispatch();
  const { productDetailLoader, productDetail } = useSelector(
    (state) => state.ironmongery,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getIronmongeryProductDetail(params.id));
  }, []);

  const deleteHandler = (id) => {
    dispatch(deleteBuildingAction(id, toast, pageNumber));
  };

  const viewHandler = (id) => {
    navigate(`/building/view/${id}`);
  };

  const editHandler = (item) => {
    navigate(routes["Building"].path + "/edit/" + item.id);
  };

  if (!productDetail) return <div>loading...</div>;
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>{productDetail?.product_details.name}</Bold>
          </Box>
          <Box className={"client-wrapper"} flexBox alCenter>
            <Box mr={30}>
              <Image
                link={`${FILE_URL}/ig/${productDetail?.product_details.image}`}
                alt="Contractor image"
                className="avatar"
              />
            </Box>

            <Box mt={10}>
              <KeyValue
                data={{
                  Brand: productDetail?.product_details.brand,
                }}
              />
              <Box mt={10}>
                <Paragraph>
                  {productDetail?.product_details.description}
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Card>

        <Box mt={20}>
          <Tab
            panes={[
              {
                render: () => (
                  <Specifications
                    data={productDetail?.product_details.spec_file}
                  />
                ),
                title: "Specifications",
              },
              {
                render: () => (
                  <TechnicalData
                    data={productDetail?.product_details.data_file}
                  />
                ),
                title: "Technical Data",
              },
            ]}
          />
        </Box>
      </Box>
    </CompWrapper>
  );
};
