import React, { useState, useEffect } from "react";
import { Toast, useToast } from "react-uicomp";
import { Auth, withNavigation } from "react-auth-navigation";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

import { publicPaths, privatePaths } from "./Routes.app";
import { userRoles, userType } from "./UserRoles.app";

import { setCookie, removeCookie } from "../../helpers";
import { userAuthAction, userAuthLogoutAction } from "../../actions";

// IMPORT COMMONS
import { SideNav } from "../common";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userAuthAction,
      userAuthLogoutAction,
    },
    dispatch,
  );
};

const MemoChild = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const { userAuthAction, userAuthLogoutAction, children } = props;
  const { handler, toast } = useToast();

  const [authLoading, setAuthLoading] = useState(true);
  const [config, setConfig] = useState({
    isLoggedIn: false,
    userRole: userType.USER,
  });

  const [sideNavExpanded, setSideNavExpanded] = useState(false); // for collapsible sidenav
  const [sideMenuStable, setSideMenuStable] = useState(sideNavExpanded);

  const loginSuccess = (data) => {
    setConfig({
      isLoggedIn: true,
      userRole:
        data?.user_details?.role === "ADMIN" &&
        (!data?.company_details?.verified ||
          data?.company_details?.status === "canceled" ||
          data?.company_details?.status === "incomplete" ||
          data?.company_details?.status === "incomplete_expired" ||
          data?.company_details?.status === "past_due" ||
          data?.company_details?.status === "unpaid" ||
          data?.company_details?.status === "unsubscribed" ||
          data?.company_details?.status === "expired_trial")
          ? "HALF_ADMIN"
          : data?.user_details?.role,
    });
  };

  const loginFailure = () => {
    setConfig({
      isLoggedIn: false,
      userRole: userType.USER,
    });
  };

  useEffect(() => {
    userAuthAction(setAuthLoading, loginSuccess, loginFailure);
  }, [userAuthAction]);

  if (authLoading) {
    return <div>Redirecting...</div>;
  }

  return (
    <Auth.Provider
      config={config}
      state={{
        toast,
        // for collapsing sidenav
        sideNavExpanded,
        setSideNavExpanded,
        sideMenuStable,
        setSideMenuStable,
        handleLogin: (token, role) => {
          setCookie("token", token);
          loginSuccess(role);
        },
        handleLogout: () => {
          userAuthLogoutAction(() => {
            removeCookie("token");
            loginFailure();
          });
        },
      }}>
      {children}
      <Toast {...handler} errorColor="#ff4343" style={{ minWidth: 300 }} />
    </Auth.Provider>
  );
});

const App = () => {
  return (
    <MemoChild>
      {
        <>
          <Auth.Screens />
          <SideNav />
        </>
      }
    </MemoChild>
  );
};

export default withNavigation(App, {
  publicPaths,
  privatePaths,
  userRoles,
  routerType: "hash",
});
