import React, { useEffect, useState } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";

import { SelectField } from "../../common";
import { Card } from "../../hoc";

import { getAllFloorByJobWebView } from "../../../actions";
import { FILE_URL } from "../../../config";

import { FloorMap as Map } from "./components";

export const FloorWebView = () => {
  const { params, location } = useNavigation();
  const dispatch = useDispatch();
  const { floorByJobLoader, floorListByJob } = useSelector(
    (state) => state.floor,
  );

  const [selectedFloor, setSelectedFloor] = useState(null);

  useEffect(() => {
    const token = location.search.replace("?token=", "");
    dispatch(getAllFloorByJobWebView(params.id, token));
  }, [params.id, dispatch, location]);

  useEffect(() => {
    setSelectedFloor(floorListByJob[0]);
  }, [floorListByJob]);

  const formatOptionLabel = (data) => {
    return <p>{data.floor_details.floor_name}</p>;
  };

  return (
    <Card>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <div style={{ fontWeight: "bold", marginRight: 10 }}>Select Floor:</div>

        <div style={{ width: 200, zIndex: 100 }}>
          <SelectField
            isLoading={floorByJobLoader}
            options={floorListByJob}
            getOptionValue
            formatOptionLabel={formatOptionLabel}
            onChangeValue={(item) => setSelectedFloor(item)}
            value={selectedFloor}
          />
        </div>
      </div>
      {selectedFloor && (
        <Map
          jobId={params.job_id}
          job_status={selectedFloor.job_details.job_status}
          data={selectedFloor.floor_details.door_list}
          floorDetail={selectedFloor.floor_details}
          jobType={selectedFloor.job_details.job_type}
          image={`${FILE_URL}/floor/${selectedFloor.floor_details.floor_map}`}
        />
      )}
    </Card>
  );
};
