import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";

import { useDocTitle } from "../../../../../hooks/useDocTitle.hook";
import {
  capitalizeFirstLetter,
  isValid,
  useInput,
  validator,
} from "../../../../../utils";

import { ActivityIndicator, Card, CompWrapper } from "../../../../hoc";
import {
  Box,
  ConfirmationModal,
  DefaultButton,
  FormInput,
  GooglePlaces,
  Grid,
  ImageUpload,
  InputField,
  KeyValue,
  PrimaryButton,
  SelectField,
} from "../../../../common";
import {
  addBuildingAction,
  getAllTemplate,
  getBuildingDetailByPostCodeAction,
  requestBuildingOwnershipAction,
} from "../../../../../actions";
import UPLOAD from "../../../../../assets/icons/upload.png";
import { Bold, Image, Paragraph } from "../../../../generics";
import { RiCloseCircleFill } from "react-icons/ri";

const typeList = [
  { name: "Residential Buildings", value: "Residential Buildings" },
  { name: "Educational Buildings", value: "Educational Buildings" },
  { name: "Institutional Buildings", value: "Institutional Buildings" },
  { name: "Assembly Buildings", value: "Assembly Buildings" },
  { name: "Business Buildings", value: "Business Buildings" },
  { name: "Mercantile Buildings", value: "Mercantile Buildings" },
  { name: "Industrial Buildings", value: "Industrial Buildings" },
  { name: "Storage Buildings", value: "Storage Buildings" },
  { name: "Wholesale Establishments", value: "Wholesale Establishments" },
  { name: "Mixed Land Use Buildings", value: "Mixed Land Use Buildings" },
  { name: "Hazardous Buildings", value: "Hazardous Buildings" },
  { name: "Detached Buildings", value: "Detached Buildings" },
  { name: "Semi-Detached Buildings", value: "Semi-Detached Buildings" },
  {
    name: "Multi-Storey or High Rise Buildings",
    value: "Multi-Storey or High Rise Buildings",
  },
  { name: "Slums", value: "Slums" },
  { name: "Unsafe Buildings", value: "Unsafe Buildings" },
  { name: "Special Buildings", value: "Special Buildings" },
  { name: "Multi-Level Car Parking", value: "Multi-Level Car Parking" },
];

export const AddBuildingPage = () => {
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const {
    addLoading,
    detailByPostalCode,
    detailByPostalCodeLoading,

    requestBuildingOwnershipLoading,
    requestBuildingOwnershipSuccess,
    requestBuildingOwnershipError,
  } = useSelector((state) => state.building);
  const { templates } = useSelector((state) => state.template);
  const {
    user: {
      data: { company_details },
    },
  } = useSelector((state) => state.user);

  useDocTitle("Add Building");
  const { data, onChangeHandler } = useInput({
    name: "",
    // stairs: "",
    buildingType: "",
    constructions: "",
    templateId: "",
  });

  const [image, setImage] = React.useState([]);
  const [locationName, setLocationName] = React.useState("");
  const [postalCode, setPostalCode] = React.useState(null);
  const [placeId, setPlaceId] = React.useState("");
  const changeImage = (data) => {
    setImage(data);
  };

  useEffect(() => {
    dispatch(getAllTemplate(1, true));
  }, []);

  useEffect(() => {
    // if (!!postalCode) {
    dispatch(getBuildingDetailByPostCodeAction(postalCode));
    // }
  }, [postalCode]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const {
      name,
      /* stairs, */ buildingType,
      constructions,
      templateId,
    } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("name", name.length === 0, () => {
      showMessage("Name can't be empty!");
    });

    validate("address", locationName.length === 0, () => {
      showMessage("Address can't be empty!");
    });

    validate("constructions", constructions.length === 0, () => {
      showMessage("Constructions can't be empty!");
    });

    validate("buildingType", buildingType.length === 0, () => {
      showMessage("BuildingType can't be empty!");
    });

    validate("templateId", !templateId, () => {
      showMessage("Template Should be selected!");
    });

    // validate("stair", stairs.length === 0, () => {
    //   showMessage("Stair can't be empty!");
    // });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }

    let formData = new FormData();
    await formData.append("propName", capitalizeFirstLetter(name));
    await formData.append(
      "propAddress",
      JSON.stringify({
        coordinates: [1, 1],
        value: locationName,
        // placeId: placeId,
        postalCode: postalCode,
      }),
    );

    await formData.append(
      "propConstruction",
      capitalizeFirstLetter(constructions),
    );

    await formData.append("templateId", templateId);
    // await formData.append("propStairs", 1);
    await formData.append("propType", buildingType);
    for (let i = 0; i < image.length; i++) {
      await formData.append("imageFile", image[i].file);
    }

    // FOR INTEGRATION
    dispatch(
      addBuildingAction(
        formData,
        () => {
          navigate(routes["Building"].path);
        },
        toast,
      ),
    );
  };

  const onRequestOwnership = (callback) => {
    // return function (e) {
    // e.preventDefault();
    console.log("callback", callback);
    dispatch(
      requestBuildingOwnershipAction(
        detailByPostalCode?.site_details?.id,
        toast,
        callback,
      ),
    );
  };

  return (
    <CompWrapper>
      <Card>
        <Box>
          <form onSubmit={submitHandler}>
            <FormInput label="Name" required>
              <InputField
                placeholder="Name"
                name="name"
                value={data.name}
                onChange={onChangeHandler("name")}
                type="text"
              />
            </FormInput>

            <FormInput label="Address" required>
              <GooglePlaces
                setLocationName={setLocationName}
                setPlaceId={setPlaceId}
                getPostalCode
                setPostalCode={setPostalCode}
              />
            </FormInput>

            {!!postalCode ? (
              <ActivityIndicator animating={detailByPostalCodeLoading}>
                {!!detailByPostalCode &&
                  (company_details.id ===
                  detailByPostalCode?.company_details?.id ? (
                    <Box mb={20}> This address is already owned by you. </Box>
                  ) : (
                    <Box mb={20} flexBox jSpace>
                      <Box>
                        <Bold>This address is currently owned by:</Bold>
                        <KeyValue
                          data={{
                            client:
                              detailByPostalCode.company_details.cont_comp_name,
                            "building name":
                              detailByPostalCode?.site_details?.prop_name,
                            "Postal Code":
                              detailByPostalCode?.site_details?.prop_address
                                ?.postalCode,
                            Address:
                              detailByPostalCode?.site_details?.prop_address
                                ?.value,
                          }}
                        />
                      </Box>
                      <Box>
                        <ConfirmationModal
                          displayElement={
                            <PrimaryButton
                              title={
                                requestBuildingOwnershipSuccess
                                  ? "Requested"
                                  : "Request for ownership of building"
                              }
                              disabled={requestBuildingOwnershipSuccess}
                            />
                          }
                          label="Are you sure you want to Requset for ownership?"
                          onConfirmClick={(callback) => {
                            onRequestOwnership(callback);
                          }}
                          confirmLabel="Yes"
                          cancelLabel="Cancel"
                          loading={requestBuildingOwnershipLoading}
                        />

                        {/* <DefaultButton
                          title={
                            requestBuildingOwnershipSuccess
                              ? "Requested"
                              : "Request for ownership of building"
                          }
                          disabled={requestBuildingOwnershipSuccess}
                          onClick={onRequestOwnership}
                        /> */}
                      </Box>
                    </Box>
                  ))}
              </ActivityIndicator>
            ) : typeof postalCode === "string" ? (
              <Box mb={20}>
                {" "}
                This Address doesnot have postal code. Please select another
                address{" "}
              </Box>
            ) : null}

            {/* <FormInput label="Stairs">
              <InputField
                placeholder="Stairs"
                name="stairs"
                value={data.stairs}
                onChange={onChangeHandler("stairs")}
                type="number"
                min="0"
              />
            </FormInput> */}

            {!detailByPostalCode && (
              <>
                <FormInput label="Template" required>
                  <SelectField
                    options={templates}
                    getOptionLabel="name"
                    getOptionValue="id"
                    onChangeValue={(item) => {
                      onChangeHandler("templateId", item?.id)();
                    }}
                    isSearchable
                    placeholder="Select Type"
                  />
                </FormInput>

                <FormInput label="Type" required>
                  <SelectField
                    options={typeList}
                    getOptionLabel="name"
                    getOptionValue="value"
                    onChangeValue={(item) => {
                      onChangeHandler("buildingType", item?.value)();
                    }}
                    isSearchable
                    placeholder="Select Type"
                  />
                </FormInput>

                <FormInput label="Client" required>
                  <InputField
                    placeholder="Client"
                    name="constructions"
                    value={data.constructions}
                    onChange={onChangeHandler("constructions")}
                    type="text"
                  />
                </FormInput>

                <FormInput label="Images" required>
                  <ImageUpload
                    onChange={changeImage}
                    buttonStyle={{ background: "red", display: "inline-block" }}
                    title="upload image"
                    multiple
                    accept="image/*"
                    buttonclick={
                      <Box mt={20}>
                        <div
                          style={{
                            display: "inline-block",
                            border: "1px dashed #000",
                            padding: 50,
                            cursor: "pointer",
                            overflow: "hidden",
                          }}>
                          <Image
                            alt="Avatar"
                            link={UPLOAD}
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "contain",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      </Box>
                    }>
                    {({
                      onUploadImage,
                      imageData,
                      onRemove,
                      deleteAllHandler,
                    }) => (
                      <Box>
                        <Grid.Container lg={4} md={2} sm={1}>
                          {imageData &&
                            imageData.map((item, index) => (
                              <div key={index}>
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                    boxShadow: "0px 2px 10px 0px #888888",
                                  }}>
                                  <Image
                                    alt="Avatar"
                                    link={item.url}
                                    style={{
                                      width: 200,
                                      height: 200,
                                      objectFit: "contain",
                                      overflow: "hidden",
                                    }}
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => onRemove(index)}>
                                    <RiCloseCircleFill color="red" size={25} />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Grid.Container>
                      </Box>
                    )}
                  </ImageUpload>
                </FormInput>
                <ActivityIndicator animating={addLoading}>
                  <DefaultButton title="Submit" type="submit" />
                </ActivityIndicator>
              </>
            )}
          </form>
        </Box>
      </Card>
    </CompWrapper>
  );
};
