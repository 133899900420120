import React, { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { getDoorPartByIdAction } from "../../../../../../actions";
import { Box, KeyValue } from "../../../../../common";
import { Bold, Heading, Paragraph, Title } from "../../../../../generics";
import { Card } from "../../../../../hoc";

export const PartDetail = () => {
  const { params } = useNavigation();
  const dispatch = useDispatch();
  const { doorPart } = useSelector((state) => state.part);
  console.log(doorPart);
  useEffect(() => {
    dispatch(getDoorPartByIdAction(params.id));
  }, [params.id]);

  const convertArrayToObject = (array, key, value) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item[value],
      };
    }, initialValue);
  };

  return (
    <Card
      containerStyle={{
        marginBottom: 20,
        maxHeight: "50vh",
        overflowY: "auto",
      }}>
      {!!doorPart ? (
        <Box>
          <Title>{doorPart.part_name}</Title>
          <KeyValue
            data={convertArrayToObject(doorPart?.details, "name", "value")}
          />

          <KeyValue
            data={{
              Schedule: `${doorPart.schedule_time.duration} ${doorPart.schedule_time.unit}`,
            }}
          />

          <Box flexBox vertical mb={10} mt={10}>
            <Title mb={10}>Inspection Questions:</Title>
            {doorPart.inspec_quesn.map((item, index) => {
              return (
                <Paragraph key={index} ml={30} p={10}>
                  {item}
                </Paragraph>
              );
            })}
          </Box>

          <Box flexBox vertical mb={10}>
            <Title mb={10}>Installation Questions:</Title>
            {doorPart.install_quesn.map((item, index) => {
              return (
                <Paragraph key={index} ml={30} p={10}>
                  {item}
                </Paragraph>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box>loading</Box>
      )}
    </Card>
  );
};
