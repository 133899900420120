import { api, APIS, TABLE_LIMIT } from "../config";
import {
  CLIENT_SUBSCRIPTION,
  SUBSCRIPTION_HISTORY,
  MAKE_PAYMENT,
  CUSTOMER_PORTAL,
  START_TRIAL,
  getUserAction,
  EXTEND_TRIAL,
} from ".";

export function getSubsctiptionHistoryList(page = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: SUBSCRIPTION_HISTORY.LOADING });
      res = await api();

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: SUBSCRIPTION_HISTORY.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: SUBSCRIPTION_HISTORY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: SUBSCRIPTION_HISTORY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getClientSubscription(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CLIENT_SUBSCRIPTION.LOADING });
      res = await api(id ? `${APIS.subscription}/${id}` : APIS.subscription);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CLIENT_SUBSCRIPTION.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CLIENT_SUBSCRIPTION.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CLIENT_SUBSCRIPTION.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function makePaymentAction(tierId, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: MAKE_PAYMENT.LOADING });
      res = await api(`${APIS.payment}/${tierId}`, "POST");

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: MAKE_PAYMENT.SUCCESS });
        toast({
          message: data.message,
          type: "success",
        });
        window.open(data.data);
      } else {
        dispatch({ type: MAKE_PAYMENT.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: MAKE_PAYMENT.ERROR });
      toast({
        message: `Payment Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function openCustomerPortal(toast, id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CUSTOMER_PORTAL.LOADING });
      res = await api(
        id
          ? `${APIS.subscription}/portal/${id}`
          : `${APIS.subscription}/portal`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CUSTOMER_PORTAL.SUCCESS });
        toast({
          message: data.message,
          type: "success",
        });
        window.open(data.data);
      } else {
        dispatch({ type: CUSTOMER_PORTAL.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: CUSTOMER_PORTAL.ERROR });
      toast({
        message: `Failed to open customer portal`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function startTrial(tierId, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: START_TRIAL.LOADING });
      res = await api(`${APIS.subscription}/trial/${tierId}`, "PATCH");

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: START_TRIAL.SUCCESS });
        toast({
          message: data.message,
          type: "success",
        });
        window.location.reload();
      } else {
        dispatch({ type: START_TRIAL.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: START_TRIAL.ERROR });
      toast({
        message: `Failed to start trial`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function extendTrialAction(id, body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EXTEND_TRIAL.LOADING });
      res = await api(`${APIS.subscription}/addtrial/${id}`, "PATCH", body);

      const { success, data, message } = res.data;

      if (success) {
        dispatch({ type: EXTEND_TRIAL.SUCCESS });
        toast({
          message: data.message,
          type: "success",
        });
        callback && callback();
        dispatch(getClientSubscription(id));
      } else {
        dispatch({ type: EXTEND_TRIAL.ERROR });
        toast({
          message: message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: EXTEND_TRIAL.ERROR });
      toast({
        message: `Extending Trial Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}
