import {
  FLOOR,
  GET_FLOOR_LIST,
  EDIT_FLOOR,
  FLOOR_DETAIL,
  DOORXFLOOR,
  GET_FLOOR_BY_JOB,
  DELETE_FLOOR,
  GET_ALL_FLOOR_LIST,
  PLOT,
} from ".";
import { api, APIS, TABLE_LIMIT, BASE_URL } from "../config";
// import { } from "./ActionTypes.action";

import axios from "axios";

export function postFloorAction(formData, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: FLOOR.LOADING });
      res = await api(APIS.floor, "POST", formData, { file: true });

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: FLOOR.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: FLOOR.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: FLOOR.ERROR });
      toast({
        message: `Image upload Failed!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function editFloorAction(floorId, formData, callback, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_FLOOR.LOADING });
      res = await api(`${APIS.floor}/${floorId}`, "PATCH", formData, {
        file: true,
      });

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: EDIT_FLOOR.SUCCESS });
        callback && callback();
        toast({
          message: data.message,
          type: "success",
        });
      } else {
        dispatch({ type: EDIT_FLOOR.ERROR });
        toast({
          message: data.message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_FLOOR.ERROR });
      toast({
        message: `Image upload Failed!!`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function getFloorList(siteId = 10, page = 1) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: GET_FLOOR_LIST.LOADING });

      res = await api(
        `${APIS.floor}/site/${siteId}?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_FLOOR_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_FLOOR_LIST.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: GET_FLOOR_LIST.ERROR });
      return 0;
    }
  };
}

export function getAllFloorList(siteId = 10) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: GET_ALL_FLOOR_LIST.LOADING });

      res = await api(`${APIS.floor}/site/${siteId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_ALL_FLOOR_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_ALL_FLOOR_LIST.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: GET_ALL_FLOOR_LIST.ERROR });
      return 0;
    }
  };
}

export function getAllFloorByJob(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({
        type: GET_FLOOR_BY_JOB.LOADING,
      });
      res = await api(`${APIS.floorByJob}/${id}`);
      const { success, data } = res.data;
      if (success === true) {
        dispatch({
          type: GET_FLOOR_BY_JOB.SUCCESS,
          payload: data.data,
        });
        return 1;
      } else {
        dispatch({ type: GET_FLOOR_BY_JOB.ERROR });
      }
    } catch ({ message }) {
      dispatch({
        type: GET_FLOOR_BY_JOB.ERROR,
      });
      console.error(message);
      return 0;
    }
  };
}

// WEB VIEW
export function getAllFloorByJobWebView(id, token) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({
        type: GET_FLOOR_BY_JOB.LOADING,
      });
      res = await axios({
        baseURL: BASE_URL,
        url: `${APIS.floorByJob}/${id}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const { success, data } = res.data;
      if (success === true) {
        dispatch({
          type: GET_FLOOR_BY_JOB.SUCCESS,
          payload: data.data,
        });
        return 1;
      } else {
        dispatch({ type: GET_FLOOR_BY_JOB.ERROR });
      }
    } catch ({ message }) {
      dispatch({
        type: GET_FLOOR_BY_JOB.ERROR,
      });
      console.error(message);
      return 0;
    }
  };
}

export function getFloorDetailAction(id = 1) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: FLOOR_DETAIL.LOADING });
      res = await api(`${APIS.floor}/${id}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: FLOOR_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: FLOOR_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: FLOOR_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getDoorWithoutMap(floorId) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: DOORXFLOOR.LOADING });

      res = await api(`${APIS.door}/floor/marker/${floorId}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DOORXFLOOR.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: DOORXFLOOR.ERROR });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: DOORXFLOOR.ERROR });
      return 0;
    }
  };
}

export function plotAction(id, body, toast, callBack) {
  console.log(id, body);
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: PLOT.LOADING });
      res = await api(`${APIS.door}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: PLOT.SUCCESS });
        callBack && callBack();
        toast({
          message: "Successfully updated floor plan",
          type: "success",
        });
      } else {
        dispatch({ type: PLOT.ERROR });
        toast({
          message: `Floor plan update Failed`,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: PLOT.ERROR });
      toast({
        message: `Floor plan update Failed`,
        type: "error",
      });
      console.error(message);
      return 0;
    }
  };
}

export function deleteFloorAction(id, toast, site, page) {
  return async function (dispatch) {
    let res;

    try {
      dispatch({ type: DELETE_FLOOR.LOADING });

      res = await api(`${APIS.floor}/delete/${id}`, "PATCH");

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DELETE_FLOOR.SUCCESS });
        dispatch(getFloorList(site, page));
        toast({
          message: "Successfully deleted floor",
          type: "success",
        });
      } else {
        dispatch({ type: DELETE_FLOOR.ERROR });
        toast({
          message: "Error deleting floor",
          type: "error",
        });
      }

      return 1;
    } catch ({ message }) {
      dispatch({ type: DELETE_FLOOR.ERROR });
      toast({
        message: "Error deleting floor",
        type: "error",
      });
      return 0;
    }
  };
}
