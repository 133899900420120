// GENERATES TEMPLATE PART
export const getIronmongeryPart = () => {
  return {
    partName: "",
    // ironmongeryCategory: "",
    productId: "",
  };
};

// GENERATES TEMPLATE
export const getIronmongery = (template) => {
  if (!template) {
    return {
      name: "",
      description: "",
      groupList: [{ ...getIronmongeryPart() }],
    };
  }

  return {
    name: template.name,
    description: template.description,
    groupList: template.groupList,
  };
};

export function ironmongeryParser(template) {
  return getIronmongery(template);
}
// "productId" : 13,
// "partName": "door_closers"
