import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/all";

import { CompWrapper, Card } from "../../../../hoc";
import {
  Box,
  KeyValue,
  DefaultButton,
  IconButton,
  ToolTip,
  Tab,
  FormInput,
  InputField,
  GooglePlaces,
} from "../../../../common";
import { Bold, Image, Title } from "../../../../generics";
import { CurrentTask, PastTask } from "./component";
import { getUserDetails } from "../../../../../actions";

export const UserDetail = () => {
  const dispatch = useDispatch();
  const { location, params, navigation } = useNavigation();
  const user = useSelector((state) => state.user);
  const { userDetail } = user;
  const { id } = params;

  useEffect(() => {
    dispatch(getUserDetails(id));
  }, [id]);

  return (
    <CompWrapper>
      {userDetail && (
        <Card>
          <Box flexBox alStart jSpace>
            <Box>
              <Box flexBox alCenter>
                <Box>
                  <Bold mb={10}>User's Info</Bold>
                </Box>
                {/* <Box mt={-5}>
                  <IconButton
                    icon={
                      <ToolTip text="Edit" down>
                        <MdEdit />
                      </ToolTip>
                    }
                    onClick={() => {
                      setShowEditInfo(true);
                    }}
                  />
                </Box> */}
              </Box>
              <Box className={"client-wrapper"}>
                <KeyValue
                  data={{
                    "First Name": userDetail?.profile_first_name,
                    "Last Name": userDetail?.profile_last_name,
                    Email: userDetail?.email,
                    Phone: userDetail?.profile_phone?.join(", "),
                    Address: userDetail?.profile_address?.value,
                    // Role: userDetail?.role,
                    "Job Title": userDetail?.profile_job_title,
                    Verified: userDetail?.verified ? "True" : "False",
                    Deactivated: userDetail?.deactivated ? "True" : "False",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      )}
      <Box mt={20}>
        <Tab
          panes={[
            {
              render: () => <CurrentTask id={id} />,
              title: "Scheduled Task",
            },
            {
              render: () => <PastTask id={id} />,
              title: "Completed Task",
            },
          ]}
        />
      </Box>
    </CompWrapper>
  );
};
