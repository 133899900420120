import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { useDocTitle } from "../../../../hooks";
import { DefaultButton, Box } from "../../../common";
import Table from "../../../common/table/Table.common";
import { Bold, Image, Paragraph } from "../../../generics";
import {
  getClientListAction,
  deleteClientAction,
  updateClientStatusAction,
  getAllTierList,
} from "../../../../actions";
import { FILE_URL } from "../../../../config";

// IMPORT HOCS
import { CompWrapper, Card } from "../../../hoc";

export const TierListPage = () => {
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  useDocTitle("Tier");

  const dispatch = useDispatch();
  const { addLoader, tierListLoader, tierList, tierListCount } = useSelector(
    (state) => state.tier,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getAllTierList(pageNumber));
  }, [pageNumber]);

  //   const deleteHandler = (id) => {
  //     dispatch(deleteClientAction(id, toast, pageNumber));
  //   };

  // const viewHandler = (id) => {
  //   navigate(routes["Client"].path + "/list/" + id);
  // };

  const editHandler = (item) => {
    navigate(routes["Tier"].path + "/edit/" + item.id);
  };

  console.log("tierList", tierList);
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Tier</Bold>
            <Box>
              <DefaultButton
                title="Add"
                onClick={() => {
                  navigate(routes["Add Tier"].path);
                }}
              />
            </Box>
          </Box>

          <Box mt={20}>
            <Table
              columns={[
                {
                  field: "name",
                  name: "Name",
                },
                {
                  field: "description",
                  name: "description",
                },
                {
                  field: "price",
                  name: "price",
                  render: (rowData) =>
                    rowData && <Paragraph>{rowData?.amount}</Paragraph>,
                },
              ]}
              data={tierList.map(({ tier_details }) => tier_details)}
              dataLoader={tierListLoader}
              totalCount={tierListCount}
              actions
              // deleteLoader={deleteLoading}
              // onDeleteHandler={deleteHandler}
              onEditHandler={editHandler}
              // onViewHandler={viewHandler}
            />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};
