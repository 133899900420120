import React, { useEffect } from "react";
import { Modal } from "react-uicomp";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { isValid, useInput, validator } from "../../../../../utils";
import { Box, DefaultButton, FormInput, InputField } from "../../../../common";
import Table from "../../../../common/table/Table.common";
import { Title, Image, Bold, Paragraph } from "../../../../generics";
import { Card } from "../../../../hoc";
import { getClientMyPeopleAction } from "../../../../../actions";

export const AdminSection = () => {
  const { toast } = useAuth();
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  const { location, params, navigation } = useNavigation();
  const { navigate } = navigation;
  const { myPeopleLoading, myPeopleList, myPeopleCount } = useSelector(
    (state) => state.client,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getClientMyPeopleAction("myadmins", pageNumber));
  }, [pageNumber]);

  const { data, onChangeHandler } = useInput({
    name: "",
    email: "",
    address: "",
    phone: "",
  });

  const deleteHandler = (id) => {
    // dispatch(deleteServiceAction(id, toast, pageNumber));
  };

  const viewHandler = (id) => {
    navigate(`/users/detail/${id}`);
  };

  const editHandler = (id) => {
    // navigate(routes["View Client"].path);

    data.name = "asdas";
    data.email = "email";
    data.address = "address";
    data.phone = 4234234;

    setShow(true);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { email, name, address, phone } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("name", name.length === 0, () => {
      showMessage("Name musn't be empty!");
    });

    validate("email", email.length === 0, () => {
      showMessage("Email musn't be empty!");
    });

    validate("address", address.length === 0, () => {
      showMessage("Address musn't be empty!");
    });
    validate("phone", phone.length === 0, () => {
      showMessage("Phone musn't be empty!");
    });

    if (!isValid(catchedErrors)) {
      //   console.error(catchedErrors);
      return;
    }
  };

  return (
    <Box>
      <Card>
        <Box flexBox alCenter jSpace>
          <Bold>Admins</Bold>
          {/* <Box>
            <DefaultButton
              title="Add Admin"
              // onClick={() => {
              //   navigate(routes["Add Building"].path);
              // }}
            />
          </Box> */}
        </Box>
        <Box mt={20}>
          <Table
            columns={[
              {
                field: "profile_first_name",
                name: "First name",
              },
              {
                field: "profile_last_name",
                name: "Last name",
              },
              {
                field: "email",
                name: "email",
              },
              {
                field: "profile_address",
                name: "address",
                render: (rowData) =>
                  rowData && <Paragraph>{rowData?.value}</Paragraph>,
              },
              {
                field: "profile_phone",
                name: "phone",
                render: (rowData) =>
                  rowData && <Paragraph>{rowData?.join(", ")}</Paragraph>,
              },
              {
                field: "profile_job_title",
                name: "Job Title",
              },
              {
                field: "deactivated",
                name: "Status",
                render: (rowData) => (
                  <Paragraph>{rowData ? "InActive" : "Active"}</Paragraph>
                ),
              },
            ]}
            data={myPeopleList}
            dataLoader={myPeopleLoading}
            totalCount={myPeopleCount}
            actions
            // deleteLoader={false}
            // onDeleteHandler={deleteHandler}
            // onEditHandler={editHandler}
            onViewHandler={viewHandler}
          />
        </Box>
      </Card>
    </Box>
  );
};
