import React, { useState, useEffect } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { useDocTitle } from "../../../../hooks";
import { DefaultButton, Box, InputField, FormInput } from "../../../common";
import { Bold, Image, Paragraph, Title } from "../../../generics";
import Table from "../../../common/table/Table.common";
import { Modal } from "react-uicomp";

// IMPORT HOCS
import { CompWrapper, Card, ActivityIndicator } from "../../../hoc";

import {
  getBuildingListAction,
  deleteBuildingAction,
  addIronmongeryCategory,
  getAllIronmongeryCategory,
  deleteIronmongeryCategory,
} from "../../../../actions";
import { FILE_URL } from "../../../../config";
import { capitalizeFirstLetter, useInput } from "../../../../utils";

export const IronmongeryCategory = () => {
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;

  const [modalVisible, setModalVisible] = useState(false);
  useDocTitle("Ironmongery");

  const dispatch = useDispatch();

  const { categorylist, categoryLoader, deleteCategoryLoader } = useSelector(
    (state) => state.ironmongery,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  const { data, onChangeHandler } = useInput({ category: "" });
  useEffect(() => {
    dispatch(getAllIronmongeryCategory());
  }, []);

  const deleteHandler = (id) => {
    console.log("deldeleteHandler", id);
    dispatch(deleteIronmongeryCategory(id, toast, () => {}));
  };

  const viewHandler = (id) => {
    navigate(`/ironmongery/category/${id}`);
  };

  const addCategoryHandler = (e) => {
    const { category } = data;
    e.preventDefault();
    const body = {
      name: capitalizeFirstLetter(category),
    };
    // console.log("submitted", category);
    dispatch(
      addIronmongeryCategory(body, toast, () => {
        setModalVisible(false);
      }),
    );
  };
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Ironmongery Category</Bold>
            <Box>
              <DefaultButton
                title="Add Ironmongery Category"
                onClick={() => {
                  setModalVisible(true);
                }}
              />
            </Box>
          </Box>
          <Box mt={20}>
            <Table
              columns={[
                {
                  field: "name",
                  name: "category name",
                },
              ]}
              data={categorylist}
              dataLoader={categoryLoader}
              // totalCount={totalCount}
              actions
              deleteLoader={deleteCategoryLoader}
              onDeleteHandler={deleteHandler}
              onViewHandler={viewHandler}
            />
          </Box>
        </Card>
      </Box>
      <Modal
        visible={modalVisible}
        onOutsideClick={() => setModalVisible(false)}>
        <Box>
          <Box mb={10}>
            <Title>Add Ironmongery Category</Title>
          </Box>

          <div
            style={{ borderBottom: "1px solid #f1f1f1", marginBottom: 20 }}
          />

          <form onSubmit={addCategoryHandler}>
            <Box>
              <FormInput label="Category Name" required>
                <InputField
                  placeholder="Category Name"
                  name="category"
                  onChange={onChangeHandler("category")}
                />
              </FormInput>
            </Box>

            <ActivityIndicator animating={false}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Modal>
    </CompWrapper>
  );
};

export default IronmongeryCategory;
