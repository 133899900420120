import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";

import { useDocTitle } from "../../../../hooks/useDocTitle.hook";
import {
  capitalizeFirstLetter,
  isValid,
  useInput,
  validator,
} from "../../../../utils";

import { ActivityIndicator, Card, CompWrapper } from "../../../hoc";
import {
  Box,
  DefaultButton,
  DisplayFileName,
  FormInput,
  GooglePlaces,
  Grid,
  ImageUpload,
  InputField,
  SelectField,
  TextArea,
} from "../../../common";
import {
  addBuildingAction,
  addIronmongeryProduct,
  editIronmongeryProduct,
  getAllIronmongeryCategory,
  getIronmongeryProductDetail,
} from "../../../../actions";
import UPLOAD from "../../../../assets/icons/upload.png";
import { Image } from "../../../generics";
import { RiCloseCircleFill } from "react-icons/ri";
import { FILE_URL } from "../../../../config";

export const EditComponent = () => {
  const { toast } = useAuth();
  const { navigation, history, params } = useNavigation();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  useDocTitle("Add Ironmongery");

  const {
    categorylist,
    addProductLoader,
    productDetailLoader,
    productDetail: { category_details, product_details },
  } = useSelector((state) => state.ironmongery);

  const { data, onChangeHandler } = useInput({
    name: product_details.name,
    brand: product_details.brand,
    description: product_details.description,
    categoryId: category_details.id,
  });

  const [productImage, setProductImage] = React.useState([]);
  const [specFiles, setSpecFiles] = React.useState([]);
  const [dataFiles, setDataFiles] = React.useState([]);

  const [removedSpecFile, setRemovedSpecFile] = React.useState([]);
  const [removedDataFile, setRemovedDataFile] = React.useState([]);

  const onRemoveSpecFile = (i) => {
    const removedArray = [...removedSpecFile];
    removedArray.push(i);
    setRemovedSpecFile(removedArray);
  };

  const onRemoveDataFile = (i) => {
    const removedArray = [...removedSpecFile];
    removedArray.push(i);
    setRemovedDataFile(removedArray);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const catchedErrors = {};
    const validate = validator(catchedErrors);

    const { name, brand, categoryId, description } = data;

    const showMessage = (message) => {
      toast({ message, type: "error" });
    };
    validate("name", name.length === 0, () => {
      showMessage("Name can't be empty!");
    });

    validate("brand", brand.length === 0, () => {
      showMessage("brand can't be empty!");
    });

    validate("description", description.length === 0, () => {
      showMessage("Description can't be empty!");
    });

    let formData = new FormData();
    if (brand !== product_details.brand)
      await formData.append("brand", capitalizeFirstLetter(brand));
    if (description !== product_details.description)
      await formData.append("description", capitalizeFirstLetter(description));

    productImage.length > 0 &&
      productImage.forEach(async (element) => {
        await formData.append("imageFile", element.file);
      });

    specFiles.length > 0 &&
      specFiles.forEach(async (element) => {
        await formData.append("specFile", element.file);
      });

    dataFiles.length > 0 &&
      dataFiles.forEach(async (element) => {
        await formData.append("dataFile", element.file);
      });

    dispatch(
      editIronmongeryProduct(params.id, formData, toast, () => {
        history.goBack();
      }),
    );

    if (!isValid(catchedErrors)) {
      return;
    }
  };

  return (
    <CompWrapper>
      <Card>
        <Box>
          <form onSubmit={submitHandler}>
            <FormInput label="Name" required>
              <InputField
                placeholder="Name"
                name="name"
                value={data.name}
                onChange={onChangeHandler("name")}
                type="text"
                disabled={true}
              />
            </FormInput>

            <FormInput label="Brand" required>
              <InputField
                placeholder="Brand"
                name="brand"
                value={data.brand}
                onChange={onChangeHandler("brand")}
                type="text"
              />
            </FormInput>

            <FormInput label="Category" required>
              <SelectField
                options={categorylist}
                getOptionValue="id"
                getOptionLabel="name"
                onChangeValue={(item) => {
                  onChangeHandler("categoryId", item?.id)();
                }}
                defaultValue={categorylist.filter(
                  ({ id }) => id === Number(category_details.id),
                )}
                isOptionDisabled={(option) =>
                  option.id !== Number(category_details.id)
                }
              />
            </FormInput>

            <FormInput label="Description" required>
              <TextArea
                placeholder="Description"
                name="description"
                value={data.description}
                onChange={onChangeHandler("description")}
                type="text"
                defaultValue={product_details.description}
              />
            </FormInput>

            <FormInput label="Product Image" required>
              <ImageUpload
                onChange={setProductImage}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                accept="image/*"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={4} md={2} sm={1}>
                      {imageData.length === 0 && !!product_details.image && (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            boxShadow: "0px 2px 10px 0px #888888",
                          }}>
                          <Image
                            alt="Avatar"
                            link={`${FILE_URL}/ig/${product_details.image}`}
                            style={{
                              width: 200,
                              height: 200,
                              objectFit: "contain",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      )}

                      {imageData &&
                        imageData.map((item, index) => (
                          <div key={index}>
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                                boxShadow: "0px 2px 10px 0px #888888",
                              }}>
                              <Image
                                alt="Avatar"
                                link={item.url}
                                style={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "contain",
                                  overflow: "hidden",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  cursor: "pointer",
                                }}
                                onClick={() => onRemove(index)}>
                                <RiCloseCircleFill color="red" size={25} />
                              </div>
                            </div>
                          </div>
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>

            <FormInput label="Specifications" required>
              <ImageUpload
                onChange={setSpecFiles}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                multiple
                accept="image/*"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={4} md={2} sm={1}>
                      {product_details.spec_file.length > 0 &&
                        product_details.spec_file.map(
                          (item, index) =>
                            !removedSpecFile.includes(index) && (
                              <DisplayFileName
                                key={index}
                                label={item}
                                onClick={() => onRemoveSpecFile(index)}
                                old
                              />
                            ),
                        )}

                      {imageData &&
                        imageData.map((item, index) => (
                          <DisplayFileName
                            key={index}
                            label={item?.file?.name}
                            onClick={() => onRemove(index)}
                          />
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>

            <FormInput label="Technical data files" required>
              <ImageUpload
                onChange={setDataFiles}
                buttonStyle={{ background: "red", display: "inline-block" }}
                title="upload image"
                multiple
                accept="image/*"
                buttonclick={
                  <Box mt={20}>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px dashed #000",
                        padding: 50,
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <Image
                        alt="Avatar"
                        link={UPLOAD}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </Box>
                }>
                {({ onUploadImage, imageData, onRemove, deleteAllHandler }) => (
                  <Box>
                    <Grid.Container lg={4} md={2} sm={1}>
                      {product_details.data_file.length > 0 &&
                        product_details.data_file.map(
                          (item, index) =>
                            !removedDataFile.includes(index) && (
                              <DisplayFileName
                                key={index}
                                label={item}
                                onClick={() => onRemoveDataFile(index)}
                                old
                              />
                            ),
                        )}

                      {imageData &&
                        imageData.map((item, index) => (
                          <DisplayFileName
                            key={index}
                            label={item?.file?.name}
                            onClick={() => onRemove(index)}
                          />
                        ))}
                    </Grid.Container>
                  </Box>
                )}
              </ImageUpload>
            </FormInput>

            <ActivityIndicator animating={addProductLoader}>
              <DefaultButton title="Submit" type="submit" />
            </ActivityIndicator>
          </form>
        </Box>
      </Card>
    </CompWrapper>
  );
};

export const EditIronmongery = () => {
  const dispatch = useDispatch();
  const { params } = useNavigation();

  const { productDetail } = useSelector((state) => state.ironmongery);

  useEffect(() => {
    dispatch(getAllIronmongeryCategory());
  }, []);

  useEffect(() => {
    dispatch(getIronmongeryProductDetail(params.id));
  }, []);

  return !!productDetail ? (
    <EditComponent />
  ) : (
    <CompWrapper>Loading...</CompWrapper>
  );
};
