import React, { useEffect } from "react";
import { CompWrapper, Card } from "../../../../hoc";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  KeyValue,
  DefaultButton,
  Grid,
  PrimaryButton,
} from "../../../../common";
import { Image } from "../../../../generics";
import { ViewerSection } from "./component/viewerSection";
import { FloorSection } from "./component/floorSection";
import { getBuildingDetailAction } from "../../../../../actions";
import { FILE_URL } from "../../../../../config";

export const ViewBuildingPage = () => {
  const dispatch = useDispatch();
  const { detail, detailloading, buildingBreadcrumb } = useSelector(
    (state) => state.building,
  );
  const { navigation, params } = useNavigation();
  const { navigate, routes } = navigation;

  useEffect(() => {
    dispatch(getBuildingDetailAction(params?.id));
  }, [params?.id]);

  const editHandler = () => {
    navigate(routes["Building"].path + "/edit/" + params?.id);
  };

  return (
    <CompWrapper breadcrumbData={buildingBreadcrumb}>
      {detail && (
        <Card>
          <Box className={"contractor-wrapper"} flexBox jSpace>
            <KeyValue
              data={{
                Name: detail?.prop_name,
                Type: detail?.prop_type,
                "Construction Type": detail?.prop_construction,
                Address: detail?.prop_address?.value,
                Floors: detail?.floors,
                Stairs: detail?.stairs,
              }}
            />
            <div>
              <PrimaryButton title="Edit" onClick={editHandler} />
            </div>
          </Box>
          <Grid.Container lg={4} md={2} sm={1}>
            {detail?.prop_image?.length
              ? detail?.prop_image?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Image
                        style={{ width: "100%" }}
                        link={`${FILE_URL}/site/${item}`}
                        alt="Contractor image"
                        className="avatar"
                      />
                    </Box>
                  );
                })
              : null}
          </Grid.Container>
        </Card>
      )}
      <Box mt={20}>
        <FloorSection />
      </Box>
      <Box mt={20}>
        <ViewerSection />
      </Box>
    </CompWrapper>
  );
};
