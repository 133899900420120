import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { api, APIS } from "../../../../../config";
import { useInput } from "../../../../../utils";
import { FormInput, Grid, SelectField } from "../../../../common";

export const DoorNPart = ({
  inspectionfloorNdoor,
  doorList,
  index,
  mainIndex,
  doorIndex,
  setInspectionFloorNdoor,
  isDoorOptionDisabled,
  isPartOptionDisabled,
  inspectionPartArray,
}) => {
  const floorNdoorClone = [...inspectionfloorNdoor];
  const mainIndexedObjectClone = { ...floorNdoorClone[mainIndex] };
  const doorNpart = [...mainIndexedObjectClone["doorNpart"]];

  const [partList, setPartList] = useState([]);
  // const { data, onChangeHandler } = useInput({
  //   door: "",
  //   part: "",
  // });

  useEffect(() => {
    let mount = true;
    if (!!doorNpart[doorIndex].door && mount) {
      (async () => {
        setPartList([]);
        const res = await api(
          `${APIS.doorPart}/door/${doorNpart[doorIndex].door}`,
        );
        const { success, data: resData } = res.data;
        if (success) {
          setPartList(resData.data[0].door_part_list);
        }
      })();
    }
    return () => {
      mount = false;
    };
  }, [doorNpart[doorIndex].door]);

  const changeValue = (key, value) => {
    let newValue;
    if (typeof value === "object") {
      newValue = value?.map((item) => {
        return item.id;
      });
    } else {
      newValue = value;
    }

    // onChangeHandler(key, newValue)();

    // const doorNpartClone = [...doorNpart];
    const z = { ...doorNpart[doorIndex] };
    z[key] = newValue;
    doorNpart[doorIndex] = z;
    mainIndexedObjectClone["doorNpart"] = doorNpart;

    floorNdoorClone[mainIndex] = mainIndexedObjectClone;
    setInspectionFloorNdoor(floorNdoorClone);
  };

  const getPart = (doorId) => {
    return partList.find((item, id) => item.id == doorId);
  };

  const getDoor = () => {
    return doorList.find((item, id) => item.id == doorNpart[doorIndex].door);
  };

  const AllOption = { part_name: "Select All", id: "*" };

  // console.log("doorNpartdoorNpartdoorNpart", doorNpart);
  return (
    <Grid.Container lg={2} md={2} sm={1}>
      <FormInput
        label=" Select Door"
        required
        newElement={
          <p style={{ color: "red" }}>
            **Only doors with the parts are shown here
          </p>
        }>
        <SelectField
          options={doorList}
          getOptionLabel="door_name"
          getOptionValue="id"
          onChangeValue={(item) => {
            changeValue("door", item?.id);
          }}
          isSearchable
          placeholder="Select Door"
          value={getDoor()}
          isOptionDisabled={isDoorOptionDisabled}
        />
      </FormInput>

      <FormInput label=" Select Part" required>
        <SelectField
          isMulti
          options={
            partList !== null && partList.length > 0
              ? [AllOption, ...partList].filter(
                  ({ id }) => !inspectionPartArray?.includes(id),
                )
              : []
          }
          getOptionLabel="part_name"
          getOptionValue="id"
          onChangeValue={(item) => {
            if (
              item !== null &&
              item.length > 0 &&
              item[item.length - 1].id === AllOption.id
            ) {
              return changeValue("part", partList);
            }
            return changeValue("part", item);
          }}
          isSearchable
          placeholder="Select Part"
          value={
            doorNpart[doorIndex]?.part?.length > 0 &&
            doorNpart[doorIndex]?.part?.map((item, id) => getPart(item))
          }
          isOptionDisabled={isPartOptionDisabled}
        />
      </FormInput>
    </Grid.Container>
  );
};
