import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-auth-navigation";
import { snapTo } from "react-ui-animate";
import { CompWrapper, Card, ActivityIndicator } from "../../hoc";
import { DefaultButton, InputField } from "../../common";
import { useBreakPoints, useDisableScroll, useDocTitle } from "../../../hooks";

import { Box, KeyValue } from "../../common";
import { CurrentClientCard, PreviousClientCard } from "./components";
import { Bold, Heading } from "../../generics";
import { getDashboardSuperadmin } from "../../../actions";

// import { postFloorPlanAction } from "../../../actions";

export function DashboardPage() {
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.dashboard);
  useBreakPoints(
    {
      576: {
        color: "red",
      },
      768: {
        color: "blue",
      },
      992: {
        color: "green",
      },
      1200: {
        color: "yellow",
      },
      1400: {
        color: "black",
      },
    },
    function (value) {
      // console.log("value", value);
    },
  );

  useEffect(() => {
    dispatch(getDashboardSuperadmin());
  }, []);
  return (
    <CompWrapper>
      <Box flexBox alCenter jSpace>
        <Heading>Clients</Heading>
      </Box>
      <Box flexBox row style={{ columnGap: 20 }}>
        <CurrentClientCard />
        <PreviousClientCard />
      </Box>

      {/* <Box flexBox alCenter jSpace pl={150} p={50}>
        <Box flex={1}>Yes</Box>
        <Box flex={2}>Yes</Box>
        <Box flex={1}>Yes</Box>
      </Box>
      <Box flexBox alCenter jSpace>
        <KeyValue
          data={{
            Name: "Dipesh",
            Phonehahahahahaha: "9800000000",
            Email: "raidipesh78@gmail.com",
          }}
        />
        <KeyValue
          data={{
            Name: "Dipesh",
            Phonehahahahahaha: "9800000000",
            Email: "raidipesh78@gmail.com",
          }}
          rightAligned
        />
      </Box> */}

      {/* <p>Coming Soon</p> */}

      {/* <div>Image Pick</div>
      <Card>
        <InputField name="photo" type="file" onChange={onChangeHandler} />
        {image && (
          <div
            style={{
              width: 1230,
              height: 691.875,
              marginTop: 20,
            }}>
            <img
              src={URL.createObjectURL(image)}
              style={{ width: "100%", height: "100%" }}
              accept="image/*"
            />
          </div>
        )}
        <div style={{ marginTop: 10 }}>
          <ActivityIndicator animating={loader}>
            <DefaultButton title="Submit" onClick={() => onSubmitHandler()} />
          </ActivityIndicator>
        </div>
      </Card> */}

      {/* <button onClick={() => setDisable((prev) => !prev)}>DISABLE</button> */}

      <div
        style={{
          height: 1000,
        }}
      />
    </CompWrapper>
  );
}
