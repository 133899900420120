import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { api, APIS } from "../../../../../config";
import { useInput } from "../../../../../utils";
import { FormInput, Grid, SelectField } from "../../../../common";

export const InstallationPart = ({
  floorNdoor,
  index,
  setFloorNdoor,
  doorArray,
}) => {
  const { allFloorList } = useSelector((state) => state.floor);

  const [doorList, setDoorList] = useState([]);
  const { data, onChangeHandler } = useInput({
    floor: floorNdoor[index].floor,
    door: floorNdoor[index].door,
  });

  const changeValue = (key, value) => {
    let newValue;
    console.log("typeof value", typeof value);
    if (typeof value === "object") {
      newValue = value?.map((item) => {
        return item.id;
      });
    } else {
      newValue = value;
    }

    onChangeHandler(key, newValue)();

    const floorNdoorClone = [...floorNdoor];
    const z = { ...floorNdoorClone[index] };
    z[key] = newValue;
    floorNdoorClone[index] = z;
    setFloorNdoor(floorNdoorClone);
  };

  useEffect(() => {
    if (!!floorNdoor[index].floor) {
      (async () => {
        setDoorList([]);
        const res = await api(
          `${APIS.door}/floor/part/${floorNdoor[index].floor}`,
        );
        const { success, data: resData } = res.data;
        console.log(resData.data);
        if (success) {
          setDoorList(resData.data);
        }
      })();
    } else {
      setDoorList([]);
    }
  }, [floorNdoor[index].floor]);

  const getValue = () => {
    // console.log("floorNdoor[index].floor", floorNdoor[index].floor);
    if (!!floorNdoor[index].floor) {
      return allFloorList.find(
        (item, id) => item.id == floorNdoor[index].floor,
      );
    }
  };

  const getDoor = (doorId) => {
    return doorList.find((item, id) => item.id == doorId);
  };

  const AllOption = { door_name: "Select All", id: "*" };
  return (
    <Grid.Container lg={2} md={2} sm={1}>
      <FormInput label=" Select Floor" required>
        <SelectField
          options={allFloorList}
          getOptionLabel="floor_name"
          getOptionValue="id"
          onChangeValue={(item) => {
            changeValue("floor", item?.id);
          }}
          isSearchable
          placeholder="Select Floor"
          value={getValue()}
        />
      </FormInput>

      <FormInput
        label=" Select Door"
        required
        newElement={
          <p style={{ color: "red" }}>
            **Only doors with the parts are shown here
          </p>
        }>
        <SelectField
          isMulti
          options={
            doorList !== null && doorList.length > 0
              ? [AllOption, ...doorList].filter(
                  ({ id }) => !doorArray?.includes(id),
                )
              : []
          }
          getOptionLabel="door_name"
          getOptionValue="id"
          onChangeValue={(item) => {
            if (
              item !== null &&
              item.length > 0 &&
              item[item.length - 1].id === AllOption.id
            ) {
              return changeValue("door", doorList);
            }
            return changeValue("door", item);
          }}
          isSearchable
          placeholder="Select Door"
          value={
            floorNdoor[index]?.door?.length > 0 &&
            floorNdoor[index]?.door?.map((item, id) => getDoor(item))
          }
        />
      </FormInput>
      {/* <MdClose onClick={() => onClose(index)} /> */}
    </Grid.Container>
  );
};
