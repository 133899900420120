import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDoorPartByIdAction, editDoorPart } from "../../../../../actions";
import { Card, CompWrapper } from "../../../../hoc";
import { Box, DefaultButton } from "../../../../common";
import { Bold, Heading, Paragraph, Title } from "../../../../generics";
import { useAuth, useNavigation } from "react-auth-navigation";
import { isValid, validator } from "../../../../../utils";

const partsParser = (parts) => {
  const partsClone = [...parts];

  const modifiedParts = partsClone.map(({ details, ...rest }) => {
    const newDetails = [];

    details.forEach((val) => {
      newDetails.push({ name: val, value: "" });
    });

    return {
      details: newDetails,
      ...rest,
    };
  });

  return modifiedParts;
};

export const EditPartPage = () => {
  const { params, location, navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { toast } = useAuth();
  const dispatch = useDispatch();

  const [partsDetail, setPartsDetail] = useState(null);

  const { doorPart, editLoader, doorPartBreadcrumb } = useSelector(
    (state) => state.part,
  );
  useEffect(() => {
    dispatch(getDoorPartByIdAction(params.id));
  }, [params.id]);

  useEffect(() => {
    setPartsDetail(doorPart?.details);
  }, [doorPart?.details]);

  const detailsChangeHandler = (detailName) => {
    return function (e) {
      const partsDetailClone = [...partsDetail];

      const i = partsDetail.indexOf(
        partsDetail.find(({ name }) => name === detailName),
      );

      const detailsObject = { ...partsDetail[i] };
      detailsObject.value = e.target.value;

      partsDetailClone[i] = detailsObject;

      setPartsDetail(partsDetailClone);
    };
  };

  const onSubmit = () => {
    const body = {
      details: partsDetail,
    };
    dispatch(
      editDoorPart(params?.id, body, toast, () => {
        navigation.navigate(
          location.pathname.replace(`/editpart/${params?.id}`, ""),
        );
      }),
    );
  };

  return (
    <CompWrapper breadcrumbData={doorPartBreadcrumb}>
      <Card>
        {!!doorPart ? (
          <Box className="doordetail-container">
            <Bold>{doorPart?.part_name}</Bold>

            {partsDetail?.map(({ name, value }, i) => {
              return (
                <Box mt={20} pl={20} mb={5} key={i} flexBox alCenter>
                  <Box pb={10}>
                    <Paragraph>{name}</Paragraph>
                  </Box>

                  <Box ml={5}>
                    <input
                      type="text"
                      name={name}
                      value={value}
                      onChange={detailsChangeHandler(name)}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : null}

        <Box flexBox mt={20}>
          <DefaultButton title="Save" onClick={onSubmit} />
        </Box>
      </Card>
    </CompWrapper>
  );
};
