import {
  ADD_CLIENTS,
  CLIENTS_DETAIL,
  CLIENTS_LIST,
  DELETE_CLIENTS,
  EDIT_CLIENTS,
  CLIENTS_PEOPLE,
  CLIENTS_COMPANIES,
  CLIENTS_MY_PEOPLE,
  USER,
  CLIENTS_DASHBOARD,
  CLIENTS_REGISTER,
  PREVIOUS_CLIENTS_LIST,
  REQUESTED_CLIENTS_LIST,
  REJECTED_CLIENTS_LIST,
  TRIAL_CLIENTS_LIST,
  UPDATE_CLIENTS_REQUEST,
  UNSUBSCRIBED_CLIENTS_LIST,
} from "../actions";

const initalState = {
  listloading: false,
  list: [],
  totalCount: null,
  isLast: false,

  previousListLoading: false,
  previousList: [],
  previousListTotalCount: null,
  previousListIsLast: false,

  trialListLoading: false,
  trialList: [],
  trialListTotalCount: null,
  trialListIsLast: false,

  unsubscribedListLoading: false,
  unsubscribedList: [],
  unsubscribedListTotalCount: null,
  unsubscribedListIsLast: false,

  requestedListLoading: false,
  requestedList: [],
  requestedListTotalCount: null,
  requestedListIsLast: false,

  rejectedListLoading: false,
  rejectedList: [],
  rejectedListTotalCount: null,
  rejectedListIsLast: false,

  updateClientRequestLoading: false,

  detail: null,
  detailloading: false,

  addLoading: false,
  editLoading: false,
  deleteLoading: false,

  peopleList: [],
  peopleLoading: false,
  peopleCount: null,

  companiesList: [],
  companiesLoading: false,
  companiesCount: null,

  myPeopleList: [],
  myPeopleLoading: false,
  myPeopleCount: null,

  dashboardListloading: false,
  dashboardList: [],

  registerLoading: false,
};

export function clientReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENTS_LIST.CLEAR:
      return { ...state, listloading: true, list: [] };
    case CLIENTS_LIST.LOADING:
      return { ...state, listloading: true };
    case CLIENTS_LIST.SUCCESS:
      return {
        ...state,
        listloading: false,
        list: payload.rows,
        totalCount: payload.total,
        isLast: payload.isLast,
      };
    case CLIENTS_LIST.ERROR:
      return { ...state, listloading: false };

    case PREVIOUS_CLIENTS_LIST.CLEAR:
      return { ...state, previousListLoading: true, previousList: [] };
    case PREVIOUS_CLIENTS_LIST.LOADING:
      return { ...state, previousListLoading: true };
    case PREVIOUS_CLIENTS_LIST.SUCCESS:
      return {
        ...state,
        previousListLoading: false,
        previousList: payload.rows,
        previousListTotalCount: payload.total,
        previousListIsLast: payload.isLast,
      };
    case PREVIOUS_CLIENTS_LIST.ERROR:
      return { ...state, previousListLoading: false };

    case TRIAL_CLIENTS_LIST.CLEAR:
      return { ...state, trialListLoading: true, trialList: [] };
    case TRIAL_CLIENTS_LIST.LOADING:
      return { ...state, trialListLoading: true };
    case TRIAL_CLIENTS_LIST.SUCCESS:
      return {
        ...state,
        trialListLoading: false,
        trialList: payload.rows,
        trialListTotalCount: payload.total,
        trialListIsLast: payload.isLast,
      };
    case TRIAL_CLIENTS_LIST.ERROR:
      return { ...state, trialListLoading: false };

    case UNSUBSCRIBED_CLIENTS_LIST.CLEAR:
      return { ...state, unsubscribedListLoading: true, unsubscribedList: [] };
    case UNSUBSCRIBED_CLIENTS_LIST.LOADING:
      return { ...state, unsubscribedListLoading: true };
    case UNSUBSCRIBED_CLIENTS_LIST.SUCCESS:
      return {
        ...state,
        unsubscribedListLoading: false,
        unsubscribedList: payload.rows,
        unsubscribedListTotalCount: payload.total,
        unsubscribedListIsLast: payload.isLast,
      };
    case UNSUBSCRIBED_CLIENTS_LIST.ERROR:
      return { ...state, unsubscribedListLoading: false };

    case REQUESTED_CLIENTS_LIST.CLEAR:
      return { ...state, requestedListLoading: true, requestedList: [] };
    case REQUESTED_CLIENTS_LIST.LOADING:
      return { ...state, requestedListLoading: true };
    case REQUESTED_CLIENTS_LIST.SUCCESS:
      return {
        ...state,

        requestedListLoading: false,
        requestedList: payload.rows,
        requestedListTotalCount: payload.total,
        requestedListIsLast: payload.isLast,
      };
    case REQUESTED_CLIENTS_LIST.ERROR:
      return { ...state, requestedListLoading: false };

    case REJECTED_CLIENTS_LIST.CLEAR:
      return { ...state, rejectedListLoading: true, rejectedList: [] };
    case REJECTED_CLIENTS_LIST.LOADING:
      return { ...state, rejectedListLoading: true };
    case REJECTED_CLIENTS_LIST.SUCCESS:
      return {
        ...state,

        rejectedListLoading: false,
        rejectedList: payload.rows,
        rejectedListTotalCount: payload.total,
        rejectedListIsLast: payload.isLast,
      };
    case REJECTED_CLIENTS_LIST.ERROR:
      return { ...state, rejectedListLoading: false };

    case UPDATE_CLIENTS_REQUEST.LOADING:
      return { ...state, updateClientRequestLoading: true };
    case UPDATE_CLIENTS_REQUEST.SUCCESS:
      const requestedListClone = [...state.requestedList];
      const requestedListCloneFilter = requestedListClone.filter(
        ({ company_details }) => company_details.id !== payload,
      );

      return {
        ...state,
        updateClientRequestLoading: false,
        requestedList: [...requestedListCloneFilter],
        requestedListTotalCount: Number(state.requestedListTotalCount) - 1,
      };
    case UPDATE_CLIENTS_REQUEST.ERROR:
      return { ...state, updateClientRequestLoading: false };

    case CLIENTS_DETAIL.LOADING:
      return { ...state, detailloading: true, detail: null };
    case CLIENTS_DETAIL.SUCCESS:
      return {
        ...state,
        detailloading: false,
        detail: payload,
      };
    case CLIENTS_DETAIL.ERROR:
      return { ...state, detailloading: false };

    case ADD_CLIENTS.LOADING:
      return { ...state, addLoading: true };
    case ADD_CLIENTS.SUCCESS:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_CLIENTS.ERROR:
      return { ...state, addLoading: false };

    case EDIT_CLIENTS.LOADING:
      return { ...state, editLoading: true };
    case EDIT_CLIENTS.SUCCESS:
      return {
        ...state,
        editLoading: false,
      };
    case EDIT_CLIENTS.ERROR:
      return { ...state, editLoading: false };

    case DELETE_CLIENTS.LOADING:
      return { ...state, deleteLoading: true };
    case DELETE_CLIENTS.SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };
    case DELETE_CLIENTS.ERROR:
      return { ...state, deleteLoading: false };

    case CLIENTS_PEOPLE.LOADING:
      return { ...state, peopleLoading: true, peopleList: [] };
    case CLIENTS_PEOPLE.SUCCESS:
      return {
        ...state,
        peopleLoading: false,
        peopleList: payload.rows,
        peopleCount: payload.total,
      };
    case CLIENTS_PEOPLE.ERROR:
      return { ...state, peopleLoading: false };

    case CLIENTS_COMPANIES.LOADING:
      return { ...state, companiesLoading: true, companiesList: [] };
    case CLIENTS_COMPANIES.SUCCESS:
      return {
        ...state,
        companiesLoading: false,
        companiesList: payload.rows,
        companiesCount: payload.total,
      };
    case CLIENTS_COMPANIES.ERROR:
      return { ...state, companiesLoading: false };

    case CLIENTS_MY_PEOPLE.LOADING:
      return { ...state, myPeopleLoading: true, myPeopleList: [] };
    case CLIENTS_MY_PEOPLE.SUCCESS:
      return {
        ...state,
        myPeopleLoading: false,
        myPeopleList: payload.rows,
        myPeopleCount: payload.total,
      };
    case CLIENTS_MY_PEOPLE.ERROR:
      return { ...state, myPeopleLoading: false };

    case CLIENTS_DASHBOARD.LOADING:
      return { ...state, dashboardListloading: true, dashboardList: [] };
    case CLIENTS_DASHBOARD.SUCCESS:
      return {
        ...state,
        dashboardListloading: false,
        dashboardList: payload,
      };
    case CLIENTS_DASHBOARD.ERROR:
      return { ...state, dashboardListloading: false };

    case CLIENTS_REGISTER.LOADING:
      return { ...state, registerLoading: true };
    case CLIENTS_REGISTER.SUCCESS:
      return {
        ...state,
        registerLoading: false,
      };
    case CLIENTS_REGISTER.ERROR:
      return { ...state, registerLoading: false };

    default:
      return state;
  }
}
