import React from "react";
import { Auth } from "react-auth-navigation";
import { Box, TabAuth } from "../../../../common";
import { Bold } from "../../../../generics";
// IMPORT HOCS
import { CompWrapper, Card } from "../../../../hoc";

export const TransferRequestPage = () => {
  return (
    <CompWrapper>
      <Box>
        <Card>
          <Box flexBox alCenter jSpace>
            <Bold>Transfer Requests</Bold>
          </Box>

          <TabAuth group="Transfer Requests" />
          <Box mt={20}>
            <Auth.Screens path="/building/transfer-request" />
          </Box>
        </Card>
      </Box>
    </CompWrapper>
  );
};
