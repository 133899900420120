import {
  ADD_IRONMONGERY_PACKS,
  DELETE_IRONMONGERY_PACKS,
  EDIT_IRONMONGERY_PACKS,
  GET_IRONMONGERY_PACKS,
  GET_IRONMONGERY_PACK_DETAIL,
} from "../actions";

const initalState = {
  addPackLoader: false,
  addPackSuccess: false,
  addPackError: false,

  editPackLoader: true,
  editPackSuccess: false,
  editPackError: false,

  ironmongeryPacklist: [],
  ironmongeryPackLoader: false,
  ironmongeryPackTotalCount: null,

  ironmongeryPackDetail: null,
  ironmongeryPackDetailLoader: false,

  deletePackLoader: false,
  deletePackSuccess: true,
};

export function irommongeryPackReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_IRONMONGERY_PACKS.LOADING:
      return {
        ...state,
        addPackLoader: true,
        addPackSuccess: false,
        addPackError: false,
      };
    case ADD_IRONMONGERY_PACKS.SUCCESS: {
      return {
        ...state,
        addPackLoader: false,
        addPackSuccess: true,
        ironmongeryPacklist: [
          ...state.ironmongeryPacklist,
          {
            group_details: {
              id: payload.id,
              name: payload.name,
              description: payload.description,
            },
            total: state.ironmongeryPackTotalCount + 1,
          },
        ],
      };
    }
    case ADD_IRONMONGERY_PACKS.ERROR:
      return { ...state, addPackLoader: false, addPackError: true };

    case EDIT_IRONMONGERY_PACKS.LOADING:
      return {
        ...state,
        editPackLoader: true,
        editPackSuccess: false,
        editPackError: false,
      };
    case EDIT_IRONMONGERY_PACKS.SUCCESS: {
      return {
        ...state,
        editPackLoader: false,
        editPackSuccess: true,
      };
    }
    case EDIT_IRONMONGERY_PACKS.ERROR:
      return { ...state, editPackLoader: false, addPackError: true };

    case GET_IRONMONGERY_PACKS.LOADING:
      return {
        ...state,
        ironmongeryPackLoader: true,
      };
    case GET_IRONMONGERY_PACKS.SUCCESS:
      return {
        ...state,
        ironmongeryPackLoader: false,
        ironmongeryPacklist: payload.rows,
        ironmongeryPackTotalCount: +payload.total,
      };
    case GET_IRONMONGERY_PACKS.ERROR:
      return { ...state, ironmongeryPackLoader: false };

    case GET_IRONMONGERY_PACK_DETAIL.LOADING:
      return {
        ...state,
        ironmongeryPackDetailLoader: true,
        ironmongeryPackDetail: null,
      };
    case GET_IRONMONGERY_PACK_DETAIL.SUCCESS:
      return {
        ...state,
        ironmongeryPackDetailLoader: false,
        ironmongeryPackDetail: payload,
      };
    case GET_IRONMONGERY_PACK_DETAIL.ERROR:
      return { ...state, ironmongeryPackDetailLoader: false };

    case DELETE_IRONMONGERY_PACKS.LOADING:
      return {
        ...state,
        deletePackLoader: true,
      };
    case DELETE_IRONMONGERY_PACKS.SUCCESS: {
      const ironmongeryPacklistClone = [...state.ironmongeryPacklist].filter(
        ({ group_details }) => group_details.id !== payload,
      );
      return {
        ...state,
        deletePackLoader: false,
        deletePackSuccess: true,
        ironmongeryPackTotalCount: state.ironmongeryPackTotalCount - 1,
        ironmongeryPacklist: ironmongeryPacklistClone,
      };
    }
    case DELETE_IRONMONGERY_PACKS.ERROR:
      return { ...state, deletePackLoader: false };

    default:
      return state;
  }
}
