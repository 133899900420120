import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { Box } from "../../../../../common";
import Table from "../../../../../common/table/Table.common";
import { getClientCompaniesAction } from "../../../../../../actions";
import { Image, Paragraph } from "../../../../../generics";
import { FILE_URL } from "../../../../../../config";

export const SitesTab = () => {
  const dispatch = useDispatch();
  const { location, params } = useNavigation();
  const { companiesLoading, companiesList, companiesCount } = useSelector(
    (state) => state.client,
  );

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getClientCompaniesAction(params?.id, pageNumber));
  }, [params?.id, pageNumber]);

  return (
    <Box>
      <Table
        columns={[
          {
            name: "Photo",
            field: "prop_image",
            render: (rowData) =>
              rowData && (
                <Image
                  alt="Avatar"
                  link={`${FILE_URL}/site/${rowData[0]}`}
                  style={{ height: 60, width: 60, borderRadius: 50 }}
                />
              ),
          },
          {
            field: "prop_name",
            name: "name",
          },
          {
            field: "prop_type",
            name: "type",
          },
          {
            field: "prop_construction",
            name: "construction",
          },
          {
            field: "prop_address",
            name: "address",
            render: (rowData) =>
              rowData && <Paragraph>{rowData?.value}</Paragraph>,
          },
          {
            field: "floors",
            name: "floors",
          },
          {
            field: "stairs",
            name: "stairs",
          },
        ]}
        data={companiesList}
        dataLoader={companiesLoading}
        totalCount={companiesCount}
        // actions
        // deleteLoader={peopleLoading}
        // onDeleteHandler={deleteHandler}
        // onEditHandler={editHandler}
        // onViewHandler={viewHandler}
      />
    </Box>
  );
};
